import { Injectable } from '@angular/core';
import { getPosition } from '@app/modules/position/store/position.actions';
import { selectPositionState } from '@app/modules/position/store/position.selectors';
import { AppStateService } from '@core/services/app-state.service';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import * as GlobalActions from './global.actions';
import { selectMapVisible, selectNeedsMap } from './global.selectors';

@Injectable()
export class GlobalEffects implements OnInitEffects {
  // Dies ist nötig, weil die Actions IsMobile und ViewMap schon VOR der Effects-Initialization feuern!
  initialization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.initApp),
      withLatestFrom(this.store.select(selectMapVisible)),
      filter(([action, mapVisible]) => mapVisible),
      map(() => GlobalActions.needsMap())
    )
  );

  loadPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.activateNearDiv, GlobalActions.activateHomeDiv, GlobalActions.needsMap),
      withLatestFrom(this.store.select(selectPositionState)),
      filter(([action, posState]) => posState.position === null),
      map(([action, posState]) => getPosition())
    )
  );

  setNeedsMapForDesktop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.setIsMobile),
      withLatestFrom(this.store.select(selectNeedsMap)),
      filter(([action, needsMap]) => !action.isMobile && !needsMap),
      map(() => GlobalActions.needsMap())
    )
  );

  setNeedsMap = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.setViewMap),
      withLatestFrom(this.store.select(selectNeedsMap)),
      filter(([action, needsMap]) => action.value && !needsMap),
      map(() => GlobalActions.needsMap())
    )
  );

  scrollToTop1$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GlobalActions.activateCountriesDiv,
          GlobalActions.activateCountryDiv,
          GlobalActions.activateStateDiv,
          GlobalActions.activateCityDiv,
          GlobalActions.activateStoreDiv
        ),
        tap(() => this.appState.scrollToTop())
      ),
    { dispatch: false }
  );

  scrollToTop2$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GlobalActions.activateHomeDiv,
          GlobalActions.activateNearDiv,
          GlobalActions.activateOtherDiv,
          GlobalActions.activateSearchDiv,
          GlobalActions.activateVisibleDiv
        ),
        tap(() => this.appState.scrollToTop())
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private appState: AppStateService, private store: Store) {}

  ngrxOnInitEffects(): Action {
    return GlobalActions.initApp();
  }
}
