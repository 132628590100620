import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  selectAllVisits,
  selectAuthenticated,
  selectColours,
  selectGotos,
  selectLikes,
  selectSaveColour,
  selectSaveGoto,
  selectSaveLike,
  selectSaveVisit,
  selectVisits,
} from '@app/modules/auth/store/auth.selectors';
import {
  faHeart as faHeartSolid,
  faCheckSquare as faCheckSolid,
  faFlagCheckered,
  faSpinner,
  faClipboardList,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartEmpty, faPlusSquare, faMinusSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { GotoType, LikeType, ShopVisit, ShopVisits } from '@shared/classes';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { setHighlightStrategy } from '@app/modules/markers/store/marker.actions';
import {
  userColourStore,
  userGotoStore,
  userLikeStore,
  userLoadVisits,
  userVisitStore,
} from '@app/modules/auth/store/auth.actions';
import { first } from 'rxjs/operators';
import { HelperService } from '@core/services/helper.service';
import { DialogsService } from '@app/core/services/dialogs.service';

@Component({
  selector: 'rs-like-buttons',
  templateUrl: './like-buttons.component.html',
  styleUrls: ['./like-buttons.component.scss'],
})
export class LikeButtonsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() storeid = 0;

  likeTypes = LikeType;
  gotoTypes = GotoType;

  savingLike$ = this.store.select(selectSaveLike);
  savingGoto$ = this.store.select(selectSaveGoto);
  savingColour$ = this.store.select(selectSaveColour);
  savingVisit$ = this.store.select(selectSaveVisit);

  likes$ = this.store.select(selectLikes);
  gotos$ = this.store.select(selectGotos);
  visits$ = this.store.select(selectVisits);
  colours$ = this.store.select(selectColours);

  like$ = new BehaviorSubject<LikeType>(LikeType.none);
  goto$ = new BehaviorSubject<GotoType>(GotoType.none);
  visit$ = new BehaviorSubject<ShopVisits | null>(null);
  colour$ = new BehaviorSubject<string>('black');

  allVisits$ = this.store.select(selectAllVisits);
  sub: Subscription | undefined;
  myVisits$ = new BehaviorSubject<ShopVisit[]>([]);

  authenticated$ = this.store.select(selectAuthenticated);

  lastVisit = 'Please log in to see your visits';
  showVisits = false;

  icons = {
    faHeart: faHeartSolid,
    faHeartEmpty,
    faCheckSolid,
    faSquare,
    faPlusSquare,
    faMinusSquare,
    faFlagCheckered,
    faSpinner,
    faClipboardList,
  };

  private subvisit: Subscription | undefined;
  private sublikes: Subscription | undefined;
  private subgotos: Subscription | undefined;
  private subvisits: Subscription | undefined;
  private subcolours: Subscription | undefined;

  constructor(
    private auth: AuthService,
    private dialogs: DialogsService,
    public helper: HelperService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.subvisit = this.visit$.subscribe((x) => {
      if (x === null) {
        this.authenticated$.pipe(first()).subscribe((authenticated) => {
          this.lastVisit = authenticated ? 'Never been here before' : 'Please login to see your visits';
        });
      } else {
        const d = new Date().setHours(0, 0, 0, 0);
        const v = new Date(x.visit).setHours(0, 0, 0, 0);
        const diffTime = Math.abs(d - v); // ms
        const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays === 0) {
          this.lastVisit = 'Last visit: today';
        } else if (diffDays === 1) {
          this.lastVisit = 'Last visit: yesterday';
        } else if (diffDays < 30) {
          this.lastVisit = `Last visit: ${diffDays} days ago`;
        } else {
          const options: Intl.DateTimeFormatOptions = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          };
          this.lastVisit = 'Last visit: ' + x.visit.toLocaleDateString(undefined, options);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initStore();
  }

  ngOnDestroy(): void {
    this.subvisit?.unsubscribe();
    this.sublikes?.unsubscribe();
    this.subgotos?.unsubscribe();
    this.subvisits?.unsubscribe();
    this.subcolours?.unsubscribe();
    this.sub?.unsubscribe();
  }

  initStore() {
    this.sublikes?.unsubscribe();
    this.sublikes = this.likes$.subscribe((likes) => {
      if (this.storeid) {
        if (likes.likeslike.includes(this.storeid)) {
          this.like$.next(LikeType.like);
        } else if (likes.likesdont.includes(this.storeid)) {
          this.like$.next(LikeType.dont);
        } else {
          this.like$.next(LikeType.none);
        }
      }
    });

    this.subgotos?.unsubscribe();
    this.subgotos = this.gotos$.subscribe((gotos) => {
      if (this.storeid) {
        if (gotos.gotosgoto.includes(this.storeid)) {
          this.goto$.next(GotoType.goto);
        } else if (gotos.gotosdont.includes(this.storeid)) {
          this.goto$.next(GotoType.dont);
        } else if (gotos.gotosgone.includes(this.storeid)) {
          this.goto$.next(GotoType.gone);
        } else {
          this.goto$.next(GotoType.none);
        }
      }
    });

    this.subcolours?.unsubscribe();
    this.subcolours = this.colours$.subscribe((items) => {
      if (this.storeid) {
        const item = items.find((x) => x.shopid === this.storeid);
        if (item) {
          this.colour$.next(item.value);
        } else {
          this.colour$.next('black');
        }
      }
    });

    this.subvisits?.unsubscribe();
    this.subvisits = this.visits$.subscribe((items) => {
      if (this.storeid) {
        const item = items.find((x) => x.storeid === this.storeid);
        if (item) {
          this.visit$.next(item);
        } else {
          this.visit$.next(null);
        }
      }
    });

    this.sub?.unsubscribe();
    this.sub = this.allVisits$.subscribe((all) =>
      this.myVisits$.next(all ? all.filter((x) => x.shopid === this.storeid) : [])
    );
  }

  selectHighlightStrategy(strategy: string) {
    this.auth.openLoginDialog$().subscribe((loggedIn) => {
      if (this.storeid && loggedIn) {
        this.store.dispatch(setHighlightStrategy({ strategy }));
      }
    });
  }

  setLike(value: LikeType) {
    this.auth.openLoginDialog$().subscribe((loggedIn) => {
      if (this.storeid && loggedIn) {
        this.store.dispatch(userLikeStore({ storeid: this.storeid, value }));
      }
    });
  }

  setGoto(value: GotoType) {
    this.auth.openLoginDialog$().subscribe((loggedIn) => {
      if (this.storeid && loggedIn) {
        this.store.dispatch(userGotoStore({ storeid: this.storeid, value }));
      }
    });
  }

  setColour(value: string) {
    this.auth.openLoginDialog$().subscribe((loggedIn) => {
      if (this.storeid && loggedIn) {
        this.store.dispatch(userColourStore({ storeid: this.storeid, value }));
      }
    });
  }

  saveVisit() {
    this.auth.openLoginDialog$().subscribe((loggedIn) => {
      if (this.storeid && loggedIn) {
        this.visit$.pipe(first()).subscribe((visit) => {
          const d = new Date();
          if (this.storeid && d.toDateString() !== visit?.visit.toDateString()) {
            this.store.dispatch(userVisitStore({ storeid: this.storeid }));
            if (this.goto$.value === GotoType.goto) {
              this.dialogs
                .openConfirmDialog(
                  'Set Goto-State to Gone?',
                  'This store is on your Goto-List - shall this be changed to Gone?',
                  'Set to Gone',
                  'No'
                )
                .subscribe((x) => {
                  if (x) {
                    this.store.dispatch(
                      userGotoStore({
                        storeid: this.storeid,
                        value: GotoType.gone,
                      })
                    );
                  }
                });
            }
          }
        });
      }
    });
  }

  toggleVisits() {
    this.showVisits = !this.showVisits;
    if (this.showVisits) {
      this.store.dispatch(userLoadVisits());
    }
  }
}
