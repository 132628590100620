import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { catchError, map, concatMap, tap, filter, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as AuthActions from './auth.actions';
import { AuthService } from '../../../core/services/auth.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { filterNullish } from '@app/shared/helpers/rxjs.helpers';
import { Action, Store } from '@ngrx/store';
import { selectLoadingOrLoadedVisits } from './auth.selectors';
import { PinsService } from '@app/core/services/pins.service';
import { DataService } from '@app/core/services/data.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects implements OnInitEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.initAuthStore),
      filter(() => this.storage.get('TOKEN') !== undefined && this.storage.get('USERID') !== undefined),
      map(() => AuthActions.loginValidAction())
    )
  );

  loginValid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginValidAction),
      concatMap(() =>
        this.auth.valid().pipe(
          map((user) => AuthActions.loginActionSuccess({ user })),
          catchError((error) => of(AuthActions.loginActionFailure({ error })))
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginAction),
      concatMap((action) =>
        this.auth.login(action.email, action.password).pipe(
          map((user) => AuthActions.loginActionSuccess({ user })),
          catchError((error) => of(AuthActions.loginActionFailure({ error })))
        )
      )
    )
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerAction),
      concatMap((action) =>
        this.auth.register(action.email, action.username, action.password).pipe(
          map((user) => AuthActions.registerActionSuccess()),
          catchError((error) => of(AuthActions.registerActionFailure({ error })))
        )
      )
    )
  );

  registerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.registerActionSuccess),
        tap(() => this.auth.closeLoginDialog()),
        tap(() => this.auth.showRegisterSuccessDialog()),
        tap(() => console.log('success'))
      ),
    { dispatch: false }
  );
  registerFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.registerActionFailure),
        tap(() => this.auth.closeLoginDialog()),
        tap(() => this.auth.showRegisterFailureDialog()),
        tap(() => console.log('failure'))
      ),
    { dispatch: false }
  );

  logingoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithGoogleAction),
      concatMap((action) =>
        this.auth.loginWithGoogle(action.user).pipe(
          map((user) => AuthActions.loginActionSuccess({ user })),
          catchError((error) => of(AuthActions.loginActionFailure({ error })))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutAction),
      concatMap((action) =>
        this.auth.logout().pipe(
          map(() => AuthActions.logoutActionSuccess()),
          catchError((error) => of(AuthActions.logoutActionFailure({ error })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginActionSuccess),
        map((action) => action.user),
        filterNullish(),
        tap((user) => this.storage.set('TOKEN', user.token)),
        tap((user) => this.storage.set('USERID', user.id))
      ),
    { dispatch: false }
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutActionSuccess),
        tap((user) => this.storage.set('TOKEN', undefined)),
        tap((user) => this.storage.set('USERID', undefined))
      ),
    { dispatch: false }
  );

  closeLoginDialogAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.closeLoginDialogAction, AuthActions.closeRegisterDialogAction),
        tap(() => this.auth.closeLoginDialog())
      ),
    { dispatch: false }
  );

  saveLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userLikeStore),
      concatMap((action) =>
        this.auth.saveLike(action.storeid, action.value).pipe(
          map((result) => AuthActions.userLikeStoreSuccess(result)),
          catchError((error) =>
            of(
              AuthActions.userLikeStoreFailure({
                storeid: action.storeid,
                error,
              })
            )
          )
        )
      )
    )
  );
  saveGoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userGotoStore),
      concatMap((action) =>
        this.auth.saveGoto(action.storeid, action.value).pipe(
          map((result) => AuthActions.userGotoStoreSuccess(result)),
          catchError((error) =>
            of(
              AuthActions.userGotoStoreFailure({
                storeid: action.storeid,
                error,
              })
            )
          )
        )
      )
    )
  );
  saveVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userVisitStore),
      concatMap((action) =>
        this.auth.saveVisit(action.storeid).pipe(
          map((visit) => AuthActions.userVisitStoreSuccess({ visit })),
          catchError((error) =>
            of(
              AuthActions.userVisitStoreFailure({
                storeid: action.storeid,
                error,
              })
            )
          )
        )
      )
    )
  );
  saveColour$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userColourStore),
      concatMap((action) =>
        this.auth.saveColour(action.storeid, action.value).pipe(
          map((result) =>
            AuthActions.userColourStoreSuccess({
              storeid: result.shopid,
              value: result.value,
            })
          ),
          catchError((error) =>
            of(
              AuthActions.userColourStoreFailure({
                storeid: action.storeid,
                error,
              })
            )
          )
        )
      )
    )
  );

  removeVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userUnvisitStore),
      concatMap((action) =>
        this.auth.removeVisit(action.visit).pipe(
          map((visit) => AuthActions.userUnvisitStoreSuccess({ visit })),
          catchError((error) => of(AuthActions.userUnvisitStoreFailure({ error })))
        )
      )
    )
  );
  loadVisits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userLoadVisits),
      withLatestFrom(this.store.select(selectLoadingOrLoadedVisits)),
      filter((action, loaded) => !loaded),
      concatMap(() =>
        this.auth.getVisits().pipe(
          map((visits) => AuthActions.userLoadVisitsSuccess({ visits })),
          catchError((error) => of(AuthActions.userLoadVisitsFailure({ error })))
        )
      )
    )
  );

  savePin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userSavePin),
      concatMap((action) =>
        this.data.savePin(action.pin).pipe(
          map((newStore) =>
            AuthActions.userSavePinSuccess({
              pin: newStore,
              isNew: action.pin.id !== newStore.id,
            })
          ),
          catchError((error) => of(AuthActions.userSavePinFailure({ error })))
        )
      )
    )
  );

  savePinSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.userSavePinSuccess),
        tap((action) => this.router.navigateByUrl('/pin/' + action.pin.id.toString()))
      ),
    { dispatch: false }
  );

  removePin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userRemovePin),
      concatMap((action) =>
        this.data.removePin(action.pinid).pipe(
          map((pinid) => AuthActions.userRemovePinSuccess({ pinid })),
          catchError((error) => of(AuthActions.userRemovePinFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private data: DataService,
    private pins: PinsService,
    private router: Router,
    private storage: LocalStorageService,
    private store: Store
  ) {}

  // Dies ist nötig, weil ich zu Beginn wissen möchte, ob der User eingeloggt ist
  ngrxOnInitEffects(): Action {
    return AuthActions.initAuthStore();
  }
}
