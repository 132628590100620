<div class="rs-container" [ngClass]="(isMobile$ | async) ? 'rs-mobile' : 'rs-desktop'">
  <rs-title></rs-title>

  <div class="rs-content">
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #sidenav class="sidenav-sidenav">
        <rs-side-nav [sidenav]="sidenav"></rs-side-nav>
      </mat-sidenav>

      <mat-sidenav-content>
        <div class="full-screen-image-viewer" *ngIf="showFullScreenImages">
          <ng-image-fullscreen-view
            [images]="fullScreenImages"
            [imageIndex]="currentIndex"
            [show]="showFullScreenImages"
            [paginationShow]="true"
            (close)="closeFullScreenImages()"
          ></ng-image-fullscreen-view>
        </div>

        <div [class.opened]="opened">
          <div id="loading-div" [class.rsloading]="loading$ | async">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>

          <rs-map
            class="mapdiv"
            [@mapSize]="currentState"
            [ngClass]="{ isactive: mapVisible$ | async }"
            rsClickStopPropagation
          ></rs-map>

          <div
            *ngIf="(isMobile$ | async) === false"
            [@mapSize]="currentState"
            class="open-close-div"
            [class.opened]="opened"
            (click)="toggleOpen()"
          >
            <fa-icon *ngIf="opened" size="lg" [icon]="icons.faClose"></fa-icon>
            <fa-icon *ngIf="!opened" size="lg" [icon]="icons.faOpen"></fa-icon>
          </div>

          <div
            class="appdiv"
            id="appdiv"
            #appdiv
            [@openAppDiv]="currentState"
            [ngClass]="{ isactive: (mapVisible$ | async) === false }"
            rsClickStopPropagation
          >
            <router-outlet class="router-content"></router-outlet>
          </div>

          <rs-bottom-bar
            class="bottombar-container"
            rsClickStopPropagation
            [@openAppDiv]="currentState"
          ></rs-bottom-bar>
        </div>

        <div id="searchdummy"></div>
        <!-- wichtig für PlacesService-Suche wenn map nicht initialisiert -->
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
