<div class="rs-panel" *ngIf="visits$ | async as visits">
  <ng-container *ngIf="visits.length > 0">
    <h2>You visited stores on these days</h2>
    <p>
      <mat-form-field appearance="fill">
        <mat-label>Select a tour</mat-label>
        <mat-select (selectionChange)="showTour($event)">
          <mat-option *ngFor="let tour of tours" [value]="tour">
            <b>{{ tour.date | date: 'EEEE, MMMM d, y' }}</b> ({{ helper.n(tour.visits.length, 'store') }})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </p>

    <ng-container *ngIf="selectedTour">
      <rs-shops-list
        *ngIf="visitedShops$ | async as stores"
        [shops]="stores"
        title="{{ helper.n(stores.length, 'Store') }} on this tour"
      ></rs-shops-list>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="visits.length === 0">
    <h2>You visited no stores yet</h2>
    <p>Click the "My Visits"-Button on a store site, and then "I'm Here Now" to record your visits.</p>
  </ng-container>
</div>
