import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Prediction } from '@app/modules/search/store/search.reducer';

@Component({
  selector: 'rs-place-item',
  templateUrl: './place-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [],
})
export class PlaceItemComponent implements OnInit {
  @Input() place!: Prediction;

  constructor() {}

  ngOnInit(): void {}
}
