<div *ngIf="shops">
  <h2>
    {{ title }}
    <span class="rs-h-small">{{ subtitle }}</span>
  </h2>

  <div
    (click)="slim = !slim"
    class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-blue rs-border-bottom-lightgray"
  >
    <rs-simple-item
      *ngIf="!slim"
      [faIcon]="faList"
      iconClass="rs-home-simple-item-icon"
      title="Show Stores as condensed list"
    ></rs-simple-item>
    <rs-simple-item
      *ngIf="slim"
      [faIcon]="faImage"
      iconClass="rs-home-simple-item-icon"
      title="Show Stores with Photos and more"
    ></rs-simple-item>
  </div>
  <div
    *ngFor="let item of shops"
    (mouseenter)="mouseEnter(item)"
    (mouseleave)="mouseLeave()"
    [ngClass]="slim ? 'rs-stores-slim' : 'rs-stores-details'"
  >
    <div routerLink="/{{ item.id }}">
      <rs-shop-item [item]="item" [handleMouseOver]="false"></rs-shop-item>
      <img
        *ngIf="!slim"
        [src]="'/api/stores/' + item.id + '/image?w=500'"
        routerLink="/{{ item.id }}"
        loading="lazy"
        class="rs-full-width-img rs-pointer"
      />
    </div>
    <div>
      <rs-like-buttons *ngIf="!slim" [storeid]="item.id"></rs-like-buttons>
    </div>
  </div>
</div>
