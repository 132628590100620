import { Component, OnInit } from '@angular/core';
import { faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import { AppStateService } from '@core/services/app-state.service';
import { Store } from '@ngrx/store';
import { selectAuthenticated } from '@app/modules/auth/store/auth.selectors';

@Component({
  selector: 'rs-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit {
  authenticated$ = this.store.select(selectAuthenticated);

  faBars = faBars;
  faSearch = faSearch;

  constructor(public appState: AppStateService, public store: Store) {}

  ngOnInit(): void {}

  toggleNav() {
    if (this.appState.sideNav) {
      this.appState.sideNav.toggle();
    }
  }
}
