import { LatLng, LatLngBounds } from '@shared/classes';

export type MapZoomLevel = 'stores' | 'cities' | 'any';

export type MapPositionWhat = 'bounds' | 'position' | 'zoomTo';
export class MapPosition {
  what: MapPositionWhat = 'position';
  level: MapZoomLevel = 'any';
  pos: LatLng | null = null;
  center: LatLng | null = null;
  bounds: LatLngBounds | null = null;
  accuracy: number | null = null;
}

export const defaultMapPosition: MapPosition = {
  what: 'position',
  level: 'cities',
  accuracy: 100,
  pos: {
    lat: 53.566424346216,
    lng: 9.975651270811,
  },
  center: null,
  bounds: null,
};
