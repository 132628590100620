<div class="rs-panel">
  <section class="welcome-section p-margins">
    <p><b>Welcome to recordstores.love!</b></p>
    <p>The best place to find record stores.</p>
    <div class="rs-tiles">
      <a class="rs-tile" routerLink="/near" [class.rs-tile-disabled]="(positionError$ | async)?.permission_denied">
        <div class="rs-tile-icon">
          <fa-icon size="lg" [icon]="faNear"></fa-icon>
        </div>
        <div class="rs-tile-text">Near Me</div>
      </a>
      <div class="rs-tile" (click)="appState.gotoMap()" *ngIf="isMobile">
        <div class="rs-tile-icon">
          <fa-icon size="lg" [icon]="faMap"></fa-icon>
        </div>
        <div class="rs-tile-text">Map</div>
      </div>
      <a class="rs-tile" routerLink="/search">
        <div class="rs-tile-icon">
          <fa-icon size="lg" [icon]="faSearch"></fa-icon>
        </div>
        <div class="rs-tile-text">Search</div>
      </a>
      <a class="rs-tile" routerLink="/support" *ngIf="!isMobile">
        <div class="rs-tile-icon red">
          <fa-icon size="lg" [icon]="faSupport"></fa-icon>
        </div>
        <div class="rs-tile-text">Support</div>
      </a>
    </div>
  </section>

  <rs-pos-state></rs-pos-state>

  <div *ngIf="supportersLoaded$ | async; else loading">
    <ng-container *ngIf="supporters$ | async as supporters">
      <div *ngIf="supporters.length > 0; else random">
        <rs-supporters-list
          title="Supporters First"
          subtitle="ordered by distance"
          [supporters]="supporters"
        ></rs-supporters-list>
      </div>

      <ng-template #random>
        <div *ngIf="randomSupporters$ | async as randomSupporters">
          <rs-supporters-list
            title="Supporters First"
            subtitle="Please support the supporters"
            [supporters]="randomSupporters"
          ></rs-supporters-list>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <ng-template #loading>
    <rs-supporters-list title="Loading Supporters" subtitle="" [supporters]="[]"></rs-supporters-list>
  </ng-template>

  <ng-container *ngIf="stores$ | async as stores">
    <div *ngIf="stores.length > 0">
      <rs-shops-list
        [shops]="stores"
        title="{{ helper.n(stores.length, 'Nearest Store') }}"
        subtitle="Click 'Near Me' for more"
      ></rs-shops-list>

      <div
        class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-border-bottom-lightgray rs-blue"
        routerLink="/near"
      >
        <rs-simple-item
          [faIcon]="faNear"
          iconClass="rs-home-simple-item-icon"
          title="More stores near you"
        ></rs-simple-item>
      </div>
      <div
        class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-blue rs-border-bottom-lightgray"
        routerLink="/countries"
      >
        <rs-simple-item
          [faIcon]="faFlag"
          iconClass="rs-home-simple-item-icon"
          title="All Stores by Country"
        ></rs-simple-item>
      </div>
      <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-blue" routerLink="/search">
        <rs-simple-item
          [faIcon]="faSearch"
          iconClass="rs-home-simple-item-icon"
          title="Search Record Stores"
        ></rs-simple-item>
      </div>
    </div>
    <div *ngIf="stores.length === 0">
      <h2 *ngIf="(positionError$ | async) === null; else nopos">No stores near you.</h2>
      <ng-template #nopos>
        <h2>Unable to load your position</h2>
      </ng-template>

      <div
        class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-blue rs-border-bottom-lightgray"
        routerLink="/countries"
      >
        <rs-simple-item
          [faIcon]="faFlag"
          iconClass="rs-home-simple-item-icon"
          title="All Stores by Country"
        ></rs-simple-item>
      </div>
      <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-blue" routerLink="/search">
        <rs-simple-item
          [faIcon]="faSearch"
          iconClass="rs-home-simple-item-icon"
          title="Search Record Stores"
        ></rs-simple-item>
      </div>
    </div>
  </ng-container>

  <rs-pins-list
    *ngIf="pins$ | async as pins"
    [expanded]="true"
    [pins]="pins"
    title="My {{ helper.n(pins.length, 'Nearest Pin') }}"
  ></rs-pins-list>

  <ng-container *ngIf="cities$ | async as cities">
    <div *ngIf="cities.length > 0">
      <h2>Selected Cities Near You</h2>

      <div>
        <a routerLink="/{{ helper.city2url(city.city) }}" *ngFor="let city of cities" class="rs-panel-row-link">
          <rs-city-item [city]="city"></rs-city-item>
        </a>
      </div>
    </div>
  </ng-container>

  <div>
    <h2>About recordstores.love</h2>

    <div>
      <p>
        This is the place where you can search all record stores worldwide. We are here since 2012. If you have any
        questions, hints or ideas, know about changes, new stores, moving stores etc, please send an email to
        <a href="mailto:support@recordstores.love">support@recordstores.love</a>.
      </p>

      <a
        class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-border-bottom-lightgray rs-border-top-lightgray rs-blue"
        href="https://www.instagram.com/recordstores.love"
        target="_blank"
        rel="”noopener”"
      >
        <rs-simple-item
          icon="/assets/markers/instagram.ico"
          iconClass="rs-home-simple-item-icon"
          title="@recordstores.love"
        ></rs-simple-item>
      </a>

      <a
        class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-border-bottom-lightgray rs-blue"
        href="https://www.facebook.com/recordstores.love"
        target="_blank"
        rel="”noopener”"
      >
        <rs-simple-item
          icon="/assets/markers/facebook_neu.ico"
          iconClass="rs-home-simple-item-icon"
          title="@recordstores.love"
        ></rs-simple-item>
      </a>

      <div
        class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-border-bottom-lightgray rs-blue"
        routerLink="/donate"
      >
        <rs-simple-item [faIcon]="faSupport" iconClass="rs-home-simple-item-icon red" title="Donate"></rs-simple-item>
      </div>

      <p>
        <a mat-flat-button (click)="dialogsService.openContactDialog('home', 'Contact')" class="rs-button">Contact</a>
      </p>
    </div>
  </div>

  <rs-sharing
    [url]="'https://recordstores.love/'"
    [name]="'recordstores.love'"
    [descript]="'recordstores.love - the record store map'"
  ></rs-sharing>
</div>
