<div class="rs-panel-row rs-clickable noselect" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
  <div>
    <img *ngIf="icon" src="{{ icon }}" class="imgfloat" />
    {{ title }}
    <span class="distance rs-h-small" *ngIf="distance && distance >= 0" [class.near]="distance && distance <= 2">
      | {{ distance | number: '1.1-1' }} km {{ direction }}
    </span>
    <span class="distance rs-h-small" *ngIf="distance && distance < 0.0"> | you are here </span>
  </div>
  <div class="rs-push rs-badge">{{ badge }}</div>
</div>
