import { OpenNowType, ShopBasics, ShopTimesHelper, User } from '@shared/classes';
import { MarkerColor } from '@app/shared/helpers/marker-definitions';

export interface HighlightStrategy {
  id: string;
  description: string;
  userRelated: boolean;
  updateOnMedium: boolean;
  getColor(store: ShopBasics, user: User | null): MarkerColor;
}

class HighlightNoneStrategy implements HighlightStrategy {
  readonly id = 'none';
  readonly description = 'No Highlighting';
  readonly userRelated = false;
  readonly updateOnMedium = false;

  getColor(store: ShopBasics, user: User | null) {
    return store.hasclosed ? MarkerColor.closed : MarkerColor.black;
  }
}

class HighlightSupportersStrategy implements HighlightStrategy {
  readonly id = 'supporters';
  readonly description = 'Supporters (default)';
  readonly userRelated = false;
  readonly updateOnMedium = false;

  getColor(store: ShopBasics, user: User | null) {
    if (store.hasclosed) {
      return MarkerColor.closed;
    } else if (store.supporter) {
      return MarkerColor.red;
    } else {
      return MarkerColor.black;
    }
  }
}

class HighlightNoPhotosStrategy implements HighlightStrategy {
  readonly id = 'photos';
  readonly description = 'Stores without Photo';
  readonly userRelated = false;
  readonly updateOnMedium = false;

  getColor(store: ShopBasics, user: User | null) {
    if (store.hasclosed) {
      return MarkerColor.closed;
    } else if (store.imageid === null) {
      return MarkerColor.red;
    } else {
      return MarkerColor.black;
    }
  }
}

class HighlightOpenNowStrategy implements HighlightStrategy {
  readonly id = 'opennow';
  readonly description = 'Stores open now';
  readonly userRelated = false;
  readonly updateOnMedium = true;

  getColor(store: ShopBasics, user: User | null) {
    if (store.hasclosed) {
      return MarkerColor.closed;
    } else if (store.isMedium) {
      switch (ShopTimesHelper.getOpenNow(store.today, store.todayfrom, store.todayuntil, store.todayclosed)) {
        case OpenNowType.closed:
          return MarkerColor.gray;
        case OpenNowType.opennow:
          return MarkerColor.green;
        case OpenNowType.opensoon:
          return MarkerColor.orange;
        case OpenNowType.wasopen:
          return MarkerColor.gray;
        default:
          return MarkerColor.black;
      }
    } else {
      return MarkerColor.black;
    }
  }
}

class HighlightPersonalColoursStrategy implements HighlightStrategy {
  readonly id = 'mycolours';
  readonly description = 'My Colours';
  readonly userRelated = true;
  readonly updateOnMedium = false;

  getColor(store: ShopBasics, user: User | null) {
    if (store.hasclosed) {
      return MarkerColor.closed;
    } else if (user === null) {
      // highlight Supporters Strategy
      if (store.hasclosed) {
        return MarkerColor.closed;
      } else if (store.supporter) {
        return MarkerColor.red;
      } else {
        return MarkerColor.black;
      }
    } else {
      const colour = user.colours.find((x) => x.shopid === store.id);
      if (colour) {
        switch (colour.value) {
          case 'green':
            return MarkerColor.green;
          case 'red':
            return MarkerColor.red;
          case 'blue':
            return MarkerColor.blue;
          case 'gray':
            return MarkerColor.gray;
          case 'orange':
            return MarkerColor.orange;
          case 'violet':
            return MarkerColor.violet;
          case 'yellow':
            return MarkerColor.yellow;
          default:
            return MarkerColor.black;
        }
      } else {
        return MarkerColor.black;
      }
    }
  }
}

class HighlightLikesStrategy implements HighlightStrategy {
  readonly id = 'likes';
  readonly description = 'My Likes';
  readonly userRelated = true;
  readonly updateOnMedium = false;

  getColor(store: ShopBasics, user: User | null) {
    if (store.hasclosed) {
      return MarkerColor.closed;
    }
    if (user === null) {
      // highlight Supporters Strategy
      if (store.supporter) {
        return MarkerColor.red;
      } else {
        return MarkerColor.black;
      }
    } else {
      if (user.likeslike.includes(store.id)) {
        return MarkerColor.red;
      } else if (user.likesdont.includes(store.id)) {
        return MarkerColor.gray;
      } else {
        return MarkerColor.black;
      }
    }
  }
}

class HighlightGotosStrategy implements HighlightStrategy {
  readonly id = 'gotos';
  readonly description = 'My Gotos';
  readonly userRelated = true;
  readonly updateOnMedium = false;

  getColor(store: ShopBasics, user: User | null) {
    if (store.hasclosed) {
      return MarkerColor.closed;
    }
    if (user === null) {
      // highlight Supporters Strategy
      if (store.supporter) {
        return MarkerColor.red;
      } else {
        return MarkerColor.black;
      }
    } else {
      if (user.gotosgoto.includes(store.id)) {
        return MarkerColor.red;
      } else if (user.gotosgone.includes(store.id)) {
        return MarkerColor.green;
      } else if (user.gotosdont.includes(store.id)) {
        return MarkerColor.gray;
      } else {
        return MarkerColor.black;
      }
    }
  }
}

class HighlightTogoXHoursStrategy implements HighlightStrategy {
  readonly id = 'togoxhours';
  readonly description = 'Togo Stores x Hours';
  readonly userRelated = true;
  readonly updateOnMedium = true;

  getColor(store: ShopBasics, user: User | null) {
    if (store.hasclosed) {
      return MarkerColor.closed;
    }
    if (user === null) {
      // highlight Supporters Strategy
      if (store.supporter) {
        return MarkerColor.red;
      } else {
        return MarkerColor.black;
      }
    } else {
      if (user.gotosgoto.includes(store.id)) {
        if (store.isMedium) {
          switch (ShopTimesHelper.getOpenNow(store.today, store.todayfrom, store.todayuntil, store.todayclosed)) {
            case OpenNowType.closed:
              return MarkerColor.black;
            case OpenNowType.opennow:
              return MarkerColor.green;
            case OpenNowType.opensoon:
              return MarkerColor.orange;
            case OpenNowType.wasopen:
              return MarkerColor.black;
            default:
              return MarkerColor.black;
          }
        } else {
          return MarkerColor.black;
        }
      } else {
        return MarkerColor.gray;
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const HighlightStrategies: HighlightStrategy[] = [
  new HighlightNoneStrategy(),
  new HighlightSupportersStrategy(),
  new HighlightNoPhotosStrategy(),
  new HighlightOpenNowStrategy(),
  new HighlightLikesStrategy(),
  new HighlightGotosStrategy(),
  new HighlightTogoXHoursStrategy(),
  new HighlightPersonalColoursStrategy(),
];
export const defaultHighlightStrategy = HighlightStrategies[1];
