export interface ShopAddress {
  name: string;
  address: string;
  addinfo: string | null;
  descript: string | null;
}

export interface ShopAddressRaw {
  name: string;
  address: string;
  addinfo: string | null;
  descript: string | null;
}

export class ShopAddressFactory {
  static new(): ShopAddress {
    return {
      name: '',
      address: '',
      addinfo: '',
      descript: '',
    };
  }
  static fromRaw(raw: ShopAddressRaw): ShopAddress {
    return {
      // nicht ...raw um überflüssige Felder nicht mitzunehmen
      name: raw.name,
      address: raw.address,
      addinfo: raw.addinfo,
      descript: raw.descript,
    };
  }
}
