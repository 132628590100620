<form [formGroup]="form" (ngSubmit)="submit()">
  <p>
    <mat-dialog-content>
      <div class="mt-3 rs-times-row">
        <mat-checkbox
          class="wleft mt-3"
          id="times_mon_open"
          (change)="changeCheckbox($event, 'mon')"
          color="primary"
          formControlName="times_mon_open"
          >Monday</mat-checkbox
        >
        <div class="rs-times-row-inner">
          <mat-form-field class="wleft mt-3">
            <input matInput type="time" formControlName="times_mon_from" placeholder="Open time" autofocus />
            <mat-error *ngIf="form.get('times_mon_from')?.invalid && form.get('times_mon_from')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
          <mat-form-field class="wleft mt-3">
            <input matInput type="time" formControlName="times_mon_until" placeholder="Close time" />
            <mat-error *ngIf="form.get('times_mon_until')?.invalid && form.get('times_mon_until')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="mt-3 rs-times-row">
        <mat-checkbox
          class="wleft mt-3"
          id="times_tue_open"
          (change)="changeCheckbox($event, 'tue')"
          color="primary"
          formControlName="times_tue_open"
          >Tuesday</mat-checkbox
        >
        <div class="rs-times-row-inner">
          <mat-form-field class="wleft mt-3">
            <input matInput type="time" formControlName="times_tue_from" placeholder="Open time" />
            <mat-error *ngIf="form.get('times_tue_from')?.invalid && form.get('times_tue_from')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
          <mat-form-field class="wleft mt-3">
            <input matInput type="time" formControlName="times_tue_until" placeholder="Close time" />
            <mat-error *ngIf="form.get('times_tue_until')?.invalid && form.get('times_tue_until')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="mt-3 rs-times-row">
        <mat-checkbox
          class="block"
          id="times_wed_open"
          (change)="changeCheckbox($event, 'wed')"
          color="primary"
          formControlName="times_wed_open"
          >Wednesday</mat-checkbox
        >
        <div class="rs-times-row-inner">
          <mat-form-field class="wleft mt-3">
            <input matInput type="time" formControlName="times_wed_from" placeholder="Open time" />
            <mat-error *ngIf="form.get('times_wed_from')?.invalid && form.get('times_wed_from')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
          <mat-form-field class="wright mt-3">
            <input matInput type="time" formControlName="times_wed_until" placeholder="Close time" />
            <mat-error *ngIf="form.get('times_wed_until')?.invalid && form.get('times_wed_until')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="mt-3 rs-times-row">
        <mat-checkbox
          class="block"
          id="times_thu_open"
          (change)="changeCheckbox($event, 'thu')"
          color="primary"
          formControlName="times_thu_open"
          >Thursday</mat-checkbox
        >
        <div class="rs-times-row-inner">
          <mat-form-field class="wleft mt-3">
            <input matInput type="time" formControlName="times_thu_from" placeholder="Open time" />
            <mat-error *ngIf="form.get('times_thu_from')?.invalid && form.get('times_thu_from')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
          <mat-form-field class="wright mt-3">
            <input matInput type="time" formControlName="times_thu_until" placeholder="Close time" />
            <mat-error *ngIf="form.get('times_thu_until')?.invalid && form.get('times_thu_until')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="mt-3 rs-times-row">
        <mat-checkbox
          class="block"
          id="times_fri_open"
          (change)="changeCheckbox($event, 'fri')"
          color="primary"
          formControlName="times_fri_open"
          >Friday</mat-checkbox
        >
        <div class="rs-times-row-inner">
          <mat-form-field class="wleft mt-3">
            <input matInput type="time" formControlName="times_fri_from" placeholder="Open time" />
            <mat-error *ngIf="form.get('times_fri_from')?.invalid && form.get('times_fri_from')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
          <mat-form-field class="wright mt-3">
            <input matInput type="time" formControlName="times_fri_until" placeholder="Close time" />
            <mat-error *ngIf="form.get('times_fri_until')?.invalid && form.get('times_fri_until')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="mt-3 rs-times-row">
        <mat-checkbox
          class="block"
          id="times_sat_open"
          (change)="changeCheckbox($event, 'sat')"
          color="primary"
          formControlName="times_sat_open"
          >Saturday</mat-checkbox
        >
        <div class="rs-times-row-inner">
          <mat-form-field class="wleft mt-3">
            <input matInput type="time" formControlName="times_sat_from" placeholder="Open time" />
            <mat-error *ngIf="form.get('times_sat_from')?.invalid && form.get('times_sat_from')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
          <mat-form-field class="wright mt-3">
            <input matInput type="time" formControlName="times_sat_until" placeholder="Close time" />
            <mat-error *ngIf="form.get('times_sat_until')?.invalid && form.get('times_sat_until')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="mt-3 rs-times-row">
        <mat-checkbox
          class="block"
          id="times_sun_open"
          (change)="changeCheckbox($event, 'sun')"
          color="primary"
          formControlName="times_sun_open"
          >Sunday</mat-checkbox
        >
        <div class="rs-times-row-inner">
          <mat-form-field class="wleft mt-3">
            <input matInput type="time" formControlName="times_sun_from" placeholder="Open time" />
            <mat-error *ngIf="form.get('times_sun_from')?.invalid && form.get('times_sun_from')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
          <mat-form-field class="wright mt-3">
            <input matInput type="time" formControlName="times_sun_until" placeholder="Close time" />
            <mat-error *ngIf="form.get('times_sun_until')?.invalid && form.get('times_sun_until')?.touched"
              >Please enter a time</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <mat-form-field class="block mt-3">
        <input matInput type="text" formControlName="times" placeholder="Additional information (like appointments)" />
        <mat-error *ngIf="form.get('times')?.invalid && form.get('times')?.touched"
          >Please enter an additional infor about the hours</mat-error
        >
      </mat-form-field>

      <mat-form-field *ngIf="withNote" class="block mt-3">
        <textarea matInput formControlName="note" placeholder="Submission Notes (only for admins)"> </textarea>
        <mat-error *ngIf="form.get('note')?.invalid && form.get('note')?.touched">Please enter a short note</mat-error>
      </mat-form-field>

      <div style="text-align: right" class="mt-3">
        <input type="button" value="Discard" class="mat-button rs-alt-button" (click)="close()" />
        <input
          type="submit"
          value="Save Changes"
          class="mat-flat-button rs-button"
          [class.disabled]="!form.valid"
          [disabled]="!form.valid"
        />
      </div>
    </mat-dialog-content>
  </p>
</form>
