import { createReducer, on } from '@ngrx/store';
import { GeolocationPositionError, LatLng, LatLngPos } from '@shared/classes';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import * as PositionActions from './position.actions';

export const positionFeatureKey = 'position';

export interface PositionState {
  position: LatLngPos | null;
  positionError: GeolocationPositionError | null;
  loading: boolean;
  loaded: boolean; // Gibt nur wieder, dass schon versucht wurde, eine Pos zu laden, egal ob erfolgreich oder nicht.
}

export const initialState: PositionState = {
  position: null,
  positionError: null,
  loading: false,
  loaded: false,
};

export const reducer = createReducer(
  initialState,

  on(PositionActions.getPosition, (state) => ({ ...state, loading: true })),
  on(PositionActions.getPositionSuccess, (state, action) => ({
    ...state,
    position: action.position,
    positionError: null,
    loading: false,
    loaded: true,
  })),
  on(PositionActions.getPositionFailure, (state, action) => ({
    ...state,
    positionError: action.error,
    loading: false,
    loaded: true,
  }))
);
