<div class="rs-panel">
  <p class="rs-black">This is a result from Google Maps. Is this place a record store?</p>
  <p>
    <span mat-button [matMenuTriggerFor]="addmenu">
      <a mat-flat-button class="rs-button" style="margin-right: 0">Add Store Or Pin</a>
    </span>
    <mat-menu #addmenu="matMenu">
      <button mat-menu-item (click)="addPlaceAsStore()">Add a Record Store</button>
      <button mat-menu-item (click)="addPlaceAsPin()">Add a Private Pin</button>
    </mat-menu>
    <a mat-flat-button (click)="showOnMap()" class="rs-alt-button" *ngIf="mobile$ | async">Show On Map</a>
  </p>
</div>
