import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  ContactDialogComponent,
  ContactDialogOptions,
  MessageDialogComponent,
  MessageDialogOptions,
} from '@modules/dialogs';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(public dialog: MatDialog) {}

  openContactDialog(from: string, title: string): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.minWidth = '300px';
    dialogConfig.maxWidth = '500px';

    const data = new ContactDialogOptions(from);
    data.settings.title = title;

    dialogConfig.data = data;

    const dialogRef = this.dialog.open(ContactDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openMessageDialog(title, 'Thank you for your message.');
      }
    });
  }

  openMessageDialog(title: string, message: string, button: string = 'OK'): void {
    const config = new MatDialogConfig();

    config.minWidth = '300px';
    config.maxWidth = '500px';

    const data = new MessageDialogOptions(title, message, button);

    config.data = data;

    this.dialog.open(MessageDialogComponent, config);
  }

  openConfirmDialog(title: string, message: string, submit = 'OK', cancel = 'Cancel'): Observable<boolean> {
    const config = new MatDialogConfig();

    config.minWidth = '300px';
    config.maxWidth = '500px';

    const data = new MessageDialogOptions(title, message, submit, cancel);

    config.data = data;

    const dialogRef = this.dialog.open(MessageDialogComponent, config);

    return dialogRef.afterClosed();
  }
}
