import { createReducer, on } from '@ngrx/store';
import { ShopBasics, ShopDetails } from '@shared/classes';
import * as ShopActions from './shop.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UpdateNum } from '@ngrx/entity/src/models';
import { searchBackendSuccess } from '@app/modules/search/store/search.actions';
import { userLoadVisitsSuccess } from '@app/modules/auth/store/auth.actions';
import { ImageDetails } from '@app/shared/classes/image-details';

export const shopFeatureKey = 'shop';

export interface ShopsState extends EntityState<ShopBasics> {
  activeStoreID: number;
  detailedStore: ShopDetails | null;

  randomNumbers: number[];

  loadingMediumIDs: number[];

  loadingSupporters: boolean;
  loadedSupporters: boolean;

  loadingStoresSlice: boolean;
  loadingStoresByPlaceID: boolean;
  loadingStore: boolean;
  savingStore: boolean;

  loadedLngs: number[];

  nearPlaces: google.maps.places.PlaceResult[];
  placeAsShop: ShopDetails | null;
}

export const adapter: EntityAdapter<ShopBasics> = createEntityAdapter<ShopBasics>();

export const initialState: ShopsState = adapter.getInitialState({
  activeStoreID: -1,
  detailedStore: null,

  randomNumbers: [],

  loadingMediumIDs: [],

  loadingSupporters: false,
  loadedSupporters: false,

  loadingStoresSlice: false,
  loadingStoresByPlaceID: false,
  loadingStore: false,
  savingStore: false,

  loadedLngs: [],

  nearPlaces: [],
  placeAsShop: null,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const reducer = createReducer(
  initialState,

  on(ShopActions.initApp, (state) => {
    // Feste Reihenfolge von Zufallszahlen, damit beim Page-Update die Supporter nicht immer wieder neu gemischt werden.
    // Die Anzahl der Zufallszahlen sollte >= der Anzahl der aktiven Supporter sein, da sonst manche eher nicht vorkommen.
    const randoms: number[] = [];
    while (randoms.length < 100) {
      const r = Math.trunc(Math.random() * 100);
      if (!randoms.includes(r)) {
        randoms.push(r);
      }
    }
    return { ...state, randomNumbers: randoms };
  }),

  on(ShopActions.loadSupporters, (state) => ({
    ...state,
    loadingSupporters: true,
  })),
  on(ShopActions.loadSupportersFailure, (state) => ({
    ...state,
    loadingSupporters: false,
  })),
  on(ShopActions.loadSupportersSuccess, (state, action) =>
    adapter.upsertMany(action.stores, {
      ...state,
      loadingSupporters: false,
      loadedSupporters: true,
    })
  ),

  on(ShopActions.loadStoresSlice, (state, action) => ({
    ...state,
    loadingStoresSlice: true,
    loadedLngs: [...state.loadedLngs, action.lng],
  })),
  on(ShopActions.loadStoresSliceFailure, (state, action) => ({
    ...state,
    loadingStoresSlice: false,
    loadedLngs: [...state.loadedLngs.filter((i) => i !== action.lng)],
  })),
  on(ShopActions.loadStoresSliceSuccess, (state, action) =>
    adapter.upsertMany(action.stores, {
      ...state,
      loadingStoresSlice: false,
    })
  ),

  on(ShopActions.loadAllStores, (state, action) => {
    const lngs: number[] = [];
    for (let i = -180; i < 180; i++) {
      lngs.push(i);
    }
    return { ...state, loadingStoresSlice: true, loadedLngs: lngs };
  }),
  on(ShopActions.loadAllStoresFailure, (state, action) => ({
    ...state,
    loadingStoresSlice: false,
  })),
  on(ShopActions.loadAllStoresSuccess, (state, action) =>
    adapter.upsertMany(action.stores, {
      ...state,
      loadingStoresSlice: false,
    })
  ),

  // Mit den Visits werden Stores geladen, die hier eingefügt werden sollen.
  on(userLoadVisitsSuccess, (state, action) => adapter.upsertMany(action.visits.shops, { ...state })),

  on(ShopActions.loadMediumStores, (state, action) => ({
    ...state,
    loadingMediumIDs: [
      ...state.loadingMediumIDs,
      ...action.stores.map((x) => x.id).filter((value, index, self) => self.indexOf(value) === index), // distinct values
    ],
    loadingMediumStores: true,
  })),
  on(ShopActions.loadMediumStoresFailure, (state) => ({
    ...state,
    loadingMediumStores: false,
  })),
  on(ShopActions.loadMediumStoresSuccess, (state, action) => {
    const updates: UpdateNum<ShopBasics>[] = action.stores.map((m) => ({
      id: m.id,
      changes: { ...m, isMedium: true },
    }));
    const loading = [...state.loadingMediumIDs].filter((value, index, self) => self.indexOf(value) === index);
    action.stores.map((m) => {
      loading.splice(loading.indexOf(m.id), 1);
    });
    return adapter.updateMany(updates, {
      ...state,
      loadingMediumIDs: loading,
    });
  }),

  on(ShopActions.loadStoresByPlaceId, (state) => ({
    ...state,
    loadingStoresByPlaceID: true,
  })),
  on(ShopActions.loadStoresByPlaceIdError, (state) => ({
    ...state,
    loadingStoresByPlaceID: false,
  })),
  on(ShopActions.loadStoresByPlaceIdSuccess, (state, action) =>
    adapter.upsertMany(action.stores, {
      ...state,
      loadingStoresByPlaceID: false,
    })
  ),

  on(ShopActions.addStore, (state, action) => adapter.addOne(action.store, state)),

  on(ShopActions.loadStore, (state) => ({ ...state, loadingStore: true })),
  on(ShopActions.loadStoreFailure, (state) => ({
    ...state,
    loadingStore: false,
  })),
  on(ShopActions.loadStoreSuccess, (state, action) => ({
    ...state,
    detailedStore: action.store,
    loadingStore: false,
  })),

  on(ShopActions.setDetailedStore, (state, action) => ({
    ...state,
    detailedStore: action.store,
    activeStoreID: action.store.id,
    nearPlaces: [],
    placeAsShop: null,
  })),
  on(ShopActions.setActiveStoreID, (state, action) => ({
    ...state,
    activeStoreID: action.id,
  })),

  on(ShopActions.saveStore, (state) => ({ ...state, savingStore: true })),
  on(ShopActions.saveStoreFailure, (state) => ({
    ...state,
    savingStore: false,
  })),
  on(ShopActions.saveStoreSuccess, (state, action) => ({
    ...state,
    savingStore: false,
    detailedStore: action.store,
  })),

  on(ShopActions.updateImage, (state) => ({ ...state, savingStore: true })),
  on(ShopActions.updateImageFailure, (state) => ({
    ...state,
    savingStore: false,
  })),
  on(ShopActions.updateImageSuccess, (state, action) => {
    let shop = state.detailedStore;
    if (shop) {
      const adminimages: ImageDetails[] = [];
      shop.adminimages.forEach((x) => {
        adminimages.push(x.id === action.image.id ? action.image : x);
      });
      shop = { ...shop, adminimages };
    }
    return {
      ...state,
      savingStore: false,
      detailedStore: shop,
    };
  }),

  on(searchBackendSuccess, (state, action) =>
    adapter.upsertMany(
      [...(action.result.stores ?? []), ...(action.result.stores_exact ?? []), ...(action.result.stores_match ?? [])],
      {
        ...state,
      }
    )
  ),

  on(ShopActions.setNearPlaces, (state, action) => ({
    ...state,
    nearPlaces: action.places,
  })),
  on(ShopActions.setPlaceAsShop, (state, action) => ({
    ...state,
    placeAsShop: action.placeAsShop,
  }))
);
