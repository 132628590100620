import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGlobal from './global.reducer';

export const selectGlobalState = createFeatureSelector<fromGlobal.GlobalState>(fromGlobal.globalFeatureKey);

export const selectActiveDiv = createSelector(selectGlobalState, (state) => state.activeDiv);

export const selectNeedsMap = createSelector(selectGlobalState, (state) => state.needsMap);

const selectViewMap = createSelector(selectGlobalState, (state) => state.viewMap);

export const selectIsMobile = createSelector(selectGlobalState, (state) => state.isMobile);

export const selectMapVisible = createSelector(
  selectViewMap,
  selectIsMobile,
  (viewMap, isMobile) => viewMap || !isMobile
);
