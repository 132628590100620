<h2 mat-dialog-title>Sign up for recordstores.love</h2>

<mat-dialog-content>
  <div class="rs-store-form rs-form mb-3">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <mat-form-field>
        <input
          matInput
          type="text"
          formControlName="username"
          placeholder="Your Name"
          class="rs-bold"
          autofocus
          autocomplete="off"
        />
        <mat-error *ngIf="form.get('username')?.invalid && form.get('username')?.touched">
          Please enter your User Name
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="email" formControlName="email" placeholder="Your Email" class="rs-bold" />
        <mat-error *ngIf="form.get('email')?.invalid && form.get('email')?.touched">Please enter your Email</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="password" formControlName="password" placeholder="Your Password" />
        <mat-error *ngIf="form.get('password')?.invalid && form.get('password')?.touched"
          >Please enter your Password</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <input matInput type="password" formControlName="password2" placeholder="Confirm Your Password" />
        <mat-error *ngIf="form.get('password2')?.invalid && form.get('password2')?.touched"
          >Please confirm your Password</mat-error
        >
      </mat-form-field>

      <div *ngIf="form.get('email')?.status === 'PENDING' || form.get('username')?.status === 'PENDING'">
        Checking...
      </div>

      <div *ngIf="form.get('username')?.errors && form.get('username')?.errors?.usernameTaken" class="red mb-3">
        This user name is already taken!
      </div>

      <div *ngIf="form.get('email')?.errors && form.get('email')?.errors?.emailTaken" class="red mb-3">
        This email is already taken!
      </div>

      <div style="text-align: right">
        <a style="visibility: hidden" mat-flat-button class="rs-button">Contact</a>
        <input type="button" value="Cancel" class="mat-button rs-alt-button" (click)="close()" />
        <input
          type="submit"
          value="Sign Up"
          class="mat-flat-button rs-button mr-3"
          [class.disabled]="!form.valid || (authenticating$ | async)"
          [disabled]="!form.valid || (authenticating$ | async)"
        />
      </div>
    </form>
  </div>

  <p *ngIf="authenticating$ | async; else google" class="red">
    <fa-icon title="Trying to sign up, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
    Signing up...
  </p>

  <ng-template #google>
    <p>You don't have to sign up, you can also sign in with Google:</p>

    <div>
      <img (click)="loginWithGoogle()" src="/assets/bilder/btn_google_signin_dark_normal_web.png" class="rs-pointer" />
    </div>
  </ng-template>
</mat-dialog-content>
