import { Component, OnInit } from '@angular/core';
import { ActiveMarker, LatLngPos, ShopBasics } from '@shared/classes';
import { AppStateService } from '@core/services/app-state.service';
import { Store } from '@ngrx/store';
import { tryLoadMediumStores } from '@app/modules/shops/store/shop.actions';
import { selectActiveMarker, selectShowMapDetails, selectStoresVisible } from '@app/modules/map/store/map.selectors';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { GooglePlacesService } from '../../../core/services/google-places.service';
import { selectActiveStore } from '@app/modules/shops/store/shop.selectors';
import { selectIsMobile } from '@app/modules/global/store/global.selectors';
import { first } from 'rxjs/operators';
import { selectActivePin } from '@app/modules/auth/store/auth.selectors';
import { AuthService } from '@app/core/services/auth.service';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-map-details',
  templateUrl: './map-details.component.html',
  styleUrls: ['./map-details.component.scss'],
})
export class MapDetailsComponent extends Unsubscriber implements OnInit {
  marker: ActiveMarker | null = null;
  shop: ShopBasics | null = null;
  pos: LatLngPos | null = null;

  activePin$ = this.store.select(selectActivePin);
  showMapDetails$ = this.store.select(selectShowMapDetails);
  storesVisible$ = this.store.select(selectStoresVisible);
  private activeStore$ = this.store.select(selectActiveStore);
  private activeMarker$ = this.store.select(selectActiveMarker);
  private isMobile$ = this.store.select(selectIsMobile);

  private position$ = this.store.select(selectPosition);

  constructor(
    public appState: AppStateService,
    private auth: AuthService,
    private googlePlacesService: GooglePlacesService,
    private store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.unsubscribeLater(
      this.activeStore$.subscribe((store) => {
        this.initStore(store);
      }),
      this.position$.subscribe((pos) => {
        this.pos = pos;
      }),
      this.activeMarker$.subscribe((marker) => {
        this.marker = marker;
      })
    );
  }

  initStore(store: ShopBasics | null | undefined) {
    if (store) {
      this.shop = store;
      if (!store.isMedium) {
        this.store.dispatch(tryLoadMediumStores({ stores: [store], from: 'map' }));
      }
    }
  }

  addStore() {
    if (this.marker?.type === 'position' && this.marker.position) {
      this.googlePlacesService.addStoreFromPosition(this.marker.position);
    } else if (this.marker?.type === 'placeIcon') {
      this.googlePlacesService.addStoreFromPlaceIcon(this.marker.placeId);
    }
  }

  addPin() {
    this.auth.openLoginDialog$().subscribe((loggedIn) => {
      if (loggedIn) {
        if (this.marker?.type === 'position' && this.marker.position) {
          this.googlePlacesService.addPinFromPosition(this.marker.position);
        } else if (this.marker?.type === 'placeIcon') {
          this.googlePlacesService.addPinFromPlaceIcon(this.marker.placeId);
        }
      }
    });
  }

  gotoStore() {
    this.isMobile$.pipe(first()).subscribe((isMobile) => {
      if (isMobile) {
        this.appState.gotoStore(false);
      }
    });
  }
}
