import { createSelector } from '@ngrx/store';
import { selectCountriesLoading, selectStatesLoading } from '@app/modules/countries/store/country.selectors';
import { selectCitiesLoading } from '@app/modules/cities/store/city.selectors';
import { selectLoadingOrSavingAny } from '@app/modules/shops/store/shop.selectors';
import { selectPositionLoading } from '@app/modules/position/store/position.selectors';
import { selectLoadingAnything, selectSearchSearching } from '@app/modules/search/store/search.selectors';
import { selectGlobalState } from './global.selectors';
import { selectAuthenticatingOrSaving } from '@app/modules/auth/store/auth.selectors';

const selectLoading = createSelector(
  selectGlobalState,
  selectPositionLoading,
  selectCountriesLoading,
  selectStatesLoading,
  selectCitiesLoading,
  selectLoadingOrSavingAny,
  selectLoadingAnything,
  selectAuthenticatingOrSaving,
  (state, pos, countries, states, cities, shops, search, auth) =>
    state.loading > 0 || pos || countries || states || cities || shops || search || auth
);

export const selectLoadingOrSearching = createSelector(selectLoading, selectSearchSearching, (a, b) => a || b);
