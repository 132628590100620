/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/// <reference types="@types/google.maps" />

export enum MarkerColor {
  closed,
  black,
  red,
  gray,
  blue,
  green,
  violet,
  yellow,
  orange,
}
export const str2MarkerColor = (x: string): MarkerColor => {
  switch (x) {
    case 'black':
      return MarkerColor.black;
      break;
    case 'red':
      return MarkerColor.red;
      break;
    case 'blue':
      return MarkerColor.blue;
      break;
    case 'green':
      return MarkerColor.green;
      break;
    case 'gray':
      return MarkerColor.gray;
      break;
    case 'violet':
      return MarkerColor.violet;
      break;
    case 'yellow':
      return MarkerColor.yellow;
      break;
    case 'orange':
      return MarkerColor.orange;
      break;
    default:
      return MarkerColor.black;
  }
};
export const MarkerColor2str = (x: MarkerColor): string => {
  switch (x) {
    case MarkerColor.black:
      return 'black';
      break;
    case MarkerColor.red:
      return 'red';
      break;
    case MarkerColor.blue:
      return 'blue';
      break;
    case MarkerColor.green:
      return 'green';
      break;
    case MarkerColor.gray:
      return 'gray';
      break;
    case MarkerColor.violet:
      return 'violet';
      break;
    case MarkerColor.yellow:
      return 'yellow';
      break;
    case MarkerColor.orange:
      return 'orange';
      break;
    default:
      return 'black';
  }
};

export class MarkerDefinitions {
  private static zIndexClosedShop = 3;
  private static zIndexPosition = 4;
  private static zIndexPin = 9;
  private static zIndexShop = 9;
  private static zIndexHighlight = 12;

  private static newShopShape: google.maps.MarkerShape = {
    coords: [11, 29, 20, 14, 20, 7, 19, 5, 15, 2, 14, 1, 7, 1, 5, 2, 2, 5, 1, 7, 1, 14, 2, 15, 11, 29],
    type: 'poly',
  };
  private static diskPinShape: google.maps.MarkerShape = {
    coords: [11, 12, 12],
    type: 'circle',
  };
  private static positionShape: google.maps.MarkerShape = {
    coords: [10, 10, 10],
    type: 'circle',
  };

  static NOT_IN_DB_MARKER = {};

  static HIGHLIGHT_MARKER = {
    icon: {
      url: '/assets/markers/rs1_28_40_red.png',
      size: new google.maps.Size(28, 40),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(14, 38),
    },
    shape: MarkerDefinitions.newShopShape,
    zIndex: MarkerDefinitions.zIndexHighlight,
  };

  static POSITION_MARKER = {
    icon: {
      url: '/assets/markers/position21.svg',
      size: new google.maps.Size(21, 21),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(10, 10),
    },
    shape: MarkerDefinitions.positionShape,
    zIndex: MarkerDefinitions.zIndexPosition,
  };

  static POSITION_ROTATION_MARKER = {
    icon: {
      path: 'M 100 100 L 50 150 L 0 100 A 70 70 0 0 1 100 100',
      scale: 1,
      anchor: new google.maps.Point(50, 150),
      fillColor: 'blue',
      fillOpacity: 0.15,
      strokeWeight: 0,
      rotation: 0,
    },
    zIndex: MarkerDefinitions.zIndexPosition - 1,
  };

  static POSITION_MARKER_N = {
    icon: {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      scale: 4,
      strokeColor: '#00F',
      rotation: 0,
    },
    shape: MarkerDefinitions.positionShape,
    zIndex: MarkerDefinitions.zIndexPosition,
  };

  static STORE_DEFAULT_MARKER = {
    icon: {
      url: '/assets/markers/disk24black.png',
      size: new google.maps.Size(24, 24),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 12),
      //labelOrigin: { x: 12, y: 13 }
    },
    shape: MarkerDefinitions.diskPinShape,
    zIndex: MarkerDefinitions.zIndexShop,
  };
  static PIN_BLACK_MARKER = {
    icon: {
      url: '/assets/markers/rs1_21_30_black.png',
      size: new google.maps.Size(28, 40),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(14, 38),
    },
    shape: MarkerDefinitions.newShopShape,
    zIndex: MarkerDefinitions.zIndexPin,
  };
  static PIN_BLUE_MARKER = {
    icon: {
      url: '/assets/markers/rs1_21_30_blue.png',
      size: new google.maps.Size(21, 30),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 29),
    },
    shape: MarkerDefinitions.newShopShape,
    zIndex: MarkerDefinitions.zIndexPin,
  };
  static PIN_GRAY_MARKER = {
    icon: {
      url: '/assets/markers/rs1_21_30_gray.png',
      size: new google.maps.Size(21, 30),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 29),
    },
    shape: MarkerDefinitions.newShopShape,
    zIndex: MarkerDefinitions.zIndexPin,
  };
  static PIN_GREEN_MARKER = {
    icon: {
      url: '/assets/markers/rs1_21_30_green.png',
      size: new google.maps.Size(21, 30),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 29),
    },
    shape: MarkerDefinitions.newShopShape,
    zIndex: MarkerDefinitions.zIndexPin,
  };
  static PIN_ORANGE_MARKER = {
    icon: {
      url: '/assets/markers/rs1_21_30_orange.png',
      size: new google.maps.Size(21, 30),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 29),
    },
    shape: MarkerDefinitions.newShopShape,
    zIndex: MarkerDefinitions.zIndexPin,
  };
  static PIN_RED_MARKER = {
    icon: {
      url: '/assets/markers/rs1_21_30_red.png',
      size: new google.maps.Size(21, 30),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 29),
    },
    shape: MarkerDefinitions.newShopShape,
    zIndex: MarkerDefinitions.zIndexPin,
  };
  static PIN_VIOLET_MARKER = {
    icon: {
      url: '/assets/markers/rs1_21_30_violet.png',
      size: new google.maps.Size(21, 30),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 29),
    },
    shape: MarkerDefinitions.newShopShape,
    zIndex: MarkerDefinitions.zIndexPin,
  };
  static PIN_YELLOW_MARKER = {
    icon: {
      url: '/assets/markers/rs1_21_30_yellow.png',
      size: new google.maps.Size(21, 30),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 29),
    },
    shape: MarkerDefinitions.newShopShape,
    zIndex: MarkerDefinitions.zIndexPin,
  };

  static STORE_RED_MARKER = {
    icon: {
      url: '/assets/markers/disk24red.png',
      size: new google.maps.Size(24, 24),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 12),
      //labelOrigin: { x: 12, y: 13 }
    },
    shape: MarkerDefinitions.diskPinShape,
    zIndex: MarkerDefinitions.zIndexShop,
  };
  static STORE_BLUE_MARKER = {
    icon: {
      url: '/assets/markers/disk24blue.png',
      size: new google.maps.Size(24, 24),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 12),
      //labelOrigin: { x: 12, y: 13 }
    },
    shape: MarkerDefinitions.diskPinShape,
    zIndex: MarkerDefinitions.zIndexShop,
  };
  static STORE_GRAY_MARKER = {
    icon: {
      url: '/assets/markers/disk24gray.png',
      size: new google.maps.Size(24, 24),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 12),
      //labelOrigin: { x: 12, y: 13 }
    },
    shape: MarkerDefinitions.diskPinShape,
    zIndex: MarkerDefinitions.zIndexShop,
  };
  static STORE_GREEN_MARKER = {
    icon: {
      url: '/assets/markers/disk24green.png',
      size: new google.maps.Size(24, 24),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 12),
      //labelOrigin: { x: 12, y: 13 }
    },
    shape: MarkerDefinitions.diskPinShape,
    zIndex: MarkerDefinitions.zIndexShop,
  };
  static STORE_ORANGE_MARKER = {
    icon: {
      url: '/assets/markers/disk24orange.png',
      size: new google.maps.Size(24, 24),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 12),
      //labelOrigin: { x: 12, y: 13 }
    },
    shape: MarkerDefinitions.diskPinShape,
    zIndex: MarkerDefinitions.zIndexShop,
  };
  static STORE_VIOLET_MARKER = {
    icon: {
      url: '/assets/markers/disk24violet.png',
      size: new google.maps.Size(24, 24),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 12),
      //labelOrigin: { x: 12, y: 13 }
    },
    shape: MarkerDefinitions.diskPinShape,
    zIndex: MarkerDefinitions.zIndexShop,
  };
  static STORE_YELLOW_MARKER = {
    icon: {
      url: '/assets/markers/disk24yellow.png',
      size: new google.maps.Size(24, 24),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 12),
      //labelOrigin: { x: 12, y: 13 }
    },
    shape: MarkerDefinitions.diskPinShape,
    zIndex: MarkerDefinitions.zIndexShop,
  };
  static CLOSED_STORE_MARKER = {
    icon: {
      url: '/assets/markers/store_closed.png',
      size: new google.maps.Size(24, 24),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 11),
      //labelOrigin: { x: 12, y: 13 }
    },
    zIndex: MarkerDefinitions.zIndexClosedShop,
  };

  static getStoreMarker(color: MarkerColor) {
    switch (color) {
      case MarkerColor.red:
        return MarkerDefinitions.STORE_RED_MARKER;
      case MarkerColor.blue:
        return MarkerDefinitions.STORE_BLUE_MARKER;
      case MarkerColor.black:
        return MarkerDefinitions.STORE_DEFAULT_MARKER;
      case MarkerColor.gray:
        return MarkerDefinitions.STORE_GRAY_MARKER;
      case MarkerColor.green:
        return MarkerDefinitions.STORE_GREEN_MARKER;
      case MarkerColor.yellow:
        return MarkerDefinitions.STORE_YELLOW_MARKER;
      case MarkerColor.orange:
        return MarkerDefinitions.STORE_ORANGE_MARKER;
      case MarkerColor.violet:
        return MarkerDefinitions.STORE_VIOLET_MARKER;
      case MarkerColor.closed:
        return MarkerDefinitions.CLOSED_STORE_MARKER;
      default:
        return MarkerDefinitions.STORE_DEFAULT_MARKER;
    }
  }
}
