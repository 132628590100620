<p class="rs-colours">
  <img
    *ngFor="let colour of colours"
    src="/assets/markers/rs1_21_30_{{ colour }}.png"
    [class.active]="pin.colour === colour"
    (click)="setColour(colour)"
  />
</p>

<p *ngIf="editStep === ''">
  <a mat-flat-button [matMenuTriggerFor]="editmenu" class="rs-button" style="margin-right: 0">
    <fa-icon [icon]="faEdit"></fa-icon>
    &nbsp; Edit Pin
  </a>
  <mat-menu #editmenu="matMenu">
    <button mat-menu-item (click)="editAddress()">Edit Name and Address</button>
    <button mat-menu-item (click)="editContact()">Edit Contact Data</button>
    <button mat-menu-item (click)="editTimes()">Edit Opening Hours</button>
  </mat-menu>
  &nbsp;
  <a mat-flat-button (click)="removePin(pin)" class="rs-button" color="warn">
    <fa-icon [icon]="faTrash"></fa-icon>
    &nbsp; Remove Pin
  </a>
</p>
<div *ngIf="editStep !== ''" class="rs-inline-form rs-h2-highlighted">
  <ng-container *ngIf="editStep === 'editDetails.address'">
    <h2 mat-dialog-title>Edit name, address and description</h2>
    <rs-address-form
      *ngIf="!sending && !sent"
      [address]="pin"
      [withNote]="false"
      (closeForm)="closeAddressForm($event)"
      class="rs-form"
    ></rs-address-form>
    <p *ngIf="sending" class="red">
      <fa-icon title="Sending your Change Submission, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
      Sending your suggestion...
    </p>
    <div *ngIf="sent">
      <p>Thank you for your suggestion. An administrator will review it asap.</p>
      <div class="mt-3 mb-3" style="text-align: center">
        <a mat-stroked-button (click)="closeForm()">Close This Form</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="editStep === 'editDetails.contact'">
    <h2 mat-dialog-title>Edit phone, email and websites</h2>
    <rs-contact-form
      *ngIf="!sending && !sent"
      [contact]="pin"
      [withNote]="false"
      (closeForm)="closeContactForm($event)"
      class="rs-form"
    ></rs-contact-form>
    <p *ngIf="sending" class="red">
      <fa-icon title="Sending your Change Submission, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
      Sending your suggestion...
    </p>
    <div *ngIf="sent">
      <p>Thank you for your suggestion. An administrator will review it asap.</p>
      <div class="mt-3 mb-3" style="text-align: center">
        <a mat-stroked-button (click)="closeForm()">Close This Form</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="editStep === 'editDetails.times'">
    <h2 mat-dialog-title>Edit opening hours</h2>
    <rs-times-form
      *ngIf="!sending && !sent"
      [times]="pin"
      [withNote]="false"
      (closeForm)="closeTimesForm($event)"
      class="rs-form"
    ></rs-times-form>
    <p *ngIf="sending" class="red">
      <fa-icon title="Sending your Change Submission, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
      Sending your suggestion...
    </p>
    <div *ngIf="sent">
      <p>Thank you for your suggestion. An administrator will review it asap.</p>
      <div class="mt-3 mb-3" style="text-align: center">
        <a mat-stroked-button (click)="closeForm()">Close This Form</a>
      </div>
    </div>
  </ng-container>
</div>
