import { Country, State } from '@shared/classes';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCountry from './country.reducer';
import { selectActualBounds, selectCountriesVisible } from '@app/modules/map/store/map.selectors';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';

export const selectCountryState = createFeatureSelector<fromCountry.CountryState>(fromCountry.countryFeatureKey);

export const selectAllContinents = createSelector(selectCountryState, (state) => state.continents);

export const selectAllCountries = createSelector(selectCountryState, (state) => state.countries);

export const selectAllStates = createSelector(selectCountryState, (state) => state.states);

export const selectCountriesFilter = createSelector(selectCountryState, (state) => state.countriesFilter);

export const selectFilteredCountries = createSelector(selectAllCountries, selectCountriesFilter, (countries, filter) =>
  countries
    .filter((x) => (filter === '100' ? x.opencnt >= 100 : x.continent === filter))
    .sort((a, b) => a.country.localeCompare(b.country))
);

export const selectActiveCountryCode = createSelector(selectCountryState, (state) => state.activeCountryCode);

export const selectActiveStateCode = createSelector(selectCountryState, (state) =>
  state.activeCountryCode === 'US' ? state.activeStateCode : ''
);

export const selectActiveCountry = createSelector(selectActiveCountryCode, selectAllCountries, (code, countries) =>
  countries.find((x) => x.countrycode === code)
);

export const selectActiveState = createSelector(selectActiveStateCode, selectAllStates, (code, states) =>
  states.find((x) => x.state === code)
);

export const selectStatesInActiveCountry = createSelector(
  selectActiveCountryCode,
  selectAllStates,
  (countrycode, states) => (countrycode === 'US' ? states : [])
);

export const selectCountriesLoading = createSelector(selectCountryState, (state) => state.loadingCountries);

export const selectCountriesLoaded = createSelector(selectCountryState, (state) => state.loadedCountries);

export const selectStatesLoading = createSelector(selectCountryState, (state) => state.loadingStates);

export const selectStatesLoaded = createSelector(selectCountryState, (state) => state.loadedStates);

export const selectTotalStoresCount = createSelector(selectCountriesLoaded, selectAllCountries, (loaded, countries) =>
  loaded ? countries.reduce((cnt, country) => cnt + country.opencnt, 0) : 0
);

export const selectTotalCountriesCount = createSelector(
  selectCountriesLoaded,
  selectAllCountries,
  (loaded, countries) => (loaded ? countries.length : 0)
);

export const selectVisibleCountries = createSelector(
  selectAllCountries,
  selectActualBounds,
  selectCountriesVisible,
  (countries, bounds, visible) => {
    let result: Country[] = [];
    if (visible && bounds) {
      let items = countries.filter((x) => PositionHelpers.posInBounds(x, bounds, 0));
      let opencnt = 1;
      while (items.length > 25) {
        items = items.filter((x) => x.opencnt > opencnt);
        opencnt++;
      }
      result = items.sort((a, b) => a.country.localeCompare(b.country));
    }
    return result;
  }
);
