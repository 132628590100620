/* eslint-disable @typescript-eslint/naming-convention */
import {
  City,
  CityFactory,
  CityRaw,
  Country,
  CountryFactory,
  CountryRaw,
  Pin,
  PinFactory,
  PinRaw,
  State,
  StateFactory,
  StateRaw,
  ShopBasics,
  ShopBasicsRaw,
  ShopFactory,
} from '@shared/classes';

export interface SearchResult {
  what: string;
  stores_exact?: ShopBasics[];
  stores_match?: ShopBasics[];
  cities_exact?: City[];
  cities_match?: City[];
  stores?: ShopBasics[];
  cities?: City[];
  states?: State[];
  countries?: Country[];
  pins?: Pin[];
}

export interface SearchResultRaw {
  what: string;
  stores_exact?: ShopBasicsRaw[];
  stores_match?: ShopBasicsRaw[];
  cities_exact?: CityRaw[];
  cities_match?: CityRaw[];
  stores?: ShopBasicsRaw[];
  cities?: CityRaw[];
  states?: StateRaw[];
  countries?: CountryRaw[];
  pins?: PinRaw[];
}

export interface ExactSearchResult {
  stores?: ShopBasics[];
  cities?: City[];
  states?: State[];
  countries?: Country[];
}

export interface ExactSearchResultRaw {
  stores?: ShopBasicsRaw[];
  cities?: CityRaw[];
  states?: StateRaw[];
  countries?: CountryRaw[];
}

export class SearchResultFactory {
  static fromRaw(s: SearchResultRaw): SearchResult {
    return {
      ...s,
      stores_exact: s.stores_exact ? s.stores_exact.map((x) => ShopFactory.fromRawBasic(x)) : [],
      stores_match: s.stores_match ? s.stores_match.map((x) => ShopFactory.fromRawBasic(x)) : [],
      cities_exact: s.cities_exact ? s.cities_exact.map((x) => CityFactory.fromRaw(x)) : [],
      cities_match: s.cities_match ? s.cities_match.map((x) => CityFactory.fromRaw(x)) : [],
      stores: s.stores ? s.stores.map((x) => ShopFactory.fromRawBasic(x)) : [],
      cities: s.cities ? s.cities.map((x) => CityFactory.fromRaw(x)) : [],
      states: s.states ? s.states.map((x) => StateFactory.fromRaw(x)) : [],
      countries: s.countries ? s.countries.map((x) => CountryFactory.fromRaw(x)) : [],
      pins: s.pins ? s.pins.map((x) => PinFactory.fromRaw(x)) : [],
    };
  }

  static fromExactRaw(s: ExactSearchResultRaw): ExactSearchResult {
    return {
      ...s,
      stores: s.stores ? s.stores.map((x) => ShopFactory.fromRawBasic(x)) : [],
      cities: s.cities ? s.cities.map((x) => CityFactory.fromRaw(x)) : [],
      states: s.states ? s.states.map((x) => StateFactory.fromRaw(x)) : [],
      countries: s.countries ? s.countries.map((x) => CountryFactory.fromRaw(x)) : [],
    };
  }
}
