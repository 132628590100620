import { Component, Input } from '@angular/core';
import { DialogsService } from '@app/core/services/dialogs.service';

@Component({
  selector: 'rs-contact-and-coffee',
  templateUrl: './contact-and-coffee.component.html',
  styleUrls: ['./contact-and-coffee.component.scss'],
})
export class ContactAndCoffeeComponent {
  @Input() url = '';
  @Input() title = '';

  constructor(public dialogsService: DialogsService) {}
}
