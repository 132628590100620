import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { MapService } from '@core/services/map.service';
import { LatLng, LatLngPos, PinFactory, ShopAddress, ShopDetails, ShopFactory, ShopTimesHelper } from '@shared/classes';
import { saveStore } from '@app/modules/shops/store/shop.actions';
import { Store } from '@ngrx/store';
import { PlaceDialogsService } from './place-dialogs.service';
import { DialogsService } from '@core/services/dialogs.service';
import { filter, first, tap } from 'rxjs/operators';
import { ShopEssentials } from '@app/shared/classes/shop-essentials';
import { userSavePin } from '@app/modules/auth/store/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class GooglePlacesService {
  private cache: google.maps.places.PlaceResult[] = [];
  private sessionToken: google.maps.places.AutocompleteSessionToken | undefined;

  constructor(
    private dialogs: DialogsService,
    private mapService: MapService,
    private zone: NgZone,
    private placeDialogsService: PlaceDialogsService,
    private store: Store
  ) {}

  searchPlaces(term: string, pos: LatLngPos | null): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable<google.maps.places.AutocompletePrediction[]>((subscriber) => {
      const doSearch = (gmap: google.maps.Map | null) => {
        // Create a new session token.
        // Siehe https://developers.google.com/maps/documentation/javascript/places-autocomplete#session_tokens
        // Ein SessionToken ist für mehrere AutoComplete-Abfragen gültig und erlischt erst, wenn PlaceDetails abgefragt werden.
        if (!this.sessionToken) {
          this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        }

        const service = new google.maps.places.AutocompleteService();
        const request: google.maps.places.AutocompletionRequest = {
          input: term,
          sessionToken: this.sessionToken,
        };
        if (gmap) {
          request.bounds = gmap.getBounds() ?? undefined;
        }
        if (pos) {
          request.origin = { lat: pos.lat, lng: pos.lng };
        }

        service.getPlacePredictions(request, (predictions, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            subscriber.error(null);
          } else {
            subscriber.next(predictions ?? []);
          }
          subscriber.complete();
        });
      };

      this.mapService.map$.pipe(first()).subscribe((m) => doSearch(m));
    });
  }

  getPlaceDetails(placeid: string, full: boolean): Observable<google.maps.places.PlaceResult> {
    return new Observable<google.maps.places.PlaceResult>((subscriber) => {
      const basicfields = [
        'address_component',
        'adr_address',
        'business_status',
        'formatted_address',
        'geometry',
        'icon',
        'name',
        'photo',
        'place_id',
        'plus_code',
        'type',
        'url',
        //'utc_offset_minutes',
        'vicinity',
      ];
      // Contact Data: 0.003$ per call
      const contactfields = ['formatted_phone_number', 'international_phone_number', 'opening_hours', 'website'];
      const doGetPlaceDetails = (gmap: google.maps.Map | null) => {
        const request: google.maps.places.PlaceDetailsRequest = {
          placeId: placeid,
          fields: [...basicfields, ...(full ? contactfields : [])],
        };

        if (this.sessionToken) {
          request.sessionToken = this.sessionToken;
          this.sessionToken = undefined;
        }

        const callback = (place: google.maps.places.PlaceResult | null, status: any) => {
          if (place && status === google.maps.places.PlacesServiceStatus.OK) {
            if (full) {
              this.cache.push(place);
            }
            subscriber.next(place);
          } else {
            subscriber.error(status);
          }
          subscriber.complete();
        };

        if (gmap !== null) {
          const service = new google.maps.places.PlacesService(gmap);
          service.getDetails(request, callback);
        } else {
          const div = document.getElementById('searchdummy') as HTMLDivElement;
          const service = new google.maps.places.PlacesService(div);
          service.getDetails(request, callback);
          //callback(null, 'map not initialized');
        }
      };

      const item = this.cache.find((x) => x.place_id === placeid);
      if (item) {
        subscriber.next(item);
        subscriber.complete();
      } else {
        this.mapService.map$.pipe(first()).subscribe((m) => doGetPlaceDetails(m));
      }
    });
  }

  getNearByPlaces(location: LatLng, radius: number, callback: any) {
    this.mapService.map$.pipe(first()).subscribe((map) => {
      const div = document.getElementById('searchdummy') as HTMLDivElement;
      const service = new google.maps.places.PlacesService(map ?? div);
      service.nearbySearch(
        {
          location,
          radius,
          //type: ['store']
        },
        callback
      );
    });
  }

  getNearByStores(location: LatLng, radius: number, callback: any) {
    this.mapService.map$.pipe(first()).subscribe((map) => {
      const div = document.getElementById('searchdummy') as HTMLDivElement;
      const service = new google.maps.places.PlacesService(map ?? div);
      service.nearbySearch(
        {
          location,
          radius,
          type: 'store',
        },
        callback
      );
    });
  }

  getGeocodeResults(location: LatLng): Observable<google.maps.GeocoderResult[]> {
    return new Observable<google.maps.GeocoderResult[]>((subscriber) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { location },
        (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
          if (status === 'OK') {
            subscriber.next(results ?? []);
          } else {
            console.warn('Geocoder failed due to: ' + status);
            subscriber.error(status);
          }
          subscriber.complete();
        }
      );
    });
  }

  getShopFromPlace(place: google.maps.places.PlaceResult): ShopDetails {
    const shop = ShopFactory.new();

    shop.name = place.name ?? '(unknown)';

    if (place.geometry?.location) {
      shop.lat = place.geometry.location.lat();
      shop.lng = place.geometry.location.lng();
    }

    this.readAddressComponents(shop, place.address_components);

    if (place.formatted_address) {
      shop.address = place.formatted_address;
    }

    if (place.international_phone_number) {
      shop.phone = place.international_phone_number;
    }

    if (place.place_id) {
      shop.place_id = place.place_id;
    }

    if (place.website) {
      shop.website = place.website.replace('m.facebook.com', 'www.facebook.com');
    }

    if (place.url) {
      shop.google_url = place.url;
    }

    const times = ShopTimesHelper.getTimesFromPlace(place);

    return { ...shop, ...times };
  }

  addStoreFromPlaceResult(place: google.maps.places.PlaceResult | undefined) {
    //this.zone.run(() =>
    //  this.dialogs.openConfirmDialog('Add a Record Store', 'Do you want to add a Record Store at this position?', 'Add Store', 'Discard')
    //  .pipe(
    //    filter(x => x)) // only react to OK-click
    //  .subscribe(() => {
    if (place && place.geometry) {
      const shop = this.getShopFromPlace(place);
      this.zone.run(() => this.placeDialogsService.openStoreForm(shop));
      //this.store.dispatch(saveStore({ store: shop, note: 'new store from PlaceResult' }));
    }
    //  })
    //);
  }

  addPinFromPlaceResult(place: google.maps.places.PlaceResult | undefined) {
    this.zone.run(() =>
      this.dialogs
        .openConfirmDialog(
          'Add a Private Pin',
          'Do you want to add a Private Pin at this position?',
          'Add Pin',
          'Discard'
        )
        .pipe(filter((x) => x)) // only react to OK-click
        .subscribe(() => {
          if (place && place.geometry) {
            let pin = PinFactory.new();
            pin.name = place.name ?? '(unknown)';
            if (place.geometry?.location) {
              pin.lat = place.geometry.location.lat();
              pin.lng = place.geometry.location.lng();
            }
            this.readAddressComponents(pin, place.address_components);
            if (place.formatted_address) {
              pin.address = place.formatted_address;
            }
            if (place.international_phone_number) {
              pin.phone = place.international_phone_number;
            }
            if (place.place_id) {
              pin.place_id = place.place_id;
            }
            if (place.website) {
              pin.website = place.website;
            }
            const times = ShopTimesHelper.getTimesFromPlace(place);
            pin = { ...pin, ...times };
            if (pin.name === '') {
              pin.name = '(unknown)';
            }
            this.store.dispatch(userSavePin({ pin }));
          }
        })
    );
  }

  addStoreFromPlaceIcon(placeId: string | undefined) {
    if (placeId) {
      this.getPlaceDetails(placeId, true).subscribe((place) => this.addStoreFromPlaceResult(place));
    }
  }

  addPinFromPlaceIcon(placeId: string | undefined) {
    if (placeId) {
      this.getPlaceDetails(placeId, true).subscribe((place) => this.addPinFromPlaceResult(place));
    }
  }

  addStoreFromPosition(position: LatLng) {
    //this.dialogs.openConfirmDialog('Add a Record Store', 'Do you want to add a Record Store at this position?', 'Add Store', 'Discard')
    //  .pipe(filter(x => x)) // only react to OK-click
    //  .subscribe(() => {
    const shop = ShopFactory.new();
    shop.lat = position.lat;
    shop.lng = position.lng;

    this.getGeocodeResults(shop).subscribe({
      next: (results) => {
        if (results[0]) {
          shop.address = results[0].formatted_address;
          this.readAddressComponents(shop, results[0].address_components);
        }
      },
      error: (status) => console.warn('Geocoder failed due to: ' + status),
      complete: () => this.zone.run(() => this.placeDialogsService.openStoreForm(shop)),
    });
    //});
  }

  addPinFromPosition(position: LatLng) {
    this.dialogs
      .openConfirmDialog(
        'Add a Private Pin',
        'Do you want to add a Private Pin at this position?',
        'Add Pin',
        'Discard'
      )
      .pipe(filter((x) => x)) // only react to OK-click
      .subscribe(() => {
        const pin = PinFactory.new();
        pin.lat = position.lat;
        pin.lng = position.lng;

        this.getGeocodeResults(pin).subscribe(
          (results) => {
            if (results[0]) {
              pin.address = results[0].formatted_address;
              this.readAddressComponents(pin, results[0].address_components);
              if (pin.name === '') {
                pin.name = '(unknown)';
              }
              this.store.dispatch(userSavePin({ pin }));
            }
          },
          (status) => console.warn('Geocoder failed due to: ' + status)
        );
      });
  }

  private readAddressComponents(
    shop: ShopAddress & ShopEssentials,
    adr: google.maps.GeocoderAddressComponent[] | undefined
  ) {
    if (adr) {
      // Country
      adr.forEach((x) => {
        if (x.types.includes('country')) {
          shop.countrycode = x.short_name;
        }
      });

      // US: State
      if (shop.countrycode === 'US') {
        adr.forEach((x) => {
          if (x.types.includes('administrative_area_level_1')) {
            shop.state = x.short_name;
          }
        });
      }

      // City:
      let level1 = '';
      let level2 = '';
      let locality = '';
      let postaltown = '';
      let sublocality = '';
      adr.forEach((x) => {
        if (x.types.includes('administrative_area_level_1') && x.long_name !== '') {
          level1 = x.long_name;
        }
        if (x.types.includes('administrative_area_level_2') && x.long_name !== '') {
          level2 = x.long_name;
        }
        if (x.types.includes('locality') && x.long_name !== '') {
          locality = x.long_name;
        }
        if (x.types.includes('postal_town') && x.long_name !== '') {
          postaltown = x.long_name;
        }
        if (x.types.includes('sublocality') && x.long_name !== '') {
          sublocality = x.long_name;
        }

        switch (shop.countrycode) {
          case 'CH':
          case 'AR':
          case 'JP':
          case 'VN':
          case 'TW':
          case 'TR':
            shop.city = level1;
            break;
          case 'BR':
          case 'CL':
            shop.city = level2;
            break;
          case 'GB':
          case 'SE':
            shop.city = postaltown;
            break;
          case 'CZ':
          case 'SK':
            shop.city = sublocality;
            break;
        }

        if (!shop.city) {
          shop.city = locality;
        }
        if (!shop.city) {
          shop.city = postaltown;
        }
        if (!shop.city) {
          shop.city = level1;
        }
      });

      // In einigen Ländern ist die Stadt in einem anderen Feld.
      if (shop.countrycode === 'AR' || shop.countrycode === 'JP' || shop.countrycode === 'AU') {
        adr.forEach((x) => {
          if (x.types.includes('administrative_area_level_1')) {
            shop.city = x.long_name;
          }
        });
      }

      // State ist nur für USA interessant.
      if (shop.countrycode !== 'US') {
        shop.state = '';
      }
    }
  }
}
