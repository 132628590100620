<div class="slideshowfragments">
  <div
    *ngFor="let i of divs"
    (click)="click(i)"
    [ngStyle]="{ 'width.px': divWidth }"
    [ngClass]="{
      slideshowfragment: true,
      active: i == active,
      inactive: i != active
    }"
  ></div>
</div>
