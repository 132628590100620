<rs-admin-store *ngIf="userIsAdmin$ | async"></rs-admin-store>

<div class="rs-panel" *ngIf="shop" [class.hasclosed]="shop.hasclosed">
  <div>
    <h1>
      <fa-icon
        *ngIf="shop.supporter"
        transform="up-3"
        [fixedWidth]="true"
        [icon]="icons.faStar"
        class="yellow"
      ></fa-icon>
      {{ shop.name }}
      <span class="rs-h-small" *ngIf="distance">{{ distance | number: '1.1-1' }} km | {{ direction }}</span>
    </h1>
  </div>

  <div *ngIf="shop.hasclosed" class="rs-border-bottom-lightgray">
    <p>
      <b class="red">This store has closed at this location (or stopped selling records) </b>
    </p>
  </div>

  <rs-breadcrumb
    *ngIf="shop.countrycode !== 'US'"
    [breadcrumbs]="[
      { routerLink: '/' + shop.countrycode, caption: shop.countrycode },
      {
        routerLink: helper.store2cityurl(shop),
        caption: helper.str2url(shop.city)
      }
    ]"
  ></rs-breadcrumb>
  <rs-breadcrumb
    *ngIf="shop.countrycode === 'US'"
    [breadcrumbs]="[
      { routerLink: '/US', caption: 'US' },
      { routerLink: '/US/' + shop.state, caption: shop.state || '' },
      {
        routerLink: helper.store2cityurl(shop),
        caption: helper.str2url(shop.city)
      }
    ]"
  ></rs-breadcrumb>

  <div>
    <div *ngIf="images.length > 0 && (activeDiv$ | async) === 'STORE'">
      <rs-carousel [images]="images"></rs-carousel>
    </div>

    <rs-upload [id]="shop.id"></rs-upload>

    <rs-like-buttons [storeid]="shop.id"></rs-like-buttons>

    <section class="contact-section">
      <h2>Contact Details</h2>
    </section>

    <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row" rsClickStopPropagation>
      <rs-simple-item
        [faIcon]="icons.faClock"
        iconClass="contact-link"
        title="{{ hours }}"
        [noselect]="false"
      ></rs-simple-item>
    </div>
    <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row" rsClickStopPropagation>
      <rs-simple-item
        [faIcon]="icons.faMarker"
        iconClass="marker"
        [title]="shop.address + (shop.addinfo ? ' (' + shop.addinfo + ')' : '')"
        [noselect]="false"
      ></rs-simple-item>
    </div>

    <div *ngFor="let link of links">
      <a href="{{ link.href }}" target="_blank" rel="”noopener”" rsClickStopPropagation>
        <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row">
          <rs-simple-item
            *ngIf="link.icon === 'faInstagram'"
            icon="/assets/markers/instagram.ico"
            [title]="link.text"
          ></rs-simple-item>
          <rs-simple-item
            *ngIf="link.icon === 'faFacebook'"
            icon="/assets/markers/facebook_neu.ico"
            [title]="link.text"
          ></rs-simple-item>
          <rs-simple-item
            *ngIf="link.icon === 'faTwitter'"
            icon="/assets/markers/twitter.png"
            [title]="link.text"
          ></rs-simple-item>
          <rs-simple-item
            *ngIf="link.icon === 'faDiscogs'"
            icon="/assets/markers/discogs.ico"
            [title]="link.text"
          ></rs-simple-item>
          <rs-simple-item
            *ngIf="
              link.icon !== 'faInstagram' &&
              link.icon !== 'faFacebook' &&
              link.icon !== 'faTwitter' &&
              link.icon !== 'faDiscogs'
            "
            [faIcon]="icons[link.icon]"
            [iconClass]="link.css"
            [title]="link.text"
          ></rs-simple-item>
        </div>
      </a>
    </div>

    <ng-container *ngIf="shop.google_url === '' || shop.google_url === null; else googleCid">
      <a
        href="https://www.google.com/maps/search/?api=1&query={{ shop.lat }},{{ shop.lng }}"
        target="_blank"
        rel="”noopener”"
      >
        <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row">
          <rs-simple-item icon="/assets/markers/gmaps.ico" title="Show store position in Google Maps"></rs-simple-item>
        </div>
      </a>
    </ng-container>
    <ng-template #googleCid>
      <a href="{{ shop.google_url }}" target="_blank" rel="”noopener”">
        <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row">
          <rs-simple-item icon="/assets/markers/gmaps.ico" title="Open store in Google Maps"></rs-simple-item>
        </div>
      </a>
    </ng-template>
    <a
      href="https://www.google.com/maps/dir/?api=1&travelmode={{
        distance && distance < 5 ? 'walking' : 'driving'
      }}&destination={{ shop.lat }},{{ shop.lng }}"
      target="_blank"
      rel="”noopener”"
    >
      <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row">
        <rs-simple-item [faIcon]="icons.faRoute" title="Show directions in Google Maps"></rs-simple-item>
      </div>
    </a>

    <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row" rsClickStopPropagation>
      <rs-simple-item
        (click)="showQr = !showQr"
        [faIcon]="icons.faQrcode"
        iconClass="qrcode"
        title="Show QR-Code"
        [noselect]="false"
      ></rs-simple-item>
    </div>

    <rs-qr-code *ngIf="showQr" [url]="'https://recordstores.love/' + shop.id"></rs-qr-code>

    <rs-store-edit [shop]="shop"></rs-store-edit>

    <section class="descript-section">
      <h2>Description</h2>
      <p *ngIf="shop.descript">
        {{ shop.descript }}
      </p>
      <p *ngIf="!shop.descript" class="gray">
        <em>No description.</em>
      </p>
    </section>

    <div *ngIf="shop.supporter">
      <h2>{{ shop.name }} is a Supporter!</h2>

      <div class="rs-border-bottom-lightgray">
        <p>
          Without Supporters this site won't exist, so please support the Supporters. And if you own a record store,
          please become a Supporter.
        </p>
      </div>
      <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-blue" routerLink="/support">
        <rs-simple-item
          [faIcon]="icons.faHeart"
          iconClass="rs-home-simple-item-icon red"
          title="How to become a supporter"
        ></rs-simple-item>
      </div>
    </div>

    <div *ngIf="supporters$ | async as supporters">
      <div *ngIf="!shop.supporter && supporters.length > 0">
        <rs-supporters-list
          [position]="shop"
          [countrycode]="shop.countrycode"
          title="{{ helper.n(supporters.length, 'Supporter') }} Near {{ shop.name }}"
          subtitle="Please support supporters"
          [supporters]="supporters"
        ></rs-supporters-list>
        <div class="rs-border-bottom-lightgray">
          <p>Without Supporters this site won't exist, so if you own a record store, please become a Supporter.</p>
        </div>
        <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-blue" routerLink="/support">
          <rs-simple-item
            [faIcon]="icons.faHeart"
            iconClass="rs-home-simple-item-icon red"
            title="How to become a supporter"
          ></rs-simple-item>
        </div>
      </div>
    </div>
  </div>

  <rs-contact-and-coffee
    [title]="shop.name + ' (' + shop.id + ') in ' + shop.city + '/' + shop.countrycode"
    [url]="'https://recordstores.love/' + shop.id"
  >
  </rs-contact-and-coffee>

  <section class="sharing-section">
    <rs-sharing
      [url]="'https://recordstores.love/' + shop.id"
      [name]="shop.name"
      [descript]="'Here is a link to ' + shop.name + ' in ' + shop.city"
    ></rs-sharing>
  </section>
</div>
