<div *ngIf="filterItems.length > 1">
  <h2 *ngIf="!isAmerica">Filter Cities</h2>
  <h2 *ngIf="isAmerica">Show big cities (10+ stores) or states</h2>
  <p>
    <span
      *ngFor="let item of filterItems"
      class="rs-filter-item"
      (click)="toggleFilter(item.filter)"
      [class.selected]="filter === item.filter"
      >{{ item.caption }}</span
    >
  </p>
</div>

<div *ngIf="filter === 'states'; else cities">
  <div *ngIf="states">
    <h2>{{ states.length }} {{ title }}</h2>
    <a routerLink="/{{ helper.state2url(state) }}" *ngFor="let state of states" class="rs-panel-row-link">
      <rs-state-item [state]="state"></rs-state-item>
    </a>
  </div>
</div>

<ng-template #cities>
  <h2>{{ title }}</h2>
  <a routerLink="/{{ helper.city2url(city.city) }}" *ngFor="let city of filteredCities" class="rs-panel-row-link">
    <rs-city-item [city]="city"></rs-city-item>
  </a>
</ng-template>
