import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {
  faBars,
  faStamp,
  faBalanceScaleLeft,
  faHeart,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faLifeRing } from '@fortawesome/free-regular-svg-icons';
import { faFacebookSquare as faFacebook, faInstagramSquare as faInstagram } from '@fortawesome/free-brands-svg-icons';
import { AuthService } from '@core/services/auth.service';
import { Store } from '@ngrx/store';
import { selectAuthenticated, selectUser } from '@app/modules/auth/store/auth.selectors';
import { logoutAction } from '@app/modules/auth/store/auth.actions';

@Component({
  selector: 'rs-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input() sidenav!: MatSidenav;

  faBars = faBars;

  faDonate = faHeart;
  faWhyDonate = faLifeRing;

  faImprint = faStamp;
  faDataPolicy = faBalanceScaleLeft;

  faFacebook = faFacebook;
  faInstagram = faInstagram;

  faLogin = faSignInAlt;
  faLogout = faSignOutAlt;
  faRegister = faUserPlus;
  faUser = faUser;

  authenticated$ = this.store.select(selectAuthenticated);
  user$ = this.store.select(selectUser);

  constructor(private auth: AuthService, private store: Store) {}

  ngOnInit(): void {}

  login() {
    this.auth.openLoginDialog();
    this.sidenav.close();
  }

  loginWithGoogle() {
    this.sidenav.close();
    this.auth.showLoginWithGoogle();
  }

  logout() {
    this.store.dispatch(logoutAction());
    this.sidenav.close();
  }

  register() {
    this.auth.openRegisterDialog();
    this.sidenav.close();
  }
}
