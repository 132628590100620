import { createFeatureSelector, createSelector } from '@ngrx/store';
import { defaultHighlightStrategy, HighlightStrategies } from '../classes/highlight-strategies';
import * as fromMarker from './marker.reducer';

export const selectMarkerState = createFeatureSelector<fromMarker.MarkerState>(fromMarker.markerFeatureKey);

export const selectSelectedHighlightStrategyID = createSelector(
  selectMarkerState,
  (state) => state.selectedHighlightStrategy
);

export const selectSelectedHighlightStrategy = createSelector(
  selectSelectedHighlightStrategyID,
  (id) => HighlightStrategies.find((s) => s.id === id) ?? defaultHighlightStrategy
);
