import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import * as fromPosition from './position.reducer';

import * as PositionActions from './position.actions';
import { LocalStorageService } from '@core/services/local-storage.service';
import { PositionService } from '@core/services/position.service';
import { Store } from '@ngrx/store';

@Injectable()
export class PositionEffects {
  savePositionToLocalStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PositionActions.getPositionSuccess),
        filter((action) => !!action.position),
        tap((action) => this.storage.set('POSITION', action.position))
      ),
    { dispatch: false }
  );

  getPosition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PositionActions.getPosition),
        tap(() => this.posService.getCurrentPosition())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private storage: LocalStorageService,
    private posService: PositionService,
    private store: Store<fromPosition.PositionState>
  ) {}
}
