import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromCity from './store/city.reducer';
import { CityEffects } from './store/city.effects';
import { CityComponent } from './city/city.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [CityComponent],
  imports: [
    SharedModule,
    StoreModule.forFeature(fromCity.cityFeatureKey, fromCity.reducer),
    EffectsModule.forFeature([CityEffects]),
  ],
})
export class CitiesModule {}
