import { Component, OnInit } from '@angular/core';
import { AppStateService, AppTarget } from '@core/services/app-state.service';
import {
  faMapMarkedAlt as faMap,
  faMapMarkerAlt as faStore,
  faList as faCity,
  faHome as faHome,
  faWalking as faNear,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { City, ShopBasics } from '@shared/classes';
import { Store } from '@ngrx/store';
import { selectActiveDiv, selectIsMobile, selectMapVisible } from '@app/modules/global/store/global.selectors';
import { selectActiveCity } from '@app/modules/cities/store/city.selectors';
import { selectActiveStore } from '@app/modules/shops/store/shop.selectors';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: [],
})
export class BottomBarComponent extends Unsubscriber implements OnInit {
  icons = {
    faMap,
    faStore,
    faCity,
    faHome,
    faNear,
    faSearch,
  };

  isMobile = true;
  mapVisible = false;
  activeCity: City | null = null;
  activeStore: ShopBasics | null = null;
  activeDiv: AppTarget | null = null;

  activeDiv$ = this.store.select(selectActiveDiv);
  activeCity$ = this.store.select(selectActiveCity);
  activeStore$ = this.store.select(selectActiveStore);
  isMobile$ = this.store.select(selectIsMobile);
  mapVisible$ = this.store.select(selectMapVisible);

  constructor(public appState: AppStateService, private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.unsubscribeLater(
      this.isMobile$.subscribe((x) => setTimeout(() => (this.isMobile = x), 0)),

      this.mapVisible$.subscribe((x) => setTimeout(() => (this.mapVisible = x), 0)),

      this.activeDiv$.subscribe((x) => setTimeout(() => (this.activeDiv = x), 0)),

      this.activeCity$.subscribe((x) => setTimeout(() => (this.activeCity = x), 0)),

      this.activeStore$.subscribe((x) => setTimeout(() => (this.activeStore = x ?? null), 0))
    );
  }

  isActive(button: AppTarget | 'MAP'): boolean {
    if (this.isMobile && this.mapVisible) {
      return button === 'MAP';
    } else {
      return button === this.activeDiv;
    }
  }
}
