<div class="rs-like-buttons">
  <ng-container *ngIf="savingLike$ | async as items" class="rs-like-buttons">
    <div *ngIf="items.includes(storeid); else noSavingLike" class="rs-like-button rs-like">
      <fa-icon [icon]="icons['faSpinner']" [spin]="true" size="2x" class="mr-3"></fa-icon>
      <span>saving</span>
    </div>
  </ng-container>
  <ng-template #noSavingLike>
    <div
      *ngIf="like$ | async as like; else nolike"
      class="rs-like-button rs-like"
      mat-button
      [matMenuTriggerFor]="likemenu"
    >
      <fa-icon *ngIf="like === likeTypes.like" [icon]="icons['faHeart']" size="2x" class="red mr-3"></fa-icon>
      <fa-icon *ngIf="like === likeTypes.dont" [icon]="icons['faHeart']" size="2x" class="gray mr-3"></fa-icon>
      <span *ngIf="like === likeTypes.like">Like</span>
      <span *ngIf="like === likeTypes.dont">Don't Like</span>
    </div>
    <ng-template #nolike>
      <div class="rs-like-button rs-like" mat-button [matMenuTriggerFor]="likemenu">
        <fa-icon [icon]="icons['faHeartEmpty']" size="2x" class="gray mr-3"></fa-icon>
        <span>Like?</span>
      </div>
    </ng-template>
    <mat-menu #likemenu="matMenu">
      <button mat-menu-item (click)="setLike(likeTypes.like)">
        <fa-icon [icon]="icons['faHeart']" size="lg" class="red mr-3"></fa-icon>
        Like
      </button>
      <button mat-menu-item (click)="setLike(likeTypes.dont)">
        <fa-icon [icon]="icons['faHeart']" size="lg" class="gray mr-3"></fa-icon>
        Don't like
      </button>
      <button mat-menu-item (click)="setLike(likeTypes.none)">
        <fa-icon [icon]="icons['faHeartEmpty']" size="lg" class="gray mr-3"></fa-icon>
        (none)
      </button>
      <button mat-menu-item (click)="selectHighlightStrategy('likes')">
        <img class="rs-middle" src="/assets/markers/disk24red.png" /> Show Likes on the Map
      </button>
    </mat-menu>
  </ng-template>

  <ng-container *ngIf="savingGoto$ | async as items">
    <div *ngIf="items.includes(storeid); else noSavingGoto" class="rs-like-button rs-like">
      <fa-icon [icon]="icons['faSpinner']" [spin]="true" size="2x" class="mr-3"></fa-icon>
      <span>saving</span>
    </div>
  </ng-container>
  <ng-template #noSavingGoto>
    <div
      *ngIf="goto$ | async as goto; else nogoto"
      class="rs-like-button rs-goto"
      mat-button
      [matMenuTriggerFor]="gotomenu"
    >
      <fa-icon *ngIf="goto === gotoTypes.goto" [icon]="icons['faPlusSquare']" size="2x" class="red mr-3"></fa-icon>
      <fa-icon *ngIf="goto === gotoTypes.gone" [icon]="icons['faCheckSolid']" size="2x" class="green mr-3"></fa-icon>
      <fa-icon *ngIf="goto === gotoTypes.dont" [icon]="icons['faMinusSquare']" size="2x" class="gray mr-3"></fa-icon>
      <span *ngIf="goto === gotoTypes.goto">Want to go</span>
      <span *ngIf="goto === gotoTypes.gone">Been there</span>
      <span *ngIf="goto === gotoTypes.dont">Don't go</span>
    </div>
    <ng-template #nogoto>
      <div class="rs-like-button rs-goto" mat-button [matMenuTriggerFor]="gotomenu">
        <fa-icon [icon]="icons['faSquare']" size="2x" class="gray mr-3"></fa-icon>
        <span>Go to?</span>
      </div>
    </ng-template>
    <mat-menu #gotomenu="matMenu">
      <button mat-menu-item (click)="setGoto(gotoTypes.goto)">
        <fa-icon [icon]="icons['faPlusSquare']" size="lg" class="red mr-3"></fa-icon>
        Want to go
      </button>
      <button mat-menu-item (click)="setGoto(gotoTypes.gone)">
        <fa-icon [icon]="icons['faCheckSolid']" size="lg" class="green mr-3"></fa-icon>
        Been there
      </button>
      <button mat-menu-item (click)="setGoto(gotoTypes.dont)">
        <fa-icon [icon]="icons['faMinusSquare']" size="lg" class="gray mr-3"></fa-icon>
        Don't go
      </button>
      <button mat-menu-item (click)="setGoto(gotoTypes.none)">
        <fa-icon [icon]="icons['faSquare']" size="lg" class="gray mr-3"></fa-icon>
        (none)
      </button>
      <button mat-menu-item (click)="selectHighlightStrategy('gotos')">
        <img class="rs-middle" src="/assets/markers/disk24red.png" /> Show Gotos on the Map
      </button>
    </mat-menu>
  </ng-template>

  <ng-container *ngIf="savingColour$ | async as items">
    <div *ngIf="items.includes(storeid); else noSavingColour" class="rs-like-button rs-like">
      <fa-icon [icon]="icons['faSpinner']" [spin]="true" size="2x" class="mr-3"></fa-icon>
      <span>saving</span>
    </div>
  </ng-container>
  <ng-template #noSavingColour>
    <div
      *ngIf="colour$ | async as colour"
      class="rs-like-button rs-my-colour"
      mat-button
      [matMenuTriggerFor]="colourmenu"
    >
      <span class="rs-my-colour-img"><img [src]="'/assets/markers/disk24' + colour + '.png'" width="24" /></span>
      My Colour
    </div>
    <mat-menu #colourmenu="matMenu">
      <button mat-menu-item class="rs-my-colour-button" (click)="setColour('black')">
        <img src="/assets/markers/disk24black.png" />
      </button>
      <button mat-menu-item class="rs-my-colour-button" (click)="setColour('red')">
        <img src="/assets/markers/disk24red.png" />
      </button>
      <button mat-menu-item class="rs-my-colour-button" (click)="setColour('blue')">
        <img src="/assets/markers/disk24blue.png" />
      </button>
      <button mat-menu-item class="rs-my-colour-button" (click)="setColour('green')">
        <img src="/assets/markers/disk24green.png" /></button
      ><br />
      <button mat-menu-item class="rs-my-colour-button" (click)="setColour('orange')">
        <img src="/assets/markers/disk24orange.png" />
      </button>
      <button mat-menu-item class="rs-my-colour-button" (click)="setColour('yellow')">
        <img src="/assets/markers/disk24yellow.png" />
      </button>
      <button mat-menu-item class="rs-my-colour-button" (click)="setColour('violet')">
        <img src="/assets/markers/disk24violet.png" />
      </button>
      <button mat-menu-item class="rs-my-colour-button" (click)="setColour('gray')">
        <img src="/assets/markers/disk24gray.png" /></button
      ><br />
      <button mat-menu-item (click)="selectHighlightStrategy('mycolours')">
        <img class="rs-middle" src="/assets/markers/disk24red.png" /> Show Colours on the Map
      </button>
    </mat-menu>
  </ng-template>

  <ng-container *ngIf="savingVisit$ | async as items">
    <div *ngIf="items.includes(storeid); else noSavingVisit" class="rs-like-button rs-like">
      <fa-icon [icon]="icons['faSpinner']" [spin]="true" size="2x" class="mr-3"></fa-icon>
      <span>saving</span>
    </div>
  </ng-container>
  <ng-template #noSavingVisit>
    <div class="rs-like-button rs-herenow" mat-button [matMenuTriggerFor]="visitmenu">
      <fa-icon [icon]="icons['faFlagCheckered']" size="2x" class="mr-3"></fa-icon>
      <span *ngIf="visit$ | async as visit; else novisit1">{{ helper.n(visit.visits, 'Visit') }}</span>
      <ng-template #novisit1>My Visits</ng-template>
    </div>
    <mat-menu #visitmenu="matMenu">
      <button mat-menu-item (click)="saveVisit()">
        <fa-icon [icon]="icons['faFlagCheckered']" size="lg" class="mr-3"></fa-icon>
        I'm Here Now
      </button>
      <ng-container *ngIf="visit$ | async as visit">
        <button *ngIf="visit.visits > 0" mat-menu-item (click)="toggleVisits()">
          <fa-icon [icon]="icons.faClipboardList" size="lg" class="mr-3"></fa-icon>
          {{ showVisits ? 'Hide My Visits' : 'Show My Visits' }}
        </button>
      </ng-container>
      <span class="rs-menu-info">{{ lastVisit }}</span>
    </mat-menu>
  </ng-template>
</div>

<ng-container *ngIf="showVisits">
  <rs-visits-in-store [visits]="myVisits$ | async"></rs-visits-in-store>
  <p>
    <input type="button" value="I'm Here Now" class="mat-flat-button rs-button" (click)="saveVisit()" />
    &nbsp;
    <input type="button" value="Hide My Visits" class="mat-button rs-alt-button" (click)="toggleVisits()" />
  </p>
</ng-container>
