import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '@shared/shared.module';
import { CoreModule } from './core/core.module';

import { GlobalModule } from '@modules/global/global.module';
import { CountriesModule } from '@modules/countries/countries.module';
import { CitiesModule } from '@modules/cities/cities.module';
import { ShopsModule } from '@modules/shops/shops.module';
import { SearchModule } from '@modules/search/search.module';
import { PositionModule } from '@modules/position/position.module';
import { MarkersModule } from '@modules/markers/markers.module';
import { AuthModule } from '@modules/auth/auth.module';
import { DialogsModule } from '@modules/dialogs/dialogs.module';
import { PinsModule } from '@modules/pins/pins.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { AuthInterceptor } from '@app/modules/auth/services/auth.interceptor';
import { environment } from '../environments/environment';
import { MapModule } from '@modules/map/map.module';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  //  FacebookLoginProvider
} from '@abacritt/angularx-social-login';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AuthModule,
    CitiesModule,
    CoreModule,
    CountriesModule,
    DialogsModule,
    GlobalModule,
    MapModule,
    MarkersModule,
    PinsModule,
    PositionModule,
    SearchModule,
    SharedModule,
    ShopsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    StorageServiceModule,
    NgImageFullscreenViewModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    SocialLoginModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '720241531748-c916b8cm1pr8hec2a7ojaquovvcd5m4e.apps.googleusercontent.com'
            ),
          },
          //        {
          //          id: FacebookLoginProvider.PROVIDER_ID,
          //          provider: new FacebookLoginProvider('clientId')
          //        }
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
