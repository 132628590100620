import { HttpClient } from '@angular/common/http';
import { EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { selectUser } from '@app/modules/auth/store/auth.selectors';
import { ShopDetails } from '@shared/classes';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

@Component({
  selector: 'rs-store-has-closed-form',
  templateUrl: './store-has-closed-form.component.html',
  styleUrls: [],
})
export class StoreHasClosedFormComponent implements OnInit, OnChanges {
  @Input() shop!: ShopDetails;
  @Input() reason = '';
  @Output() closeForm = new EventEmitter<boolean>();

  user$ = this.store.select(selectUser);

  storeForm: FormGroup = this.fb.group({
    name: [''],
    email: ['', Validators.email],
    descript: [''],
  });
  sending = false;
  sent = false;

  faSpinnerIcon = faSpinner;

  constructor(private fb: FormBuilder, private http: HttpClient, private store: Store) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.initForm();
    this.setFormValues(this.shop);
  }

  submitForm() {
    const formValue = this.storeForm.value;
    const newStore: ShopDetails = {
      ...formValue,
    };

    const subject = `${this.reason} ${this.shop.id} ${this.shop.name} in ${this.shop.city} / ${this.shop.countrycode}`;
    const email = formValue.email;
    const from = `https://www.recordstores.love/${this.shop.id}`;

    let userInfo = 'not authenticated';
    this.user$.pipe(first()).subscribe((user) => {
      userInfo = user ? `User: ID = ${user.id} - name = ${user.username} - email = ${user.email}` : 'not authenticated';
    });

    this.sending = true;
    this.http
      .post('/api/contact', {
        email,
        subject,
        message:
          'from: ' +
          from +
          '\n' +
          'user: ' +
          userInfo +
          '\n' +
          'store: ' +
          `${this.shop.name} in ${this.shop.city} / ${this.shop.countrycode}` +
          '\n' +
          'email: ' +
          formValue.email +
          '\n' +
          'name: ' +
          formValue.name +
          '\n' +
          'reason: ' +
          this.reason +
          '\n\n' +
          formValue.descript,
      })
      .subscribe(() => {
        this.sending = false;
        this.sent = true;
      });

    //this.storeForm.reset();
    //this.closeForm.emit(true);
  }

  close() {
    this.storeForm.reset();
    this.closeForm.emit(false);
  }

  private initForm() {
    let email = '';
    this.user$.pipe(first()).subscribe((user) => {
      email = user ? user.email : '';
    });

    this.storeForm = this.fb.group({
      name: [''],
      email: [email, Validators.email],
      descript: [''],
    });
  }

  private setFormValues(store: ShopDetails) {
    //this.storeForm.patchValue(store);
  }
}
