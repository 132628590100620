<h2 mat-dialog-title>Signin to recordstores.love</h2>

<mat-dialog-content>
  <div class="rs-store-form rs-form mb-3">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <mat-form-field>
        <input matInput type="email" formControlName="email" placeholder="Your Email" class="rs-bold" autofocus />
        <mat-error *ngIf="form.get('email')?.invalid && form.get('email')?.touched">Please enter your Email</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="password" formControlName="password" placeholder="Your Password" />
        <mat-error *ngIf="form.get('password')?.invalid && form.get('password')?.touched"
          >Please enter your Password</mat-error
        >
      </mat-form-field>

      <p class="rs-small">
        Forgot your password? Enter your Email and
        <span class="rs-pointer rs-blue" (click)="forgotPassword()">click here</span>. Password reset instructions will
        be sent via email.
      </p>

      <div style="text-align: right">
        <a style="visibility: hidden" mat-flat-button class="rs-button">Contact</a>
        <input type="button" value="Cancel" class="mat-button rs-alt-button" (click)="close()" />
        <input
          type="submit"
          value="Login"
          class="mat-flat-button rs-button mr-3"
          [class.disabled]="!form.valid || (authenticating$ | async)"
          [disabled]="!form.valid || (authenticating$ | async)"
        />
      </div>
    </form>
  </div>

  <p *ngIf="authenticating$ | async; else google" class="red">
    <fa-icon title="Trying to sign in, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
    Logging in...
  </p>

  <ng-template #google>
    <p>You can also sign in with Google:</p>

    <div>
      <img (click)="loginWithGoogle()" src="/assets/bilder/btn_google_signin_dark_normal_web.png" class="rs-pointer" />
    </div>
  </ng-template>
</mat-dialog-content>
