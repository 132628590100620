import { Component, OnInit } from '@angular/core';
import { activateOtherDiv } from '@app/modules/global/store/global.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: [],
})
export class ImprintComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(activateOtherDiv());
  }
}
