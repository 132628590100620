import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ContactDialogSettings {
  title = 'Contact';
  namePlaceholder = 'Your Name';
  emailPlaceholder = 'Your Email';
  messagePlaceholder = 'Your Message';
}
export class ContactDialogData {
  name = '';
  email = '';
  message = '';
}
export class ContactDialogOptions {
  from: string; // from where this dialog was called (url)
  settings: ContactDialogSettings = new ContactDialogSettings();
  data: ContactDialogData = new ContactDialogData();

  constructor(from: string) {
    this.from = from;
  }
}

@Component({
  selector: 'rs-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit {
  contactForm: FormGroup = this.fb.group({
    name: [this.options.data.name, Validators.required],
    email: [this.options.data.email, [Validators.required, Validators.email]],
    message: [this.options.data.message, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public options: ContactDialogOptions,
    private http: HttpClient
  ) {}

  ngOnInit() {}

  save() {
    if (this.contactForm.valid) {
      const formValue = this.contactForm.value;

      this.http
        .post('/api/contact', {
          email: formValue.email,
          subject: this.options.from,
          message:
            'from: ' +
            this.options.from +
            '\n' +
            'email: ' +
            formValue.email +
            '\n' +
            'name: ' +
            formValue.name +
            '\n\n' +
            formValue.message,
        })
        .subscribe();
      this.contactForm.reset();
      this.dialogRef.close(true);
      return true;
    } else {
      return false;
    }
  }

  close() {
    this.contactForm.reset();
    this.dialogRef.close(false);
  }
}
