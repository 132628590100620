import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromCities from './city.reducer';
import * as CityActions from './city.actions';
import * as GlobalActions from '@app/modules/global/store/global.actions';
import { DataService } from '@app/core/services/data.service';
import { Store } from '@ngrx/store';
import { selectCitiesLoaded, selectCitiesLoading } from './city.selectors';
import { zoomChanged } from '@app/modules/map/store/map.actions';
import { selectCitiesVisible } from '@app/modules/map/store/map.selectors';

@Injectable()
export class CityEffects {
  loadCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CityActions.loadCities),
      concatMap(() =>
        this.data.getAllCities().pipe(
          map((cities) => CityActions.loadCitiesSuccess({ cities })),
          catchError((error) => of(CityActions.loadCitiesFailure({ error })))
        )
      )
    )
  );

  // Ein paar Divs brauchen Countries - wenn noch nicht geladen, dann anstoßen.
  loadCitiesOnActivate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        GlobalActions.activateCountryDiv,
        GlobalActions.activateStateDiv,
        GlobalActions.activateCityDiv,
        GlobalActions.activateNearDiv,
        GlobalActions.activateHomeDiv,
        GlobalActions.activateVisibleDiv,
        GlobalActions.needsMap
      ),
      withLatestFrom(this.store.select(selectCitiesLoading), this.store.select(selectCitiesLoaded)),
      filter(([action, loading, loaded]) => !loading && !loaded),
      map(([action, loading, loaded]) => CityActions.loadCities())
    )
  );

  loadCitiesOnZoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(zoomChanged),
      withLatestFrom(this.store.select(selectCitiesLoaded), this.store.select(selectCitiesVisible)),
      filter(([action, loaded, visible]) => visible && !loaded),
      map(() => CityActions.loadCities())
    )
  );

  constructor(private actions$: Actions, private data: DataService, private store: Store<fromCities.CityState>) {}
}
