/* eslint-disable @typescript-eslint/naming-convention */

export interface ShopEssentials {
  id: number;
  place_id?: string;
  google_url?: string | null;

  city: string;
  state: string;
  countrycode: string;

  lat: number;
  lng: number;
}

export interface ShopEssentialsRaw {
  id: string;
  place_id?: string;
  google_url: string | null;

  city: string;
  state: string;
  countrycode: string;

  lat: string;
  lng: string;
}

export class ShopEssentialsFactory {
  static new(): ShopEssentials {
    return {
      id: 0,
      place_id: '',
      google_url: '',

      city: '',
      state: '',
      countrycode: '',

      lat: 0,
      lng: 0,
    };
  }
  static fromRaw(raw: ShopEssentialsRaw): ShopEssentials {
    return {
      // nicht ...raw um überflüssige Felder nicht mitzunehmen
      id: parseInt(raw.id, 10),
      place_id: raw.place_id,
      google_url: raw.google_url,

      city: raw.city,
      state: raw.state,
      countrycode: raw.countrycode,

      lat: parseFloat(raw.lat),
      lng: parseFloat(raw.lng),
    };
  }
}
