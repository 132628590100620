<div class="rs-panel rs-header inverse">
  <rs-user-image
    *ngIf="authenticated$ | async; else burger"
    [width]="38"
    class="rs-user-image"
    (click)="toggleNav()"
  ></rs-user-image>
  <ng-template #burger>
    <fa-icon (click)="toggleNav()" [icon]="faBars" class="rs-icon flex-item-3" title="Click to open Menu"></fa-icon>
  </ng-template>
  <div class="flex-item-2 inverse logo-container">
    <div class="rs-wwwrecordstoreslove" routerLink="/">
      <span class="rs-gray">www.</span>recordstores.<span class="rs-red">love</span>
    </div>
  </div>
  <fa-icon
    routerLink="/search"
    [fixedWidth]="true"
    [icon]="faSearch"
    title="Search"
    class="rs-icon flex-item-3 rs-no-outline"
  ></fa-icon>
</div>
