/* eslint-disable radix */
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStateService } from '@core/services/app-state.service';
import { State, Country, Pin, CityListItem, ShopBasics } from '@shared/classes';
import { HelperService } from '@core/services/helper.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { clearSearch, searchBackend } from '../store/search.actions';
import { SearchFusedResult, selectSearchFusedBackendResults } from '../store/search.selectors';
import { MarkersService } from '@core/services/markers.service';
import { GooglePlacesService } from '@core/services/google-places.service';
import { activateSearchDiv } from '@app/modules/global/store/global.actions';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Unsubscriber } from '@app/framework/unsubscriber';

type Prediction = google.maps.places.AutocompletePrediction;

@Component({
  selector: 'rs-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent extends Unsubscriber implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef;

  keyUp$ = new Subject<EventTarget | null>();
  value = '';
  faArrow = faArrowLeft;

  results: SearchFusedResult | null = null;
  focusedItem: ShopBasics | CityListItem | State | Country | Pin | Prediction | null = null;
  items: (ShopBasics | CityListItem | State | Country | Pin | Prediction)[] = [];

  searchResults$ = this.store.select(selectSearchFusedBackendResults);

  //place: google.maps.places.PlaceResult | undefined;

  selectedPlace: google.maps.places.PlaceResult | undefined;
  openedPlace: ShopBasics | CityListItem | State | Country | Pin | Prediction | null = null;

  constructor(
    private route: ActivatedRoute,
    private appState: AppStateService,
    private cdr: ChangeDetectorRef,
    private googlePlacesService: GooglePlacesService,
    public helper: HelperService,
    private markersService: MarkersService,
    private router: Router,
    private store: Store
  ) {
    super();

    // Beim Start mit dieser Komponente muss der MarkerService irgendwie instanziiert werden,
    // sonst fehlt er.
    this.markersService.hoverOff();

    this.value =
      this.route.snapshot.queryParams.q ??
      this.route.snapshot.paramMap.get('query') ??
      this.appState.dictionary.get('Search.Query') ??
      '';
  }

  ngOnInit(): void {
    this.store.dispatch(activateSearchDiv());
    this.appState.setActiveTitle('Search for record stores on recordstores.love');

    // Über den ExactSearchResolver könnte schon ein Suchergebnis geladen sein.
    // Das wird aufgerufen, wenn man über eine url hierher gelang und sollte -
    // wenn es genau ein Ergebnis gibt - nicht SearchComponent anzeigen sondern
    // direkt weiterleiten.
    this.route.data.pipe(first()).subscribe((data) => {
      if (data.exactsearch) {
        const res = data.exactsearch;
        if (res.stores?.length === 1) {
          this.gotoStore(res.stores[0]);
        } else if (res.cities?.length === 1) {
          this.gotoCity({ city: res.cities[0] });
        } else if (res.countries?.length === 1) {
          this.gotoCountry(res.countries[0]);
        } else if (res.states?.length === 1) {
          this.gotoState(res.states[0]);
        }
        console.log('found exact search result', data.exactsearch);
      }
    });

    if (this.value) {
      this.store.dispatch(searchBackend({ term: this.value }));
    }

    this.unsubscribeLater(
      this.searchResults$.subscribe((x) => {
        this.setSearchResults(x);
      })
    );

    this.keyUp$
      .pipe(
        map((target) => (target as HTMLInputElement).value),
        distinctUntilChanged(),
        tap(() => (this.selectedPlace = undefined)),
        tap(() => (this.openedPlace = null)),
        filter((term) => this.checkTerm(term)),
        debounceTime(500)
      )
      .subscribe((term) => this.store.dispatch(searchBackend({ term })));
  }

  ngAfterViewInit(): void {
    this.searchInput.nativeElement.focus();
  }

  // checkTerm liefert true wenn der Term zum Backend geschickt werden soll und false sonst.
  // Außerdem wird der term gespeichert oder die bisherigen Ergebnisse gelöscht.
  checkTerm(term: string): boolean {
    if (term.length >= 2 || parseInt(term).toString() === term) {
      this.appState.dictionary.set('Search.Query', term);
      return true;
    } else {
      this.clearResults();
      return false;
    }
  }

  clearInput() {
    this.value = '';
    this.clearResults();
  }

  clearResults() {
    this.store.dispatch(clearSearch());
    this.items = [];
    this.focusedItem = null;
  }

  updateItems() {
    this.focusedItem = null;
  }

  setSearchResults(results: SearchFusedResult | null) {
    this.items = [];
    this.results = results;
    this.focusedItem = null;

    if (results) {
      if (results.what === 'country') {
        this.items = [...results.countries, ...results.cities];
      } else {
        this.items = [
          ...results.stores,
          ...results.cities,
          ...results.states,
          ...results.countries,
          ...results.pins,
          ...results.places,
        ];
      }
      this.focusedItem = this.items.length ? this.items[0] : null;
    }
    this.cdr.detectChanges();
  }

  gotoItem() {
    if (this.focusedItem) {
      let i = 0;
      while (i < this.items.length && this.items[i] !== this.focusedItem) {
        i++;
      }

      if (this.results) {
        const r = this.results;

        if (this.results.what === 'country') {
          if (i === 0) {
            this.gotoCountry(this.focusedItem as Country);
          } else {
            this.gotoCity(this.focusedItem as CityListItem);
          }
        } else {
          if (i < this.results.stores.length) {
            this.gotoStore(this.focusedItem as ShopBasics);
          } else if (i < r.stores.length + r.cities.length) {
            this.gotoCity(this.focusedItem as CityListItem);
          } else if (i < r.stores.length + r.cities.length + r.states.length) {
            this.gotoState(this.focusedItem as State);
          } else if (i < r.stores.length + r.cities.length + r.states.length + r.countries.length) {
            this.gotoCountry(this.focusedItem as Country);
          } else if (i < r.stores.length + r.cities.length + r.states.length + r.countries.length + r.pins.length) {
            this.gotoPin(this.focusedItem as Pin);
          } else {
            this.gotoPlace(this.focusedItem as Prediction);
          }
        }
      }
    }
  }

  gotoCountry(country: Country) {
    this.router.navigateByUrl('/' + country.countrycode);
  }

  gotoState(state: State) {
    this.router.navigateByUrl('/' + state.countrycode + '/' + state.state);
  }

  gotoCity(city: CityListItem) {
    this.router.navigateByUrl(this.helper.city2url(city.city));
  }

  gotoStore(store: ShopBasics) {
    this.router.navigateByUrl('/' + store.id.toString());
  }

  gotoPin(pin: Pin) {
    console.warn('TODO: gotoPin');
  }

  gotoPlace(place: Prediction) {
    this.focusedItem = place;
    this.googlePlacesService.getPlaceDetails(place.place_id, false).subscribe((x) => {
      this.selectedPlace = x;
      this.openedPlace = place;
      this.cdr.detectChanges();
    });
  }

  handleKeyUp(key: string) {
    // Egal welche Taste: Wenn noch kein focusedItem selektiert ist, dann das erste selektieren.

    if (!this.focusedItem && this.items.length > 0) {
      this.focusedItem = this.items[0];
    } else {
      if (key === 'UP') {
        for (let i = 0; i <= this.items.length - 2; i++) {
          if (this.items[i + 1] === this.focusedItem) {
            this.focusedItem = this.items[i];
          }
        }
      }

      if (key === 'DOWN') {
        for (let i = this.items.length - 1; i > 0; i--) {
          if (this.items[i - 1] === this.focusedItem) {
            this.focusedItem = this.items[i];
          }
        }
      }
    }

    if (key === 'ENTER') {
      this.gotoItem();
    }
  }
}
