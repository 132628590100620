import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'rs-title-subtitle-item',
  templateUrl: './title-subtitle-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [],
})
export class TitleSubtitleItemComponent {
  @Input() title = '';
  @Input() subtitle = '';

  constructor() {}
}
