import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromGlobal from './store/global.reducer';
import { EffectsModule } from '@ngrx/effects';
import { GlobalEffects } from './store/global.effects';
import { PositionModule } from '@app/modules/position/position.module';

@NgModule({
  declarations: [],
  imports: [
    PositionModule,
    StoreModule.forFeature(fromGlobal.globalFeatureKey, fromGlobal.reducer),
    EffectsModule.forFeature([GlobalEffects]),
  ],
})
export class GlobalModule {}
