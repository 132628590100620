import { NgModule } from '@angular/core';

import { MapComponent } from './map/map.component';
import { DialogsModule } from '@app/modules/dialogs/dialogs.module';
import { StoreModule } from '@ngrx/store';
import * as fromMap from './store/map.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MapEffects } from './store/map.effects';
import { MapLayersComponent } from './map-layers/map-layers.component';
import { MapHighlightsComponent } from './map-highlights/map-highlights.component';
import { MapWhereamiComponent } from './map-whereami/map-whereami.component';
import { MapDetailsComponent } from './map-details/map-details.component';
import { SharedModule } from '@shared/shared.module';
import { HighlightStrategiesComponent } from './components/highlight-strategies/highlight-strategies.component';

@NgModule({
  declarations: [
    MapComponent,
    MapDetailsComponent,
    MapLayersComponent,
    MapHighlightsComponent,
    MapWhereamiComponent,
    HighlightStrategiesComponent,
  ],
  imports: [
    SharedModule,
    DialogsModule,
    StoreModule.forFeature(fromMap.mapFeatureKey, fromMap.reducer),
    EffectsModule.forFeature([MapEffects]),
  ],
  exports: [MapComponent],
})
export class MapModule {}
