import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppStateService } from '@core/services/app-state.service';
import {
  faBars as faBurger,
  faMapMarkedAlt as faMap,
  faMapMarkerAlt as faStore,
  faList as faCity,
  faHome as faHome,
  faWalking as faNear,
  faCaretLeft as faClose,
  faCaretRight as faOpen,
} from '@fortawesome/free-solid-svg-icons';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatSidenav } from '@angular/material/sidenav';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { selectIsMobile, selectMapVisible } from '@app/modules/global/store/global.selectors';
import { FullScreenImage } from '@shared/classes/full-screen-image';
import { selectLoadingOrSearching } from '@app/modules/global/store/loading.selector';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('openAppDiv', [
      state('closed', style({ left: '-450px' })),
      state('opened', style({ left: '0' })),
      state('mobile', style({ left: '0' })),
      transition('closed=>opened', animate('1000ms')),
      transition('opened=>closed', animate('1000ms')),
    ]),
    trigger('mapSize', [
      state('closed', style({ left: '0' })),
      state('opened', style({ left: '450px' })),
      state('mobile', style({ left: '0' })),
      transition('closed=>opened', animate('1000ms')),
      transition('opened=>closed', animate('1000ms')),
    ]),
  ],
})
export class AppComponent extends Unsubscriber implements OnInit {
  title = 'rslove';

  loading$ = this.store.select(selectLoadingOrSearching);
  isMobile$ = this.store.select(selectIsMobile);
  mapVisible$ = this.store.select(selectMapVisible);

  fullScreenImages: FullScreenImage[] = [];
  showFullScreenImages = false;
  currentIndex = -1;

  // im desktop-Modus das div mit den Infos links neben der Karte.
  isMobile!: boolean;
  opened = true;
  currentState = 'opened';

  icons = {
    faMap,
    faStore,
    faCity,
    faHome,
    faNear,
    faOpen,
    faClose,
    faBurger,
  };

  constructor(
    public appState: AppStateService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private store: Store
  ) {
    super();

    iconRegistry.addSvgIcon('rslovelogo', sanitizer.bypassSecurityTrustResourceUrl('rslovelogo.svg'));
    iconRegistry.addSvgIcon('rslovelogorand', sanitizer.bypassSecurityTrustResourceUrl('rslovelogo_rand.svg'));

    this.appState.setActiveTitle('recordstores.love - the record store map');
  }

  @ViewChild('sidenav') set setSideNav(sidenav: MatSidenav) {
    this.appState.sideNav = sidenav;
  }

  @ViewChild('appdiv') set setAppDiv(appDiv: ElementRef) {
    this.appState.setAppDiv(appDiv);
  }

  ngOnInit() {
    this.unsubscribeLater(
      this.isMobile$.subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.updateCurrentState();
      }),

      this.appState.fullScreenImages$.subscribe((images) => {
        this.currentIndex = 0;
        this.fullScreenImages = images;
        this.showFullScreenImages = images.length > 0;
      })
    );
  }

  updateCurrentState() {
    this.currentState = this.isMobile ? 'mobile' : this.opened ? 'opened' : 'closed';
  }

  toggleOpen() {
    this.opened = !this.opened;
    this.updateCurrentState();
  }

  closeFullScreenImages() {
    this.showFullScreenImages = false;
    this.fullScreenImages = [];
    this.currentIndex = -1;
  }
}
