import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShopAddress } from '@app/shared/classes';

export interface AddressFormResult {
  address: ShopAddress;
  note: string;
}

@Component({
  selector: 'rs-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {
  @Input() address!: ShopAddress;
  @Input() withNote = true;
  @Output() closeForm = new EventEmitter<AddressFormResult | null>();

  form: FormGroup = this.fb.group({
    name: ['', Validators.required],
    address: ['', Validators.required],
    addinfo: [''],
    descript: [''],
    note: [''],
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.patchValue(this.address);
  }

  close() {
    this.closeForm.emit(null);
  }

  submit() {
    const formValue = this.form.value;

    const result: AddressFormResult = {
      address: {
        ...this.address,
        ...formValue,
      },
      note: formValue.note,
    };

    this.closeForm.emit(result);
  }
}
