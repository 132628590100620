/* eslint-disable @typescript-eslint/naming-convention */

export interface ShopTimes {
  times: string | null;

  times_mon_from: string | null;
  times_tue_from: string | null;
  times_wed_from: string | null;
  times_thu_from: string | null;
  times_fri_from: string | null;
  times_sat_from: string | null;
  times_sun_from: string | null;

  times_mon_until: string | null;
  times_tue_until: string | null;
  times_wed_until: string | null;
  times_thu_until: string | null;
  times_fri_until: string | null;
  times_sat_until: string | null;
  times_sun_until: string | null;

  times_mon_closed: boolean;
  times_tue_closed: boolean;
  times_wed_closed: boolean;
  times_thu_closed: boolean;
  times_fri_closed: boolean;
  times_sat_closed: boolean;
  times_sun_closed: boolean;
}

export interface ShopTimesRaw {
  times: string | null;

  times_mon_from: string | null;
  times_tue_from: string | null;
  times_wed_from: string | null;
  times_thu_from: string | null;
  times_fri_from: string | null;
  times_sat_from: string | null;
  times_sun_from: string | null;

  times_mon_until: string | null;
  times_tue_until: string | null;
  times_wed_until: string | null;
  times_thu_until: string | null;
  times_fri_until: string | null;
  times_sat_until: string | null;
  times_sun_until: string | null;

  times_mon_closed: string;
  times_tue_closed: string;
  times_wed_closed: string;
  times_thu_closed: string;
  times_fri_closed: string;
  times_sat_closed: string;
  times_sun_closed: string;
}

export class ShopTimesFactory {
  static new() {
    return {
      times: '',
      times_mon_closed: false,
      times_mon_from: '',
      times_mon_until: '',
      times_tue_closed: false,
      times_tue_from: '',
      times_tue_until: '',
      times_wed_closed: false,
      times_wed_from: '',
      times_wed_until: '',
      times_thu_closed: false,
      times_thu_from: '',
      times_thu_until: '',
      times_fri_closed: false,
      times_fri_from: '',
      times_fri_until: '',
      times_sat_closed: false,
      times_sat_from: '',
      times_sat_until: '',
      times_sun_closed: false,
      times_sun_from: '',
      times_sun_until: '',
    };
  }
  static fromRaw(raw: ShopTimesRaw): ShopTimes {
    return {
      // nicht ...raw um überflüssige Felder nicht mitzunehmen
      times: raw.times,

      times_mon_from: raw.times_mon_from,
      times_tue_from: raw.times_tue_from,
      times_wed_from: raw.times_wed_from,
      times_thu_from: raw.times_thu_from,
      times_fri_from: raw.times_fri_from,
      times_sat_from: raw.times_sat_from,
      times_sun_from: raw.times_sun_from,

      times_mon_until: raw.times_mon_until,
      times_tue_until: raw.times_tue_until,
      times_wed_until: raw.times_wed_until,
      times_thu_until: raw.times_thu_until,
      times_fri_until: raw.times_fri_until,
      times_sat_until: raw.times_sat_until,
      times_sun_until: raw.times_sun_until,

      times_mon_closed: raw.times_mon_closed === '1',
      times_tue_closed: raw.times_tue_closed === '1',
      times_wed_closed: raw.times_wed_closed === '1',
      times_thu_closed: raw.times_thu_closed === '1',
      times_fri_closed: raw.times_fri_closed === '1',
      times_sat_closed: raw.times_sat_closed === '1',
      times_sun_closed: raw.times_sun_closed === '1',
    };
  }
}

export enum OpenNowType {
  closed,
  opensoon,
  opennow,
  wasopen,
  unknown,
}
export class ShopTimesHelper {
  static getNew(): ShopTimes {
    return {
      times: '',
      times_mon_from: null,
      times_mon_until: null,
      times_mon_closed: true,
      times_tue_from: null,
      times_tue_until: null,
      times_tue_closed: true,
      times_wed_from: null,
      times_wed_until: null,
      times_wed_closed: true,
      times_thu_from: null,
      times_thu_until: null,
      times_thu_closed: true,
      times_fri_from: null,
      times_fri_until: null,
      times_fri_closed: true,
      times_sat_from: null,
      times_sat_until: null,
      times_sat_closed: true,
      times_sun_from: null,
      times_sun_until: null,
      times_sun_closed: true,
    };
  }
  static getOpenNow(
    today: string | null,
    from: string | null,
    until: string | null,
    closed: boolean | null
  ): OpenNowType {
    if (today) {
      if (closed) {
        return OpenNowType.closed;
      } else {
        if (from && until) {
          const d = new Date();
          const h = d.getHours();
          const m = d.getMinutes();
          const now = (h < 10 ? '0' : '') + h.toString() + ':' + (m < 10 ? '0' : '') + m.toString();

          if (now < from) {
            return OpenNowType.opensoon;
          } else if (now >= from && now <= until) {
            return OpenNowType.opennow;
          } else if (now > until) {
            return OpenNowType.wasopen;
          }
        }
      }
    }
    return OpenNowType.unknown;
  }

  static getTimesFromPlace(place: google.maps.places.PlaceResult): ShopTimes {
    const result = this.getNew();

    if (place.opening_hours && place.opening_hours.periods) {
      const getMinOpen = (res: string | null, pl: string) => (res === null ? pl : pl < res ? pl : res);
      const getMaxOpen = (res: string | null, pl: string) => (res === null ? pl : pl > res ? pl : res);

      place.opening_hours.periods.forEach((item) => {
        if (item.open && item.open.day === 0) {
          result.times_sun_closed = false;
          if (item.open && item.close) {
            result.times_sun_from = getMinOpen(result.times_sun_from, item.open.time);
            result.times_sun_until = getMaxOpen(result.times_sun_until, item.close.time);
          }
        }
        if (item.open && item.open.day === 1) {
          result.times_mon_closed = false;
          if (item.open && item.close) {
            result.times_mon_from = getMinOpen(result.times_mon_from, item.open.time);
            result.times_mon_until = getMaxOpen(result.times_mon_until, item.close.time);
          }
        }
        if (item.open && item.open.day === 2) {
          result.times_tue_closed = false;
          if (item.open && item.close) {
            result.times_tue_from = getMinOpen(result.times_tue_from, item.open.time);
            result.times_tue_until = getMaxOpen(result.times_tue_until, item.close.time);
          }
        }
        if (item.open && item.open.day === 3) {
          result.times_wed_closed = false;
          if (item.open && item.close) {
            result.times_wed_from = getMinOpen(result.times_wed_from, item.open.time);
            result.times_wed_until = getMaxOpen(result.times_wed_until, item.close.time);
          }
        }
        if (item.open && item.open.day === 4) {
          result.times_thu_closed = false;
          if (item.open && item.close) {
            result.times_thu_from = getMinOpen(result.times_thu_from, item.open.time);
            result.times_thu_until = getMaxOpen(result.times_thu_until, item.close.time);
          }
        }
        if (item.open && item.open.day === 5) {
          result.times_fri_closed = false;
          if (item.open && item.close) {
            result.times_fri_from = getMinOpen(result.times_fri_from, item.open.time);
            result.times_fri_until = getMaxOpen(result.times_fri_until, item.close.time);
          }
        }
        if (item.open && item.open.day === 6) {
          result.times_sat_closed = false;
          if (item.open && item.close) {
            result.times_sat_from = getMinOpen(result.times_sat_from, item.open.time);
            result.times_sat_until = getMaxOpen(result.times_sat_until, item.close.time);
          }
        }
      });
    }
    return result;
  }
}
