<div>
  <ngx-kjua
    [text]="url"
    [ecLevel]="'H'"
    [size]="width"
    [fill]="'#222'"
    [rounded]="100"
    [quiet]="1"
    [render]="'image'"
    [cssClass]="'qrcodediv'"
  ></ngx-kjua>
</div>
