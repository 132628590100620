import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class Unsubscriber implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private unsubscribeCallbacks: (() => void)[] = [];

  ngOnDestroy(): void {
    this.unsubscribeNow();
  }

  protected unsubscribeLater(...subscriptions: Subscription[]): void {
    this.subscriptions.push(...subscriptions);
  }

  protected doOnDestroy(...callbacks: (() => void)[]): void {
    this.unsubscribeCallbacks.push(...callbacks);
  }

  protected clearOnDestroy(...timeouts: ReturnType<typeof setTimeout>[]): void {
    this.doOnDestroy(...timeouts.map((ref) => () => clearTimeout(ref)));
  }

  protected unsubscribeNow(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
    this.unsubscribeCallbacks.forEach((cb) => cb());
    this.unsubscribeCallbacks = [];
  }
}
