import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class MessageDialogOptions {
  title: string;
  message: string;
  submit: string;
  cancel: string | undefined;

  constructor(title: string, message: string, submit = 'OK', cancel: string | undefined = undefined) {
    this.title = title;
    this.message = message;
    this.submit = submit;
    this.cancel = cancel;
  }
}

@Component({
  selector: 'rs-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public options: MessageDialogOptions,
    private ngZone: NgZone
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }
}
