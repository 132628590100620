import { LatLng, LatLngBounds, Pin, PinListItem, ShopBasics, ShopListItem } from '@shared/classes';

export class PositionHelpers {
  static getLatFactorFor1Km(pos: LatLng) {
    return 1 / this.getDistanceBetween(pos, { lat: pos.lat + 1, lng: pos.lng });
  }

  static getLngFactorFor1Km(pos: LatLng) {
    return 1 / this.getDistanceBetween(pos, { lat: pos.lat, lng: pos.lng + 1 });
  }

  static getDistanceBetween(pos1: LatLng, pos2: LatLng): number {
    const dLat = ((pos2.lat - pos1.lat) * Math.PI) / 180.0;
    const dLon = ((pos2.lng - pos1.lng) * Math.PI) / 180.0;
    const lat1 = (pos1.lat * Math.PI) / 180.0;
    const lat2 = (pos2.lat * Math.PI) / 180.0;

    const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const R = 6373.0; // ungefährer Erdradius

    const distance = R * c;
    return distance; // km
  }

  static getDirection(start: LatLng, target: LatLng): string {
    // Egal wo ich bin : lat ist immer ca. 111.2 km pro Grad.
    const lat1 = 111.2;
    // Für lng gilt das nicht, da muss ich rechnen.
    const lng1 = PositionHelpers.getDistanceBetween(start, {
      lat: start.lat,
      lng: start.lng + 1.0,
    });

    // Jetzt berechne ich x und y per Dreisatz:
    const x = (target.lng - start.lng) * lng1;
    const y = (target.lat - start.lat) * lat1;

    // Jetzt eigtl simpel: x > 0 => Osten, x < 0 => Westen, y > 0 => Norden, y < 0 => Süden
    // Für NE, SW usw nutze ich die Geraden y = x * 2, y = x / 2, y = - x * 2 und y = - x / 2
    let result = '';
    if (x > 0 && y > 0) {
      if (y < x / 2) {
        result = 'E';
      } else if (y > 2 * x) {
        result = 'N';
      } else {
        result = 'NE';
      }
    }
    if (x < 0 && y > 0) {
      if (y < -x / 2) {
        result = 'W';
      } else if (y > -2 * x) {
        result = 'N';
      } else {
        result = 'NW';
      }
    }
    if (x > 0 && y < 0) {
      if (y > -x / 2) {
        result = 'E';
      } else if (y < -2 * x) {
        result = 'S';
      } else {
        result = 'SE';
      }
    }
    if (x < 0 && y < 0) {
      if (y > x / 2) {
        result = 'W';
      } else if (y < 2 * x) {
        result = 'S';
      } else {
        result = 'SW';
      }
    }

    return result;
  }

  static posInBounds(pos: LatLng, bounds: LatLngBounds, kmMargin: number = 1.0): boolean {
    let result = false;

    if (pos && bounds) {
      const lat1 = PositionHelpers.getLatFactorFor1Km(pos) * kmMargin;
      const lng1 = PositionHelpers.getLngFactorFor1Km(pos) * kmMargin;
      result =
        pos.lat >= bounds.latmin - lat1 &&
        pos.lat <= bounds.latmax + lat1 &&
        pos.lng >= bounds.lngmin - lng1 &&
        pos.lng <= bounds.lngmax + lng1;
    }

    return result;
  }

  static getNearestStores(stores: ShopBasics[], cnt: number, pos: LatLng, maxDist: number): ShopListItem[] {
    let result: ShopListItem[] = [];

    if (pos && stores.length > 0) {
      // 1km
      const lat1 = PositionHelpers.getLatFactorFor1Km(pos);
      const lng1 = PositionHelpers.getLngFactorFor1Km(pos);

      // Stores innerhalb eines Quadrates mit Seitenlänge = 2*km
      const inRing = (km: number) => {
        const dLat = km * lat1;
        const dLng = km * lng1;
        return stores.filter((store) => Math.abs(store.lat - pos.lat) < dLat && Math.abs(store.lng - pos.lng) < dLng);
      };

      // Solange den Kreis um 5km erhöhen, bis ich 2*cnt Stores hab (max maxDist Entfernung)
      let dist = 5;
      let storesInDist = inRing(dist);
      while (storesInDist.length < 2 * cnt && dist < maxDist) {
        storesInDist = inRing(dist);
        dist += 5;
      }

      // Für diese Stores Entfernung und Richtung berechnen
      storesInDist.forEach((store) => {
        result.push({
          direction: PositionHelpers.getDirection(pos, store),
          distance: PositionHelpers.getDistanceBetween(store, pos),
          store,
        });
      });
      result.sort(
        (a, b) => (a.distance !== undefined ? a.distance : 9999) - (b.distance !== undefined ? b.distance : 9999)
      );
      result = result.filter((x) => x.distance !== undefined && x.distance <= maxDist).slice(0, cnt);
    }
    return result;
  }

  static getNearestPins(pins: Pin[], cnt: number, pos: LatLng, maxDist: number): Pin[] {
    let result: PinListItem[] = [];

    if (pos && pins.length > 0) {
      // 1km
      const lat1 = PositionHelpers.getLatFactorFor1Km(pos);
      const lng1 = PositionHelpers.getLngFactorFor1Km(pos);

      // Stores innerhalb eines Quadrates mit Seitenlänge = 2*km
      const inRing = (km: number) => {
        const dLat = km * lat1;
        const dLng = km * lng1;
        return pins.filter((pin) => Math.abs(pin.lat - pos.lat) < dLat && Math.abs(pin.lng - pos.lng) < dLng);
      };

      // Solange den Kreis um 5km erhöhen, bis ich 2*cnt Stores hab (max maxDist Entfernung)
      let dist = 5;
      let pinsInDist = inRing(dist);
      while (pinsInDist.length < 2 * cnt && dist < maxDist) {
        pinsInDist = inRing(dist);
        dist += 5;
      }

      // Für diese Stores Entfernung und Richtung berechnen
      pinsInDist.forEach((pin) => {
        result.push({
          direction: PositionHelpers.getDirection(pos, pin),
          distance: PositionHelpers.getDistanceBetween(pin, pos),
          item: pin,
        });
      });
      result.sort(
        (a, b) => (a.distance !== undefined ? a.distance : 9999) - (b.distance !== undefined ? b.distance : 9999)
      );
      result = result.filter((x) => x.distance !== undefined && x.distance <= maxDist).slice(0, cnt);
    }
    return result.map((x) => x.item);
  }
}
