<div
  *ngIf="item"
  class="rs-panel-row rs-clickable rs-store-panel-row store-item-border noselect blueborder rs-border-bottom-lightgray"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
>
  <div class="store-item-container">
    <div class="store-item-icon">
      <img *ngIf="iconurl !== ''" src="{{ iconurl }}" />
    </div>
    <div class="store-item-title-container">
      <div class="store-item-title">
        <b>{{ item.name }}</b>
      </div>
      <div class="store-item-title-info">
        <span *ngIf="distance && distance >= 1.0">
          {{ distance | number: '1.1-1' }} km {{ direction }}
          <span class="rs-separator"> |</span>
        </span>
        <span *ngIf="distance && distance >= 0 && distance < 1.0"
          ><span class="near"> {{ distance * 1000.0 | number: '1.0-0' }} m {{ direction }}</span>
          <span class="rs-separator"> |</span>
        </span>
        {{ item.address }}
      </div>
    </div>
  </div>
</div>
