<rs-slider-dots
  *ngIf="imageSources.length > 0"
  [active]="slideshow.slideIndex"
  [count]="imageSources.length"
  (selectSlide)="selectSlide($event)"
>
</rs-slider-dots>

<div class="slideshow-container">
  <rs-slideshow
    #slideshow
    [height]="height"
    [minHeight]="'100px'"
    [autoPlay]="true"
    [autoPlayWaitForLazyLoad]="true"
    [autoPlayTransition]="'fade'"
    [autoPlayInterval]="5000"
    [backgroundSize]="'contain'"
    [showArrows]="true"
    [showDots]="false"
    [imageUrls]="imageSources"
    [lazyLoad]="true"
  >
  </rs-slideshow>
</div>

<section class="p-margins rs-expand-images-section" *ngIf="imageSources.length > 0">
  <div>
    <span (click)="showFullScreenImages()" class="expand-images-button">
      <fa-icon [fixedWidth]="true" [icon]="faExpand" size="lg"></fa-icon>
      <span style="margin-left: 0.5rem">Expand Images</span>
    </span>
  </div>
</section>
