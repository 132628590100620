/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, shareReplay } from 'rxjs/operators';
import {
  ExactSearchResult,
  ExactSearchResultRaw,
  SearchResult,
  SearchResultFactory,
  SearchResultRaw,
} from '@app/modules/search/classes/search-results';
import {
  City,
  CityRaw,
  CityFactory,
  State,
  StateRaw,
  StateFactory,
  Country,
  CountryRaw,
  CountryFactory,
  ShopBasicsRaw,
  ShopMediumDataRaw,
  ShopMediumData,
  ShopBasics,
  ShopFactory,
  ShopDetailsRaw,
  ShopDetails,
  Pin,
  PinRaw,
  PinFactory,
} from '@shared/classes';
import {
  ImageDetails,
  ImageDetailsFactory,
  ImageDetailsRaw,
  ImageUpdateDetails,
} from '@app/shared/classes/image-details';

// /api/store/99999 liefert, wenn 99999 eine ungültige id ist, {"id":-1,"error":"store not found"}
interface NoStoreFoundResult {
  id: number;
  error: string;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private readonly api = '/api';

  constructor(private http: HttpClient) {}

  private countriesCache$: Observable<Country[]> | undefined;
  getAllCountries(): Observable<Country[]> {
    if (this.countriesCache$ === undefined) {
      this.countriesCache$ = this.http.get<CountryRaw[]>(`${this.api}/countries`).pipe(
        retry(3),
        map((raw) =>
          raw
            .map((item) => CountryFactory.fromRaw(item))
            .sort((a, b) => a.country.toLowerCase().localeCompare(b.country.toLowerCase()))
        ),
        catchError(this.errorHandler),
        shareReplay(1)
      );
    }
    return this.countriesCache$;
  }

  private statesCache$: Observable<State[]> | undefined;
  getAllStates(): Observable<State[]> {
    if (this.statesCache$ === undefined) {
      this.statesCache$ = this.http.get<StateRaw[]>(`${this.api}/states`).pipe(
        retry(3),
        map((raw) =>
          raw
            .map((item) => StateFactory.fromRaw(item))
            .sort((a, b) => a.state.toLowerCase().localeCompare(b.state.toLowerCase()))
        ),
        catchError(this.errorHandler),
        shareReplay(1)
      );
    }
    return this.statesCache$;
  }

  private citiesCache$: Observable<City[]> | undefined;
  getAllCities(): Observable<City[]> {
    if (this.citiesCache$ === undefined) {
      this.citiesCache$ = this.http.get<CityRaw[]>(`${this.api}/cities`).pipe(
        retry(3),
        map((raw) =>
          raw
            .map((item) => CityFactory.fromRaw(item))
            .sort((a, b) => a.city.toLowerCase().localeCompare(b.city.toLowerCase()))
        ),
        catchError(this.errorHandler),
        shareReplay(1)
      );
    }
    return this.citiesCache$;
  }

  private supportersCache$: Observable<ShopBasics[]> | undefined;
  getSupporters(): Observable<ShopBasics[]> {
    if (this.supportersCache$ === undefined) {
      this.supportersCache$ = this.http.get<ShopBasicsRaw[]>(`${this.api}/stores?type=start`).pipe(
        retry(3),
        map((raw) => raw.map((item) => ShopFactory.fromRawBasic(item))),
        catchError(this.errorHandler),
        shareReplay(1)
      );
    }
    return this.supportersCache$;
  }

  getStoresSlice(lng: number): Observable<ShopBasics[]> {
    return this.http.get<ShopBasicsRaw[]>(`${this.api}/stores?type=lng&lng=${lng}`).pipe(
      retry(3),
      map((raw) => raw.map((item) => ShopFactory.fromRawBasic(item))),
      catchError(this.errorHandler)
    );
  }

  getAllStores(): Observable<ShopBasics[]> {
    return this.http.get<ShopBasicsRaw[]>(`${this.api}/stores?type=all`).pipe(
      retry(3),
      map((raw) => raw.map((item) => ShopFactory.fromRawBasic(item))),
      catchError(this.errorHandler)
    );
  }

  getStoresByPlaceID(placeids: string[]): Observable<ShopBasics[]> {
    return this.http.get<ShopBasicsRaw[]>(`${this.api}/stores?type=google&placeids=${placeids.join()}`).pipe(
      retry(3),
      map((raw) => raw.map((item) => ShopFactory.fromRawBasic(item))),
      catchError(this.errorHandler)
    );
  }

  getMedium(stores: ShopBasics[], from: string): Observable<ShopMediumData[]> {
    if (stores.length > 0) {
      const ids: number[] = [];
      stores.forEach((x) => ids.push(x.id));
      const d = new Date();
      return this.http
        .post<ShopMediumDataRaw[]>(`${this.api}/storeswithids`, {
          from,
          ids,
          weekday: d.getDay(),
        })
        .pipe(
          retry(3),
          map((raw) => raw.map((item) => ShopFactory.fromRawMedium(item))),
          catchError(this.errorHandler)
        );
    } else {
      return of([]);
    }
  }

  getSingle(id: number): Observable<ShopDetails | null> {
    return this.http.get<ShopDetailsRaw>(`${this.api}/stores/${id}`).pipe(
      retry(3),
      catchError((err) => of(null)),
      map((raw) => (raw === null ? null : ShopFactory.fromRawDetails(raw as ShopDetailsRaw)))
    );
  }

  getSearch(query: string): Observable<SearchResult> {
    return this.http.get<SearchResultRaw>(`${this.api}/autocomplete/${query}`).pipe(
      retry(3),
      map((raw) => SearchResultFactory.fromRaw(raw)),
      catchError(this.errorHandler)
    );
  }

  getExactSearch(query: string): Observable<ExactSearchResult> {
    return this.http.get<ExactSearchResultRaw>(`${this.api}/exactsearch/${query}`).pipe(
      retry(3),
      map((raw) => SearchResultFactory.fromExactRaw(raw)),
      catchError(this.errorHandler)
    );
  }

  saveStore(store: ShopDetails): Observable<ShopDetails> {
    // Theoretisch müsste man store noch in ShopRaw umwandeln, geht aber evtl auch so:
    const url = `${this.api}/stores` + (store.id ? `/${store.id}` : '');
    return this.http.post<ShopDetailsRaw>(url, store).pipe(
      map((result) => ShopFactory.fromRawDetails(result)),
      catchError(this.errorHandler)
    );
  }

  updateImage(payload: ImageUpdateDetails): Observable<ImageDetails> {
    const url = `${this.api}/setimage`;
    return this.http.post<ImageDetailsRaw>(url, payload).pipe(
      map((result) => ImageDetailsFactory.fromRaw(result)),
      catchError(this.errorHandler)
    );
  }

  savePin(pin: Pin): Observable<Pin> {
    const url = `${this.api}/pins` + (pin.id ? `/${pin.id}` : '');
    return this.http.post<PinRaw>(url, pin).pipe(
      map((result) => PinFactory.fromRaw(result)),
      catchError(this.errorHandler)
    );
  }

  removePin(pinid: number): Observable<number> {
    const d = new Date();
    return this.http.delete<any>(`${this.api}/pins/${pinid}`).pipe(map(() => pinid));
  }

  private errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error('Fehler bei HTTP-Request', error);
    return throwError(() => error);
  }
}
