import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rs-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup = this.fb.group(
    {
      password: ['', Validators.required],
      password2: ['', Validators.required],
    },
    { validators: this.validatePasswordsEqual.bind(this) }
  );

  sending = false;
  success: boolean | undefined;
  faSpinner = faSpinner;

  private userid!: number;
  private key!: string;

  constructor(private auth: AuthService, private fb: FormBuilder, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.userid = Number(this.route.snapshot.paramMap.get('userid'));
    this.key = String(this.route.snapshot.paramMap.get('key'));
  }

  submitForm() {
    const password = this.form.value.password;
    this.sending = true;
    this.auth.resetPassword({ userid: this.userid, key: this.key, password }).subscribe(
      (x) => {
        this.sending = false;
        this.success = true;
      },
      (err) => {
        this.sending = false;
        this.success = false;
      }
    );
  }

  validatePasswordsEqual(control: AbstractControl): ValidationErrors | null {
    const p1 = control.get('password')?.value;
    const p2 = control.get('password2')?.value;
    if (p1 === p2) {
      control.get('password2')?.setErrors(null);
      return null;
    } else {
      control.get('password2')?.setErrors({ passwordEquality: false });
      return { passwordEquality: false };
    }
  }
}
