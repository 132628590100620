/* eslint-disable @typescript-eslint/naming-convention */
export interface LatLng {
  lat: number;
  lng: number;
}

export interface LatLngBounds {
  latmin: number;
  latmax: number;
  lngmin: number;
  lngmax: number;
}

export class LatLngHelper {
  static fromGoogleMapsBounds(bnds: google.maps.LatLngBounds): LatLngBounds {
    return {
      latmin: bnds.getSouthWest().lat(),
      lngmin: bnds.getSouthWest().lng(),
      latmax: bnds.getNorthEast().lat(),
      lngmax: bnds.getNorthEast().lng(),
    };
  }
}

export interface LatLngPos {
  lat: number;
  lng: number;
  accuracy: number | null; // in m
  heading: number | null; // Richtung in Grad, 0=N, 90=E, 180=S, 270=W
  speed: number | null; // Geschwindigkeit in m/s
  timestamp: EpochTimeStamp; // Millisekunden
}

export interface GeolocationCoordinates {
  readonly accuracy: number;
  readonly altitude: number | null;
  readonly altitudeAccuracy: number | null;
  readonly heading: number | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly speed: number | null;
}

export interface GeolocationPosition {
  readonly coords: GeolocationCoordinates;
  readonly timestamp: number;
}

export interface GeolocationPositionError {
  readonly code: number;
  readonly message: string;
  readonly PERMISSION_DENIED: number;
  readonly POSITION_UNAVAILABLE: number;
  readonly TIMEOUT: number;
}

export const pos2LatLng = (pos: GeolocationPosition): LatLng => ({
  lat: pos.coords.latitude,
  lng: pos.coords.longitude,
});

export const pos2LatLngPos = (pos: GeolocationPosition): LatLngPos => ({
  lat: pos.coords.latitude,
  lng: pos.coords.longitude,
  accuracy: pos.coords.accuracy,
  heading: pos.coords.heading,
  speed: pos.coords.speed,
  timestamp: pos.timestamp,
});
