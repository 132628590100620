<div *ngIf="showMapDetails$ | async as showDetails; else noDetails">
  <div *ngIf="marker?.type === 'store' && shop" class="pr-3">
    <rs-shop-item *ngIf="showDetails" class="rs-pointer" [item]="shop" (click)="gotoStore()"></rs-shop-item>
  </div>

  <div *ngIf="marker?.type === 'pin'" class="pr-3">
    <ng-container *ngIf="activePin$ | async as pin; else noDetails">
      <rs-pin-item *ngIf="showDetails" class="rs-pointer" [item]="pin" [routerLink]="'/pin/' + pin.id"></rs-pin-item>
    </ng-container>
  </div>

  <div *ngIf="marker?.type === 'placeIcon' || marker?.type === 'position'" mat-button [matMenuTriggerFor]="addmenu">
    <a mat-flat-button class="rs-button" style="margin-right: 0">Add Store Or Pin</a>
  </div>
  <mat-menu #addmenu="matMenu">
    <button mat-menu-item (click)="addStore()">Add a Record Store</button>
    <button mat-menu-item (click)="addPin()">Add a Private Pin</button>
  </mat-menu>
</div>

<ng-template #noDetails>
  <p *ngIf="storesVisible$ | async" class="small">
    Click on a marker to see more details<br />
    or somewhere else to add a store.
  </p>
</ng-template>
