/* eslint-disable @typescript-eslint/naming-convention */
import { Component } from '@angular/core';
import { ShopDetails } from '@app/shared/classes';
import { Store } from '@ngrx/store';
import { loadAllStores, saveStore, setNearPlaces, setPlaceAsShop } from '../../store/shop.actions';
import { selectAllStores, selectDetailedStore, selectNearPlaces, selectPlaceAsShop } from '../../store/shop.selectors';
import { faAngleLeft, faAngleRight, faCheck, faSearch, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GooglePlacesService } from '@app/core/services/google-places.service';
import { BehaviorSubject, combineLatest, first } from 'rxjs';
import { HelperService } from '@app/core/services/helper.service';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import { selectAllCities } from '@app/modules/cities/store/city.selectors';
import { Router } from '@angular/router';
import { MarkersService } from '@app/core/services/markers.service';
import { Md5 } from 'ts-md5';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-admin-store',
  templateUrl: './admin-store.component.html',
  styleUrls: ['./admin-store.component.scss'],
})
export class AdminStoreComponent extends Unsubscriber {
  store$ = this.store.select(selectDetailedStore);
  stores$ = this.store.select(selectAllStores);
  cities$ = this.store.select(selectAllCities);
  nearPlaces$ = this.store.select(selectNearPlaces);
  placeAsShop$ = this.store.select(selectPlaceAsShop);

  shop: ShopDetails | null = null;
  faCheck = faCheck;
  faSearch = faSearch;
  faSpinner = faSpinner;
  faTimes = faTimes;
  faLeft = faAngleLeft;
  faRight = faAngleRight;
  place: google.maps.places.PlaceResult | null = null;
  //  placeAsShop: ShopDetails | null = null;
  shours = '';
  hasInsta = false;
  hasNewImages = false;
  showImages = false;
  phours = '';
  distance = 0;
  loadingPlace$ = new BehaviorSubject<boolean>(false);
  loadingNearPlaces$ = new BehaviorSubject<boolean>(false);
  error = '';
  altCity = '';
  autoSave = false;
  dragging = false;

  constructor(
    private google: GooglePlacesService,
    private helper: HelperService,
    private markers: MarkersService,
    private router: Router,
    private store: Store
  ) {
    super();

    this.unsubscribeLater(
      this.store$.subscribe((x) => {
        if (x) {
          this.shours = this.helper.getShortTimesString(x);
          this.hasInsta = false;
          [x.website ?? '', x.ebay ?? '', x.discogs ?? '', x.facebook ?? ''].forEach((url) => {
            this.hasInsta = this.hasInsta || url.includes('//instagram.com/') || url.includes('www.instagram.com/');
          });
          this.hasNewImages = false;
          x.adminimages.forEach((i) => {
            this.hasNewImages = this.hasNewImages || !i.active;
          });
          if (this.hasNewImages && !this.showImages) {
            this.showImages = true;
          }
          if (this.shop?.id !== x.id) {
            this.shop = x;
            this.loadPlace(x?.place_id);
          }
          this.shop = x;
          this.altCity = '';
          this.cities$.pipe(first()).subscribe((cities) => {
            const cs = cities.filter(
              (c) =>
                c.city !== x.city &&
                c.countrycode === x.countrycode &&
                c.latmin <= x.lat &&
                x.lat <= c.latmax &&
                c.lngmin <= x.lng &&
                x.lng <= c.lngmax
            );
            if (cs.length > 0) {
              this.altCity = cs[0].city;
            }
          });
        }
      }),

      combineLatest([this.placeAsShop$, this.store$]).subscribe(([placeAsShop, shop]) => {
        if (shop && placeAsShop) {
          this.distance = Math.round(PositionHelpers.getDistanceBetween(shop, placeAsShop) * 1000.0);
          this.phours = this.helper.getShortTimesString(placeAsShop);

          if (!this.autoSave && placeAsShop.google_url !== shop.google_url) {
            this.saveGoogleUrl(shop, placeAsShop);
          }
        } else {
          this.distance = 0;
          this.phours = '';
        }
      })
    );
  }

  reset() {
    this.place = null;
    this.phours = '';
    this.distance = 0;
    this.error = '';
  }

  highlight(place: google.maps.places.PlaceResult | null) {
    if (place) {
      if (place.geometry?.location) {
        const pos = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.markers.highlightOn(pos, 'store');
      }
    } else {
      if (this.shop) {
        this.markers.highlightOn(this.shop, 'store');
      }
    }
  }
  highlightShop(shop: ShopDetails | null) {
    if (shop) {
      this.markers.highlightOn(shop, 'store');
    } else {
      if (this.shop) {
        this.markers.highlightOn(this.shop, 'store');
      }
    }
  }

  loadAllStores() {
    this.store.dispatch(loadAllStores());
  }

  next(shop: ShopDetails): void {
    this.stores$.pipe(first()).subscribe((x) => {
      let nxt = 999999;
      let frst = 999999;
      x.forEach((store) => {
        if (store.id > shop.id && store.id < nxt) {
          nxt = store.id;
        }
        if (store.id < frst) {
          frst = store.id;
        }
      });
      if (nxt < 999999) {
        this.router.navigateByUrl(`/${nxt}`);
      } else {
        this.router.navigateByUrl(`/${frst}`);
      }
    });
  }
  prev(shop: ShopDetails) {
    this.stores$.pipe(first()).subscribe((x) => {
      let prv = 0;
      let lst = 0;
      x.forEach((store) => {
        if (store.id < shop.id && store.id > prv) {
          prv = store.id;
        }
        if (store.id > lst) {
          lst = store.id;
        }
      });
      if (prv > 0) {
        this.router.navigateByUrl(`/${prv}`);
      } else {
        this.router.navigateByUrl(`/${lst}`);
      }
    });
  }

  loadPlace(place_id: string | undefined) {
    this.reset();
    if (place_id) {
      this.loadingPlace$.next(true);
      this.google.getPlaceDetails(place_id, true).subscribe({
        next: (place) => {
          this.loadingPlace$.next(false);
          this.place = place;
          const placeAsShop = this.google.getShopFromPlace(place);
          this.store.dispatch(setPlaceAsShop({ placeAsShop }));
          if (this.shop) {
            if (this.shop.place_id === place_id && place.business_status === 'OPERATIONAL') {
              this.saveBasicsOrGotoNext(this.shop, placeAsShop, this.distance, this.shours, this.phours);
            }
          }
        },
        error: (error) => {
          this.error = error;
          this.loadingPlace$.next(false);
        },
      });
    } else {
      if (this.shop) {
        this.loadNearPlaces(this.shop, 25);
      }
    }
  }

  comparePlace(place: google.maps.places.PlaceResult) {
    console.log(place);
    this.loadPlace(place.place_id);
  }

  loadNearPlaces(store: ShopDetails, radius: number) {
    this.loadingNearPlaces$.next(true);
    const callback = (
      a: google.maps.places.PlaceResult[] | null,
      b: google.maps.places.PlacesServiceStatus,
      c: google.maps.places.PlaceSearchPagination | null
    ) => {
      this.loadingNearPlaces$.next(false);
      this.store.dispatch(setNearPlaces({ places: a ?? [] }));
    };
    if (radius < 100) {
      this.google.getNearByPlaces(store, radius, callback);
    } else {
      this.google.getNearByStores(store, radius, callback);
    }
  }

  saveBasicsOrGotoNext(shop: ShopDetails, newShop: ShopDetails, distance: number, sHours: string, nsHours: string) {
    if (this.autoSave) {
      console.log(`${shop.id}`);
      if (
        shop.google_url !== newShop.google_url ||
        (distance > 1 && distance < 30 && shop.address === newShop.address) ||
        (newShop.phone && shop.phone !== newShop.phone) ||
        (sHours !== nsHours && nsHours !== 'Hours unknown')
      ) {
        let hybrid = { ...shop };
        if (shop.google_url !== newShop.google_url) {
          console.log(`${shop.id} - google_url`, newShop.google_url);
          hybrid.google_url = newShop.google_url;
        }
        if (distance > 1 && distance < 30 && shop.address === newShop.address) {
          console.log(`${shop.id} - lat lng`, distance);
          hybrid.lat = newShop.lat;
          hybrid.lng = newShop.lng;
        }
        if (newShop.phone && shop.phone !== newShop.phone) {
          console.log(`${shop.id} - phone`, shop.phone, newShop.phone);
          hybrid.phone = newShop.phone;
        }
        if (sHours !== nsHours && nsHours !== 'Hours unknown') {
          console.log(`${shop.id} - hours`, sHours, nsHours);
          hybrid = {
            ...hybrid,
            times_mon_closed: newShop.times_mon_closed,
            times_mon_from: newShop.times_mon_from,
            times_mon_until: newShop.times_mon_until,
            times_tue_closed: newShop.times_tue_closed,
            times_tue_from: newShop.times_tue_from,
            times_tue_until: newShop.times_tue_until,
            times_wed_closed: newShop.times_wed_closed,
            times_wed_from: newShop.times_wed_from,
            times_wed_until: newShop.times_wed_until,
            times_thu_closed: newShop.times_thu_closed,
            times_thu_from: newShop.times_thu_from,
            times_thu_until: newShop.times_thu_until,
            times_fri_closed: newShop.times_fri_closed,
            times_fri_from: newShop.times_fri_from,
            times_fri_until: newShop.times_fri_until,
            times_sat_closed: newShop.times_sat_closed,
            times_sat_from: newShop.times_sat_from,
            times_sat_until: newShop.times_sat_until,
            times_sun_closed: newShop.times_sun_closed,
            times_sun_from: newShop.times_sun_from,
            times_sun_until: newShop.times_sun_until,
          };
        }
        this.store.dispatch(saveStore({ note: 'updated basic fields by admin', store: hybrid }));
      } else {
        if (
          shop.name === newShop.name &&
          (sHours === nsHours || nsHours === 'Hours unknown') &&
          distance <= 1 &&
          shop.address === newShop.address &&
          (shop.phone === newShop.phone || newShop.phone === '' || newShop.phone === null) &&
          shop.city === newShop.city &&
          shop.place_id === newShop.place_id &&
          (newShop.website === '' ||
            newShop.website === null ||
            newShop.website === shop.ebay ||
            newShop.website === shop.discogs ||
            newShop.website === shop.facebook ||
            newShop.website === shop.website)
        ) {
          console.log(`${shop.id} - >> next >>`);
          this.next(shop);
        }
      }
    }
  }

  saveAddress(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated address by admin',
        store: { ...store, address: placeAsShop.address },
      })
    );
  }
  saveCity(store: ShopDetails, city: string) {
    this.altCity = '';
    this.store.dispatch(saveStore({ note: 'updated city by admin', store: { ...store, city } }));
  }
  saveGoogleUrl(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated google_url by admin',
        store: { ...store, google_url: placeAsShop.google_url },
      })
    );
  }
  saveLatLng(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated latlng by admin',
        store: { ...store, lat: placeAsShop.lat, lng: placeAsShop.lng },
      })
    );
  }
  saveName(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated name by admin',
        store: { ...store, name: placeAsShop.name },
      })
    );
  }
  savePhone(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated phone by admin',
        store: { ...store, phone: placeAsShop.phone },
      })
    );
  }
  savePlaceID(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated placeid by admin',
        store: { ...store, place_id: placeAsShop.place_id },
      })
    );
  }
  saveTimes(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated times by admin',
        store: {
          ...store,
          times_mon_closed: placeAsShop.times_mon_closed,
          times_mon_from: placeAsShop.times_mon_from,
          times_mon_until: placeAsShop.times_mon_until,
          times_tue_closed: placeAsShop.times_tue_closed,
          times_tue_from: placeAsShop.times_tue_from,
          times_tue_until: placeAsShop.times_tue_until,
          times_wed_closed: placeAsShop.times_wed_closed,
          times_wed_from: placeAsShop.times_wed_from,
          times_wed_until: placeAsShop.times_wed_until,
          times_thu_closed: placeAsShop.times_thu_closed,
          times_thu_from: placeAsShop.times_thu_from,
          times_thu_until: placeAsShop.times_thu_until,
          times_fri_closed: placeAsShop.times_fri_closed,
          times_fri_from: placeAsShop.times_fri_from,
          times_fri_until: placeAsShop.times_fri_until,
          times_sat_closed: placeAsShop.times_sat_closed,
          times_sat_from: placeAsShop.times_sat_from,
          times_sat_until: placeAsShop.times_sat_until,
          times_sun_closed: placeAsShop.times_sun_closed,
          times_sun_from: placeAsShop.times_sun_from,
          times_sun_until: placeAsShop.times_sun_until,
        },
      })
    );
  }
  saveDiscogs(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated discogs by admin',
        store: { ...store, discogs: placeAsShop.website },
      })
    );
  }
  saveEbay(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated ebay by admin',
        store: { ...store, ebay: placeAsShop.website },
      })
    );
  }
  saveFacebook(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated facebook by admin',
        store: { ...store, facebook: placeAsShop.website },
      })
    );
  }
  saveWebsite(store: ShopDetails, placeAsShop: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'updated website by admin',
        store: { ...store, website: placeAsShop.website },
      })
    );
  }

  closeStore(store: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'store closed by admin',
        store: { ...store, hasclosed: !store.hasclosed },
      })
    );
  }

  removePlaceID(store: ShopDetails) {
    this.store.dispatch(
      saveStore({
        note: 'store place id removed by admin',
        store: { ...store, place_id: '' },
      })
    );
  }

  moveStore(shop: ShopDetails, newStore: ShopDetails) {
    const store = {
      ...newStore,
      website: shop.website,
      ebay: shop.ebay,
      email: shop.email,
      facebook: shop.facebook,
      discogs: shop.discogs,
    };
    this.store.dispatch(saveStore({ note: `store ${shop.id} moved by admin`, store }));
  }

  containsKeyWord(word: string, storeName: string) {
    let result = false;
    const words = [
      ...storeName.toLowerCase().split(' '),
      'vinyl',
      'vinil',
      'record',
      'music',
      'disco',
      'rock',
      'sound',
    ];
    word = word.toLowerCase();
    words.forEach((w) => (result = result || word.includes(w)));
    return result;
  }

  placeIsStore(placeId: string) {
    let result = false;
    const placeidh = Md5.hashStr(placeId);
    this.stores$.pipe(first()).subscribe((xx) => {
      xx.forEach((x) => {
        result = result || x.placeidh === placeidh;
      });
    });
    return result;
  }

  editPhotos(store: ShopDetails) {
    this.showImages = !this.showImages;
  }
  startDrag(store: ShopDetails) {
    this.dragging = true;
    this.markers.setMarkerDraggable(store.id, true);
  }
  stopDrag(store: ShopDetails) {
    this.dragging = false;
    this.markers.setMarkerDraggable(store.id, false);
    const pos = this.markers.getMarkerPosition(store.id);
    if (pos) {
      this.store.dispatch(
        saveStore({
          note: `store ${store.id} dragged by admin`,
          store: { ...store, lat: pos.lat, lng: pos.lng },
        })
      );
    }
  }
  cancelDrag(store: ShopDetails) {
    this.dragging = false;
    this.markers.setMarkerDraggable(store.id, false);
    this.markers.setMarkerPosition(store.id, store);
  }
}
