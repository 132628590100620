import { Action, createReducer, on } from '@ngrx/store';
import { Continent, Country, State } from '@shared/classes';
import * as CountryActions from './country.actions';
import * as ShopActions from '@app/modules/shops/store/shop.actions';
import { activateCountryDiv, activateStateDiv } from '@app/modules/global/store/global.actions';

export const countryFeatureKey = 'country';

export interface CountryState {
  continents: Continent[];
  countries: Country[];
  states: State[];

  loadingCountries: boolean;
  loadedCountries: boolean;
  loadingStates: boolean;
  loadedStates: boolean;

  activeCountryCode: string | null;
  activeStateCode: string | null;

  countriesFilter: string; // CountriesComponent
}

export const initialState: CountryState = {
  continents: [
    {
      code: 'AF',
      name: 'Africa',
      opencnt: 0,
      latmin: -34,
      latmax: 35,
      lngmin: -17,
      lngmax: 56,
    },
    //{ code: 'AN', name: 'Antarctica', opencnt: 0, latmin: -90, latmax: -43, lngmin: -37, lngmax: 73 },
    {
      code: 'AS',
      name: 'Asia',
      opencnt: 0,
      latmin: 9,
      latmax: 65,
      lngmin: 32,
      lngmax: 153,
    },
    {
      code: 'EU',
      name: 'Europe',
      opencnt: 0,
      latmin: 37,
      latmax: 66,
      lngmin: -23,
      lngmax: 39,
    },
    {
      code: 'NA',
      name: 'North America',
      opencnt: 0,
      latmin: 12,
      latmax: 64,
      lngmin: -146,
      lngmax: -65,
    },
    {
      code: 'OC',
      name: 'Oceania',
      opencnt: 0,
      latmin: -47,
      latmax: 0,
      lngmin: 111,
      lngmax: 179,
    },
    {
      code: 'SA',
      name: 'South and Central America',
      opencnt: 0,
      latmin: -46,
      latmax: 11,
      lngmin: -80,
      lngmax: -34,
    },
  ],
  countries: [],
  states: [],

  loadingCountries: false,
  loadedCountries: false,
  loadingStates: false,
  loadedStates: false,

  activeCountryCode: null,
  activeStateCode: null,

  countriesFilter: '100',
};

export const reducer = createReducer(
  initialState,

  on(CountryActions.loadStates, (state) => ({ ...state, loadingStates: true })),
  on(CountryActions.loadCountries, (state) => ({
    ...state,
    loadingCountries: true,
  })),

  on(CountryActions.loadStatesFailure, (state) => ({
    ...state,
    loadingStates: false,
  })),
  on(CountryActions.loadCountriesFailure, (state) => ({
    ...state,
    loadingCountries: false,
  })),

  on(CountryActions.loadCountriesSuccess, (state, action) => {
    const result = {
      ...state,
      loadingCountries: false,
      loadedCountries: true,
    };
    if (action.countries.length !== state.countries.length) {
      result.countries = action.countries;
    }
    return result;
  }),

  on(CountryActions.loadStatesSuccess, (state, action) => {
    const result = {
      ...state,
      loadingStates: false,
      loadedStates: true,
    };
    if (action.states.length !== state.states.length) {
      result.states = action.states;
    }
    return result;
  }),

  on(CountryActions.setCountriesFilter, (state, action) => ({
    ...state,
    countriesFilter: action.filter,
  })),

  on(CountryActions.setActiveCountry, (state, action) => ({
    ...state,
    activeCountryCode: action.countrycode,
  })),
  on(CountryActions.setActiveState, (state, action) => ({
    ...state,
    activeCountryCode: 'US',
    activeStateCode: action.statecode,
  })),

  on(ShopActions.saveStoreSuccess, (state, action) => {
    const result = { ...state, savingStore: false };
    const shop = action.store;
    if (action.isNew) {
      // Neu: Datensatz anhängen und opencnt in City, State, Country erhöhen.
      if (shop.countrycode === 'US' && result.loadedStates) {
        const usstate = result.states.find((x) => x.state === shop.state);
        if (usstate) {
          result.states = [...result.states.filter((x) => x !== usstate), { ...usstate, opencnt: usstate.opencnt + 1 }];
        }
      }
      if (result.loadingCountries) {
        const country = result.countries.find((x) => x.countrycode === shop.countrycode);
        if (country) {
          result.countries = [
            ...result.countries.filter((x) => x !== country),
            { ...country, opencnt: country.opencnt + 1 },
          ];
        }
      }
    } else {
      // nicht neu => Nur die Datensätze austauschen.
      // State und Country kann nur der Admin ändern.
    }
    return result;
  })
);
