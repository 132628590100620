<div class="rs-store-form rs-form">
  <h2>Become A Supporter</h2>
  <p>It's great that you want to support this project. In order to send you an invoice, I need some data.</p>
  <form [formGroup]="formGroup" (ngSubmit)="submitForm()">
    <p>
      <mat-dialog-content>
        <label id="years-radio-group-label">How many years do you want to support?</label>
        <mat-radio-group class="years-radio-group" formControlName="years">
          <mat-radio-button value="1" class="years-radio-button">1 year = 60 EUR</mat-radio-button>
          <mat-radio-button value="2" class="years-radio-button">2 years = 100 EUR</mat-radio-button>
          <mat-radio-button value="4" class="years-radio-button">4 years = 160 EUR</mat-radio-button>
        </mat-radio-group>

        <mat-form-field>
          <input matInput formControlName="name" placeholder="Your Name" class="rs-bold" />
          <mat-error *ngIf="formGroup.get('name')?.invalid && formGroup.get('name')?.touched"
            >Please enter your name</mat-error
          >
        </mat-form-field>

        <mat-form-field class="mt-3">
          <input matInput formControlName="store" placeholder="Store Name and City" class="rs-bold" />
          <mat-error *ngIf="formGroup.get('store')?.invalid && formGroup.get('store')?.touched"
            >Please the name of the record store</mat-error
          >
        </mat-form-field>

        <mat-form-field class="mt-3">
          <input matInput type="email" formControlName="email" placeholder="Your Email" />
          <mat-error *ngIf="formGroup.get('email')?.invalid && formGroup.get('email')?.touched"
            >Please enter your Email</mat-error
          >
        </mat-form-field>

        <mat-form-field class="mt-3">
          <input matInput formControlName="vat" placeholder="Your VAT number (if available)" />
        </mat-form-field>

        <mat-form-field class="mt-3">
          <textarea matInput formControlName="address" placeholder="Your Billing Address"> </textarea>
        </mat-form-field>

        <mat-form-field class="mt-3">
          <textarea matInput formControlName="note" placeholder="Do you have any questions?"> </textarea>
        </mat-form-field>

        <div style="text-align: right" *ngIf="!sending && !sent" class="mt-3">
          <a style="visibility: hidden" mat-flat-button class="rs-button">Contact</a>
          <input
            *ngIf="formGroup.dirty"
            type="button"
            value="Discard"
            class="mat-button rs-alt-button"
            (click)="close()"
          />
          <input
            type="submit"
            value="Support Now"
            class="mat-flat-button rs-button mr-3"
            [class.disabled]="!formGroup.valid"
            [disabled]="!formGroup.valid"
          />
        </div>
      </mat-dialog-content>
    </p>

    <div *ngIf="sending">
      <p>
        <fa-icon title="Sending your data, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
        Sending your data, please wait
      </p>
    </div>
    <div *ngIf="sent">
      <p>Thank you for your support. We will get back to you shortly.</p>
    </div>
    <div *ngIf="error">
      <p>
        An error occured. Please contact us via
        <a href="mailto:support@recordstores.love?subject=Support" target="_blank">support@recordstores.love</a>.
      </p>
    </div>
  </form>
</div>
