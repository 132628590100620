<div class="rs-panel" *ngIf="countries$ | async as countries">
  <h1 class="rs-header-1">
    Countries
    <span *ngIf="countries.length" class="rs-h-small"
      >{{ totalStores$ | async }} Stores in {{ totalCountries$ | async }} Countries</span
    >
    <span *ngIf="countries.length === 0" class="rs-h-small">...loading...</span>
  </h1>

  <rs-breadcrumb [breadcrumbs]="[{ routerLink: '', caption: 'countries' }]"></rs-breadcrumb>

  <div *ngIf="countriesFilter$ | async as filter">
    <h2>Show big countries or continents</h2>
    <p>
      <span class="rs-filter-item" (click)="setFilter('100')" [class.selected]="filter === '100'">100+</span>
      <span *ngFor="let item of continents$ | async">
        <span class="rs-filter-item" (click)="setFilter(item.code)" [class.selected]="filter === item.code">{{
          item.code
        }}</span>
      </span>
    </p>
  </div>

  <h2>{{ countries.length }} {{ title }}</h2>

  <a routerLink="/{{ country.countrycode }}" *ngFor="let country of countries" class="rs-panel-row-link">
    <rs-country-item [country]="country"></rs-country-item>
  </a>

  <rs-contact-and-coffee title="all countries" url="https://recordstores.love/countries"> </rs-contact-and-coffee>

  <rs-sharing
    url="recordstores.love/countries"
    name="recordstores.love"
    descript="Follow this link to find all record stores worldwide"
  ></rs-sharing>
</div>
