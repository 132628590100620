import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';

import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import * as MarkerActions from './marker.actions';
import { Action, Store } from '@ngrx/store';
import { LocalStorageService } from '@core/services/local-storage.service';
import { boundsChanged, zoomChanged } from '@app/modules/map/store/map.actions';
import { MarkersService } from '../../../core/services/markers.service';
import {
  loginActionSuccess,
  logoutActionSuccess,
  userColourStoreSuccess,
  userGotoStoreSuccess,
  userLikeStoreSuccess,
} from '@app/modules/auth/store/auth.actions';
import { selectSelectedHighlightStrategy } from './marker.selectors';
import {
  loadAllStoresSuccess,
  loadStoresSliceSuccess,
  tryLoadMediumStores,
} from '@app/modules/shops/store/shop.actions';
import { selectAllStores, selectStoresByDistance, selectVisibleStores } from '@app/modules/shops/store/shop.selectors';
import { selectStoresVisible } from '@app/modules/map/store/map.selectors';

@Injectable()
export class MarkerEffects implements OnInitEffects {
  loadHighlightStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActionSuccess),
      map(() => this.localStorage.get('MARKER.HighlightStrategy')),
      filter((strategy) => strategy !== undefined),
      map((strategy) => MarkerActions.setHighlightStrategy({ strategy }))
    )
  );

  setDefaultHighlightStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutActionSuccess),
      map((strategy) => MarkerActions.setDefaultHighlightStrategy())
    )
  );

  saveHighlightStrategy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MarkerActions.setHighlightStrategy),
        tap((action) => this.localStorage.set('MARKER.HighlightStrategy', action.strategy))
      ),
    { dispatch: false }
  );

  loadMediumForNearStoresOnPlaceDependentHighlightStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkerActions.setHighlightStrategy),
      filter((action) => action.strategy === 'opennow' || action.strategy === 'togoxhours'),
      withLatestFrom(this.store.select(selectStoresByDistance), this.store.select(selectVisibleStores)),
      map(([action, stores1, stores2]) =>
        tryLoadMediumStores({
          stores: [...stores1.map((x) => x.store), ...stores2],
          from: action.strategy,
        })
      )
    )
  );

  handleMarkersOnZoom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(zoomChanged),
        tap(() => this.markersService.onZoomChanged())
      ),
    { dispatch: false }
  );

  updateUserRelatedColoursOnLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginActionSuccess, logoutActionSuccess),
        withLatestFrom(this.store.select(selectSelectedHighlightStrategy)),
        filter(([action, strategy]) => strategy.userRelated),
        tap(([action, strategy]) => this.markersService.updateAllStoreMarkerColors(strategy))
      ),
    { dispatch: false }
  );

  updateColoursOnChangeOfHighlightStrategy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MarkerActions.setHighlightStrategy),
        withLatestFrom(this.store.select(selectSelectedHighlightStrategy)),
        tap(([action, strategy]) => this.markersService.updateAllStoreMarkerColors(strategy))
      ),
    { dispatch: false }
  );

  updateUserRelatedColoursOnLikeSaveGoto$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userLikeStoreSuccess, userGotoStoreSuccess, userColourStoreSuccess),
        withLatestFrom(this.store.select(selectSelectedHighlightStrategy)),
        filter(([action, strategy]) => strategy.userRelated),
        tap(([action, strategy]) => this.markersService.updateSelectedStoreMarkerColors([action.storeid], strategy))
      ),
    { dispatch: false }
  );

  showMarkersAfterSliceLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadStoresSliceSuccess),
        withLatestFrom(this.store.select(selectAllStores)),
        tap(([action, stores]) => this.markersService.initStoresSlice(stores, action.lng))
      ),
    { dispatch: false }
  );

  showMarkersAfterAllStoresLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAllStoresSuccess),
        withLatestFrom(this.store.select(selectAllStores)),
        tap(([action, stores]) => this.markersService.initAllStores(stores))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private localStorage: LocalStorageService,
    private markersService: MarkersService,
    private store: Store
  ) {}

  // Dies ist nötig, weil die Actions IsMobile und ViewMap schon VOR der Effects-Initialization feuern!
  ngrxOnInitEffects(): Action {
    return MarkerActions.initMarkersStore();
  }
}
