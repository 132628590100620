<div>
  <p>
    #{{ image.id }} by user {{ image.userid }} | {{ image.imageurl }} |
    {{ image.created }}
  </p>
  <p>
    <button *ngIf="!image.active" mat-stroked-button (click)="toggleActive(image)">Active</button>
    <button *ngIf="image.active" mat-flat-button (click)="toggleActive(image)" color="primary">Active</button>
    &nbsp;
    <span *ngIf="image.active">
      <button *ngIf="shop.imageid !== image.id" mat-stroked-button (click)="setMainImage(shop, image.id)">Main</button>
      <button *ngIf="shop.imageid === image.id" mat-flat-button (click)="setMainImage(shop, image.id)" color="primary">
        Main
      </button>
      &nbsp;
    </span>
    <button *ngIf="!image.deleted" mat-stroked-button (click)="toggleDeleted(image)">Deleted</button>
    <button *ngIf="image.deleted" mat-flat-button (click)="toggleDeleted(image)" color="warn">Deleted</button>
  </p>
  <p>
    <mat-chip-list>
      <mat-chip
        *ngFor="let item of ['street', 'logo', 'inside', 'owner', 'card', 'bag', 'misc']"
        (click)="setWhat(image, item)"
        [color]="image.what === item ? 'primary' : 'accent'"
        [selected]="image.what === item"
        >{{ item }}</mat-chip
      >
    </mat-chip-list>
  </p>
  <p>
    <mat-chip-list>
      <mat-chip
        *ngFor="let item of [0, 90, 180, 270]"
        (click)="setRotation(image, item)"
        [color]="image.rotation === item ? 'primary' : 'accent'"
        [selected]="image.rotation === item"
        >{{ item }}°</mat-chip
      >
    </mat-chip-list>
  </p>
  <img [src]="'/images/mthumb.php?w=430&rot=' + image.rotation + '&src=' + image.imageurl" />
  <br />
</div>
