import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectActiveCity, selectActiveCityItem } from '@app/modules/cities/store/city.selectors';
import { City, ShopBasics, ShopListItem } from '@shared/classes';
import { selectActiveCountryCode, selectActiveStateCode } from '@app/modules/countries/store/country.selectors';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import * as fromShop from './shop.reducer';
import { selectActiveDiv } from '@app/modules/global/store/global.selectors';
import { selectActualBounds, selectStoresVisible } from '@app/modules/map/store/map.selectors';

export const selectShopState = createFeatureSelector<fromShop.ShopsState>(fromShop.shopFeatureKey);

export const selectAllStores = createSelector(selectShopState, fromShop.selectAll);

export const selectLoadingOrSavingAny = createSelector(
  selectShopState,
  (state) =>
    state.loadingMediumIDs.length > 0 ||
    state.loadingStore ||
    state.loadingSupporters ||
    state.savingStore ||
    state.loadingStoresByPlaceID ||
    state.loadingStoresSlice
);

export const selectSupportersLoaded = createSelector(selectShopState, (state) => state.loadedSupporters);

const selectLoadedMediumIds = createSelector(selectAllStores, (stores) =>
  stores.filter((x) => x.isMedium).map((x) => x.id)
);

export const selectLoadingOrLoadedMediumIds = createSelector(selectShopState, selectLoadedMediumIds, (state, ids) => [
  ...ids,
  ...state.loadingMediumIDs,
]);

export const selectLoadedLngs = createSelector(selectShopState, (state) => state.loadedLngs);

export const selectDetailedStore = createSelector(selectShopState, (state) => state.detailedStore);

export const selectRandomNumbers = createSelector(selectShopState, (state) => state.randomNumbers);

export const selectAllSupporters = createSelector(selectAllStores, (stores) => stores.filter((x) => x.supporter));

const selectActiveStoreID = createSelector(selectShopState, (state) => state.activeStoreID);

export const selectActiveStore = createSelector(selectAllStores, selectActiveStoreID, (stores, id) =>
  stores.find((store) => store.id === id)
);

export const selectAllShopsInActiveCity = createSelector(selectActiveCity, selectAllStores, (city, stores) => {
  let result: ShopBasics[] = [];
  if (city && stores) {
    const compare = (s: ShopBasics, c: City) =>
      c.countrycode === s.countrycode &&
      c.latmin <= s.lat &&
      s.lat <= c.latmax &&
      c.lngmin <= s.lng &&
      s.lng <= c.lngmax;
    result = stores
      .filter((store) => compare(store, city))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
  return result;
});

// Alle Stores im Umkreis von 50km
export const selectStoresByDistance = createSelector(
  selectActiveDiv,
  selectPosition,
  selectAllStores,
  (div, pos, stores) => {
    let result: ShopListItem[] = [];
    if (pos) {
      if (div === 'NEAR') {
        result = PositionHelpers.getNearestStores(stores, 100, pos, 100);
      } else if (div === 'HOME') {
        // Home braucht nur 5
        result = PositionHelpers.getNearestStores(stores, 5, pos, 100);
      } else {
        result = PositionHelpers.getNearestStores(stores, 100, pos, 100);
      }
      if (result.length === 0) {
        result = PositionHelpers.getNearestStores(stores, 5, pos, 2000);
      }
    }
    return result;
  }
);

export const selectVisibleStores = createSelector(
  selectAllStores,
  selectActualBounds,
  selectStoresVisible,
  (stores, bounds, visible) =>
    visible && bounds
      ? stores.filter((x) => PositionHelpers.posInBounds(x, bounds, 0)).sort((a, b) => a.name.localeCompare(b.name))
      : []
);

export const selectVisibleSupporters = createSelector(selectVisibleStores, (stores) =>
  stores.filter((x) => x.supporter)
);

export const selectSupportersInCountry = createSelector(
  selectAllStores,
  selectActiveCountryCode,
  selectRandomNumbers,
  (stores, countrycode, randoms) => {
    const result: ShopBasics[] = [];
    const supporters = stores.filter((s) => s.supporter && s.countrycode === countrycode);

    if (supporters.length > 0) {
      // In DE zuerst analogetontraeger
      const analogetontraeger = supporters.find((x) => x.id === 250);
      if (!!analogetontraeger) {
        result.push(analogetontraeger);
      }

      let i = 0;
      while (result.length < 8 && result.length < supporters.length && i < randoms.length) {
        if (randoms[i] < supporters.length && supporters[randoms[i]] !== analogetontraeger) {
          result.push(supporters[randoms[i]]);
        }
        i++;
      }
    }
    return result;
  }
);

export const selectSupportersInState = createSelector(
  selectAllStores,
  selectActiveCountryCode,
  selectActiveStateCode,
  selectRandomNumbers,
  (stores, countrycode, statecode, randoms) => {
    const result: ShopBasics[] = [];
    const supporters = stores.filter((s) => s.supporter && s.countrycode === countrycode && s.state === statecode);

    if (supporters.length > 0) {
      let i = 0;
      while (result.length < 8 && result.length < supporters.length && i < randoms.length) {
        if (randoms[i] < supporters.length) {
          result.push(supporters[randoms[i]]);
        }
        i++;
      }
    }
    return result;
  }
);

export const selectSupportersInCity = createSelector(
  selectAllShopsInActiveCity,
  selectRandomNumbers,
  (stores, randoms) => {
    const result: ShopBasics[] = [];
    const supporters = stores.filter((x) => x.supporter);

    if (supporters.length > 0) {
      const analogetontraeger = supporters.find((x) => x.id === 250);
      if (!!analogetontraeger) {
        result.push(analogetontraeger);
      }

      let i = 0;
      while (result.length < 100 && result.length < supporters.length && i < randoms.length) {
        if (randoms[i] < supporters.length && supporters[randoms[i]] !== analogetontraeger) {
          result.push(supporters[randoms[i]]);
        }
        i++;
      }
    }
    return result;
  }
);

// Alle Supporter im Umkreis
export const selectSupportersByDistance = createSelector(
  selectActiveDiv,
  selectPosition,
  selectAllSupporters,
  (div, pos, supporters) => {
    let result: ShopListItem[] = [];
    if (pos) {
      if (div === 'NEAR') {
        result = PositionHelpers.getNearestStores(supporters, 50, pos, 200);
      } else {
        // auf HOME reichen 5
        result = PositionHelpers.getNearestStores(supporters, 5, pos, 200);
      }
      // Wenn es weniger als 4 Supporter gibt und analogetontraeger nicht dabei ist
      if (result.length > 0 && result.length < 4 && result.filter((x) => x.store.id === 250).length === 0) {
        const analogetontraeger = supporters.find((x) => x.id === 250);
        if (analogetontraeger) {
          if (PositionHelpers.getDistanceBetween(analogetontraeger, pos) < 250) {
            result.push({
              store: analogetontraeger,
              distance: PositionHelpers.getDistanceBetween(pos, analogetontraeger),
              direction: PositionHelpers.getDirection(pos, analogetontraeger),
            });
          }
        }
      }
    }
    return result.map((x) => x.store);
  }
);

// Alle Supporter im Umkreis von 500km
export const selectSupportersNearDetailedStore = createSelector(
  selectActiveDiv,
  selectActiveStore,
  selectAllSupporters,
  (div, store, supporters) => {
    let result: ShopListItem[] = [];
    if (div === 'STORE' && store !== undefined) {
      result = PositionHelpers.getNearestStores(supporters, 5, store, 200);
      if (result.length > 0 && result.length < 4 && result.filter((x) => x.store.id === 250).length === 0) {
        const analogetontraeger = supporters.find((x) => x.id === 250);
        if (analogetontraeger) {
          if (PositionHelpers.getDistanceBetween(analogetontraeger, store) < 250) {
            result.push({ store: analogetontraeger });
          }
        }
      }
    }
    return result.map((x) => x.store);
  }
);

// 5 Supporter in beliebiger Reihenfolge
export const selectRandomSupporters = createSelector(selectAllSupporters, selectRandomNumbers, (stores, randoms) => {
  const result: ShopBasics[] = [];

  if (stores.length > 0) {
    const supporters = stores.filter((x) => x.supporter);

    // analogetontraeger first
    const analogetontraeger = supporters.find((x) => x.id === 250);
    if (analogetontraeger) {
      result.push(analogetontraeger);
    }

    let i = 0;
    while (result.length < 5 && result.length < supporters.length && i < randoms.length) {
      if (randoms[i] < supporters.length && supporters[i] !== analogetontraeger) {
        result.push(supporters[randoms[i]]);
      }
      i++;
    }
  }
  return result;
});

// da withLatestFrom nur eine bestimmte Zahl an Parametern aufnehmen kann,
// werden hier mehrere aggregiert, die für einen Effect benötigt werden
export const selectMiscSupporters = createSelector(
  selectSupportersByDistance,
  selectRandomSupporters,
  selectSupportersInCountry,
  selectSupportersInState,
  (byDistance, byRandom, inCountry, inState) => ({
    byDistance,
    byRandom,
    inCountry,
    inState,
  })
);

export const selectNearPlaces = createSelector(selectShopState, (state) => state.nearPlaces);
export const selectPlaceAsShop = createSelector(selectShopState, (state) => state.placeAsShop);
