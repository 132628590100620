/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ShopTimes } from '@app/shared/classes';

export interface TimesFormResult {
  times: ShopTimes;
  note: string;
}

@Component({
  selector: 'rs-times-form',
  templateUrl: './times-form.component.html',
  styleUrls: ['./times-form.component.scss'],
})
export class TimesFormComponent implements OnInit {
  @Input() times!: ShopTimes;
  @Input() withNote = true;
  @Output() closeForm = new EventEmitter<TimesFormResult | null>();

  form: FormGroup = this.fb.group({
    times_mon_from: [{ value: '', disabled: false }],
    times_mon_until: [{ value: '', disabled: false }],
    times_mon_open: [true],
    times_tue_from: [{ value: '', disabled: false }],
    times_tue_until: [{ value: '', disabled: false }],
    times_tue_open: [true],
    times_wed_from: [{ value: '', disabled: false }],
    times_wed_until: [{ value: '', disabled: false }],
    times_wed_open: [true],
    times_thu_from: [{ value: '', disabled: false }],
    times_thu_until: [{ value: '', disabled: false }],
    times_thu_open: [true],
    times_fri_from: [{ value: '', disabled: false }],
    times_fri_until: [{ value: '', disabled: false }],
    times_fri_open: [true],
    times_sat_from: [{ value: '', disabled: false }],
    times_sat_until: [{ value: '', disabled: false }],
    times_sat_open: [true],
    times_sun_from: [{ value: '', disabled: false }],
    times_sun_until: [{ value: '', disabled: false }],
    times_sun_open: [true],
    times: [''],
    note: [''],
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.patchValue({
      ...this.times,
      times_mon_open: !this.times.times_mon_closed,
      times_tue_open: !this.times.times_tue_closed,
      times_wed_open: !this.times.times_wed_closed,
      times_thu_open: !this.times.times_thu_closed,
      times_fri_open: !this.times.times_fri_closed,
      times_sat_open: !this.times.times_sat_closed,
      times_sun_open: !this.times.times_sun_closed,
    });

    this.enable('mon', !this.times.times_mon_closed);
    this.enable('tue', !this.times.times_tue_closed);
    this.enable('wed', !this.times.times_wed_closed);
    this.enable('thu', !this.times.times_thu_closed);
    this.enable('fri', !this.times.times_fri_closed);
    this.enable('sat', !this.times.times_sat_closed);
    this.enable('sun', !this.times.times_sun_closed);
  }

  close() {
    this.closeForm.emit(null);
  }

  submit() {
    const formValue = this.form.value;

    const result: TimesFormResult = {
      times: {
        ...this.times,
        ...formValue,
        times_mon_closed: !formValue.times_mon_open,
        times_tue_closed: !formValue.times_tue_open,
        times_wed_closed: !formValue.times_wed_open,
        times_thu_closed: !formValue.times_thu_open,
        times_fri_closed: !formValue.times_fri_open,
        times_sat_closed: !formValue.times_sat_open,
        times_sun_closed: !formValue.times_sun_open,
      },
      note: formValue.note,
    };

    this.closeForm.emit(result);
  }

  enable(day: string, enable: boolean) {
    if (enable) {
      this.form.get('times_' + day + '_from')?.enable();
      this.form.get('times_' + day + '_until')?.enable();
    } else {
      this.form.get('times_' + day + '_from')?.disable();
      this.form.get('times_' + day + '_until')?.disable();
    }
  }
  changeCheckbox(event: MatCheckboxChange, which: string) {
    this.enable(which, event.checked);
  }
}
