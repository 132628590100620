<form [formGroup]="form" (ngSubmit)="submit()">
  <p>
    <mat-dialog-content>
      <mat-form-field>
        <input matInput formControlName="name" placeholder="Name" class="rs-bold" autofocus />
        <mat-error *ngIf="form.get('name')?.invalid && form.get('name')?.touched">Please enter the name</mat-error>
      </mat-form-field>

      <mat-form-field class="mt-3">
        <input matInput formControlName="address" placeholder="Address" />
        <mat-error *ngIf="form.get('address')?.invalid && form.get('address')?.touched"
          >Please enter the address</mat-error
        >
      </mat-form-field>

      <mat-form-field class="mt-3">
        <input matInput formControlName="addinfo" placeholder="Additional info (e.g. 'Backyard' or '1st floor')" />
        <mat-error *ngIf="form.get('addinfo')?.invalid && form.get('addinfo')?.touched"
          >Please enter additional address information</mat-error
        >
      </mat-form-field>

      <mat-form-field class="mt-3">
        <textarea matInput formControlName="descript" placeholder="Description"> </textarea>
        <mat-error *ngIf="form.get('descript')?.invalid && form.get('descript')?.touched"
          >Please enter a short description</mat-error
        >
      </mat-form-field>

      <mat-form-field *ngIf="withNote" class="mt-3">
        <textarea matInput formControlName="note" placeholder="Submission Notes (only for admins)"> </textarea>
        <mat-error *ngIf="form.get('note')?.invalid && form.get('note')?.touched">Please enter a short note</mat-error>
      </mat-form-field>

      <div style="text-align: right" class="mt-3">
        <input type="button" value="Discard" class="mat-button rs-alt-button" (click)="close()" />
        <input
          type="submit"
          value="Save Address"
          class="mat-flat-button rs-button"
          [class.disabled]="!form.valid"
          [disabled]="!form.valid"
        />
      </div>
    </mat-dialog-content>
  </p>
</form>
