<div class="rs-panel" #storediv>
  <div>
    <h1 class="rs-header-1">
      Record Stores Near Me
      <span class="rs-h-small"></span>
      <span class="float-right rs-icon">
        <span *ngIf="positionLoading$ | async; else notloading">
          <fa-icon title="Trying to update location, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
        </span>
        <ng-template #notloading>
          <fa-icon title="Update Location" (click)="updateWithNewPosition()" [icon]="faRedoIcon"></fa-icon>
        </ng-template>
      </span>
    </h1>
  </div>

  <rs-breadcrumb [breadcrumbs]="[{ routerLink: '', caption: 'near' }]"></rs-breadcrumb>

  <rs-pos-state></rs-pos-state>

  <div *ngIf="position">
    <div *ngIf="stores.length === 0">
      <h2>No stores near you.</h2>

      <a routerLink="/countries" class="rs-panel-row-link">
        <div class="rs-panel-row rs-clickable">
          <div>Browse stores by country...</div>
        </div>
      </a>
    </div>

    <div *ngIf="supportersLoaded$ | async; else loading">
      <div *ngIf="supporters$ | async as supporters; else nosupporters">
        <rs-supporters-list
          title="{{ helper.n(supporters.length, 'Supporter') }} Near You"
          [subtitle]="supporters.length > 1 ? 'ordered by distance' : ''"
          [supporters]="supporters"
        ></rs-supporters-list>
      </div>
      <ng-template #nosupporters>
        <div *ngIf="supportersLoaded$ | async">
          <rs-supporters-list title="No Supporters Near You" subtitle="" [supporters]="[]"></rs-supporters-list>
        </div>
      </ng-template>
    </div>
    <ng-template #loading>
      <rs-supporters-list title="Loading Supporters" subtitle="" [supporters]="[]"></rs-supporters-list>
    </ng-template>

    <div *ngIf="stores.length > 0 || cities.length > 0">
      <div *ngIf="stores1.length > 0">
        <rs-shops-list
          [shops]="stores1"
          title="{{ helper.n(stores1.length, 'Store') }} within 1 km"
          subtitle="Unfiltered"
        ></rs-shops-list>
      </div>

      <div *ngIf="stores.length - stores1.length > 10 || filtered">
        <h2>Filter Directions</h2>
        <div>
          <p>
            <span class="rs-filter-item" (click)="toggleFilter('N')" [class.selected]="filter.has('N')">North</span>
            <span class="rs-filter-item" (click)="toggleFilter('S')" [class.selected]="filter.has('S')">South</span>
            <span class="rs-filter-item" (click)="toggleFilter('E')" [class.selected]="filter.has('E')">East</span>
            <span class="rs-filter-item" (click)="toggleFilter('W')" [class.selected]="filter.has('W')">West</span>
          </p>
        </div>
      </div>

      <div *ngIf="stores5.length > 0">
        <rs-shops-list
          [shops]="stores5"
          title="{{ helper.n(stores5.length, 'Store') }} within 5 km"
          [subtitle]="filtered ? 'Filtered' : ''"
        ></rs-shops-list>
      </div>

      <div *ngIf="stores10.length > 0 && stores1.length + stores5.length < 30">
        <rs-shops-list
          [shops]="stores10"
          title="{{ helper.n(stores10.length, 'Store') }} within 10 km"
          [subtitle]="filtered ? 'Filtered' : ''"
        ></rs-shops-list>
      </div>

      <div *ngIf="stores25.length > 0 && stores1.length + stores5.length + stores10.length < 30">
        <rs-shops-list
          [shops]="stores25"
          title="{{ helper.n(stores25.length, 'Store') }} within 25 km"
          [subtitle]="filtered ? 'Filtered' : ''"
        ></rs-shops-list>
      </div>

      <div *ngIf="storesfar.length > 0">
        <rs-shops-list
          [shops]="storesfar"
          title="There are no stores near you - these are the nearest"
          [subtitle]="filtered ? 'Filtered' : ''"
        ></rs-shops-list>
      </div>

      <rs-pins-list
        *ngIf="pins$ | async as pins"
        [expanded]="false"
        [pins]="pins"
        title="My {{ helper.n(pins.length, 'Nearest Pin') }}"
      ></rs-pins-list>

      <div *ngIf="true || cities.length > nearCities.length">
        <h2>
          {{ cities.length }} Cities within 200 km
          <span *ngIf="filtered" class="rs-h-small">Filtered</span>
        </h2>

        <div>
          <a routerLink="/{{ helper.city2url(city.city) }}" *ngFor="let city of cities" class="rs-panel-row-link">
            <rs-city-item [city]="city"></rs-city-item>
          </a>
        </div>
      </div>
    </div>
  </div>

  <rs-sharing
    [url]="'https://recordstores.love/near'"
    [name]="'All record stores near you'"
    [descript]="'Follow this link to find all record stores near you'"
  ></rs-sharing>
</div>
