interface OverlayItem {
  lat: number;
  lng: number;
  latmin: number;
  lngmin: number;
  latmax: number;
  lngmax: number;
  opencnt: number;
}

export abstract class CustomOverlay<T extends OverlayItem> extends google.maps.OverlayView {
  protected div: HTMLElement | undefined;
  protected fracture: number;
  protected toolTip: HTMLDivElement | undefined;
  protected iconColor = 'rgba(28, 26, 227, 0.75)';
  protected minRadius = 1;

  constructor(protected map: google.maps.Map, protected items: T[], public zoom: number) {
    super();
    this.minRadius = zoom < 5 ? 2 : 1;
    this.fracture = 1;
    for (let i = 11; i > zoom; i--) {
      this.fracture = this.fracture * Math.sqrt(2);
    }
    this.setMap(map);
  }

  onAdd() {
    if (!this.div) {
      this.div = document.createElement('div');
      this.div.id = 'citieslayer_' + this.fracture;
    }
    const panes = this.getPanes();
    if (panes) {
      panes.overlayMouseTarget.appendChild(this.div);
    }
  }

  draw() {
    if (this.map.getZoom() === this.zoom) {
      const bnds = this.map.getBounds();

      if (bnds) {
        const inBounds = (item: T) =>
          item.opencnt === 1
            ? bnds.contains(item)
            : bnds.contains(item) ||
              bnds.contains({ lat: item.latmin, lng: item.lngmin }) ||
              bnds.contains({ lat: item.latmin, lng: item.lngmax }) ||
              bnds.contains({ lat: item.latmax, lng: item.lngmin }) ||
              bnds.contains({ lat: item.latmax, lng: item.lngmax });

        this.items.forEach((item, idx) => {
          const icon = this.ensureIcon(item, idx, inBounds(item));
          if (icon) {
            this.div?.appendChild(icon);
          }
        });
      }
    }
  }

  onRemove() {
    this.hideTooltip();
    if (this.div) {
      (this.div.parentNode as HTMLElement).removeChild(this.div);
      delete this.div;
    }
  }

  protected getRadius(item: T): number {
    const result = (Math.sqrt(item.opencnt) * 8) / this.fracture;

    return Math.max(this.minRadius, Math.ceil(result));
  }

  protected createIcon(item: T, idx: number, radius: number): HTMLCanvasElement {
    const icon = document.createElement('canvas');
    const overlayProjection = this.getProjection();
    const pos = overlayProjection.fromLatLngToDivPixel(new google.maps.LatLng(item.lat, item.lng));

    icon.id = 'cityIcon_' + idx + '_' + this.zoom;
    icon.width = icon.height = radius * 2 + 2;
    icon.style.width = icon.width + 'px';
    icon.style.height = icon.height + 'px';
    if (pos) {
      icon.style.left = pos.x - radius - 1 + 'px';
      icon.style.top = pos.y - radius - 1 + 'px';
    }
    icon.style.position = 'absolute';
    icon.style.zIndex = item.opencnt.toString();

    const centerX = icon.width / 2;
    const centerY = icon.height / 2;
    const ctx = icon.getContext('2d');
    if (ctx) {
      ctx.fillStyle = this.iconColor;
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
      ctx.fill();
    }

    google.maps.event.addDomListener(icon, 'mouseover', (event) => {
      const pos2 = overlayProjection.fromLatLngToContainerPixel(new google.maps.LatLng(item.lat, item.lng));
      if (pos2) {
        document.body.classList.toggle('cursor-pointer', true);
        this.showTooltip(item, pos2.x, pos2.y);
      }
    });
    google.maps.event.addDomListener(icon, 'mouseout', (event) => {
      document.body.classList.toggle('cursor-pointer', false);
      this.hideTooltip();
    });
    google.maps.event.addDomListener(icon, 'click', (event) => {
      event.stopPropagation();
      document.body.classList.toggle('cursor-pointer', false);
      this.hideTooltip();
      this.onClick(item);
    });

    return icon;
  }

  protected ensureIcon(item: T, idx: number, contained: boolean) {
    const radius = this.getRadius(item);

    const icon = document.getElementById('cityIcon_' + idx + '_' + this.zoom);
    if (icon) {
      const overlayProjection = this.getProjection();
      const pos = overlayProjection.fromLatLngToDivPixel(new google.maps.LatLng(item.lat, item.lng));
      if (pos) {
        icon.style.left = pos.x - radius - 1 + 'px';
        icon.style.top = pos.y - radius - 1 + 'px';
      }
      return contained ? icon : null;
    } else {
      return contained ? this.createIcon(item, idx, radius) : null;
    }
  }

  protected hideTooltip() {
    if (this.toolTip) {
      document.body.removeChild(this.toolTip);
      this.toolTip = undefined;
    }
  }

  protected showTooltip(item: T, x: number, y: number) {
    const text = this.getName(item) + ' (' + item.opencnt + ')';
    let element: any = document.getElementById('map');
    let top = 0;
    let left = 0;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    if (!this.toolTip) {
      this.toolTip = document.createElement('div');
      this.toolTip.style.background = 'white';
      this.toolTip.style.paddingLeft = '3px';
      this.toolTip.style.paddingRight = '3px';
      this.toolTip.style.fontFamily = 'Arial,Helvetica';
      this.toolTip.style.fontSize = 'small';
      this.toolTip.style.textAlign = 'center';
      this.toolTip.style.zIndex = '1000';
      this.toolTip.innerHTML = text;
      this.toolTip.style.position = 'fixed';
      document.body.appendChild(this.toolTip);
    }
    this.toolTip.innerHTML = text;
    this.toolTip.style.top = y + top + window.scrollY + 25 + 'px';
    this.toolTip.style.left = x + left + window.scrollX + 0 + 'px';
  }

  protected abstract getName(item: T): string;
  protected abstract onClick(item: T): void;
}
