/* eslint-disable no-underscore-dangle */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { loadStore } from '../../store/shop.actions';

const URL = '/api/upload';

@Component({
  selector: 'rs-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit, OnChanges {
  @Input() id!: number;

  faTrash = faTrash;

  uploader: FileUploader = new FileUploader({
    url: URL,
    isHTML5: true,
    allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
    autoUpload: false,
    method: 'post',
  });
  hasBaseDropZoneOver = false;
  response = '';

  constructor(public sanitizer: DomSanitizer, private store: Store) {}

  ngOnInit(): void {
    this.uploader = new FileUploader({
      url: URL,
      isHTML5: true,
      allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
      autoUpload: false,
      method: 'post',
      additionalParameter: { shopid: this.id },
    });

    this.uploader.response.subscribe((res) => (this.response = res));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.uploader) {
      this.uploader.options.additionalParameter = {
        shopid: changes.id.currentValue,
      };
    }
  }

  getThumbnailSrc(item: FileItem) {
    return window.URL ? window.URL.createObjectURL(item._file) : (window as any).webkitURL.createObjectURL(item._file);
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  done() {
    this.uploader.clearQueue();
    this.store.dispatch(loadStore({ id: this.id }));
  }
}
