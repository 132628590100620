import { GotoType, LikeType, ShopVisit, ShopVisits, User } from '@shared/classes';
import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isRegistering: boolean;

  savingPin: boolean;
  removingPin: boolean;
  loadingVisits: boolean;
  loadedVisits: boolean;

  saveLike: number[];
  saveGoto: number[];
  saveVisit: number[];
  saveColour: number[];

  user: User | null;
  visits: ShopVisit[] | null;
  error: string | null;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isRegistering: false,

  savingPin: false,
  removingPin: false,
  loadingVisits: false,
  loadedVisits: false,

  saveLike: [],
  saveGoto: [],
  saveVisit: [],
  saveColour: [],

  user: null,
  visits: null,
  error: null,
};

export const reducer = createReducer(
  initialState,

  // loginValid und login machen im wesentlichen das gleiche (aus Sicht vom State).
  on(AuthActions.loginValidAction, (state) => ({
    ...state,
    isAuthenticating: true,
  })),
  on(AuthActions.loginAction, (state) => ({
    ...state,
    isAuthenticating: true,
  })),
  on(AuthActions.loginActionSuccess, (state, action) => ({
    ...state,
    isAuthenticated: action.user !== null,
    isAuthenticating: false,
    user: action.user,
    error: null,
  })),
  on(AuthActions.loginActionFailure, (state, action) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: false,
    user: null,
    error: action.error.message,
  })),

  on(AuthActions.registerAction, (state) => ({
    ...state,
    isRegistering: true,
  })),
  on(AuthActions.registerActionSuccess, (state) => ({
    ...state,
    isRegistering: false,
  })),
  on(AuthActions.registerActionFailure, (state) => ({
    ...state,
    isRegistering: false,
  })),

  on(AuthActions.logoutAction, (state) => ({
    ...state,
    isAuthenticating: true,
  })),
  on(AuthActions.logoutActionSuccess, (state, action) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: false,
    user: null,
    error: null,
  })),
  on(AuthActions.logoutActionFailure, (state, action) => ({
    ...state,
    isAuthenticating: false,
    error: action.error.message,
  })),

  on(AuthActions.userLikeStore, (state, action) => ({
    ...state,
    saveLike: [...state.saveLike, action.storeid],
  })),
  on(AuthActions.userLikeStoreSuccess, (state, action) => {
    const items = state.saveLike.filter((x) => x !== action.storeid);
    if (state.user !== null) {
      const likeslike = state.user.likeslike.filter((x) => x !== action.storeid);
      const likesdont = state.user.likesdont.filter((x) => x !== action.storeid);
      if (action.value === LikeType.like) {
        likeslike.push(action.storeid);
      } else if (action.value === LikeType.dont) {
        likesdont.push(action.storeid);
      }
      return {
        ...state,
        saveLike: items,
        user: { ...state.user, likesdont, likeslike },
      };
    } else {
      return {
        ...state,
        saveLike: items,
      };
    }
  }),
  on(AuthActions.userLikeStoreFailure, (state, action) => {
    const items = state.saveLike.filter((x) => x !== action.storeid);
    return { ...state, saveLike: items };
  }),

  on(AuthActions.userGotoStore, (state, action) => ({
    ...state,
    saveGoto: [...state.saveGoto, action.storeid],
  })),
  on(AuthActions.userGotoStoreSuccess, (state, action) => {
    const items = state.saveGoto.filter((x) => x !== action.storeid);
    if (state.user !== null) {
      const gotosgoto = state.user.gotosgoto.filter((x) => x !== action.storeid);
      const gotosdont = state.user.gotosdont.filter((x) => x !== action.storeid);
      const gotosgone = state.user.gotosgone.filter((x) => x !== action.storeid);
      if (action.value === GotoType.goto) {
        gotosgoto.push(action.storeid);
      } else if (action.value === GotoType.dont) {
        gotosdont.push(action.storeid);
      } else if (action.value === GotoType.gone) {
        gotosgone.push(action.storeid);
      }
      return {
        ...state,
        saveGoto: items,
        user: { ...state.user, gotosgoto, gotosdont, gotosgone },
      };
    } else {
      return {
        ...state,
        saveGoto: items,
      };
    }
  }),
  on(AuthActions.userGotoStoreFailure, (state, action) => {
    const items = state.saveGoto.filter((x) => x !== action.storeid);
    return { ...state, saveGoto: items };
  }),

  on(AuthActions.userVisitStore, (state, action) => ({
    ...state,
    saveVisit: [...state.saveVisit, action.storeid],
  })),
  on(AuthActions.userVisitStoreSuccess, (state, action) => {
    const storeid = action.visit.shopid;
    const items = state.saveVisit.filter((x) => x !== storeid);
    const allVisits = state.visits ? [action.visit, ...state.visits] : state.visits;
    if (state.user !== null) {
      const visits = state.user.visits.filter((x) => x.storeid !== storeid);
      const visitItem = state.user.visits.find((x) => x.storeid === storeid);
      visits.push({
        storeid,
        visit: action.visit.visit,
        visits: visitItem ? visitItem.visits + 1 : 1,
      });
      return {
        ...state,
        visits: allVisits,
        saveVisit: items,
        user: { ...state.user, visits },
      };
    } else {
      return {
        ...state,
        saveSave: items,
      };
    }
  }),
  on(AuthActions.userVisitStoreFailure, (state, action) => {
    const items = state.saveVisit.filter((x) => x !== action.storeid);
    return { ...state, saveVisit: items };
  }),

  on(AuthActions.userColourStore, (state, action) => ({
    ...state,
    saveColour: [...state.saveColour, action.storeid],
  })),
  on(AuthActions.userColourStoreSuccess, (state, action) => {
    const items = state.saveColour.filter((x) => x !== action.storeid);
    if (state.user !== null) {
      const colours = state.user.colours.filter((x) => x.shopid !== action.storeid);
      if (action.value !== 'black') {
        colours.push({ shopid: action.storeid, value: action.value });
      }
      return { ...state, saveColour: items, user: { ...state.user, colours } };
    } else {
      return {
        ...state,
        saveColour: items,
      };
    }
  }),
  on(AuthActions.userColourStoreFailure, (state, action) => {
    const items = state.saveColour.filter((x) => x !== action.storeid);
    return { ...state, saveColour: items };
  }),

  on(AuthActions.userLoadVisits, (state) => ({
    ...state,
    loadingVisits: !(state.loadingVisits || state.loadedVisits),
  })),
  on(AuthActions.userLoadVisitsSuccess, (state, action) => ({
    ...state,
    loadingVisits: false,
    visits: action.visits.visits,
    loadedVisits: true,
  })),
  on(AuthActions.userLoadVisitsFailure, (state) => ({
    ...state,
    loadingVisits: false,
    visits: null,
    loadedVisits: false,
  })),

  on(AuthActions.userUnvisitStore, (state) => ({
    ...state,
    loadingVisits: true,
  })),
  on(AuthActions.userUnvisitStoreSuccess, (state, action) => {
    const visits = state.visits ? state.visits.filter((v) => v.id !== action.visit.id) : state.visits;
    let user = state.user;
    if (user) {
      const userVisits = user.visits.filter((v) => v.storeid !== action.visit.shopid);
      if (visits && visits.length > 0) {
        const latestVisit = visits.find((v) => v.shopid === action.visit.shopid);
        if (latestVisit) {
          const newItem: ShopVisits = {
            storeid: action.visit.shopid,
            visit: latestVisit.visit,
            visits: visits.filter((v) => v.shopid === action.visit.shopid).length,
          };
          user = { ...user, visits: [newItem, ...userVisits] };
        }
      }
    }
    return {
      ...state,
      loadingVisits: false,
      visits,
      user,
    };
  }),
  on(AuthActions.userUnvisitStoreFailure, (state) => ({
    ...state,
    loadingVisits: false,
  })),

  on(AuthActions.userSavePin, (state) => ({ ...state, savingPin: true })),
  on(AuthActions.userSavePinFailure, (state) => ({
    ...state,
    savingPin: false,
  })),
  on(AuthActions.userSavePinSuccess, (state, action) => {
    if (state.user) {
      const pins = state.user.pins.filter((pin) => pin.id !== action.pin.id);
      pins.push(action.pin);
      return {
        ...state,
        savingPin: false,
        user: { ...state.user, pins },
      };
    }
    return { ...state, savingPin: false };
  }),

  on(AuthActions.userRemovePin, (state) => ({ ...state, removingPin: true })),
  on(AuthActions.userRemovePinSuccess, (state, action) => {
    if (state.user) {
      const pins = state.user.pins.filter((x) => x.id !== action.pinid);
      return { ...state, user: { ...state.user, pins }, removingPin: false };
    }
    return { ...state, removingPin: false };
  }),
  on(AuthActions.userRemovePinFailure, (state) => ({
    ...state,
    removingPin: false,
  }))
);
