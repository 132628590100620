import { Component, OnInit } from '@angular/core';
import { Image, LatLngPos, ShopDetails } from '@shared/classes';
import { HelperService, HOURSUNKNOWN } from '@core/services/helper.service';
import { LinkHelper, Link } from '@app/shared/helpers/link.helper';
import { faFacebookSquare, faInstagramSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import {
  faPen,
  faPhone,
  faEnvelope as faMail,
  faGlobeAmericas as faGlobe,
  faMapMarkerAlt as faMarker,
  faStar,
  faRoute,
  faMapMarkedAlt,
  faHeart as faHeartSolid,
  faSpinner,
  faQrcode,
} from '@fortawesome/free-solid-svg-icons';
import { faClock, faLifeRing } from '@fortawesome/free-regular-svg-icons';
import { AppStateService } from '@core/services/app-state.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectActiveDiv } from '@app/modules/global/store/global.selectors';
import {
  selectAllStores,
  selectDetailedStore,
  selectSupportersNearDetailedStore,
} from '@app/modules/shops/store/shop.selectors';
import { loadMediumStores, setActiveStoreID, setDetailedStore } from '../../store/shop.actions';
import { filter, first } from 'rxjs/operators';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { setActiveMarker2Store, zoomToStore } from '@app/modules/map/store/map.actions';
import { activateStoreDiv } from '@app/modules/global/store/global.actions';
import { filterNullish } from '@app/shared/helpers/rxjs.helpers';
import { DialogsService } from '@core/services/dialogs.service';
import { selectUserIsAdmin } from '@app/modules/auth/store/auth.selectors';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
})
export class StoreComponent extends Unsubscriber implements OnInit {
  icons = {
    faFacebook: faFacebookSquare,
    faGlobe,
    faInstagram: faInstagramSquare,
    faMail,
    faPen,
    faPhone,
    faTwitter: faTwitterSquare,
    faMarker,
    faClock,
    faStar,
    faGmaps: faMapMarkedAlt,
    faRoute,
    faLifeRing,
    faHeart: faHeartSolid,
    faSpinner,
    faQrcode,
  };

  activeDiv$ = this.store.select(selectActiveDiv);
  stores$ = this.store.select(selectAllStores);
  store$ = this.store.select(selectDetailedStore);
  position$ = this.store.select(selectPosition);
  supporters$ = this.store.select(selectSupportersNearDetailedStore);
  userIsAdmin$ = this.store.select(selectUserIsAdmin);

  shop: ShopDetails | undefined;
  hours = '';
  hoursunknown = true;
  links: Link[] = [];
  images: Image[] = [];
  showQr = false;

  distance: number | undefined = undefined;
  direction: string | undefined = undefined;

  private positionSubscription: Subscription | undefined;
  private storeSubscription: Subscription | undefined;

  constructor(
    public appState: AppStateService,
    public dialogsService: DialogsService,
    public helper: HelperService,
    private route: ActivatedRoute,
    private store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(activateStoreDiv());

    // Über den StoreResolver müsste schon der richtige Store geladen sein.
    // Ist der null, gibt es keinen Store mit der id.
    // Den geladenen Store verwende ich nicht zur Anzeige, dazu lade ich ihn über den Store.
    this.unsubscribeLater(
      this.route.data.subscribe((data) => {
        if (data.store !== null) {
          this.store.dispatch(setDetailedStore({ store: data.store }));
          this.initStoreByID(data.store.id);
        } else {
          this.route.paramMap.pipe(first()).subscribe((params) => {
            const id = params.get('id');
            this.appState.gotoSearch(id ?? '');
          });
        }
      })
    );

    this.doOnDestroy(
      () => this.positionSubscription?.unsubscribe(),
      () => this.storeSubscription?.unsubscribe()
    );
  }

  initStoreByID(id: number) {
    // Den einen detaillierten Datensatz holen (ist null wenn id nicht auffindbar)
    this.storeSubscription?.unsubscribe();
    this.storeSubscription = this.store$
      .pipe(
        filterNullish(),
        filter((x) => x.id === id)
      )
      .subscribe((store) => {
        this.initStore(store);
      });

    // Falls stores schon geladen wurden, dann zusätzlich activeStore setzen
    this.stores$.pipe(first((x) => x.length > 0)).subscribe((stores) => {
      const store = stores.find((x) => x.id === id);
      if (store) {
        this.store.dispatch(setActiveStoreID({ id: store.id })); //
      }
    });
  }

  initStore(store: ShopDetails | undefined | null) {
    if (store) {
      this.shop = store;
      this.appState.setActiveTitle(store.name + ' / ' + store.city + ' on recordstores.love');
      this.hours = store.hasclosed
        ? 'permanently closed'
        : this.helper.getShortTimesString(store) + (store.times ? ` (${store.times})` : '');
      this.hoursunknown = this.hours === HOURSUNKNOWN;

      this.links = [];

      // eMail nur für Admins sichtbar
      this.userIsAdmin$.pipe(first()).subscribe((x) => {
        if (x && store.email) {
          this.links.push(LinkHelper.mail2link(store.email));
        }
      });

      if (store.phone) {
        this.links.push(LinkHelper.tel2link(store.phone));
      }
      [store.website, store.ebay, store.discogs, store.facebook].forEach((url) => {
        if (url && !url.toLowerCase().includes('vinylhub')) {
          this.links.push(LinkHelper.url2link(url));
        }
      });

      this.links = this.links.filter((x) => x.valid);
      this.links.sort((a, b: Link) => a.order - b.order);

      this.images = [];
      const img = store.images?.find((x) => x.id === store.imageid);
      if (img) {
        this.images.push(img);
      }
      this.images = [...this.images, ...(store.images ?? []).filter((x) => x.id !== store.imageid)];

      if (!store.supporter) {
        this.supporters$.pipe(first((x) => !!x)).subscribe((supporters) => {
          const stores = supporters.filter((x) => !x.isMedium);
          if (stores.length > 0) {
            this.store.dispatch(loadMediumStores({ stores, from: 'store/' + store.id.toString() }));
          }
        });
      }

      this.positionSubscription?.unsubscribe();
      this.positionSubscription = this.position$.subscribe((pos: LatLngPos | null) => {
        if (pos && this.shop) {
          const d = PositionHelpers.getDistanceBetween(pos, this.shop);
          if (d < 1000) {
            this.distance = d;
            this.direction = PositionHelpers.getDirection(pos, this.shop);
          } else {
            this.distance = undefined;
            this.direction = undefined;
          }
        }
      });

      this.store.dispatch(zoomToStore({ position: store }));
      this.store.dispatch(setActiveMarker2Store({ position: store, storeId: store.id }));
    }
  }
}
