import { MarkerColor, MarkerDefinitions } from '@app/shared/helpers/marker-definitions';

export class MarkerObject {
  constructor(public marker: google.maps.Marker, public storeid: number, private color: MarkerColor) {}

  setColor(color: MarkerColor) {
    if (this.color !== color) {
      this.color = color;
      const marker = MarkerDefinitions.getStoreMarker(color);
      this.marker.setIcon(marker.icon ?? null);
    }
  }
}
