<div>
  <img title="Map Highlights" [matMenuTriggerFor]="menu" src="/assets/markers/diskpin24red.png" />
  <mat-menu #menu="matMenu">
    <p class="rs-map-menu-title"><b>Highlight Stores</b></p>
    <mat-radio-group class="rs-radio-group" [(ngModel)]="selectedHighlight" (change)="onChange()">
      <mat-radio-button
        class="rs-radio-button"
        *ngFor="let highlight of highlights"
        [value]="highlight.id"
        [disabled]="highlight.userRelated && (authenticated$ | async) === false"
      >
        {{ highlight.description }}
        <span *ngIf="highlight.userRelated">*</span>
      </mat-radio-button>
    </mat-radio-group>
    <button mat-button color="primary" routerLink="/highlights">Click here for more info</button>
    <p class="rs-map-menu-title">* for registered users</p>
  </mat-menu>
</div>
