<div class="rs-panel" *ngIf="state$ | async as state">
  <h1 class="rs-header-1">
    {{ state.name }}
    <span class="rs-h-small">{{ state.state }} - {{ state.opencnt }} Stores</span>
  </h1>

  <rs-breadcrumb
    [breadcrumbs]="[
      { routerLink: '/US', caption: state.countrycode },
      { routerLink: '', caption: state.state }
    ]"
  ></rs-breadcrumb>

  <div *ngIf="supportersLoaded$ | async; else loading">
    <div *ngIf="supporters$ | async as supporters">
      <rs-supporters-list
        [countrycode]="state.countrycode"
        title="{{ helper.n(supporters.length, 'Supporter') }} in {{ state.name }}"
        [subtitle]="supporters.length > 1 ? 'in random order' : ''"
        [supporters]="supporters"
      ></rs-supporters-list>
    </div>
  </div>
  <ng-template #loading>
    <rs-supporters-list
      [countrycode]="state.countrycode"
      title="Loading Supporters in {{ state.name }}"
      subtitle=""
      [supporters]="[]"
    ></rs-supporters-list>
  </ng-template>

  <rs-cities-states [name]="state.name" [cities]="cities$ | async" [states]="[]"></rs-cities-states>

  <rs-contact-and-coffee
    [title]="state.name + ' - ' + state.state"
    [url]="'https://recordstores.love/US/' + state.state"
  >
  </rs-contact-and-coffee>

  <rs-sharing
    [url]="'recordstores.love/US/' + state.state"
    [name]="'All record stores in ' + state.name"
    [descript]="'Follow this link to find all record stores in ' + state.name"
  ></rs-sharing>
</div>
