/* eslint-disable @typescript-eslint/naming-convention */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPosition from './position.reducer';

export const selectPositionState = createFeatureSelector<fromPosition.PositionState>(fromPosition.positionFeatureKey);

export const selectPosition = createSelector(selectPositionState, (state) => state.position);

const selectPositionError = createSelector(selectPositionState, (state) => state.positionError);

export const selectPositionLoading = createSelector(selectPositionState, (state) => state.loading);

// Loaded heißt nicht, dass es eine Pos gibt, sondern nur dass bereits ein Ergebnis
// vorliegt. Das kann auch eine Fehlermeldung sein.
export const selectPositionLoaded = createSelector(selectPositionState, (state) => state.loaded);

export const selectPositionErrorDetails = createSelector(selectPositionError, (error) =>
  error
    ? {
        error,
        permission_denied: error?.code === error?.PERMISSION_DENIED,
        timeout: error?.code === error?.TIMEOUT,
        position_unavailable: error?.code === error?.POSITION_UNAVAILABLE,
        message: error?.message,
      }
    : null
);
