import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MarkersService } from '@app/core/services/markers.service';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { LatLng, OpenNowType, ShopBasics, ShopTimesHelper, User } from '@app/shared/classes';
import { faHeart as faSolidHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import {
  faHeart as faEmptyHeart,
  faCheckSquare,
  faPlusSquare,
  faMinusSquare,
} from '@fortawesome/free-regular-svg-icons';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { selectAuthenticated, selectGotos, selectLikes, selectUser } from '@app/modules/auth/store/auth.selectors';
import { selectSelectedHighlightStrategy } from '@app/modules/markers/store/marker.selectors';
import { first } from 'rxjs/operators';
import { MarkerDefinitions } from '@app/shared/helpers/marker-definitions';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'rs-shop-item',
  templateUrl: './shop-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./shop-item.component.scss'],
})
export class ShopItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() item!: ShopBasics;
  @Input() handleMouseOver = true;
  @Input() position: LatLng | null = null; // Wenn für die Entfernung nicht der aktuelle Standort genommen werden soll.

  iconurl = '';
  distance = -1;
  direction = '';
  times = '';
  timesClass = '';

  faCheckSquare = faCheckSquare;
  faPlusSquare = faPlusSquare;
  faMinusSquare = faMinusSquare;
  faSolidHeart = faSolidHeart;
  faEmptyHeart = faEmptyHeart;
  faStar = faStar;

  likeLike$ = new BehaviorSubject<boolean>(false);
  likeDont$ = new BehaviorSubject<boolean>(false);
  gotoGoto$ = new BehaviorSubject<boolean>(false);
  gotoGone$ = new BehaviorSubject<boolean>(false);
  gotoDont$ = new BehaviorSubject<boolean>(false);

  private position$ = this.store.select(selectPosition);
  private user$ = this.store.select(selectUser);
  private likes$ = this.store.select(selectLikes);
  private gotos$ = this.store.select(selectGotos);
  private authenticated$ = this.store.select(selectAuthenticated);
  private highlightStrategy$ = this.store.select(selectSelectedHighlightStrategy);

  private subPosition?: Subscription;
  private subHiglightStrategy?: Subscription;
  private subAuthenticated?: Subscription;
  private subLikes?: Subscription;
  private subGotos?: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private helper: HelperService,
    private markersService: MarkersService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.subHiglightStrategy = this.highlightStrategy$.subscribe((s) => this.initIconUrl());
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    this.init();
  }

  ngOnDestroy(): void {
    this.subPosition?.unsubscribe();
    this.subHiglightStrategy?.unsubscribe();
    this.subAuthenticated?.unsubscribe();
    this.subLikes?.unsubscribe();
    this.subGotos?.unsubscribe();
  }

  init() {
    if (this.item) {
      this.initDistance();
      this.initIconUrl();
      this.initTimes();
      this.initTimesClass();
      this.initLikes();
    }
  }

  initDistance() {
    if (this.position) {
      const d = PositionHelpers.getDistanceBetween(this.position, this.item);
      if (d < 250) {
        this.distance = d;
        this.direction = PositionHelpers.getDirection(this.position, this.item);
      } else {
        this.distance = -1;
        this.direction = '';
      }
    } else {
      this.subPosition?.unsubscribe();
      if (this.item) {
        this.distance = -1;
        this.direction = '';
        this.subPosition = this.position$.subscribe((pos) => {
          if (pos && this.item) {
            const d = PositionHelpers.getDistanceBetween(pos, this.item);
            if (d < 250) {
              this.distance = d;
              this.direction = PositionHelpers.getDirection(pos, this.item);
            } else {
              this.distance = -1;
              this.direction = '';
            }
            this.cdr.detectChanges();
          }
        });
      }
    }
    this.cdr.detectChanges();
  }

  initLikes() {
    this.subAuthenticated?.unsubscribe();
    this.subLikes?.unsubscribe();
    this.subGotos?.unsubscribe();
    this.subLikes = undefined;
    this.subGotos = undefined;

    this.subAuthenticated = this.authenticated$.subscribe((x) => {
      if (x) {
        this.subLikes = this.likes$.subscribe((likes) => {
          this.likeLike$.next(likes.likeslike.includes(this.item.id));
          this.likeDont$.next(likes.likesdont.includes(this.item.id));
        });
        this.subGotos = this.gotos$.subscribe((gotos) => {
          this.gotoGoto$.next(gotos.gotosgoto.includes(this.item.id));
          this.gotoGone$.next(gotos.gotosgone.includes(this.item.id));
          this.gotoDont$.next(gotos.gotosdont.includes(this.item.id));
        });
      } else {
        this.likeLike$.next(false);
        this.likeDont$.next(false);
        this.gotoGoto$.next(false);
        this.gotoGone$.next(false);
        this.gotoDont$.next(false);
        this.subLikes?.unsubscribe();
        this.subLikes = undefined;
        this.subGotos?.unsubscribe();
        this.subGotos = undefined;
      }
    });
  }

  initIconUrl() {
    if (this.item) {
      this.highlightStrategy$.pipe(first()).subscribe((strategy) => {
        let user: User | null = null;
        if (strategy.userRelated) {
          this.user$.pipe(first()).subscribe((u) => (user = u));
        }
        const color = strategy.getColor(this.item, user);
        const icon = MarkerDefinitions.getStoreMarker(color);
        if (icon?.icon) {
          const iconurl = icon.icon.url;
          if (iconurl !== this.iconurl) {
            this.iconurl = icon.icon.url;
            this.cdr.detectChanges();
          }
        }
      });
    }
  }

  initTimes() {
    const times = this.helper.getShortTimeStringToday(this.item);
    if (times !== this.times) {
      this.times = times;
      this.cdr.detectChanges();
    }
  }

  initTimesClass() {
    let result = '';
    const store = this.item;
    if (store.hasclosed) {
      result = 'todayclosed';
    } else if (store.today) {
      switch (ShopTimesHelper.getOpenNow(store.today, store.todayfrom, store.todayuntil, store.todayclosed)) {
        case OpenNowType.closed:
          result = 'todayclosed';
          break;
        case OpenNowType.opennow:
          result = 'opennow';
          break;
        case OpenNowType.opensoon:
          result = 'opensoon';
          break;
        case OpenNowType.wasopen:
          result = 'wasopen';
          break;
        default:
          result = 'todayunknown';
      }
    }
    if (this.timesClass !== result) {
      this.timesClass = result;
      this.cdr.detectChanges();
    }
  }

  mouseEnter() {
    if (this.item && this.handleMouseOver) {
      this.markersService.hoverOn(this.item);
    }
  }

  mouseLeave() {
    if (this.item && this.handleMouseOver) {
      this.markersService.hoverOff();
    }
  }
}
