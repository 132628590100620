<div class="rs-panel">
  <h2 mat-dialog-title>Reset Your Password for recordstores.love</h2>

  <p>You forgot your password. Just type in a new password and confirm it to be able to sign in into your account.</p>

  <div *ngIf="success !== true" class="rs-store-form rs-form mb-3 p-margins">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <mat-form-field>
        <input matInput type="password" formControlName="password" placeholder="Your Password" />
        <mat-error *ngIf="form.get('password')?.invalid && form.get('password')?.touched"
          >Please enter your Password</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <input matInput type="password" formControlName="password2" placeholder="Confirm Your Password" />
        <mat-error *ngIf="form.get('password2')?.invalid && form.get('password2')?.touched"
          >Please confirm your Password</mat-error
        >
      </mat-form-field>

      <div style="text-align: right">
        <a style="visibility: hidden" mat-flat-button class="rs-button">Contact</a>
        <input type="button" value="Cancel" class="mat-button rs-alt-button" routerLink="/" />
        <input
          type="submit"
          value="Set New Password"
          class="mat-flat-button rs-button mr-3"
          [class.disabled]="!form.valid || sending"
          [disabled]="!form.valid || sending"
        />
      </div>
    </form>
  </div>

  <p *ngIf="sending" class="red">
    <fa-icon title="Trying to update your password, please wait" [icon]="faSpinner" [spin]="true"></fa-icon>
    Updateing password...
  </p>
  <p *ngIf="success === false" class="red">
    An error occured while trying to reset your password. This happens when the key has been used or you logged in after
    you requested this password reset mail. Please try again.
  </p>
  <p *ngIf="success === true">
    Your password has been changed.<br />
    <span class="rs-pointer rs-blue" routerLink="/">Click here to go to the Welcome Page.</span>.
  </p>
</div>
