import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Country, State } from '@shared/classes';

export const loadCountries = createAction('[Countries] Load Countries');
export const loadCountriesSuccess = createAction(
  '[Countries] Load Countries Success',
  props<{ countries: Country[] }>()
);
export const loadCountriesFailure = createAction(
  '[Countries] Load Countries Failure',
  props<{ error: HttpErrorResponse }>()
);

export const loadStates = createAction('[Countries] Load States');
export const loadStatesSuccess = createAction('[Countries] Load States Success', props<{ states: State[] }>());
export const loadStatesFailure = createAction('[Countries] Load States Failure', props<{ error: HttpErrorResponse }>());

export const setCountriesFilter = createAction('[Countries] Set Filter', props<{ filter: string }>());

export const setActiveCountry = createAction('[Countries] Set Active Country', props<{ countrycode: string }>());
export const setActiveState = createAction('[Countries] Set Active State', props<{ statecode: string }>());
