import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { MarkersService } from '../../../../core/services/markers.service';

@Component({
  selector: 'rs-badge-item',
  templateUrl: './badge-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./badge-item.component.scss'],
})
export class BadgeItemComponent implements OnInit {
  @Input() icon!: string;
  @Input() title!: string;
  @Input() badge!: number;
  @Input() lat!: number;
  @Input() lng!: number;

  @Input() distance?: number;
  @Input() direction?: string;

  constructor(private markersService: MarkersService) {}

  ngOnInit(): void {}

  mouseEnter() {
    if (this.lat && this.lng) {
      this.markersService.hoverOn({ lat: this.lat, lng: this.lng });
    }
  }

  mouseLeave() {
    this.markersService.hoverOff();
  }
}
