<div class="rs-panel">
  <div>
    <h1 class="rs-header-1">
      Data Policy
      <span class="rs-h-small">Datenschutz</span>
    </h1>
  </div>

  <rs-breadcrumb [breadcrumbs]="[{ routerLink: '', caption: 'datenschutz' }]"></rs-breadcrumb>

  <div class="impressum">
    <p>
      <strong>Datenschutzerklärung</strong>
    </p>
    <p>
      Wir nehmen den Schutz persönlicher Daten sehr ernst und halten uns an die einschlägigen gesetzlichen Vorschriften.
      Die nachfolgende Erklärung informiert Sie darüber, welche Art von personenbezogenen Daten zu welchem Zweck auf
      dieser Webseite erhoben werden und in welchem Maße diese Daten Dritten zugänglich gemacht werden.
    </p>
    <p>
      <strong>Log-Dateien</strong>
    </p>
    <p>
      In den Serverstatistiken werden automatisch solche Daten gespeichert, die der Browser an uns übermittelt, um Ihnen
      den Besuch der Website zu ermöglichen (sogenannte „logfiles“).
    </p>
    <p>Dies sind im Einzelnen folgende Daten:</p>
    <ul>
      <li>Sprache und Version der Browsersoftware</li>
      <li>verwendetes Betriebssystem und dessen Oberfläche</li>
      <li>Referrer URL (die zuvor besuchte Seite)</li>
      <li>Hostname des zugreifenden Rechners (IP- Adresse)</li>
      <li>Datum und Uhrzeit der Serveranfrage</li>
      <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
      <li>Inhalt der Anforderung (konkrete Seite)</li>
      <li>übertragene Datenmenge</li>
      <li>Zugriffsstatus/ HTTP- Statuscode.</li>
    </ul>
    <p>
      Diese Daten können wir in der Regel nicht bestimmten Personen zuordnen. Eine Zusammenführung dieser Daten mit
      anderen Datenquellen wird nicht vorgenommen. Die Daten werden zudem nach einer statistischen Auswertung innerhalb
      von 4 Wochen gelöscht.
    </p>
    <p>
      Wir verwenden diese Daten nur für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und Optimierung
      unseres Angebotes.
    </p>
    <p>
      <strong>Weitere personenbezogene Daten bei Nutzung der Webseite</strong>
    </p>
    <p>
      Die von Ihnen zur Verfügung gestellten personenbezogenen Daten, wie Name, Adresse oder E-Mail- Adresse werden nur
      intern verwendet, um Ihre Anfragen zu beantworten, Ihre Aufträge zu bearbeiten oder Ihnen Zugang zu bestimmten
      Informationen zu verschaffen.
    </p>
    <p>
      Bei Ihrer Kontaktaufnahme mit uns per E-Mail werden Ihre Angaben von uns gespeichert, um Ihre Fragen zu
      beantworten. Wenn der Zweck der Anfrage entfällt, werden diese Daten gelöscht.
    </p>
    <p><strong>Datenweitergabe an Dritte; Zweckänderung</strong></p>
    <p>
      An Dritte werden Daten nur weitergegeben, wenn dies zur Vertragserfüllung notwendig ist und/oder wir im Einzelfall
      dazu gesetzlich verpflichtet sind.
    </p>
    <p>
      Sollen Ihre Daten für andere Zwecke verwendet werden, werden wir Sie zuvor informieren und die Daten nur
      verwenden, soweit Sie vorher ausdrücklich Ihre Einwilligung dazu erklärt haben.
    </p>
    <p>
      <strong> Cookies </strong>
    </p>
    <p>
      Diese Seite verwendet Cookies. Cookies sind kleine Textdateien, die auf Ihrem Rechner gespeichert werden und durch
      welche die Stelle, die den Cookie setzt, bestimmte Informationen zufließen. Sie dienen dazu das Internetangebot
      nutzerfreundlicher und effektiver zu gestalten oder/und Ihnen die Navigation auf unserer Webseite zu erleichtern.
      Selbstverständlich können Sie unsere Website auch nutzen, ohne dass Sie Cookies akzeptieren. Sie können auch Ihre
      Browser- Einstellungen entsprechend Ihren Wünschen konfigurieren und insgesamt z.B. die Annahme von Third- Party-
      Cookies oder allen Cookies ablehnen oder bereits gespeicherte Cookies löschen. Sollten Sie Cookies nicht
      akzeptieren, beachten Sie bitte, dass in diesem Fall unser Angebot unter Umständen nicht fehlerfrei funktioniert.
      Die gespeicherten Informationen werden getrennt von eventuell weiter bei uns angegebenen Daten gespeichert. Die
      Daten der Cookies werden nicht mit Ihren weiteren Daten verknüpft.
    </p>
    <p><strong>Auskunft, Berichtigung, Sperrung, Löschung</strong></p>

    <p>
      Wir geben gerne Auskunft über Ihre bei uns gespeicherten persönlichen Daten, sowie zu deren Herkunft, Empfängern
      oder Kategorien von Empfängern, an die diese Daten weitergegeben werden und den Zweck der Speicherung. Dieses
      Recht können Sie jederzeit geltend machen sowie gegebenenfalls ein Recht auf Berichtigung, Sperrung und Löschung
      Ihrer Daten. Erteilte Einwilligungen können Sie jederzeit wiederrufen.
    </p>
    <p>
      Senden Sie hierzu eine Anfrage per E-Mail und verwenden Sie bitte in der Betreffzeile den Betreff "Datenschutz".
    </p>
    <hr />
    <p>
      Diese Datenschutzerklärung wurde von der
      <a href="http://www.kanzlei-sieling.de" target="_blank">
        Rechtsanwaltskanzlei Sieling – Fachanwaltskanzlei für IT-Recht</a
      >
      zur Verfügung gestellt.
    </p>
  </div>

  <rs-contact-and-coffee title="data policy" url="https://recordstores.love/datenschutz"> </rs-contact-and-coffee>

  <rs-sharing
    url="recordstores.love/datenschutz"
    name="recordstores.love"
    descript="Follow this link to find all record stores worldwide"
  ></rs-sharing>
</div>
