/// <reference types="@types/google.maps" />
import { Component, OnInit, ViewChild } from '@angular/core';
import { selectMapVisible } from '@app/modules/global/store/global.selectors';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { MapService } from '../../../core/services/map.service';

@Component({
  selector: 'rs-map',
  templateUrl: './map.component.html',
  styleUrls: [],
})
export class MapComponent implements OnInit {
  @ViewChild('gmap', { static: true }) gmapElement: any;

  mapVisible$ = this.store.select(selectMapVisible);

  constructor(private mapService: MapService, private store: Store) {}

  ngOnInit(): void {
    this.mapVisible$.pipe(first((x) => x)).subscribe(() => this.mapService.initMap(this.gmapElement.nativeElement));
  }
}
