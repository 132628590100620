<h2 mat-dialog-title>Add new</h2>

<rs-address-form
  *ngIf="!sending && !sent"
  [address]="shop"
  [withNote]="true"
  (closeForm)="closeAddressForm($event)"
  class="rs-form"
></rs-address-form>

<p *ngIf="sending" class="red">
  <fa-icon title="Saving, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
  Saving... - please wait.
</p>

<div *ngIf="sent">
  <p>Thank you.</p>
  <div class="mt-3 mb-3" style="text-align: center">
    <a mat-stroked-button (click)="closeForm()">Close This Form</a>
  </div>
</div>
