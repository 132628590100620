<h2 mat-dialog-title>{{ options.title }}</h2>

<mat-dialog-content>
  {{ options.message }}

  <div style="text-align: right" class="mt-3">
    <button mat-button mat-dialog-close *ngIf="options.cancel" class="rs-alt-button">
      {{ options.cancel }}
    </button>
    <button mat-flat-button (click)="onSubmit()" cdkFocusInitial class="rs-button">
      {{ options.submit }}
    </button>
  </div>
</mat-dialog-content>
