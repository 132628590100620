<div class="rs-panel-row rs-clickable" [class.noselect]="noselect">
  <div class="rs-simple-item-container">
    <div class="rs-simple-item-icon">
      <img *ngIf="icon" src="{{ icon }}" class="imgfloat {{ iconClass }}" />
      <fa-icon *ngIf="faIcon" [fixedWidth]="true" size="lg" [icon]="faIcon" class="imgfloat {{ iconClass }}"></fa-icon>
    </div>
    <div class="rs-simple-item-text">
      {{ title }}
    </div>
  </div>
</div>
