import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/core/services/helper.service';
import { selectVisiblePins } from '@app/modules/auth/store/auth.selectors';
import { selectVisibleCities } from '@app/modules/cities/store/city.selectors';
import { selectVisibleCountries } from '@app/modules/countries/store/country.selectors';
import { activateVisibleDiv } from '@app/modules/global/store/global.actions';
import { selectCitiesVisible, selectCountriesVisible, selectStoresVisible } from '@app/modules/map/store/map.selectors';
import { Store } from '@ngrx/store';
import { selectVisibleStores, selectVisibleSupporters } from '../../store/shop.selectors';

@Component({
  selector: 'rs-visible',
  templateUrl: './visible.component.html',
  styleUrls: ['./visible.component.scss'],
})
export class VisibleComponent implements OnInit {
  supporters$ = this.store.select(selectVisibleSupporters);
  stores$ = this.store.select(selectVisibleStores);
  pins$ = this.store.select(selectVisiblePins);
  cities$ = this.store.select(selectVisibleCities);
  countries$ = this.store.select(selectVisibleCountries);
  storesVisible$ = this.store.select(selectStoresVisible);
  citiesVisible$ = this.store.select(selectCitiesVisible);
  countriesVisible$ = this.store.select(selectCountriesVisible);

  subtitle = '';

  constructor(public helper: HelperService, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(activateVisibleDiv());
  }
}
