import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MarkersService } from '@app/core/services/markers.service';
import { Unsubscriber } from '@app/framework/unsubscriber';
import { LatLng, ShopBasics } from '@shared/classes';

@Component({
  selector: 'rs-supporters-list',
  templateUrl: './supporters-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./supporters-list.component.scss'],
})
export class SupportersListComponent extends Unsubscriber implements OnInit {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() supporters: ShopBasics[] = [];
  @Input() countrycode = '';
  @Input() position: LatLng | null = null; // Wenn für die Entfernung nicht der aktuelle Standort genommen werden soll.

  dach = false;
  rndm = 0;

  private hovering = false;

  constructor(private markersService: MarkersService) {
    super();
    this.doOnDestroy(() => {
      if (this.hovering) {
        this.mouseLeave();
      }
    });
  }

  ngOnInit(): void {
    this.dach = this.countrycode === 'DE' || this.countrycode === 'CH' || this.countrycode === 'AT';
    this.rndm = Math.round(Math.random() * 100);
  }
  mouseEnter(item: ShopBasics) {
    this.hovering = true;
    this.markersService.hoverOn(item);
  }

  mouseLeave() {
    this.hovering = false;
    this.markersService.hoverOff();
  }
}
