<div class="rs-panel">
  <div class="rs-border-bottom-lightgray">
    <div class="p-margins rs-panel-row">
      <mat-form-field class="rs-search-input">
        <mat-label>Search record stores, cities and more</mat-label>
        <input
          matInput
          autofocus
          #searchInput
          type="text"
          [(ngModel)]="value"
          autocomplete="off"
          type="search"
          (keyup)="keyUp$.next($event.target)"
          (keyup.arrowup)="handleKeyUp('UP')"
          (keyup.arrowdown)="handleKeyUp('DOWN')"
          (keyup.enter)="handleKeyUp('ENTER')"
        />
        <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearInput()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <ng-container *ngIf="searchResults$ | async as result; else instructions">
    <div *ngIf="result.what === 'city' && result.cities.length > 0">
      <h2>Cities</h2>

      <a
        routerLink="/{{ helper.city2url(city.city) }}"
        *ngFor="let city of result.cities"
        class="rs-panel-row-link"
        [class.rs-active]="city === focusedItem"
      >
        <rs-city-item [city]="city"></rs-city-item>
      </a>
    </div>

    <div *ngIf="result.stores.length > 0">
      <h2>Stores</h2>

      <a
        routerLink="/{{ store.id }}"
        *ngFor="let store of result.stores"
        class="rs-panel-row-link rs-no-last-line"
        [class.rs-active]="store === focusedItem"
      >
        <rs-shop-item [item]="store"></rs-shop-item>
      </a>
    </div>

    <div *ngIf="result.what === 'country' && result.countries.length > 0">
      <h2>{{ result.countries[0].countrycode }} is a Country Code</h2>

      <a
        routerLink="/{{ country.countrycode }}"
        *ngFor="let country of result.countries"
        class="rs-panel-row-link rs-no-last-line"
        [class.rs-active]="country === focusedItem"
      >
        <rs-country-item [country]="country"></rs-country-item>
      </a>

      <h2 *ngIf="result.cities.length === 1">Only 1 City in {{ result.countries[0].country }}</h2>
      <h2 *ngIf="result.cities.length > 1 && result.cities.length < 5">
        Only {{ result.cities.length }} Cities in
        {{ result.countries[0].country }}
      </h2>
      <h2 *ngIf="result.cities.length >= 5">
        {{ result.cities.length }} biggest Cities in
        {{ result.countries[0].country }}
      </h2>

      <a
        routerLink="/{{ helper.city2url(city.city) }}"
        *ngFor="let city of result.cities"
        class="rs-panel-row-link"
        [class.rs-active]="city === focusedItem"
      >
        <rs-city-item [city]="city"></rs-city-item>
      </a>
    </div>

    <div *ngIf="result.what !== 'country' && result.what !== 'city' && result.cities.length > 0">
      <h2>Cities</h2>

      <a
        routerLink="/{{ helper.city2url(city.city) }}"
        *ngFor="let city of result.cities"
        class="rs-panel-row-link"
        [class.rs-active]="city === focusedItem"
      >
        <rs-city-item [city]="city"></rs-city-item>
      </a>
    </div>

    <div *ngIf="result.states.length > 0">
      <h2>States</h2>

      <a
        routerLink="/{{ helper.state2url(state) }}"
        *ngFor="let state of result.states"
        class="rs-panel-row-link"
        [class.rs-active]="state === focusedItem"
      >
        <rs-state-item [state]="state"></rs-state-item>
      </a>
    </div>

    <div *ngIf="result.what !== 'country' && result.countries.length > 0">
      <h2>Countries</h2>

      <a
        routerLink="/{{ country.countrycode }}"
        *ngFor="let country of result.countries"
        class="rs-panel-row-link rs-no-last-line"
        [class.rs-active]="country === focusedItem"
      >
        <rs-country-item [country]="country"></rs-country-item>
      </a>
    </div>

    <div *ngIf="result.places.length > 0">
      <h2>Results from Google</h2>

      <a
        (click)="gotoPlace(place)"
        class="rs-panel-row-link"
        *ngFor="let place of result.places"
        [class.rs-active]="place === focusedItem"
      >
        <rs-place-item [place]="place"></rs-place-item>
        <ng-container *ngIf="selectedPlace && place === openedPlace">
          <rs-place [place]="selectedPlace"></rs-place>
        </ng-container>
      </a>
    </div>
  </ng-container>

  <ng-template #instructions>
    <section class="result-section p-margins">
      <p><b>Tipps for your Search</b></p>
      <ul>
        <li>Just type in a name of a store, city or country.</li>
        <li>If you want to find a store by the <b>Instagram name</b>, you can type @ and then the instagram name.</li>
        <li>
          You can also type in a <b>country code</b> (like DE for Germany, GB for United Kingdom or IT for Italy), this
          will show you that country and the 10 biggest record store cities in that country.
        </li>
        <li>And you can type in the <b>store id</b>.</li>
      </ul>
    </section>
  </ng-template>
</div>
