<!-- Menupunkte -->
<mat-nav-list>
  <!-- Workaround, weil das erste Item den Fokus hat und gehighlighted wird. -->
  <a
    class="hidden"
    mat-list-item
    href="https://paypal.me/rslove/10"
    target="_blank"
    rel="”noopener”"
    (click)="sidenav.close()"
    >&nbsp;</a
  >

  <div *ngIf="authenticated$ | async; else loginItem">
    <div class="rs-side-nav-user-image">
      <rs-user-image [width]="80"></rs-user-image>
    </div>
    <div *ngIf="user$ | async as user">
      <p class="rs-user-details">
        <b>{{ user.username }}</b
        ><br />
        {{ user.email }}
      </p>
    </div>
    <a mat-list-item routerLink="/user" (click)="sidenav.close()">
      <fa-icon matListIcon [fixedWidth]="true" [icon]="faUser"></fa-icon>
      <div class="mat-list-text">Your Account</div>
    </a>
    <a mat-list-item (click)="logout()">
      <fa-icon matListIcon [fixedWidth]="true" [icon]="faLogout"></fa-icon>
      <div class="mat-list-text">Signout</div>
    </a>
  </div>
  <ng-template #loginItem>
    <a mat-list-item (click)="login()">
      <fa-icon matListIcon [fixedWidth]="true" [icon]="faLogin"></fa-icon>
      <div class="mat-list-text">Signin</div>
    </a>
    <a mat-list-item (click)="loginWithGoogle()">
      <fa-icon matListIcon [fixedWidth]="true" [icon]="faLogin"></fa-icon>
      <div class="mat-list-text">Signin with Google</div>
    </a>
    <a mat-list-item (click)="register()">
      <fa-icon matListIcon [fixedWidth]="true" [icon]="faRegister"></fa-icon>
      <div class="mat-list-text">Sign Up</div>
    </a>
  </ng-template>
</mat-nav-list>

<mat-divider></mat-divider>

<mat-nav-list>
  <a mat-list-item routerLink="/donate" (click)="sidenav.close()">
    <fa-icon class="red" matListIcon [fixedWidth]="true" [icon]="faDonate"></fa-icon>
    <div class="mat-list-text">Donate</div>
  </a>
  <a mat-list-item routerLink="/support" (click)="sidenav.close()">
    <fa-icon class="red" matListIcon [fixedWidth]="true" [icon]="faDonate"></fa-icon>
    <div class="mat-list-text">Become A Supporter</div>
  </a>
</mat-nav-list>

<mat-divider></mat-divider>

<mat-nav-list class="mt8">
  <a
    mat-list-item
    href="https://www.instagram.com/recordstores.love/"
    target="_blank"
    rel="”noopener”"
    (click)="sidenav.close()"
  >
    <fa-icon matListIcon [fixedWidth]="true" [icon]="faFacebook"></fa-icon>
    <div class="mat-list-text">@recordstores.love on Facebook</div>
  </a>
  <a
    mat-list-item
    href="https://www.instagram.com/recordstores.love/"
    target="_blank"
    rel="”noopener”"
    (click)="sidenav.close()"
  >
    <fa-icon matListIcon [fixedWidth]="true" [icon]="faInstagram"></fa-icon>
    <div class="mat-list-text">@recordstores.love on Instagram</div>
  </a>
  <a mat-list-item routerLink="/impressum" (click)="sidenav.close()">
    <fa-icon matListIcon [fixedWidth]="true" [icon]="faImprint"></fa-icon>
    <div class="mat-list-text">Imprint</div>
  </a>
  <a mat-list-item routerLink="/datenschutz" (click)="sidenav.close()">
    <fa-icon matListIcon [fixedWidth]="true" [icon]="faDataPolicy"></fa-icon>
    <div class="mat-list-text">Data Policy</div>
  </a>
  <!--
    <mat-list-item>
        <a matLine href="...">Link</a>
        <button mat-icon-button>
           info
        </button>                
    </mat-list-item>
    -->
</mat-nav-list>
