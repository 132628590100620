<p class="rs-panel-row">
  <a routerLink="/">recordstores.love</a>
  <span *ngFor="let item of breadcrumbs">
    <span class="rs-url-separator">/</span>
    <span *ngIf="item.routerLink === ''">{{ item.caption }}</span>
    <span *ngIf="item.routerLink !== ''"
      ><a [routerLink]="item.routerLink">{{ item.caption }}</a></span
    >
  </span>
  <span *ngIf="additional">
    &nbsp; ...&nbsp;
    <a [routerLink]="additional.routerLink"> {{ additional.caption }}</a>
  </span>
</p>
