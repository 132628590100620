import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, concatMap, withLatestFrom, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromCountries from './country.reducer';

import * as CountryActions from './country.actions';
import { DataService } from '@app/core/services/data.service';
import {
  selectCountriesLoaded,
  selectCountriesLoading,
  selectStatesLoaded,
  selectStatesLoading,
} from './country.selectors';
import { Store } from '@ngrx/store';
import { zoomChanged } from '@app/modules/map/store/map.actions';
import { selectCountriesVisible } from '@app/modules/map/store/map.selectors';
import {
  activateCountriesDiv,
  activateCountryDiv,
  activateStateDiv,
  needsMap,
} from '@app/modules/global/store/global.actions';

@Injectable()
export class CountryEffects {
  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.loadCountries),
      concatMap(() =>
        this.data.getAllCountries().pipe(
          map((countries) => CountryActions.loadCountriesSuccess({ countries })),
          catchError((error) => of(CountryActions.loadCountriesFailure({ error })))
        )
      )
    )
  );

  loadStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.loadStates),
      concatMap(() =>
        this.data.getAllStates().pipe(
          map((states) => CountryActions.loadStatesSuccess({ states })),
          catchError((error) => of(CountryActions.loadStatesFailure({ error })))
        )
      )
    )
  );

  // Ein paar Divs brauchen Countries - wenn noch nicht geladen, dann anstoßen.
  loadCountriesOnActivate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateCountriesDiv, activateCountryDiv, activateStateDiv, needsMap),
      withLatestFrom(this.store.select(selectCountriesLoading), this.store.select(selectCountriesLoaded)),
      filter(([action, loading, loaded]) => !loading && !loaded),
      map(([action, loading, loaded]) => CountryActions.loadCountries())
    )
  );

  loadCountriesOnZoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(zoomChanged),
      withLatestFrom(
        this.store.select(selectCountriesLoading),
        this.store.select(selectCountriesLoaded),
        this.store.select(selectCountriesVisible)
      ),
      filter(([action, loading, loaded, visible]) => !loading && !loaded && visible),
      map(() => CountryActions.loadCountries())
    )
  );

  // US-Country-Div braucht States - wenn noch nicht geladen, dann anstoßen
  loadStatesOnActivateUS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.setActiveCountry),
      withLatestFrom(this.store.select(selectStatesLoading), this.store.select(selectStatesLoaded)),
      filter(([action, loading, loaded]) => !loading && !loaded && action.countrycode === 'US'),
      map(([action, loading, loaded]) => CountryActions.loadStates())
    )
  );

  // States brauchen States - wenn noch nicht geladen, dann anstoßen
  loadStatesOnActivateState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateStateDiv),
      withLatestFrom(this.store.select(selectStatesLoading), this.store.select(selectStatesLoaded)),
      filter(([action, loading, loaded]) => !loading && !loaded),
      map(([action, loading, loaded]) => CountryActions.loadStates())
    )
  );

  constructor(private actions$: Actions, private data: DataService, private store: Store<fromCountries.CountryState>) {}
}
