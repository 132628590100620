import { HelperService } from '@app/core/services/helper.service';
import { selectActiveCity } from '@app/modules/cities/store/city.selectors';
import { selectActiveDiv } from '@app/modules/global/store/global.selectors';
import { selectActiveMarker, selectActualBounds, selectStoresVisible } from '@app/modules/map/store/map.selectors';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { selectAllStores } from '@app/modules/shops/store/shop.selectors';
import { City, Pin } from '@app/shared/classes';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(fromAuth.authFeatureKey);

export const selectAuthenticating = createSelector(
  selectAuthState,
  (state) => state.isAuthenticating || state.isRegistering
);

export const selectLoadingOrSaving = createSelector(
  selectAuthState,
  (state) =>
    state.saveGoto.length ||
    state.saveLike.length ||
    state.saveColour.length ||
    state.saveVisit.length ||
    state.loadingVisits ||
    state.removingPin ||
    state.savingPin
);

export const selectLoadingOrLoadedVisits = createSelector(
  selectAuthState,
  (state) => state.loadedVisits || state.loadingVisits
);

export const selectSaveLike = createSelector(selectAuthState, (state) => state.saveLike);
export const selectSaveGoto = createSelector(selectAuthState, (state) => state.saveGoto);
export const selectSaveColour = createSelector(selectAuthState, (state) => state.saveColour);
export const selectSaveVisit = createSelector(selectAuthState, (state) => state.saveVisit);

export const selectAuthenticatingOrSaving = createSelector(
  selectAuthenticating,
  selectLoadingOrSaving,
  (a, s) => a || s
);

export const selectAuthenticated = createSelector(selectAuthState, (state) => state.isAuthenticated);

export const selectUser = createSelector(selectAuthState, (state) => state.user);

export const selectUserIsAdmin = createSelector(selectUser, (user) => user?.id === 2);

export const selectLikes = createSelector(selectUser, (user) => ({
  likeslike: user?.likeslike ?? [],
  likesdont: user?.likesdont ?? [],
}));

export const selectGotos = createSelector(selectUser, (user) => ({
  gotosgoto: user?.gotosgoto ?? [],
  gotosdont: user?.gotosdont ?? [],
  gotosgone: user?.gotosgone ?? [],
}));

export const selectSaves = createSelector(selectUser, (user) => user?.saves ?? []);

export const selectColours = createSelector(selectUser, (user) => user?.colours ?? []);

export const selectVisits = createSelector(selectUser, (user) => user?.visits ?? []);

export const selectAllVisits = createSelector(selectAuthState, (state) => state.visits);

export const selectTodaysVisits = createSelector(selectVisits, selectAllStores, (visits, stores) =>
  visits
    .filter((v) => HelperService.formatDate(v.visit) === HelperService.formatDate(new Date()))
    .sort((a, b) => (a.visit < b.visit ? -1 : a.visit > b.visit ? 1 : 0))
    .map((visit) => ({
      visit,
      store: stores.find((s) => s.id === visit.storeid),
    }))
);

export const selectAllPins = createSelector(selectUser, (user) => (user ? user.pins : []));
export const selectVisiblePins = createSelector(
  selectAllPins,
  selectActualBounds,
  selectStoresVisible,
  (pins, bounds, visible) =>
    visible && bounds
      ? pins.filter((x) => PositionHelpers.posInBounds(x, bounds, 0)).sort((a, b) => a.name.localeCompare(b.name))
      : []
);
export const selectAllPinsInActiveCity = createSelector(selectActiveCity, selectAllPins, (city, pins) => {
  let result: Pin[] = [];
  if (city && pins) {
    const compare = (s: Pin, c: City) =>
      c.countrycode === s.countrycode &&
      c.latmin <= s.lat &&
      s.lat <= c.latmax &&
      c.lngmin <= s.lng &&
      s.lng <= c.lngmax;
    result = pins
      .filter((pin) => compare(pin, city))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
  return result;
});
export const selectPinsByDistance = createSelector(selectActiveDiv, selectPosition, selectAllPins, (div, pos, pins) => {
  let result: Pin[] = [];
  if (pos) {
    if (div === 'NEAR') {
      result = PositionHelpers.getNearestPins(pins, 100, pos, 10);
    } else {
      // Home braucht nur 5
      result = PositionHelpers.getNearestPins(pins, 5, pos, 10);
    }
  }
  return result;
});

export const selectActivePin = createSelector(selectActiveMarker, selectUser, (marker, user) => {
  if (marker?.type === 'pin') {
    return user?.pins.find((pin) => pin.id === marker.pinId);
  } else {
    return undefined;
  }
});
