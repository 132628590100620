import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { HelperService } from '@core/services/helper.service';
import { AppStateService } from '@core/services/app-state.service';
import { selectSupportersInCountry, selectSupportersLoaded } from '@app/modules/shops/store/shop.selectors';
import { selectCitiesInActiveCountry } from '@app/modules/cities/store/city.selectors';
import { setMapBounds } from '@app/modules/map/store/map.actions';

import { selectActiveCountry, selectAllCountries, selectStatesInActiveCountry } from '../../store/country.selectors';
import { activateCountryDiv } from '@app/modules/global/store/global.actions';
import { setActiveCountry } from '@app/modules/countries/store/country.actions';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-country',
  templateUrl: './country.component.html',
  styleUrls: [],
})
export class CountryComponent extends Unsubscriber implements OnInit {
  countries$ = this.store.select(selectAllCountries);
  country$ = this.store.select(selectActiveCountry);
  cities$ = this.store.select(selectCitiesInActiveCountry);
  states$ = this.store.select(selectStatesInActiveCountry);
  supporters$ = this.store.select(selectSupportersInCountry);
  supportersLoaded$ = this.store.select(selectSupportersLoaded);

  additional = { routerLink: '/countries', caption: 'show all Countries' };

  constructor(
    public appState: AppStateService,
    private route: ActivatedRoute,
    public helper: HelperService,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(activateCountryDiv());

    this.unsubscribeLater(
      this.route.paramMap.subscribe((params) => {
        const countrycode = (params.get('countrycode') || '').toUpperCase();
        this.initCountry(countrycode);
      })
    );
  }

  initCountry(countrycode: string) {
    this.countries$.pipe(first((x) => x.length > 0)).subscribe((countries) => {
      const country = countries.find((x) => x.countrycode === countrycode);
      if (country) {
        this.appState.setActiveTitle(country.country + ' on recordstores.love');
        this.store.dispatch(setActiveCountry({ countrycode }));
        this.store.dispatch(
          setMapBounds({
            bounds: country,
            zoomlevel: 'cities',
            zoomCenter: country,
          })
        );
      } else {
        this.appState.gotoSearch(countrycode);
      }
    });
  }
}
