import { Action, createReducer, on } from '@ngrx/store';
import { LatLng, LatLngPos, LatLngBounds, ActiveMarkerType, ActiveMarker } from '@shared/classes';
import { MapPosition, MapPositionWhat } from '../classes/map-position';
import * as MapActions from './map.actions';

export const mapFeatureKey = 'map';

export interface MapState {
  mapPosition: MapPosition | null;
  activeMarker: ActiveMarker | null;
  zoom: number | undefined;
  // Bounds werden getrennt in wantedBounds und actualBounds.
  // Auf wantedBounds reagiert die Map und setzt die Bounds entsprechend.
  // actualBounds ist der aktuelle Ausschnitt der Map und wird nur von der Map gesetzt.
  actualBounds: LatLngBounds | undefined;
  wantedBounds: LatLngBounds | undefined;
}

export const initialState: MapState = {
  mapPosition: null,
  activeMarker: null,
  zoom: undefined,
  actualBounds: undefined,
  wantedBounds: undefined,
};

export const latLngPos2MapPosition = (pos: LatLngPos): MapPosition => ({
  what: 'position',
  level: 'stores',
  pos,
  accuracy: pos.accuracy,
  bounds: null,
  center: null,
});

export const zoomToMapPosition = (pos: LatLng, isNull: boolean): MapPosition => ({
  what: isNull ? 'position' : 'zoomTo',
  level: 'stores',
  pos,
  accuracy: 100,
  bounds: null,
  center: null,
});

export const bounds2MapPosition = (
  bounds: LatLngBounds,
  zoomlevel: 'stores' | 'cities' | 'any',
  zoomCenter: LatLng | null
) => ({
  what: 'bounds' as MapPositionWhat,
  level: zoomlevel,
  pos: {
    lat: (bounds.latmin + bounds.latmax) / 2.0,
    lng: (bounds.lngmin + bounds.lngmax) / 2.0,
  },
  center: zoomCenter,
  accuracy: 0,
  bounds,
});

export const reducer = createReducer(
  initialState,

  on(MapActions.setMapPosition, (state, action) => ({
    ...state,
    mapPosition: action.newPosition,
  })),
  on(MapActions.setLatLngPosMapPosition, (state, action) => ({
    ...state,
    mapPosition: latLngPos2MapPosition(action.pos),
  })),
  on(MapActions.setMapBounds, (state, action) => ({
    ...state,
    mapPosition: bounds2MapPosition(action.bounds, action.zoomlevel, action.zoomCenter),
  })),
  on(MapActions.zoomToStore, (state, action) => ({
    ...state,
    mapPosition: zoomToMapPosition(action.position, state.mapPosition === null),
  })),

  on(MapActions.clickOnPlaceIcon, MapActions.setActiveMarker2Place, (state, action) => ({
    ...state,
    activeMarker: {
      type: 'placeIcon' as ActiveMarkerType,
      position: action.position,
      placeId: action.placeId,
    },
  })),
  on(MapActions.clickOnMap, MapActions.setActiveMarker2Pos, (state, action) => ({
    ...state,
    activeMarker: {
      type: 'position' as ActiveMarkerType,
      position: action.position,
    },
  })),
  on(MapActions.setActiveMarker2Store, (state, action) => ({
    ...state,
    activeMarker: {
      type: 'store' as ActiveMarkerType,
      position: action.position,
      storeId: action.storeId,
    },
  })),
  on(MapActions.setActiveMarker2Pin, (state, action) => ({
    ...state,
    activeMarker: {
      type: 'pin' as ActiveMarkerType,
      position: action.position,
      pinId: action.pinId,
    },
  })),
  on(MapActions.unsetActiveMarker, (state) => ({
    ...state,
    activeMarker: null,
  })),

  on(MapActions.zoomChanged, (state, action) => ({
    ...state,
    zoom: action.zoom,
  })),
  on(MapActions.boundsChanged, (state, action) => ({
    ...state,
    actualBounds: action.bounds,
  }))
);
