import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogsService } from '@core/services/dialogs.service';
import { activateOtherDiv } from '@app/modules/global/store/global.actions';

@Component({
  selector: 'rs-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  additional = { routerLink: '/donate', caption: 'Click here to Donate' };

  constructor(public dialogsService: DialogsService, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(activateOtherDiv());
  }
}
