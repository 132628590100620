import { Component, OnInit } from '@angular/core';
import { AppStateService } from '@app/core/services/app-state.service';
import { selectVisiblePins } from '@app/modules/auth/store/auth.selectors';
import { selectStoresVisible } from '@app/modules/map/store/map.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-pins',
  templateUrl: './pins.component.html',
  styleUrls: ['./pins.component.scss'],
})
export class PinsComponent implements OnInit {
  visiblePins$ = this.store.select(selectVisiblePins);
  storesVisible$ = this.store.select(selectStoresVisible);

  constructor(private appState: AppStateService, private store: Store) {}

  ngOnInit(): void {
    this.appState.setActiveTitle('My private Pins on recordstores.love');
  }
}
