<div class="rs-panel">
  <div>
    <h1 class="rs-header-1">
      Imprint
      <span class="rs-h-small">Impressum</span>
    </h1>
  </div>

  <rs-breadcrumb [breadcrumbs]="[{ routerLink: '', caption: 'impressum' }]"></rs-breadcrumb>

  <div class="impressum">
    <p>Angaben gemäß § 5 TMG</p>
    <p>
      Marcus Maack<br />
      Schlankreye 15<br />
      20144 Hamburg <br />
    </p>
    <p>
      <strong>Contact</strong> <br />
      Tel: +49 40 3990 8888<br />
      E-Mail:
      <a href="mailto:support@recordstores.love">support@recordstores.love</a>
    </p>
    <p>
      <strong>Logo Design</strong> <br />
      Stefan Biedermann (DJ DSL)<br />
      <a href="https://www.dj-dsl.com/" target="_blank" rel="”noopener”">www.dj-dsl.com</a>
    </p>
    <p>
      <strong>Legal Support</strong> <br />
      Carola Sieling<br />
      <a href="https://www.kanzlei-sieling.de/" target="_blank" rel="”noopener”">www.kanzlei-sieling.de</a>
    </p>
    <p>
      <strong>Flag Icons</strong><br />
      by <br />
      <a href="https://www.whoishostingthis.com" target="_blank" rel="”noopener”">WhoIsHostingThis.com</a>
    </p>
  </div>

  <rs-contact-and-coffee title="imprint" url="https://recordstores.love/impressum"> </rs-contact-and-coffee>

  <rs-sharing
    url="recordstores.love/impressum"
    name="recordstores.love"
    descript="Follow this link to find all record stores worldwide"
  ></rs-sharing>
</div>
