import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DialogsService } from '@app/core/services/dialogs.service';
import { userRemovePin, userSavePin } from '@app/modules/auth/store/auth.actions';
import { Pin } from '@app/shared/classes';
import { AddressFormResult } from '@app/shared/forms/address-form/address-form.component';
import { ContactFormResult } from '@app/shared/forms/contact-form/contact-form.component';
import { TimesFormResult } from '@app/shared/forms/times-form/times-form.component';
import { faEdit, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-pin-edit',
  templateUrl: './pin-edit.component.html',
  styleUrls: ['./pin-edit.component.scss'],
})
export class PinEditComponent implements OnInit, OnChanges {
  @Input() pin!: Pin;

  faEdit = faEdit;
  faTrash = faTrash;
  faSpinnerIcon = faSpinner;

  editStep = '';
  sending = false;
  sent = false;

  colours = ['black', 'blue', 'red', 'green', 'orange', 'yellow', 'violet', 'gray'];

  constructor(private dialogs: DialogsService, private store: Store) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.closeForm();
  }

  closeForm() {
    this.sending = false;
    this.sent = false;
    this.editStep = '';
  }

  setColour(colour: string) {
    if (this.pin.colour !== colour) {
      this.store.dispatch(userSavePin({ pin: { ...this.pin, colour } }));
    }
  }

  editAddress() {
    this.editStep = 'editDetails.address';
    this.sending = false;
    this.sent = false;
  }
  editContact() {
    this.editStep = 'editDetails.contact';
    this.sending = false;
    this.sent = false;
  }
  editTimes() {
    this.editStep = 'editDetails.times';
    this.sending = false;
    this.sent = false;
  }

  closeAddressForm(result: AddressFormResult | null) {
    if (result) {
      this.sending = true;
      this.store.dispatch(userSavePin({ pin: { ...this.pin, ...result.address } }));
    } else {
      this.closeForm();
    }
  }

  closeContactForm(result: ContactFormResult | null) {
    if (result) {
      this.sending = true;
      this.store.dispatch(userSavePin({ pin: { ...this.pin, ...result.contact } }));
    } else {
      this.closeForm();
    }
  }

  closeTimesForm(result: TimesFormResult | null) {
    if (result) {
      this.sending = true;
      this.store.dispatch(userSavePin({ pin: { ...this.pin, ...result.times } }));
    } else {
      this.closeForm();
    }
  }

  removePin(pin: Pin) {
    this.dialogs
      .openConfirmDialog(
        'Remove Pin',
        'Do you really want to remove this marker for ' + pin.name + '?',
        'Remove Pin',
        'Cancel'
      )
      .subscribe((x) => {
        if (x) {
          this.store.dispatch(userRemovePin({ pinid: pin.id }));
        }
      });
  }
}
