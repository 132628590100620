import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { PinComponent } from './pin/pin.component';
import { PinsComponent } from './pins/pins.component';
import { PinEditComponent } from './pin-edit/pin-edit.component';

@NgModule({
  declarations: [PinComponent, PinsComponent, PinEditComponent],
  imports: [SharedModule],
})
export class PinsModule {}
