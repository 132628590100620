<div class="rs-panel" *ngIf="pin$ | async as pin; else notFound">
  <h1>
    {{ pin.name }}
    <span class="rs-h-small" *ngIf="distance">{{ distance | number: '1.1-1' }} km | {{ direction }}</span>
  </h1>

  <rs-breadcrumb
    *ngIf="pin.countrycode !== 'US'"
    [additional]="additional"
    [breadcrumbs]="[
      { routerLink: '/' + pin.countrycode, caption: pin.countrycode },
      {
        routerLink: helper.cityurl(pin.city, pin.countrycode, pin.state),
        caption: helper.str2url(pin.city)
      }
    ]"
  ></rs-breadcrumb>
  <rs-breadcrumb
    *ngIf="pin.countrycode === 'US'"
    [additional]="additional"
    [breadcrumbs]="[
      { routerLink: '/US', caption: 'US' },
      { routerLink: '/US/' + pin.state, caption: pin.state || '' },
      {
        routerLink: helper.cityurl(pin.city, pin.countrycode, pin.state),
        caption: helper.str2url(pin.city)
      }
    ]"
  ></rs-breadcrumb>

  <section class="contact-section">
    <h2>Contact Details</h2>
  </section>

  <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row" rsClickStopPropagation>
    <rs-simple-item
      [faIcon]="icons.faClock"
      iconClass="contact-link"
      title="{{ hours }} {{ pin.times }}"
      [noselect]="false"
    ></rs-simple-item>
  </div>
  <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row" rsClickStopPropagation>
    <rs-simple-item
      [faIcon]="icons.faMarker"
      iconClass="marker"
      [title]="pin.address + (pin.addinfo ? ' (' + pin.addinfo + ')' : '')"
      [noselect]="false"
    ></rs-simple-item>
  </div>

  <div *ngFor="let link of links">
    <a href="{{ link.href }}" target="_blank" rel="”noopener”" rsClickStopPropagation>
      <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row">
        <rs-simple-item
          *ngIf="link.icon === 'faInstagram'"
          icon="/assets/markers/instagram.ico"
          [title]="link.text"
        ></rs-simple-item>
        <rs-simple-item
          *ngIf="link.icon === 'faFacebook'"
          icon="/assets/markers/facebook_neu.ico"
          [title]="link.text"
        ></rs-simple-item>
        <rs-simple-item
          *ngIf="link.icon === 'faTwitter'"
          icon="/assets/markers/twitter.png"
          [title]="link.text"
        ></rs-simple-item>
        <rs-simple-item
          *ngIf="link.icon === 'faDiscogs'"
          icon="/assets/markers/discogs.ico"
          [title]="link.text"
        ></rs-simple-item>
        <rs-simple-item
          *ngIf="link.icon !== 'faInstagram' && link.icon !== 'faFacebook' && link.icon !== 'faTwitter'"
          [faIcon]="icons[link.icon]"
          [iconClass]="link.css"
          [title]="link.text"
        ></rs-simple-item>
      </div>
    </a>
  </div>

  <ng-container *ngIf="pin.google_url === '' || pin.google_url === null; else googleCid">
    <a
      href="https://www.google.com/maps/search/?api=1&query={{ pin.lat }},{{ pin.lng }}"
      target="_blank"
      rel="”noopener”"
    >
      <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row">
        <rs-simple-item icon="/assets/markers/gmaps.ico" title="Open pin in Google Maps"></rs-simple-item>
      </div>
    </a>
  </ng-container>
  <ng-template #googleCid>
    <a href="{{ pin.google_url }}" target="_blank" rel="”noopener”">
      <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row">
        <rs-simple-item icon="/assets/markers/gmaps.ico" title="Open store in Google Maps"></rs-simple-item>
      </div>
    </a>
  </ng-template>
  <a
    href="https://www.google.com/maps/dir/?api=1&travelmode={{
      distance && distance < 10 ? 'walking' : 'driving'
    }}&destination={{ pin.lat }},{{ pin.lng }}"
    target="_blank"
    rel="”noopener”"
  >
    <div class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row">
      <rs-simple-item [faIcon]="icons.faRoute" title="Show directions in Google Maps"></rs-simple-item>
    </div>
  </a>

  <section class="descript-section">
    <h2>Description</h2>
    <p *ngIf="pin.descript">
      {{ pin.descript }}
    </p>
    <p *ngIf="!pin.descript" class="gray">
      <em>No description.</em>
    </p>
    <p class="gray">
      <em>You created this marker {{ pin.created }}</em>
    </p>
  </section>

  <section class="actions-section">
    <rs-pin-edit [pin]="pin"></rs-pin-edit>
  </section>
</div>
<ng-template #notFound>
  <div class="rs-panel">
    <h1>Pin not found.</h1>
  </div>
</ng-template>
