import { Country } from '@shared/classes';
import { AppStateService } from '@core/services/app-state.service';
import { Store } from '@ngrx/store';
import { CustomOverlay } from './custom-overlay';

export class CountriesOverlay extends CustomOverlay<Country> {
  constructor(
    protected map: google.maps.Map,
    protected items: Country[],
    protected appState: AppStateService,
    protected store: Store,
    public zoom: number
  ) {
    super(map, items, zoom);
    this.iconColor = 'rgba(0, 123, 255, 0.75)';
  }
  protected getName(item: Country): string {
    return item.country;
  }
  protected onClick(item: Country) {
    this.appState.gotoCountry(item.countrycode, true);
  }
}
