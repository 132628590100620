/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { City, State, ShopTimes, ShopMediumData, ShopBasics, ShopDetails } from '@shared/classes';

export const HOURSUNKNOWN = 'Hours unknown';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor() {}

  n(cnt: number, einzahl: string, mehrzahl = ''): string {
    if (!mehrzahl) {
      mehrzahl = einzahl + 's';
    }
    if (cnt === 0) {
      return `No ${mehrzahl}`;
    } else if (cnt === 1) {
      return `1 ${einzahl}`;
    } else {
      return `${cnt} ${mehrzahl}`;
    }
  }

  cityurl(city: string, countrycode: string, state: string = '') {
    if (countrycode === 'US' && state !== '') {
      return '/' + countrycode + '/' + state + '/' + this.str2url(city);
    } else {
      return '/' + countrycode + '/' + this.str2url(city);
    }
  }

  city2url(city: City): string {
    return city ? this.cityurl(city.city, city.countrycode, city.state) : '';
  }

  store2cityurl(store: ShopDetails | ShopBasics | undefined): string {
    return store ? this.cityurl(store.city, store.countrycode, store.state ?? '') : '';
  }

  state2url(item: State): string {
    return item ? '/' + item.countrycode + '/' + item.state : '';
  }

  str2url(s: string): string {
    return s ? this.normalizeString(s) : '';
  }

  static formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  // normalizeString liefert einen String ohne Sonderzeichen und Leerzeichen.
  // Großbuchstaben bleiben erhalten.
  normalizeString(s: string): string {
    // Der Replace-Befehl ersetzt alle Leerzeichen durch Leerstring.
    // \s+ = one or more space character, /g = replace all occurences
    return this.normalizeChars(s.replace(/\s+/g, ''));
  }

  stringSort(a: string, b: string): number {
    const aa = this.normalizeString(a).toLowerCase();
    const bb = this.normalizeString(b).toLowerCase();
    return aa < bb ? -1 : aa > bb ? 1 : 0;
  }

  // Ein toLowerCase wird nicht durchgeführt! Leerzeichen bleiben erhalten.
  private normalizeChars(s: string): string {
    return encodeURIComponent(
      s
        .replace(/ä/g, 'ae')
        .replace(/á/g, 'a')
        .replace(/à/g, 'a')
        .replace(/â/g, 'a')
        .replace(/ã/g, 'a')
        .replace(/å/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/Ä/g, 'Ae')
        .replace(/Á/g, 'A')
        .replace(/À/g, 'A')
        .replace(/Â/g, 'A')
        .replace(/Ã/g, 'A')
        .replace(/Å/g, 'A')
        .replace(/Æ/g, 'Ae')
        .replace(/ç/g, 'c')
        .replace(/č/g, 'c')
        .replace(/Ç/g, 'C')
        .replace(/Č/g, 'C')
        .replace(/Ë/g, 'E')
        .replace(/É/g, 'E')
        .replace(/È/g, 'E')
        .replace(/Ê/g, 'E')
        .replace(/ë/g, 'e')
        .replace(/é/g, 'e')
        .replace(/è/g, 'e')
        .replace(/ê/g, 'e')
        .replace(/ę/g, 'e')
        .replace(/Ï/g, 'I')
        .replace(/Í/g, 'I')
        .replace(/Ì/g, 'I')
        .replace(/Î/g, 'I')
        .replace(/ï/g, 'i')
        .replace(/í/g, 'i')
        .replace(/ì/g, 'i')
        .replace(/î/g, 'i')
        .replace(/Ł/g, 'L')
        .replace(/ł/g, 'l')
        .replace(/Ñ/g, 'N')
        .replace(/ñ/g, 'n')
        .replace(/ń/g, 'n')
        .replace(/ö/g, 'oe')
        .replace(/ó/g, 'o')
        .replace(/ò/g, 'o')
        .replace(/ô/g, 'o')
        .replace(/õ/g, 'o')
        .replace(/ø/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/ō/g, 'o')
        .replace(/Ö/g, 'Oe')
        .replace(/Ó/g, 'O')
        .replace(/Ò/g, 'O')
        .replace(/Ô/g, 'O')
        .replace(/Õ/g, 'O')
        .replace(/Ø/g, 'O')
        .replace(/Œ/g, 'OE')
        .replace(/Ō/g, 'O')
        .replace(/Š/g, 'S')
        .replace(/š/g, 's')
        .replace(/ß/g, 'ss')
        .replace(/ü/g, 'ue')
        .replace(/ú/g, 'u')
        .replace(/ù/g, 'u')
        .replace(/û/g, 'u')
        .replace(/Ü/g, 'Ue')
        .replace(/Ú/g, 'U')
        .replace(/Ù/g, 'U')
        .replace(/Û/g, 'U')
        .replace(/Ÿ/g, 'Y')
        .replace(/Ý/g, 'Y')
        .replace(/ÿ/g, 'y')
        .replace(/ý/g, 'y')
        .replace(/ź/g, 'z')
        .replace(/'/g, '')
        .replace(/\W+/g, '-')
        .replace(/--/g, '-')
    );
  }

  getShortTime(time: string): string {
    let result = time;

    if (time) {
      let parts = time.split(':');
      if (parts.length === 1 && parts[0].length === 4) {
        time = parts[0].charAt(0) + parts[0].charAt(1) + ':' + parts[0].charAt(2) + parts[0].charAt(3);
        parts = time.split(':');
      }
      if (parts.length >= 2) {
        if (parts[0].charAt(0) === '0') {
          parts[0] = parts[0].charAt(1);
        }
        result = parts[1] === '00' ? parts[0] : parts[0] + ':' + parts[1];
      }
    }

    return result;
  }
  getShortTimeStringToday(store: ShopBasics): string {
    let result = '';

    if (store.hasclosed) {
      result = 'Closed';
    } else if (store.todayclosed) {
      result = store.today ? store.today + ' closed' : 'Closed';
    } else {
      if (store.today && store.todayfrom && store.todayuntil) {
        result = store.today + ' ' + this.getShortTime(store.todayfrom) + '-' + this.getShortTime(store.todayuntil);
      } else if (store.today) {
        result = store.today + ' No Info';
      }
    }

    return result;
  }

  getShortTimesString(shop: ShopTimes): string {
    const days: {
      name: string;
      from?: string;
      until?: string;
      closed?: boolean;
      result: string;
    }[] = [
      {
        name: 'Mon',
        from: shop.times_mon_from ?? '',
        until: shop.times_mon_until ?? '',
        closed: shop.times_mon_closed,
        result: '',
      },
      {
        name: 'Tue',
        from: shop.times_tue_from ?? '',
        until: shop.times_tue_until ?? '',
        closed: shop.times_tue_closed,
        result: '',
      },
      {
        name: 'Wed',
        from: shop.times_wed_from ?? '',
        until: shop.times_wed_until ?? '',
        closed: shop.times_wed_closed,
        result: '',
      },
      {
        name: 'Thu',
        from: shop.times_thu_from ?? '',
        until: shop.times_thu_until ?? '',
        closed: shop.times_thu_closed,
        result: '',
      },
      {
        name: 'Fri',
        from: shop.times_fri_from ?? '',
        until: shop.times_fri_until ?? '',
        closed: shop.times_fri_closed,
        result: '',
      },
      {
        name: 'Sat',
        from: shop.times_sat_from ?? '',
        until: shop.times_sat_until ?? '',
        closed: shop.times_sat_closed,
        result: '',
      },
      {
        name: 'Sun',
        from: shop.times_sun_from ?? '',
        until: shop.times_sun_until ?? '',
        closed: shop.times_sun_closed,
        result: '',
      },
    ];

    days.forEach((day) => {
      if (!day.closed && day.from && day.until) {
        day.result = this.getShortTime(day.from) + '-' + this.getShortTime(day.until);
      } else if (day.closed) {
        day.result = 'Closed';
      } else {
        day.result = 'Unknown';
      }
    });

    interface ShopTimesItem {
      from: string;
      to: string;
      time: string;
    }
    const times: ShopTimesItem[] = [];
    let i = 0;
    let item = { from: days[i].name, to: days[i].name, time: days[i].result };
    for (i = 1; i < 7; i++) {
      if (item.time === days[i].result) {
        item.to = days[i].name;
      } else {
        if (item.time !== 'Closed' && item.time !== 'Unknown') {
          times.push(item);
        }
        item = { from: days[i].name, to: days[i].name, time: days[i].result };
      }
    }
    if (item.time !== 'Closed' && item.time !== 'Unknown') {
      times.push(item);
    }

    let result = '';
    for (i = 0; i < times.length; i++) {
      result =
        result +
        (result === '' ? '' : ' | ') +
        times[i].from +
        (times[i].to === times[i].from ? '' : '-' + times[i].to) +
        ' ' +
        times[i].time;
    }

    return result === '' ? HOURSUNKNOWN : result;
  }
}
