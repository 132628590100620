import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromMarker from './store/marker.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MarkerEffects } from './store/marker.effects';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    StoreModule.forFeature(fromMarker.markerFeatureKey, fromMarker.reducer),
    EffectsModule.forFeature([MarkerEffects]),
  ],
})
export class MarkersModule {}
