<div>
  <h2>
    {{ title }} <span class="rs-h-small">{{ subtitle }}</span>
  </h2>

  <div class="rs-supporter-cards-container">
    <mat-card
      *ngFor="let item of supporters; let i = index"
      (mouseenter)="mouseEnter(item)"
      (mouseleave)="mouseLeave()"
    >
      <a routerLink="/{{ item.id }}" class="rs-panel-row-link">
        <img class="rs-supporter-img" [src]="'/api/stores/' + item.id + '/image'" width="300" loading="lazy" />
        <rs-shop-item [item]="item" [handleMouseOver]="false" [position]="position"></rs-shop-item>
      </a>
    </mat-card>

    <mat-card *ngIf="dach ? rndm < 30 : rndm < 50">
      <a routerLink="/support" class="rs-panel-row-link">
        <div class="rs-advert rs-advert-blue">
          Your favorite<br />
          record store<br />
          here?<br />
          <span class="rs-advert-small">Support Now.</span>
        </div>
        <rs-title-subtitle-item
          title="Support Your Local Record Store"
          subtitle="Click here for details."
        ></rs-title-subtitle-item>
      </a>
    </mat-card>

    <mat-card *ngIf="dach ? rndm > 70 : rndm >= 50">
      <a routerLink="/support" class="rs-panel-row-link">
        <img class="rs-supporter-img" src="/assets/bilder/becomeasupporternow.png" width="300" />
        <rs-title-subtitle-item
          title="You want your store here?"
          subtitle="Become a Supporter Now!"
        ></rs-title-subtitle-item>
      </a>
    </mat-card>

    <mat-card *ngIf="dach">
      <a href="http://www.analogetontraeger.de/ankauf/" target="_blank" rel="noopener" class="rs-panel-row-link">
        <div class="rs-advert rs-advert-red">
          <b>Wir kaufen Ihre Schallplatten.</b><br />
          <br />
          Unkompliziert, schnell und transparent.
        </div>
        <rs-title-subtitle-item
          title="Für weitere Infos hier klicken"
          subtitle="analogetontraeger.de/ankauf"
        ></rs-title-subtitle-item>
      </a>
    </mat-card>

    <mat-card>
      <a routerLink="/support" class="rs-panel-row-link">
        <img class="rs-supporter-img" src="/assets/logos/logo_breit.png" width="300" />
        <rs-title-subtitle-item
          title="You want your store here?"
          subtitle="Become a Supporter Now!"
        ></rs-title-subtitle-item>
      </a>
    </mat-card>
  </div>
</div>
