import { Component, OnInit } from '@angular/core';
import { AppStateService } from '@core/services/app-state.service';
import { ShopListItem, CityListItem, LatLngPos, ShopBasics } from '@shared/classes';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { HelperService } from '@core/services/helper.service';
import { faSync, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { selectCitiesByDistance } from '@app/modules/cities/store/city.selectors';
import {
  selectStoresByDistance,
  selectSupportersByDistance,
  selectSupportersLoaded,
} from '@app/modules/shops/store/shop.selectors';
import { filter } from 'rxjs/operators';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import {
  selectPosition,
  selectPositionErrorDetails,
  selectPositionLoading,
} from '@app/modules/position/store/position.selectors';
import { getPosition } from '@app/modules/position/store/position.actions';
import { setMapBounds } from '@app/modules/map/store/map.actions';
import { activateNearDiv } from '@app/modules/global/store/global.actions';
import { selectIsMobile } from '@app/modules/global/store/global.selectors';
import { selectPinsByDistance } from '@app/modules/auth/store/auth.selectors';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-near',
  templateUrl: './near.component.html',
  styleUrls: [],
})
export class NearComponent extends Unsubscriber implements OnInit {
  isMobile$ = this.store.select(selectIsMobile);

  positionError$ = this.store.select(selectPositionErrorDetails);
  positionLoading$ = this.store.select(selectPositionLoading);

  pins$ = this.store.select(selectPinsByDistance);

  position: LatLngPos | null = null;

  stores: ShopListItem[] = []; // Alle Stores aus allStores gefiltert nach Direction
  stores1: ShopBasics[] = [];
  stores5: ShopBasics[] = [];
  stores10: ShopBasics[] = [];
  stores25: ShopBasics[] = [];
  storesfar: ShopBasics[] = [];

  supporters: ShopBasics[] = [];

  cities: CityListItem[] = []; // Alle Cities aber gefiltert
  nearCities: CityListItem[] = []; // Und die nochmal selektiert nach Größe

  filtered = false;
  initialized = false;

  faRedoIcon = faSync;
  faSpinnerIcon = faSpinner;

  filter = new Set(['N', 'S', 'E', 'W']);

  supportersByDistance$ = this.store.select(selectSupportersByDistance);
  supportersLoaded$ = this.store.select(selectSupportersLoaded);
  supporters$ = new BehaviorSubject<ShopBasics[]>([]);
  stores$ = new BehaviorSubject<ShopListItem[]>([]);
  oldPosition: LatLngPos | null = null;

  private allStores: ShopListItem[] = [];
  private allCities: CityListItem[] = [];

  private cities$ = this.store.select(selectCitiesByDistance);
  private storesByDistance$ = this.store.select(selectStoresByDistance);
  private position$ = this.store.select(selectPosition);

  constructor(public appState: AppStateService, public helper: HelperService, private store: Store) {
    super();
    this.updateWithNewPosition();
  }

  ngOnInit(): void {
    this.appState.setActiveTitle('all record stores near you on recordstores.love');
    this.store.dispatch(activateNearDiv());

    const fltr = this.appState.dictionary.get('Filter.Near');
    if (fltr !== '' && fltr !== undefined) {
      ['N', 'S', 'E', 'W'].forEach((x) => {
        if (fltr?.indexOf(x) < 0) {
          this.toggleFilter(x);
        }
      });
    }

    // Ich möchte die Entfernung zu den Supporters neu berechnen, wenn sich die
    // eigene Position ändert, aber nur dann, wenn der nächste Supporter keine
    // 50km entfernt ist. Ansonsten würde es bei jedem Positionsupdate flackern,
    // auch wenn die Stadt sehr weit entfernt ist.
    this.unsubscribeLater(
      combineLatest([this.supportersByDistance$, this.storesByDistance$, this.position$]).subscribe(
        ([supporters, stores, position]) => {
          const oldStores = this.stores$.value;
          const mediumReducer = (a, b: ShopListItem) => a + (b.store.isMedium ? 1 : 0);
          if (
            supporters.length !== this.supporters$.value.length ||
            stores.length !== oldStores.length ||
            stores.reduce(mediumReducer, 0) !== oldStores.reduce(mediumReducer, 0)
          ) {
            this.supporters$.next(supporters);
            this.stores$.next(stores);
            this.oldPosition = position;
          } else if (position && this.oldPosition) {
            // Update, wenn man 10m gegangen ist.
            const gone = PositionHelpers.getDistanceBetween(position, this.oldPosition);
            if (gone > 0.1) {
              this.supporters$.next(supporters);
              this.stores$.next(stores);
              this.oldPosition = position;
            }
          }
        }
      ),

      this.cities$.pipe(filter((x) => x.length > 0)).subscribe((cities) => {
        this.allCities = cities;
        this.update();
      }),
      this.stores$.pipe(filter((x) => x.length > 0)).subscribe((stores) => {
        this.allStores = stores;
        this.update();
      }),
      this.position$.pipe(filter((x) => x !== null)).subscribe((pos) => {
        this.position = pos;
        this.updateBounds();
      })
    );
  }

  toggleFilter = (direction: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.filter.has(direction) ? this.filter.delete(direction) : this.filter.add(direction);
    this.updateStores();
    this.updateCities();
    this.filtered = this.filter.size < 4;
    this.appState.dictionary.set('Filter.Near', Array.from(this.filter).join(''));
  };

  getRing(distanceFrom: number, distanceTo: number) {
    // Als Basis werden die nach Direction gefilterten Stores genommen
    return this.stores
      .filter((x) => x.distance !== undefined && x.distance >= distanceFrom && x.distance < distanceTo)
      .map((x) => x.store);
  }

  update() {
    this.updateStores();
    this.updateCities();
    this.updateBounds();
  }

  updateWithNewPosition() {
    this.store.dispatch(getPosition());
  }

  updateStores() {
    if (this.allStores.length > 0) {
      this.stores = this.filteredStores(this.allStores);
      this.stores1 = this.allStores.filter((x) => x.distance !== undefined && x.distance < 1).map((x) => x.store); // diese nicht nach direction filtern
      this.stores5 = this.getRing(1, 5);
      if (this.stores1.length + this.stores5.length < 10) {
        this.stores10 = this.getRing(5, 10);
      }
      if (this.stores1.length + this.stores5.length + this.stores10.length < 10) {
        this.stores25 = this.getRing(10, 25);
      }
    }

    if (this.getRing(0, 25).length === 0) {
      this.storesfar = this.stores.slice(0, 5).map((x) => x.store);
    }
  }

  filteredStores(list: ShopListItem[]): ShopListItem[] {
    if (this.filter.size === 4) {
      return list;
    } else {
      return list.filter((store) => {
        let result = store.distance !== undefined && store.distance <= 0.5;
        if (!result) {
          this.filter.forEach(
            (item) => (result = result || (store.direction ? store.direction.includes(item) : false))
          );
        }
        return result;
      });
    }
  }

  updateCities() {
    if (this.allCities.length > 0) {
      // cities grob filtern, da gehört fast alles rein
      this.cities = this.filteredCities(this.allCities).filter(
        (x) =>
          x.distance !== undefined &&
          (x.distance < 50.0 ||
            (x.distance < 100.0 && x.city.opencnt > 1) ||
            (x.distance < 200.0 && x.city.opencnt > 2))
      );
      // nearCities feiner filtern
      this.nearCities = this.cities.filter(
        (x) =>
          x.distance !== undefined &&
          (x.distance < 5.0 ||
            (x.distance < 25.0 && x.city.opencnt > 1) ||
            (x.distance < 50.0 && x.city.opencnt > 2) ||
            (x.distance < 100.0 && x.city.opencnt > 3) ||
            (x.distance < 200.0 && x.city.opencnt > 4))
      );
    }
  }

  filteredCities(list: CityListItem[]): CityListItem[] {
    list = list.filter((x) => x.distance !== undefined && (x.distance < 10 || x.city.opencnt > 1));
    if (this.filter.size === 4) {
      return list;
    } else {
      return list.filter((city) => {
        let result = city.distance !== undefined && city.distance <= 5.0;
        if (!result) {
          this.filter.forEach(
            (item) => (result = result || (city.direction !== undefined ? city.direction.includes(item) : false))
          );
        }
        return result;
      });
    }
  }

  updateBounds() {
    const pos = this.position;

    if (pos && this.allStores.length > 0 && !this.initialized) {
      this.initialized = true;

      const bounds = {
        latmin: pos.lat,
        latmax: pos.lat,
        lngmin: pos.lng,
        lngmax: pos.lng,
      };

      if (this.stores.length > 0) {
        const boundStores: ShopBasics[] = [];

        // 1km
        boundStores.push(...this.stores1);

        let stop = false;
        let dist = 1.0;
        while (dist < 25.0 && !stop) {
          const ring = this.getRing(dist, dist + 1);
          if (boundStores.length < 5 && ring.length > 0) {
            boundStores.push(...ring);
          } else {
            stop = boundStores.length > 0;
          }
          dist++; // nicht ins while !!
        }

        boundStores.forEach((x) => {
          bounds.latmin = Math.min(x.lat, bounds.latmin);
          bounds.lngmin = Math.min(x.lng, bounds.lngmin);
          bounds.latmax = Math.max(x.lat, bounds.latmax);
          bounds.lngmax = Math.max(x.lng, bounds.lngmax);
        });
      }

      if (bounds.latmin === bounds.latmax && bounds.lngmin === bounds.lngmax) {
        // 1 km in beide Richtungen berechnen
        const lat1 = PositionHelpers.getLatFactorFor1Km({
          lat: bounds.latmin,
          lng: bounds.lngmin,
        });
        const lng1 = PositionHelpers.getLngFactorFor1Km({
          lat: bounds.latmin,
          lng: bounds.lngmin,
        });

        bounds.latmin = bounds.latmin - 2 * lat1;
        bounds.latmax = bounds.latmax + 2 * lat1;
        bounds.lngmin = bounds.lngmin - 2 * lng1;
        bounds.lngmax = bounds.lngmax + 2 * lng1;
      }

      this.store.dispatch(setMapBounds({ bounds, zoomlevel: 'any', zoomCenter: null }));
    }
  }
}
