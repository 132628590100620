import { Component, Input } from '@angular/core';
import { faListAlt, faImage } from '@fortawesome/free-solid-svg-icons';
import { ShopBasics } from '@app/shared/classes';
import { MarkersService } from '@app/core/services/markers.service';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-shops-list',
  templateUrl: './shops-list.component.html',
  styleUrls: ['./shops-list.component.scss'],
})
export class ShopsListComponent extends Unsubscriber {
  @Input() shops: ShopBasics[] = [];
  @Input() title = '';
  @Input() subtitle = '';

  slim = true;
  faList = faListAlt;
  faImage = faImage;

  private hovering = false;

  constructor(private markersService: MarkersService) {
    super();
    this.doOnDestroy(() => {
      if (this.hovering) {
        this.mouseLeave();
      }
    });
  }

  mouseEnter(item: ShopBasics) {
    this.hovering = true;
    this.markersService.hoverOn(item);
  }

  mouseLeave() {
    this.hovering = false;
    this.markersService.hoverOff();
  }
}
