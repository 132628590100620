<div>
  <h2>Something is wrong?</h2>

  <p>
    This site is still under development. If you see something is wrong, missing or not working - please get in contact.
  </p>
  <p>
    <a
      mat-flat-button
      class="rs-button"
      (click)="dialogsService.openContactDialog(url + ' - ' + title, 'Suggest a Change')"
      >Contact</a
    >
  </p>
</div>
