<div class="rs-panel" #storediv>
  <h1 class="rs-header-1">
    Visble Stores
    <span class="rs-h-small">Drag the map and the list will update automatically</span>
  </h1>

  <rs-breadcrumb [breadcrumbs]="[{ routerLink: '', caption: 'visible' }]"></rs-breadcrumb>

  <ng-container *ngIf="storesVisible$ | async">
    <div *ngIf="supporters$ | async as supporters">
      <div *ngIf="supporters.length > 0">
        <rs-supporters-list
          title="You see {{ helper.n(supporters.length, 'Supporter') }}"
          [subtitle]="supporters.length > 1 ? 'in random order' : ''"
          [supporters]="supporters"
        ></rs-supporters-list>
      </div>

      <div *ngIf="supporters.length === -1">
        <rs-supporters-list title="No visible Supporters" subtitle="" [supporters]="supporters"></rs-supporters-list>
      </div>
    </div>

    <rs-shops-list
      *ngIf="stores$ | async as stores"
      [shops]="stores"
      title="{{ helper.n(stores.length, 'visible Store') }}"
    ></rs-shops-list>

    <rs-pins-list
      *ngIf="pins$ | async as pins"
      [pins]="pins"
      title="My {{ helper.n(pins.length, 'visible Pin') }}"
    ></rs-pins-list>
  </ng-container>

  <ng-container *ngIf="citiesVisible$ | async">
    <div *ngIf="cities$ | async as cities">
      <h2>{{ cities.length }} biggest visible Cities</h2>

      <div>
        <a routerLink="/{{ helper.city2url(city.city) }}" *ngFor="let city of cities" class="rs-panel-row-link">
          <rs-city-item [city]="city"></rs-city-item>
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="countriesVisible$ | async">
    <div *ngIf="countries$ | async as countries">
      <h2>{{ countries.length }} biggest visible Countries</h2>

      <a routerLink="/{{ country.countrycode }}" *ngFor="let country of countries" class="rs-panel-row-link">
        <rs-country-item [country]="country"></rs-country-item>
      </a>
    </div>
  </ng-container>

  <rs-sharing
    [url]="'https://recordstores.love/visible'"
    [name]="'Visible Record Stores'"
    [descript]="'Follow this link to find all record stores worldwide'"
  ></rs-sharing>
</div>
