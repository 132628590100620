import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { State } from '@shared/classes';
import { IconsHelper } from '@app/shared/helpers/icons.helper';

@Component({
  selector: 'rs-state-item',
  templateUrl: './state-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [],
})
export class StateItemComponent implements OnInit {
  @Input() state!: State;

  constructor() {}

  ngOnInit(): void {}

  getIconPath() {
    return IconsHelper.getStateIconPath(this.state.opencnt);
  }
}
