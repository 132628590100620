<div class="rs-upload-container mt-3 mb-3">
  <div
    ng2FileDrop
    class="well rs-upload-zone"
    [ngClass]="{ 'rs-file-over': hasBaseDropZoneOver }"
    (click)="fileInput.click()"
    (fileOver)="fileOverBase($event)"
    [uploader]="uploader"
  >
    <span class="rs-visible-mobile">Tap here to select photos for upload</span>
    <span class="rs-visible-desktop">Drop photos or click here to upload photos</span>
  </div>

  <div style="height: 0">
    <input type="file" #fileInput ng2FileSelect style="visibility: hidden" [uploader]="uploader" multiple /><br />
  </div>

  <div *ngIf="uploader?.queue?.length">
    <div class="mt-3">
      <div *ngIf="uploader.progress">
        Queue progress: {{ uploader.progress }}%
        <div class="progress mb-3">
          <mat-progress-bar mode="determinate" [value]="uploader.progress"></mat-progress-bar>
        </div>
      </div>
      <div>
        <a
          mat-flat-button
          class="rs-button rs-primary"
          (click)="uploader.uploadAll()"
          [disabled]="!uploader.getNotUploadedItems().length"
          >Start Upload</a
        >
        <a
          mat-flat-button
          class="rs-button rs-secondary"
          (click)="uploader.clearQueue()"
          [disabled]="!uploader.queue.length"
          *ngIf="uploader.progress !== 100"
          >Clear List</a
        >
        <a
          mat-flat-button
          class="rs-button rs-secondary"
          (click)="done()"
          [disabled]="!uploader.queue.length"
          *ngIf="uploader.progress === 100"
          >Done</a
        >
        <a
          mat-flat-button
          class="rs-button rs-warn"
          (click)="uploader.cancelAll()"
          color="warn"
          *ngIf="uploader.isUploading"
          >Cancel</a
        >
      </div>
      <div *ngIf="uploader.progress === 100" class="red mt-3">
        <b>Attention!</b> The photos you uploaded will not appear before an admin has confirmed them.
      </div>
    </div>

    <div class="rs-queue-thumbs mt-3">
      <div class="rs-queue-thumb" *ngFor="let item of uploader.queue">
        <div class="rs-thumbnail">
          <img [src]="sanitizer.bypassSecurityTrustUrl(getThumbnailSrc(item))" />
        </div>
        <div>
          <div *ngIf="uploader.options.isHTML5">
            <div class="progress" style="margin-bottom: 0">
              <mat-progress-bar mode="determinate" [value]="item.progress"></mat-progress-bar>
            </div>
          </div>
          <div>
            <button mat-icon-button (click)="item.remove()" color="warn" [disabled]="item.progress === 100">
              <fa-icon [icon]="faTrash"></fa-icon> Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
