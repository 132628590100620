import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setMapPositionToMe } from '../store/map.actions';

@Component({
  selector: 'rs-map-whereami',
  templateUrl: './map-whereami.component.html',
  styleUrls: ['./map-whereami.component.scss'],
})
export class MapWhereamiComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {}

  setPositionToMe() {
    this.store.dispatch(setMapPositionToMe());
  }
}
