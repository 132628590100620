import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';

import { City, CityListItem } from '@shared/classes';
import { HelperService } from '@core/services/helper.service';
import { AppStateService } from '@core/services/app-state.service';
import { setMapBounds } from '@app/modules/map/store/map.actions';
import { activateCityDiv } from '@app/modules/global/store/global.actions';

import { selectActiveCityItem, selectAllCities } from '../store/city.selectors';
import { setActiveCity } from '../store/city.actions';
import { selectAllShopsInActiveCity, selectSupportersInCity } from '@app/modules/shops/store/shop.selectors';
import { selectAllPinsInActiveCity } from '@app/modules/auth/store/auth.selectors';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-city',
  templateUrl: './city.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [],
})
export class CityComponent extends Unsubscriber implements OnInit {
  additional = { routerLink: '/visible', caption: 'Visible Stores' };

  city$ = this.store.select(selectActiveCityItem);
  cities$ = this.store.select(selectAllCities);
  stores$ = this.store.select(selectAllShopsInActiveCity);
  supporters$ = this.store.select(selectSupportersInCity);
  pins$ = this.store.select(selectAllPinsInActiveCity);

  subtitle = '';

  constructor(
    private appState: AppStateService,
    public helper: HelperService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(activateCityDiv());

    // Bei Aufruf die Stadt laden
    this.readCityFromRoute();

    // bei Navigation von einer Stadt zur nächsten entsprechend die neue nachladen
    this.unsubscribeLater(
      this.router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
        .subscribe(() => this.readCityFromRoute()),

      this.city$.subscribe((x) => {
        this.subtitle = x ? this.getSubTitle(x) : '';
      })
    );
  }

  private getSubTitle(x: CityListItem): string {
    let result = this.helper.n(x.city.opencnt, 'Store');

    if (x.distance && x.distance < 800) {
      const disttext = x.distance < 0 ? '| You are here' : `| ${x.distance.toFixed(1)} km ${x.direction}`;
      result = `${result} ${disttext}`;
    }
    return result;
  }

  private readCityFromRoute() {
    this.route.paramMap.pipe(first()).subscribe((params) => {
      this.initCity(params);
    });
  }

  private initCity(params: ParamMap) {
    const countrycode = (params.get('countrycode') || '').toUpperCase();
    const statecode = (params.get('state') || '').toUpperCase();
    const cityname = this.helper.str2url(params.get('city') || '').toLowerCase();

    this.cities$.pipe(first((x) => x.length > 0)).subscribe((cities) => {
      let city: City | undefined;

      if (countrycode === 'US' && statecode !== '') {
        city = cities.find(
          (x) =>
            x.countrycode === 'US' && x.state === statecode && this.helper.str2url(x.city).toLowerCase() === cityname
        );
      } else if (countrycode !== '') {
        city = cities.find(
          (x) => x.countrycode === countrycode && this.helper.str2url(x.city).toLowerCase() === cityname
        );
      } else {
        city = cities.find((x) => this.helper.str2url(x.city).toLowerCase() === cityname);
      }

      if (city) {
        this.appState.setActiveTitle(city.city + ' on recordstores.love');
        this.store.dispatch(setActiveCity({ city }));
        this.store.dispatch(setMapBounds({ bounds: city, zoomlevel: 'stores', zoomCenter: null }));
      } else {
        this.appState.gotoSearch(cityname);
      }
    });
  }
}
