import { HttpClient } from '@angular/common/http';
import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rs-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss'],
})
export class SupportFormComponent implements OnInit {
  @Output() closeForm = new EventEmitter<boolean>();

  sending = false;
  sent = false;
  error = false;
  faSpinnerIcon = faSpinner;

  formGroup: FormGroup = this.fb.group({
    name: ['', Validators.required],
    store: ['', Validators.required],
    email: ['', Validators.required],
    address: [''],
    vat: [''],
    note: [''],
    years: ['1'],
  });

  constructor(private fb: FormBuilder, private http: HttpClient) {}

  ngOnInit(): void {}

  submitForm() {
    const formValue = this.formGroup.value;

    this.sending = true;
    this.http
      .post('/api/contact', {
        email: formValue.email,
        subject: 'New Supporter',
        message:
          'years: ' +
          formValue.years +
          '\n' +
          'name: ' +
          formValue.name +
          '\n' +
          'store: ' +
          formValue.store +
          '\n' +
          'email: ' +
          formValue.email +
          '\n' +
          'vat: ' +
          formValue.vat +
          '\n\n' +
          'address:\n' +
          formValue.address +
          '\n\n' +
          'note:\n' +
          formValue.note +
          '\n\n',
      })
      .subscribe(
        () => {
          this.sending = false;
          this.sent = true;
        },
        () => {
          this.sending = false;
          this.error = true;
        }
      );
    //this.formGroup.reset();
    this.closeForm.emit(true);
  }

  close() {
    this.formGroup.reset();
    this.closeForm.emit(false);
  }
}
