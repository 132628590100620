import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { BottomBarComponent } from './components/bottom-bar/bottom-bar.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { TitleComponent } from './components/title/title.component';
import { DataPolicyComponent } from './pages/data-policy/data-policy.component';
import { DonateComponent } from './pages/donate/donate.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { SupportComponent } from './pages/support/support.component';

@NgModule({
  declarations: [
    BottomBarComponent,
    SideNavComponent,
    TitleComponent,
    DataPolicyComponent,
    DonateComponent,
    ImprintComponent,
    SupportComponent,
  ],
  exports: [
    BottomBarComponent,
    SideNavComponent,
    TitleComponent,
    DataPolicyComponent,
    DonateComponent,
    ImprintComponent,
    SupportComponent,
  ],
  imports: [SharedModule],
  providers: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
