export interface State {
  name: string;
  state: string;
  countrycode: string;

  lat: number;
  lng: number;
  latmax: number;
  latmin: number;
  lngmax: number;
  lngmin: number;

  opencnt: number;
}

export interface StateRaw {
  name: string;
  state: string;
  countrycode: string;

  lat: string;
  lng: string;
  latmax: string;
  latmin: string;
  lngmax: string;
  lngmin: string;

  opencnt: string;
}

export class StateFactory {
  static fromRaw(s: StateRaw, isMedium: boolean = false): State {
    const result: State = {
      ...s,
      lat: parseFloat(s.lat),
      lng: parseFloat(s.lng),
      latmax: parseFloat(s.latmax),
      lngmax: parseFloat(s.lngmax),
      latmin: parseFloat(s.latmin),
      lngmin: parseFloat(s.lngmin),
      opencnt: parseInt(s.opencnt, 10),
    };

    return result;
  }
}
