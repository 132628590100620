import { AppTarget } from '@core/services/app-state.service';
import { createAction, props } from '@ngrx/store';

export const initApp = createAction('[Global] Initialize Application');

export const beginLoading = createAction('[Global] Begin Loading', props<{ what: string }>());
export const endLoading = createAction('[Global] End Loading', props<{ what: string }>());

export const activateHomeDiv = createAction('[Global] Activate Home Div');
export const activateUserDiv = createAction('[Global] Activate User Div');
export const activateVisibleDiv = createAction('[Global] Activate Visible Div');
export const activateCountriesDiv = createAction('[Global] Activate Countries Div');
export const activateCountryDiv = createAction('[Global] Activate Country Div');
export const activateStateDiv = createAction('[Global] Activate State Div');
export const activateCityDiv = createAction('[Global] Activate City Div');
export const activateNearDiv = createAction('[Global] Activate Near Div');
export const activateStoreDiv = createAction('[Global] Activate Store Div');
export const activateSearchDiv = createAction('[Global] Activate Search Div');
export const activateOtherDiv = createAction('[Global] Activate Other Div');

export const setIsMobile = createAction('[Global] Set IsMobile', props<{ isMobile: boolean }>());
export const needsMap = createAction('[Global] Needs Map');
export const setViewMap = createAction('[Global] Set View Map', props<{ value: boolean }>());
