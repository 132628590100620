import { City } from '@shared/classes';
import { AppStateService } from '@core/services/app-state.service';
import { Store } from '@ngrx/store';
import { setActiveCity } from '@app/modules/cities/store/city.actions';
import { CustomOverlay } from './custom-overlay';

export class CitiesOverlay extends CustomOverlay<City> {
  constructor(
    protected map: google.maps.Map,
    protected items: City[],
    protected appState: AppStateService,
    protected store: Store,
    public zoom: number
  ) {
    super(map, items, zoom);
    this.iconColor = 'rgba(227, 26, 28, 0.75)';
  }
  protected getName(item: City): string {
    return item.city;
  }
  protected onClick(item: City) {
    this.store.dispatch(setActiveCity({ city: item }));
    this.appState.gotoCity(true);
  }
}
