import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { CityListItem } from '@shared/classes';
import { IconsHelper } from '@app/shared/helpers/icons.helper';

@Component({
  selector: 'rs-city-item',
  templateUrl: './city-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [],
})
export class CityItemComponent implements OnInit {
  @Input() city!: CityListItem;

  constructor() {}

  ngOnInit(): void {}

  getIconPath() {
    return IconsHelper.getCityIconPath(this.city.city.opencnt);
  }

  getTitle(): string {
    if (this.city.city.countrycode === 'US') {
      return this.city.city.city + ' / ' + this.city.city.state;
    } else {
      return this.city.city.city;
    }
  }
}
