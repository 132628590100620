import { Pin, PinFactory, PinRaw } from './pin';
import { ShopBasics, ShopBasicsRaw } from './shop-basics';

export enum LikeType {
  none,
  like,
  dont,
}
export enum GotoType {
  none,
  goto,
  dont,
  gone,
}

export interface ShopLike {
  storeid: number;
  value: LikeType;
}
export interface ShopGoto {
  storeid: number;
  value: GotoType;
}
export interface ShopVisit {
  id: number;
  shopid: number;
  visit: Date;
}
export interface ShopVisitRaw {
  id: string;
  shopid: string;
  visit: string;
}
export interface VisitsResultRaw {
  visits: ShopVisitRaw[];
  shops: ShopBasicsRaw[];
}
export interface VisitsResult {
  visits: ShopVisit[];
  shops: ShopBasics[];
}
export interface ShopVisits {
  storeid: number;
  visit: Date;
  visits: number;
}
export interface ShopVisitsRaw {
  shopid: string;
  visit: string;
  visits: string;
}
export interface ShopLikeSaveGotoRaw {
  storeid: number;
  value: number;
}

export interface StoreColour {
  shopid: number;
  value: string;
}
export interface StoreColourRaw {
  shopid: string;
  value: string;
}

export interface User {
  id: number;
  email: string;
  username: string;
  token: string;
  likeslike: number[];
  likesdont: number[];
  saves: number[];
  gotosgoto: number[];
  gotosdont: number[];
  gotosgone: number[];
  colours: StoreColour[];
  visits: ShopVisits[];
  photoUrl: string | null;
  pins: Pin[];
}

export interface UserRaw {
  id: string;
  email: string;
  username: string;
  token: string;
  likeslike: number[];
  likesdont: number[];
  saves: number[];
  gotosgoto: number[];
  gotosdont: number[];
  gotosgone: number[];
  colours: StoreColourRaw[];
  visits: ShopVisitsRaw[];
  photoUrl: string | null;
  pins: PinRaw[];
}

export class UserFactory {
  static raw2visit(raw: ShopVisitsRaw): ShopVisits {
    return {
      //...raw,
      storeid: parseInt(raw.shopid, 10),
      visit: new Date(raw.visit),
      visits: parseInt(raw.visits, 10),
    };
  }

  static fromRaw(raw: UserRaw): User | null {
    const result: User = {
      ...raw,
      id: parseInt(raw.id, 10),
      colours: raw.colours.map((x) => ({
        ...x,
        shopid: parseInt(x.shopid, 10),
      })),
      visits: raw.visits.map((x) => this.raw2visit(x)),
      pins: raw.pins.map((x) => PinFactory.fromRaw(x)),
    };

    return result.id > 0 && result.email && result.username ? result : null;
  }
}
