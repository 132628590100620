<div class="rs-panel-row rs-clickable rs-store-panel-row store-item-border redborder noselect">
  <div class="store-item-container">
    <div class="store-item-title-container">
      <div class="store-item-title">
        <b>{{ title }}</b>
      </div>
      <div class="store-item-title-info">
        {{ subtitle }}
      </div>
    </div>
  </div>
</div>
