import { ShopAddress, ShopAddressFactory, ShopAddressRaw } from './shop-address';
import { ShopContact, ShopContactFactory, ShopContactRaw } from './shop-contact';
import { ShopEssentials, ShopEssentialsFactory, ShopEssentialsRaw } from './shop-essentials';
import { ShopTimes, ShopTimesFactory, ShopTimesRaw } from './shop-times';

export interface Pin extends ShopEssentials, ShopAddress, ShopContact, ShopTimes {
  colour: string;
  userid: number;
  created: string;
}

export interface PinRaw extends ShopEssentialsRaw, ShopAddressRaw, ShopContactRaw, ShopTimesRaw {
  colour: string;
  userid: string;
  created: string;
}

export class PinFactory {
  static fromRaw(raw: PinRaw): Pin {
    return {
      ...raw,
      ...ShopEssentialsFactory.fromRaw(raw),
      ...ShopAddressFactory.fromRaw(raw),
      ...ShopContactFactory.fromRaw(raw),
      ...ShopTimesFactory.fromRaw(raw),
      userid: parseInt(raw.userid, 10),
    };
  }
  static new(): Pin {
    return {
      ...ShopEssentialsFactory.new(),
      ...ShopAddressFactory.new(),
      ...ShopContactFactory.new(),
      ...ShopTimesFactory.new(),

      colour: 'blue',
      userid: 0,
      created: '',
    };
  }
}
