import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { selectUser } from '../store/auth.selectors';
import { AuthService } from '../../../core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private user$ = this.store.select(selectUser);

  constructor(private auth: AuthService, private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: Das brauche ich an dieser Stelle evtl gar nicht.
    // Evtl sollte man stattdessen den User zu Beginn laden, wenn der Token noch gilt.
    // Und dann den Store verwenden.
    let token: string = this.auth.getToken() ?? '';
    let userid: string = (this.auth.getUserID() ?? '').toString();
    const d = new Date();

    this.user$.pipe(first()).subscribe((x) => {
      if (x) {
        userid = x.id.toString();
        token = x.token ?? '';
      }
    });

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      setHeaders: {
        'x-session-token': token ?? '-',
        'x-session-userid': userid ?? '0',
        'x-session-weekday': d.getDay().toString(),
      },
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
