/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import * as MapActions from './map.actions';
import { Store } from '@ngrx/store';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { DialogsService } from '@core/services/dialogs.service';
import { loadStoresSliceSuccess, loadStoreSuccess, tryLoadMediumStores } from '@app/modules/shops/store/shop.actions';
import { selectActiveMarker } from './map.selectors';
import { Md5 } from 'ts-md5';
import { filterNullish } from '@app/shared/helpers/rxjs.helpers';
import { loginActionSuccess } from '@app/modules/auth/store/auth.actions';
import { Router } from '@angular/router';
import { selectVisibleStores } from '@app/modules/shops/store/shop.selectors';

@Injectable()
export class MapEffects {
  constructor(
    private actions$: Actions,
    private dialogs: DialogsService,
    private router: Router,
    private store: Store
  ) {}

  setPositionToMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.setMapPositionToMe),
      withLatestFrom(this.store.select(selectPosition)),
      map(([action, pos]) => (pos === null ? MapActions.showNoPosModal() : MapActions.setLatLngPosMapPosition({ pos })))
    )
  );

  showNoPosModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MapActions.showNoPosModal),
        tap(() =>
          this.dialogs.openMessageDialog(
            'Position Unavailable',
            'Your position is not available - please check your browser settings.'
          )
        )
      ),
    { dispatch: false }
  );

  changeActiveMarkerFromPlaceToStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStoresSliceSuccess),
      withLatestFrom(this.store.select(selectActiveMarker)),
      filter(([action, marker]) => marker?.type === 'placeIcon'),
      map(([action, marker]) => action.stores.find((store) => store.placeidh === Md5.hashStr(marker?.placeId ?? ''))),
      filterNullish(),
      // Es gibt (inzwischen) einen Store für diese PlaceID => 2 Actions nötig, Store laden und Marker aktualisieren
      tap((store) => this.store.dispatch(tryLoadMediumStores({ stores: [store], from: 'Google Search' }))),
      map((store) => MapActions.setActiveMarker2Store({ position: store, storeId: store.id }))
    )
  );

  changeActiveMarkerFromPlaceToPin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActionSuccess),
      withLatestFrom(this.store.select(selectActiveMarker)),
      filter(([action, marker]) => marker?.type === 'placeIcon'),
      map(([action, marker]) => action.user?.pins.find((pin) => pin.place_id === marker?.placeId ?? '')),
      filterNullish(),
      // Es gibt (inzwischen) einen Pin für diese PlaceID => Marker aktualisieren
      tap((pin) => {
        this.router.navigateByUrl('/pin/' + pin.id);
        return pin;
      }),
      map((pin) => MapActions.setActiveMarker2Pin({ position: pin, pinId: pin.id }))
    )
  );

  loadMediumOnBoundsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.boundsChanged),
      withLatestFrom(this.store.select(selectVisibleStores)),
      map(([action, stores]) => tryLoadMediumStores({ stores, from: action.type }))
    )
  );
}
