import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'rs-slider-dots',
  templateUrl: './slider-dots.component.html',
  styleUrls: ['./slider-dots.component.scss'],
})
export class SliderDotsComponent implements OnInit, OnChanges {
  @Input() active = 0;
  @Input() count = 0;
  @Output() selectSlide = new EventEmitter<number>();

  divWidth = 0;
  divs: number[] = [];

  constructor() {}

  ngOnInit(): void {
    this.initDivs();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const prop in changes) {
      if (prop === 'count') {
        this.count = changes[prop].currentValue;
        this.initDivs();
      }
    }
  }

  initDivs() {
    this.divs = [];
    while (this.divs.length < this.count) {
      this.divs.push(this.divs.length);
    }
    let w = window.innerWidth;
    if (w > 600) {
      w = 450;
    }
    this.divWidth = w / this.count;
  }

  click(index: number) {
    this.selectSlide.emit(index);
  }
}
