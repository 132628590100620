<ng-container *ngIf="pins.length">
  <h2>{{ title }}</h2>
  <div
    *ngIf="!expanded"
    (click)="expanded = !expanded"
    class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-blue rs-border-bottom-lightgray"
  >
    <rs-simple-item
      [faIcon]="faMapMarker"
      iconClass="rs-home-simple-item-icon"
      title="Click here to see Pins"
    ></rs-simple-item>
  </div>
  <div
    *ngIf="expanded"
    (click)="expanded = !expanded"
    class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row rs-blue rs-border-bottom-lightgray"
  >
    <rs-simple-item
      [faIcon]="faMapMarker"
      iconClass="rs-home-simple-item-icon"
      title="Click here to hide Pins"
    ></rs-simple-item>
  </div>
  <ng-container *ngIf="expanded">
    <rs-pin-item *ngFor="let pin of pins" class="rs-pointer" [item]="pin" [routerLink]="'/pin/' + pin.id"></rs-pin-item>
  </ng-container>
</ng-container>
