import { Component, OnInit, Input, HostListener, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Image } from '@shared/classes';
import { Subscription, timer } from 'rxjs';
import { IImage } from '../slideshow/IImage';
import { AppStateService } from '@core/services/app-state.service';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { FullScreenImage } from '@shared/classes/full-screen-image';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent extends Unsubscriber implements OnInit, OnChanges {
  @ViewChild('slideshow') slideshow: any;
  @Input() images: Image[] = [];

  faExpand = faExpandAlt;

  imageSources: IImage[] = [];
  resizeSubscription: Subscription | null = null;
  width: number;

  constructor(private appState: AppStateService) {
    super();

    this.width = this.getWidth();

    this.doOnDestroy(() => this.resizeSubscription?.unsubscribe());
  }

  get height(): string {
    return Math.round((this.width * 3.0) / 4.0) + 'px';
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.resizeSubscription?.unsubscribe();
    this.resizeSubscription = null;
    this.resizeSubscription = timer(1000).subscribe((t) => {
      const w = this.getWidth();
      if (w !== this.width) {
        this.width = w;
        this.resizeSubscription = null;
        this.updateImageUrls();
      }
    });
  }

  ngOnInit(): void {
    this.updateImageUrls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const prop in changes) {
      if (prop === 'images') {
        this.images = changes[prop].currentValue;
        this.updateImageUrls();
        if (this.slideshow) {
          this.slideshow.goToSlide(0);
          this.slideshow.autoPlay = true;
        }
      }
    }
  }

  getClass(index: number) {
    return this.slideshow && this.slideshow.slideIndex === index ? 'slideactive' : 'slideinactive';
  }

  selectSlide(index: number) {
    this.slideshow.goToSlide(index);
  }

  getWidth() {
    const w = Math.round(window.innerWidth);
    return w > 600 ? 450 : w;
  }

  updateImageUrls() {
    this.imageSources = [];
    this.images.forEach((img) =>
      this.imageSources.push({
        url: `/images/mthumb.php?w=${this.width}&rot=${img.rotation}&src=${img.imageurl}`,
        backgroundSize: 'contain',
      })
    );
  }

  showFullScreenImages() {
    const images: FullScreenImage[] = [];
    const width = window.innerWidth;
    const i = 1;
    this.images.forEach((img) => {
      images.push({
        title: '',
        image: `/images/mthumb.php?w=${width}&rot=${img.rotation}&src=${img.imageurl}`,
        thumbImage: `/images/mthumb.php?w=${this.width}&rot=${img.rotation}&src=${img.imageurl}`,
      });
    });
    this.appState.showFullScreenImages(images);
  }
}
