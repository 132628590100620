<div class="bottombar noselect" (click)="$event.stopPropagation()">
  <div class="bottombaricon" (click)="appState.gotoHome()" [ngClass]="{ active: isActive('HOME') }">
    <fa-icon size="lg" [icon]="icons.faHome"></fa-icon>
    <div class="rs-tile-text"><span class="noselect">Home</span></div>
  </div>
  <div class="bottombaricon" (click)="appState.gotoNear(false)" [ngClass]="{ active: isActive('NEAR') }">
    <fa-icon size="lg" [icon]="icons.faNear"></fa-icon>
    <div class="rs-tile-text"><span class="noselect">Near Me</span></div>
  </div>
  <div
    class="bottombaricon"
    (click)="appState.gotoCity(false)"
    [ngClass]="{
      active: isActive('CITY'),
      disabled: activeCity === null && activeStore === null
    }"
  >
    <fa-icon size="lg" [icon]="icons.faCity"></fa-icon>
    <div class="rs-tile-text">
      <span class="noselect">{{ activeCity ? activeCity.city : activeStore ? activeStore.city : 'City' }}</span>
    </div>
  </div>
  <div class="bottombaricon visibleicon" routerLink="/visible" [ngClass]="{ active: isActive('VISIBLE') }">
    <fa-icon size="lg" [icon]="icons.faCity"></fa-icon>
    <div class="rs-tile-text"><span class="noselect">Visible</span></div>
  </div>
  <div
    class="bottombaricon"
    (click)="appState.gotoStore(false)"
    [ngClass]="{ active: isActive('STORE'), disabled: activeStore === null }"
  >
    <fa-icon size="lg" [icon]="icons.faStore"></fa-icon>
    <div class="rs-tile-text">
      <span class="noselect">{{ activeStore ? activeStore.name : 'Store' }}</span>
    </div>
  </div>
  <div class="bottombaricon mapicon" (click)="appState.gotoMap()" [ngClass]="{ active: isActive('MAP') }">
    <fa-icon size="lg" [icon]="icons.faMap"></fa-icon>
    <div class="rs-tile-text"><span class="noselect">Map</span></div>
  </div>
</div>
