<div id="whereami" (click)="setPositionToMe()">
  <img
    id="whereami-img"
    title="Where am I?"
    alt="Where am I?"
    src="/assets/markers/position21.svg"
    width="32"
    height="32"
  />
</div>
