import { createAction, props } from '@ngrx/store';
import { LatLng, LatLngPos, LatLngBounds } from '@shared/classes';
import { MapPosition, MapZoomLevel } from '../classes/map-position';

export const noOpAction = createAction('[Map] No Op');
export const showNoPosModal = createAction('[Map] Show No Pos Modal');
export const initMapPosition = createAction('[Map] Init Map Position');
export const setMapPosition = createAction('[Map] Set Map Position', props<{ newPosition: MapPosition }>());
export const setMapPositionToMe = createAction('[Map] Set Map Position To Me');
export const zoomToStore = createAction('[Map] Zoom To Store', props<{ position: LatLng }>());
export const setLatLngPosMapPosition = createAction('[Map] Set Position', props<{ pos: LatLngPos }>());
export const setMapBounds = createAction(
  '[Map] Set Map Bounds',
  props<{
    bounds: LatLngBounds;
    zoomlevel: MapZoomLevel;
    zoomCenter: LatLng | null;
  }>()
);
export const setActiveMarker2Place = createAction(
  '[Map] Set Active Marker (PlaceID)',
  props<{ position: LatLng; placeId: string }>()
);
export const setActiveMarker2Pos = createAction('[Map] Set Active Marker (Position)', props<{ position: LatLng }>());
export const setActiveMarker2Store = createAction(
  '[Map] Set Active Marker (StoreID)',
  props<{ position: LatLng; storeId: number }>()
);
export const setActiveMarker2Pin = createAction(
  '[Map] Set Active Marker (PinID)',
  props<{ position: LatLng; pinId: number }>()
);
export const unsetActiveMarker = createAction('[Map] Unset Active Marker');

// Map Events
export const clickOnMap = createAction('[Map] Click Map', props<{ position: LatLng }>());
export const clickOnPlaceIcon = createAction(
  '[Map] Click Place Icon',
  props<{
    position: LatLng;
    placeId: string;
  }>()
);
export const zoomChanged = createAction('[Map] Zoom Changed', props<{ zoom: number }>());
export const boundsChanged = createAction('[Map] Bounds Changed', props<{ bounds: LatLngBounds }>());
