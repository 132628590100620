import { Component, OnInit } from '@angular/core';
import { faCamera, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faLifeRing, faClock } from '@fortawesome/free-regular-svg-icons';
import { Store } from '@ngrx/store';
import { setHighlightStrategy } from '@app/modules/markers/store/marker.actions';
import {
  defaultHighlightStrategy,
  HighlightStrategies,
  HighlightStrategy,
} from '@app/modules/markers/classes/highlight-strategies';
import { selectSelectedHighlightStrategyID } from '@app/modules/markers/store/marker.selectors';
import { selectAuthenticated } from '@app/modules/auth/store/auth.selectors';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-map-highlights',
  templateUrl: './map-highlights.component.html',
  styleUrls: ['./map-highlights.component.scss'],
})
export class MapHighlightsComponent extends Unsubscriber implements OnInit {
  faNoHighlights = faTimes;
  faNoPhoto = faCamera;
  faSupporters = faLifeRing;
  faOpenNow = faClock;

  highlights: HighlightStrategy[] = HighlightStrategies;
  selectedHighlight: string = defaultHighlightStrategy.id;
  selectedHighlight$ = this.store.select(selectSelectedHighlightStrategyID);
  authenticated$ = this.store.select(selectAuthenticated);

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.unsubscribeLater(
      this.selectedHighlight$.subscribe((x) => {
        if (x !== this.selectedHighlight) {
          this.selectedHighlight = x;
        }
      })
    );
  }

  onChange() {
    this.store.dispatch(setHighlightStrategy({ strategy: this.selectedHighlight }));
  }
}
