<rs-simple-item
  *ngIf="place && !place.structured_formatting"
  [icon]="'/assets/markers/gmaps.ico'"
  [title]="place.description"
></rs-simple-item>

<div *ngIf="place && place.structured_formatting" class="rs-panel-row rs-clickable rs-store-panel-row noselect">
  <div class="store-item-container">
    <div class="store-item-icon">
      <img src="/assets/markers/gmaps.ico" class="google-place-icon" />
    </div>
    <div class="store-item-title-container">
      <div class="store-item-title">
        <b>{{ place.structured_formatting.main_text }}</b>
      </div>
      <div class="store-item-title-info">
        <span *ngIf="place.distance_meters && place.distance_meters >= 1000 && place.distance_meters < 100 * 1000">
          {{ place.distance_meters / 1000.0 | number: '1.1-1' }} km
          <span class="rs-separator"> |</span>
        </span>
        <span *ngIf="place.distance_meters && place.distance_meters >= 0 && place.distance_meters < 1000"
          ><span class="near"> {{ place.distance_meters | number: '1.0-0' }} m </span>
          <span class="rs-separator"> |</span>
        </span>
        {{ place.structured_formatting.secondary_text }}
      </div>
    </div>
  </div>
</div>
