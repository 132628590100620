<div class="rs-panel" *ngIf="visits">
  <h2>You visited this store {{ helper.n(visits.length, 'time') }}</h2>
  <p>
    <ng-container *ngFor="let visit of visits">
      {{ visit.visit | date: 'EEEE, MMMM d, y, HH:mm' }}
      <fa-icon
        [icon]="faTimes"
        size="lg"
        class="rs-red mr-3 rs-pointer"
        (click)="removeVisit(visit)"
        title="Remove this visit."
      ></fa-icon>
      <br />
    </ng-container>
  </p>
</div>
