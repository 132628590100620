import { Component, Input, OnInit } from '@angular/core';
import { Pin } from '@app/shared/classes';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rs-pins-list',
  templateUrl: './pins-list.component.html',
  styleUrls: [],
})
export class PinsListComponent implements OnInit {
  @Input() pins: Pin[] = [];
  @Input() title = '';
  @Input() subtitle = '';
  @Input() expanded = false;

  faMapMarker = faMapMarkerAlt;

  constructor() {}

  ngOnInit(): void {}
}
