<form [formGroup]="form" (ngSubmit)="submit()">
  <p>
    <mat-dialog-content>
      <mat-form-field>
        <input matInput type="tel" formControlName="phone" placeholder="Telephone" autofocus />
        <mat-error *ngIf="form.get('phone')?.invalid && form.get('phone')?.touched"
          >Please enter a telephone number</mat-error
        >
      </mat-form-field>

      <mat-form-field class="mt-3">
        <input matInput type="email" formControlName="email" placeholder="Email" />
        <mat-error *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
          >Please enter a valid Email here</mat-error
        >
      </mat-form-field>

      <mat-form-field class="mt-3">
        <input matInput type="url" formControlName="website" placeholder="Url 1" />
        <mat-error *ngIf="form.get('website')?.invalid && form.get('website')?.touched"
          >Please enter a valid website here</mat-error
        >
      </mat-form-field>

      <mat-form-field class="mt-3">
        <input matInput type="url" formControlName="facebook" placeholder="Url 2" />
        <mat-error *ngIf="form.get('facebook')?.invalid && form.get('facebook')?.touched"
          >Please enter a valid website here</mat-error
        >
      </mat-form-field>

      <mat-form-field class="mt-3">
        <input matInput type="url" formControlName="discogs" placeholder="Url 3" />
        <mat-error *ngIf="form.get('discogs')?.invalid && form.get('discogs')?.touched"
          >Please enter a valid website here</mat-error
        >
      </mat-form-field>

      <mat-form-field class="mt-3">
        <input matInput type="url" formControlName="ebay" placeholder="Url 4" />
        <mat-error *ngIf="form.get('ebay')?.invalid && form.get('ebay')?.touched"
          >Please enter a valid website here</mat-error
        >
      </mat-form-field>

      <mat-form-field *ngIf="withNote" class="mt-3">
        <textarea matInput formControlName="note" placeholder="Submission Notes (only for admins)"> </textarea>
        <mat-error *ngIf="form.get('note')?.invalid && form.get('note')?.touched">Please enter a short note</mat-error>
      </mat-form-field>

      <div style="text-align: right" class="mt-3">
        <input type="button" value="Discard" class="mat-button rs-alt-button" (click)="close()" />
        <input
          type="submit"
          value="Save Changes"
          class="mat-flat-button rs-button"
          [class.disabled]="!form.valid"
          [disabled]="!form.valid"
        />
      </div>
    </mat-dialog-content>
  </p>
</form>
