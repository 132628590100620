import { Component, Input, OnInit } from '@angular/core';
import { ShopDetails } from '@app/shared/classes';
import { ImageDetails, ImageUpdateDetails } from '@app/shared/classes/image-details';
import { Store } from '@ngrx/store';
import { saveStore, updateImage } from '../../store/shop.actions';

@Component({
  selector: 'rs-admin-image',
  templateUrl: './admin-image.component.html',
  styleUrls: ['./admin-image.component.scss'],
})
export class AdminImageComponent implements OnInit {
  @Input() image!: ImageDetails;
  @Input() shop!: ShopDetails;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  setMainImage(shop: ShopDetails, imageid: number) {
    console.log(imageid);
    if (shop.imageid !== imageid) {
      this.store.dispatch(
        saveStore({
          note: `store ${shop.id} main image changed by admin`,
          store: { ...shop, imageid },
        })
      );
    }
  }
  setRotation(image: ImageDetails, rot: number) {
    const payload: ImageUpdateDetails = {
      id: image.id,
      field: 'rotation',
      value: `${rot}`,
    };
    this.store.dispatch(updateImage({ payload, note: `toggle image ${image.id} rotation` }));
  }
  toggleActive(image: ImageDetails) {
    const payload: ImageUpdateDetails = {
      id: image.id,
      field: 'active',
      value: image.active ? '0' : '1',
    };
    this.store.dispatch(updateImage({ payload, note: `toggle image ${image.id} active` }));
  }
  toggleDeleted(image: ImageDetails) {
    const payload: ImageUpdateDetails = {
      id: image.id,
      field: 'deleted',
      value: image.deleted ? '0' : '1',
    };
    this.store.dispatch(updateImage({ payload, note: `toggle image ${image.id} deleted` }));
  }
  setWhat(image: ImageDetails, what: string) {
    const payload: ImageUpdateDetails = {
      id: image.id,
      field: 'what',
      value: what,
    };
    this.store.dispatch(updateImage({ payload, note: `toggle image ${image.id} what` }));
  }
}
