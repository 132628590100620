<h2 mat-dialog-title>{{ options.settings.title }}</h2>

<form [formGroup]="contactForm" (ngSubmit)="save()">
  <mat-dialog-content>
    <mat-form-field>
      <input matInput [placeholder]="options.settings.namePlaceholder" formControlName="name" />
      <mat-error *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched"
        >Please enter your name</mat-error
      >
    </mat-form-field>

    <mat-form-field>
      <input matInput [placeholder]="options.settings.emailPlaceholder" formControlName="email" type="email" />
      <mat-error *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched"
        >Please enter your email</mat-error
      >
    </mat-form-field>

    <mat-form-field>
      <textarea matInput [placeholder]="options.settings.messagePlaceholder" formControlName="message"> </textarea>
      <mat-error *ngIf="contactForm.get('message')?.invalid && contactForm.get('message')?.touched"
        >Please enter a message</mat-error
      >
    </mat-form-field>

    <div style="text-align: right">
      <input type="button" value="Close" class="mat-button rs-alt-button" (click)="close()" />
      <input
        type="submit"
        value="Send"
        class="mat-flat-button rs-button"
        [class.disabled]="!contactForm.valid"
        [disabled]="!contactForm.valid"
      />
    </div>
  </mat-dialog-content>
</form>
