export interface ImageDetails {
  id: number;
  shopid: number;
  userid: number;
  imageurl: string;
  created: string;
  active: boolean;
  deleted: boolean;
  rotation: number;
  what: 'street' | 'inside' | 'logo' | 'misc' | 'owner' | 'card' | 'bag' | null;
  instaurl: string | null;
  instausername: string | null;
  instafullname: string | null;
  instauploaddate: string | null;
}

export interface ImageDetailsRaw {
  id: string;
  shopid: string;
  userid: string;
  imageurl: string;
  created: string;
  active: string;
  deleted: string;
  rotation: string;
  what: 'street' | 'inside' | 'logo' | 'misc' | 'owner' | 'card' | 'bag' | null;
  instaurl: string | null;
  instausername: string | null;
  instafullname: string | null;
  instauploaddate: string | null;
}

export interface ImageUpdateDetails {
  id: number;
  field: 'active' | 'deleted' | 'rotation' | 'what';
  value: string;
}

export class ImageDetailsFactory {
  static fromRaw(raw: ImageDetailsRaw): ImageDetails {
    return {
      ...raw,
      id: parseInt(raw.id, 10),
      shopid: parseInt(raw.shopid, 10),
      userid: parseInt(raw.userid, 10),
      //created: new Date(raw.created),
      active: parseInt(raw.active, 10) === 1,
      deleted: parseInt(raw.deleted, 10) === 1,
      rotation: parseInt(raw.rotation, 10),
    };
  }

  static fromRawArray(images: ImageDetailsRaw[] | undefined): ImageDetails[] {
    return images ? images.map((item) => ImageDetailsFactory.fromRaw(item)) : [];
  }
}
