import { ShopDetails } from './shop-details';

/* eslint-disable radix */
export interface City {
  city: string;
  state: string;
  countrycode: string;

  lat: number;
  lng: number;
  latmax: number;
  latmin: number;
  lngmax: number;
  lngmin: number;

  opencnt: number;
}

export interface CityRaw {
  city: string;
  state: string;
  countrycode: string;

  lat: string;
  lng: string;
  latmax: string;
  latmin: string;
  lngmax: string;
  lngmin: string;

  opencnt: string;
}

export class CityFactory {
  static fromRaw(s: CityRaw, isMedium: boolean = false): City {
    const result: City = {
      ...s,
      lat: parseFloat(s.lat),
      lng: parseFloat(s.lng),
      latmax: parseFloat(s.latmax),
      lngmax: parseFloat(s.lngmax),
      latmin: parseFloat(s.latmin),
      lngmin: parseFloat(s.lngmin),
      opencnt: parseInt(s.opencnt),
    };

    return result;
  }

  static fromStore(s: ShopDetails): City {
    return {
      city: s.city,
      state: (s.countrycode === 'US' ? s.state : '') ?? '',
      countrycode: s.countrycode,
      lat: s.lat,
      latmin: s.lat,
      latmax: s.lat,
      lng: s.lng,
      lngmin: s.lng,
      lngmax: s.lng,
      opencnt: 1,
    };
  }
}
