/// <reference types="@types/google.maps" />
import { Injectable } from '@angular/core';
import { MarkerDefinitions } from '@app/shared/helpers/marker-definitions';
import { LocalStorageService } from '@core/services/local-storage.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { LatLng, LatLngBounds, LatLngHelper, LatLngPos } from '@shared/classes';
import { Store } from '@ngrx/store';
import { debounceTime, first } from 'rxjs/operators';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { defaultMapPosition, MapPosition, MapZoomLevel } from '@modules/map/classes/map-position';
import {
  selectMapPosition,
  ZOOM_SHOW_CITIES,
  ZOOM_SHOW_COUNTRIES,
  ZOOM_STORE_DEFAULT,
} from '@modules/map/store/map.selectors';
import {
  setMapPosition,
  setLatLngPosMapPosition,
  zoomChanged,
  boundsChanged,
  clickOnPlaceIcon,
  clickOnMap,
  setActiveMarker2Store,
  setActiveMarker2Pin,
} from '@modules/map/store/map.actions';
import { selectAllStores } from '@app/modules/shops/store/shop.selectors';
import { Md5 } from 'ts-md5';
import { setActiveStoreID } from '@app/modules/shops/store/shop.actions';
import { selectAllPins } from '@app/modules/auth/store/auth.selectors';

const initialMapOptions: google.maps.MapOptions = {
  center: defaultMapPosition.pos ?? undefined,
  zoom: 15,
  clickableIcons: true,
  mapTypeControl: false,
  scaleControl: false,
  rotateControl: false,
  fullscreenControl: false,
  zoomControl: true,
  zoomControlOptions: {
    position: google.maps.ControlPosition.RIGHT_BOTTOM,
  },
  streetViewControl: true,
  streetViewControlOptions: {
    position: google.maps.ControlPosition.RIGHT_TOP,
  },
  mapTypeId: google.maps.MapTypeId.ROADMAP,
};

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private mapSubject = new BehaviorSubject<google.maps.Map | null>(null);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly map$ = this.mapSubject.asObservable();

  private mapPosition$ = this.store.select(selectMapPosition);

  private map: google.maps.Map | null = null;
  private posMarker: google.maps.Marker | null = null;
  private posCircle: google.maps.Circle | null = null;
  private posRotation: google.maps.Marker | null = null;

  private trafficLayer: google.maps.TrafficLayer | null = null;
  private transitLayer: google.maps.TransitLayer | null = null;

  private position$ = this.store.select(selectPosition);
  private allStores$ = this.store.select(selectAllStores);
  private allPins$ = this.store.select(selectAllPins);

  private boundsChangedEvent$ = new Subject<google.maps.LatLngBounds | null | undefined>();
  private zoomChangedEvent$ = new Subject<number>();

  private clickOnMapTimer: NodeJS.Timeout | undefined;

  get storesVisible(): boolean {
    const zoom = this.map?.getZoom();
    return zoom ? zoom > ZOOM_SHOW_CITIES : false;
  }
  get citiesVisible(): boolean {
    const zoom = this.map?.getZoom();
    return zoom ? zoom > ZOOM_SHOW_COUNTRIES && zoom <= ZOOM_SHOW_CITIES : false;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(private storage: LocalStorageService, private store: Store) {}

  initMap(mapDiv: HTMLElement): void {
    const map = new google.maps.Map(mapDiv, initialMapOptions);
    this.map = map;

    this.initMapDivs(map);
    this.initMapListeners(map);
    this.initMapLayers(map);
    this.initMapPosition();

    setTimeout(() => {
      this.mapSubject.next(map);
      this.position$.subscribe((position) => this.updateCurrentLocation(position));
    }, 0);

    this.mapPosition$.subscribe((x) => this.setMapPosition(x));
  }

  showTrafficLayer(on: boolean) {
    if (on && !this.trafficLayer) {
      this.trafficLayer = new google.maps.TrafficLayer();
    }
    this.trafficLayer?.setMap(on ? this.map : null);

    this.storage.set('MAP.TrafficLayer', on);
  }

  showTransitLayer(on: boolean) {
    if (on && !this.transitLayer) {
      this.transitLayer = new google.maps.TransitLayer();
    }
    this.transitLayer?.setMap(on ? this.map : null);

    this.storage.set('MAP.TransitLayer', on);
  }

  setSatelliteView(on: boolean) {
    this.map?.setMapTypeId(on ? 'hybrid' : 'roadmap');

    this.storage.set('MAP.SatelliteView', on);
  }

  private setMapPosition(pos: MapPosition | null) {
    if (this.map && pos) {
      switch (pos.what) {
        case 'position':
          this.setMapPositionToPosition(pos.pos, pos.level);
          break;
        case 'bounds':
          this.setMapPositionToBounds(pos.bounds, pos.level, pos.center);
          break;
        case 'zoomTo':
          this.zoomToDetailPosition(pos.pos);
      }
    }
  }

  private setMapPositionToPosition(pos: LatLng | null, level: MapZoomLevel) {
    if (pos && this.map) {
      this.map.setCenter(new google.maps.LatLng(pos.lat, pos.lng));

      const zoom = this.map.getZoom();
      if (zoom) {
        if (zoom <= ZOOM_SHOW_CITIES && level === 'stores') {
          this.map.setZoom(ZOOM_STORE_DEFAULT);
        } else if (zoom > ZOOM_SHOW_CITIES && level === 'cities') {
          this.map.setZoom(ZOOM_SHOW_CITIES);
        }
      } else {
        this.map.setZoom(level === 'stores' ? ZOOM_STORE_DEFAULT : ZOOM_SHOW_CITIES);
      }
    }
  }

  private setMapPositionToBounds(bounds: LatLngBounds | null, level: MapZoomLevel, center: LatLng | null) {
    if (this.map && bounds) {
      this.map.fitBounds(
        new google.maps.LatLngBounds(
          new google.maps.LatLng(bounds.latmin, bounds.lngmin),
          new google.maps.LatLng(bounds.latmax, bounds.lngmax)
        )
      );

      if (level === 'cities' && !this.citiesVisible) {
        if (this.storesVisible) {
          // ich sehe Stores
          this.map.setZoom(ZOOM_SHOW_CITIES); // ich sehe Cities
        } else if (center !== null) {
          // ich sehe Countries und hab ein Center zu dem ich zoomen kann
          this.map.setCenter(center);
          this.map.setZoom(ZOOM_SHOW_COUNTRIES + 1);
        }
      } else if (level === 'stores' && !this.storesVisible) {
        // ich sehe Cities oder Countries
        this.map.setZoom(ZOOM_SHOW_CITIES + 1); // ich sehe Stores
      }
    }
  }

  private zoomToDetailPosition(pos: LatLng | null) {
    if (this.map && pos) {
      const latlng = new google.maps.LatLng(pos.lat, pos.lng);

      const bnds = this.map.getBounds();
      if (bnds) {
        if (bnds.contains(pos)) {
          // Marker ist schon im Sichtfeld => Zoomen nur wenn Store-Markers noch nicht angezeigt werden.
          if (!this.storesVisible) {
            this.map.panTo(latlng);
            this.map.setZoom(ZOOM_STORE_DEFAULT);
          }
        } else {
          // Marker ist noch nicht im sichtbaren Bereich
          this.map.panTo(latlng);
          if (!this.storesVisible) {
            this.map.setZoom(ZOOM_STORE_DEFAULT);
          }
        }
      } else {
        this.map.setCenter(latlng);
        this.map.setZoom(ZOOM_STORE_DEFAULT);
      }
    }
  }

  // updateCurrentLocation ändert nie den Kartenausschnitt sondern aktualisiert nur den Positionsmarker.
  private updateCurrentLocation(position: LatLngPos | null) {
    if (this.map && position) {
      // Der Genauigkeitskreis ...
      if (!this.posCircle) {
        this.posCircle = new google.maps.Circle({
          strokeColor: '#0000FF',
          strokeOpacity: 0.2,
          strokeWeight: 1,
          fillColor: '#0000FF',
          fillOpacity: 0.05,
          map: this.map,
          center: position,
          radius: position.accuracy ?? undefined,
          visible: true,
          clickable: false,
        });
      } else {
        this.posCircle.setCenter(position);
        this.posCircle.setRadius(position.accuracy ?? 0);
      }

      // der Positions-Marker ...
      if (!this.posMarker) {
        this.posMarker = new google.maps.Marker(MarkerDefinitions.POSITION_MARKER);
        this.posMarker.setMap(this.map);
      }

      if (!this.posRotation) {
        this.posRotation = new google.maps.Marker(MarkerDefinitions.POSITION_ROTATION_MARKER);
        this.posRotation.setMap(this.map);
      }

      if (position.heading === null) {
        this.posRotation.setVisible(false);
      } else {
        this.posRotation.setIcon({
          ...MarkerDefinitions.POSITION_ROTATION_MARKER.icon,
          rotation: position.heading,
        });
        this.posRotation.setVisible(this.storesVisible);
      }

      this.posMarker.setPosition(new google.maps.LatLng(position.lat, position.lng));
      this.posRotation.setPosition(new google.maps.LatLng(position.lat, position.lng));
      this.posMarker.setVisible(true);
    }
  }

  private initMapPosition() {
    // Die Optionen enthalten keine Position, daher die Position ermitteln
    // und - falls sie noch null ist - notfalls setzen.
    this.mapPosition$.pipe(first()).subscribe((x) => {
      if (x === null) {
        // Wenn es noch keine MapPosition gibt, dann wie folgt setzen:
        // 1. Gibt es eine aktuelle Position? Dann die nehmen.
        this.position$.pipe(first()).subscribe((pos) => {
          if (pos !== null) {
            this.store.dispatch(setLatLngPosMapPosition({ pos }));
          } else {
            // 2. Falls nicht, dann die aus dem Storage nehmen wenn vorhanden.
            const storagePos = this.storage.get('POSITION');
            if (storagePos) {
              this.store.dispatch(setLatLngPosMapPosition({ pos: storagePos }));
            } else {
              // 3. Falls auch das nicht, dann die DefaultPosition nehmen.
              this.store.dispatch(setMapPosition({ newPosition: defaultMapPosition }));
            }
          }
        });
      }
    });
  }
  private initMapLayers(map: google.maps.Map) {
    if (this.storage.get('MAP.TrafficLayer') ?? false) {
      this.showTrafficLayer(true);
    }
    if (this.storage.get('MAP.TransitLayer') ?? true) {
      this.showTransitLayer(true);
    }
    if (this.storage.get('MAP.SatelliteView') ?? false) {
      this.setSatelliteView(true);
    }
  }

  private initMapListeners(map: google.maps.Map) {
    map.addListener('zoom_changed', () => {
      const mapZoom = map.getZoom();
      if (mapZoom !== undefined) {
        this.zoomChangedEvent$.next(mapZoom);
      }
    });
    map.addListener('bounds_changed', () => this.boundsChangedEvent$.next(map.getBounds()));
    map.addListener('click', (event: google.maps.MapMouseEvent) => this.onMapClick(map, event));
    map.addListener('dblclick', () => this.onMapDblClick(map));

    this.boundsChangedEvent$.pipe(debounceTime(200)).subscribe((bnds) => {
      if (bnds) {
        const bounds = LatLngHelper.fromGoogleMapsBounds(bnds);
        this.store.dispatch(boundsChanged({ bounds }));
      }
    });

    this.zoomChangedEvent$.subscribe((mapZoom) => this.store.dispatch(zoomChanged({ zoom: mapZoom })));

    // Gleich die ersten beiden feuern
    this.boundsChangedEvent$.next(map.getBounds());
    const zoom = map.getZoom();
    if (zoom !== undefined) {
      this.zoomChangedEvent$.next(zoom);
    }
  }

  private initMapDivs(map: google.maps.Map) {
    this.initWhereAmIDiv(map);
    this.initInfoDiv(map);
    this.initLayersDiv(map);
    this.initHighlightsDiv(map);
  }

  private isIconMouseEvent(e: google.maps.MapMouseEvent | google.maps.IconMouseEvent): e is google.maps.IconMouseEvent {
    return 'placeId' in e;
  }

  private onMapClick(map: google.maps.Map, event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      if (this.storesVisible) {
        const position: LatLng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };

        if (this.isIconMouseEvent(event)) {
          if (event.placeId) {
            const placeId = event.placeId;
            this.allStores$.pipe(first()).subscribe((stores) => {
              const md5 = Md5.hashStr(placeId, false).toString();
              const store = stores.find((x) => x.placeidh === md5);
              if (store) {
                event.stop(); // Google Infowindow unterbinden
                this.store.dispatch(setActiveStoreID({ id: store.id }));
                this.store.dispatch(setActiveMarker2Store({ position: store, storeId: store.id }));
              } else {
                this.allPins$.pipe(first()).subscribe((pins) => {
                  const pin = pins.find((p) => p.place_id === placeId);
                  if (pin) {
                    this.store.dispatch(setActiveMarker2Pin({ position: pin, pinId: pin.id }));
                  } else {
                    this.store.dispatch(clickOnPlaceIcon({ position, placeId }));
                  }
                });
              }
            });
          }
        } else {
          // Um einen Doppelklick nicht zu behandeln wird ein Timer gestartet
          this.clickOnMapTimer = setTimeout(() => {
            this.store.dispatch(clickOnMap({ position }));
            this.clickOnMapTimer = undefined;
          }, 250);
        }
      }
    }
  }

  private onMapDblClick(map: google.maps.Map) {
    if (this.clickOnMapTimer) {
      clearTimeout(this.clickOnMapTimer);
      this.clickOnMapTimer = undefined;
    }
  }

  private initWhereAmIDiv(map: google.maps.Map) {
    const elem = document.getElementById('rs-map-whereami');
    if (elem) {
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(elem);
      elem.hidden = false;
    }
  }

  private initInfoDiv(map: google.maps.Map) {
    const elem = document.getElementById('rs-map-details');
    if (elem) {
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(elem);
      elem.hidden = false;
    }
  }

  private initLayersDiv(map: google.maps.Map) {
    const elem = document.getElementById('rs-map-layers');
    if (elem) {
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(elem);
      elem.hidden = false;
    }
  }

  private initHighlightsDiv(map: google.maps.Map) {
    const elem = document.getElementById('rs-map-highlights');
    if (elem) {
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(elem);
      elem.hidden = false;
    }
  }
}
