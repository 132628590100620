import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Country } from '@shared/classes';
import { IconsHelper } from '@app/shared/helpers/icons.helper';

@Component({
  selector: 'rs-country-item',
  templateUrl: './country-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [],
})
export class CountryItemComponent implements OnInit {
  @Input() country!: Country;

  constructor() {}

  ngOnInit(): void {}

  getIconPath() {
    return IconsHelper.getCountryIconPath(this.country);
  }
}
