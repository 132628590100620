import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ShopDetails } from '@app/shared/classes';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rs-admin-images',
  templateUrl: './admin-images.component.html',
  styleUrls: ['./admin-images.component.scss'],
})
export class AdminImagesComponent implements OnChanges {
  @Input() shop!: ShopDetails;

  nr = 0;
  faLeft = faAngleLeft;
  faRight = faAngleRight;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'shop': {
            this.selectFirstImageToFix();
          }
        }
      }
    }
  }

  selectFirstImageToFix() {
    const nr = this.shop.adminimages.findIndex((x) => x.what === null);
    if (nr >= 0) {
      this.nr = nr;
    }
  }

  prev() {
    if (this.nr-- === 0) {
      this.nr = this.shop.adminimages.length - 1;
    }
  }
  next() {
    if (this.nr++ === this.shop.adminimages.length - 1) {
      this.nr = 0;
    }
  }
}
