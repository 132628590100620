import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Md5 } from 'ts-md5/dist/md5';
import { CityListItem, Country, Pin, ShopBasics, State } from '@shared/classes';
import { selectAllStores } from '@app/modules/shops/store/shop.selectors';
import * as fromSearch from './search.reducer';
import { map } from 'rxjs/operators';

export class SearchFusedResult {
  what!: string;
  stores!: ShopBasics[];
  cities!: CityListItem[];
  states!: State[];
  countries!: Country[];
  pins!: Pin[];
  places!: fromSearch.Prediction[];

  static isEmpty(sfr: SearchFusedResult): boolean {
    return (
      sfr === null ||
      (sfr.stores.length === 0 &&
        sfr.cities.length === 0 &&
        sfr.states.length === 0 &&
        sfr.countries.length === 0 &&
        sfr.pins.length === 0 &&
        sfr.places.length === 0)
    );
  }
}

export const selectSearchState = createFeatureSelector<fromSearch.SearchState>(fromSearch.searchFeatureKey);

export const selectLoadingAnything = createSelector(selectSearchState, (state) => state.searching);

export const selectSearchSearching = createSelector(selectSearchState, (state) => state.searching);

export const selectSearchTerm = createSelector(selectSearchState, (state) => state.term);

export const selectSearchBackendResults = createSelector(selectSearchState, (state) => state.result);

export const selectSearchGoogleResults = createSelector(selectSearchState, (state) => state.google);

export const selectSearchFusedBackendResults = createSelector(
  selectSearchBackendResults,
  selectSearchGoogleResults,
  selectAllStores,
  (data, google, stores) => {
    if (data === null && google === null) {
      return null;
    }

    const result: SearchFusedResult = {
      what: data ? data.what : 'any',
      stores: [],
      cities: [],
      states: [],
      countries: [],
      pins: [],
      places: [],
    };

    if (data) {
      if (data.what === 'any') {
        const exact = data.stores_exact ?? [];
        const match = data.stores_match ?? [];
        // Duplikate entfernen
        const match2 = match.filter((x) => exact.find((y) => y.id === x.id) === undefined);

        result.stores = [...exact, ...match2].map((store) => stores.find((x) => x.id === store.id) ?? store);
        result.cities = [...(data.cities_exact ?? []), ...(data.cities_match ?? [])].map((city) => ({ city }));
        result.states = data.states ?? [];
        result.countries = data.countries ?? [];
        result.pins = data.pins ?? [];
      } else if (data.what === 'store' && data.stores) {
        result.stores = data.stores.map((store) => stores.find((x) => x.id === store.id) ?? store);
      } else if (data.what === 'city' && data.cities_exact && data.stores_exact) {
        result.cities = data.cities_exact.map((city) => ({ city }));
        result.stores = data.stores_exact.map((store) => stores.find((x) => x.id === store.id) ?? store);
      } else if (data.what === 'country' && data.cities && data.countries) {
        result.cities = data.cities.map((city) => ({ city }));
        result.countries = data.countries;
      }
    }

    if (google) {
      google.forEach((place) => {
        const hash = Md5.hashStr(place.place_id);
        const store = stores.find((s) => s.placeidh === hash);
        if (store) {
          result.stores.push(store);
        } else {
          result.places.push(place);
        }
      });
    }

    return SearchFusedResult.isEmpty(result) ? null : result;
  }
);
