import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rs-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit {
  @Input() url = '';

  width = 433;

  constructor() {
    const w = Math.round(window.innerWidth);
    this.width = w > 433 ? 433 : w;
  }

  ngOnInit(): void {}
}
