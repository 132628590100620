import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import * as fromCountry from './store/country.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CountryEffects } from './store/country.effects';

import { StateComponent } from './components/state/state.component';
import { CountryComponent } from './components/country/country.component';
import { CountriesComponent } from './components/countries/countries.component';
import { CitiesStatesComponent } from './components/cities-states/cities-states.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [CountriesComponent, CountryComponent, StateComponent, CitiesStatesComponent],
  imports: [
    SharedModule,
    StoreModule.forFeature(fromCountry.countryFeatureKey, fromCountry.reducer),
    EffectsModule.forFeature([CountryEffects]),
  ],
})
export class CountriesModule {}
