import { createReducer, on } from '@ngrx/store';
import { defaultHighlightStrategy } from '../classes/highlight-strategies';
import { MarkerObject } from '../classes/marker-object';
import * as MarkerActions from './marker.actions';

export const markerFeatureKey = 'marker';

export interface MarkerState {
  selectedHighlightStrategy: string;
  storeMarkers: MarkerObject[];
}

export const initialState: MarkerState = {
  selectedHighlightStrategy: defaultHighlightStrategy.id,
  storeMarkers: [],
};

export const reducer = createReducer(
  initialState,

  on(MarkerActions.setHighlightStrategy, (state, action) => ({
    ...state,
    selectedHighlightStrategy: action.strategy,
  })),
  on(MarkerActions.setDefaultHighlightStrategy, (state) => ({
    ...state,
    selectedHighlightStrategy: defaultHighlightStrategy.id,
  }))
);
