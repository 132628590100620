import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, withLatestFrom, filter, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as SearchActions from './search.actions';
import * as ShopActions from '@app/modules/shops/store/shop.actions';
import { DataService } from '@core/services/data.service';
import { Action, Store } from '@ngrx/store';
import * as fromSearch from './search.reducer';
import * as fromPosition from '@app/modules/position/store/position.reducer';
import { selectSearchFusedBackendResults, selectSearchTerm } from './search.selectors';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { GooglePlacesService } from '@core/services/google-places.service';

@Injectable()
export class SearchEffects {
  loadSearchResultsFromBackend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.searchBackend),
      withLatestFrom(this.searchStore.select(selectSearchTerm)),
      filter(([action, term]) => action.term !== term && action.term !== ''),
      switchMap(([action, _]) =>
        this.data.getSearch(action.term).pipe(
          map((result) => SearchActions.searchBackendSuccess({ result, term: action.term })),
          catchError((error) => of(SearchActions.searchBackendFailure({ error })))
        )
      )
    )
  );

  loadMediumStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.searchBackendSuccess, SearchActions.searchGoogleSuccess),
      withLatestFrom(this.searchStore.select(selectSearchFusedBackendResults)),
      concatMap(([action, result]): Action[] => {
        const storesToBeLoaded = result?.stores.filter((x) => !x.isMedium) ?? [];
        const from = `SEARCH`;
        return storesToBeLoaded.length ? [ShopActions.loadMediumStores({ stores: storesToBeLoaded, from })] : [];
      })
    )
  );

  startGoogleSearchOnEmptyResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.searchBackendSuccess),
      withLatestFrom(
        this.searchStore.select(selectSearchTerm),
        this.searchStore.select(selectSearchFusedBackendResults)
      ),
      filter(([action, term, results]) => term !== '' && results === null),
      map(([action, term, result]) => SearchActions.searchGoogle({ term }))
    )
  );

  loadSearchResultsFromGooglePlaces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.searchGoogle),
        filter((action) => action.term !== ''),
        withLatestFrom(this.searchStore.select(selectSearchFusedBackendResults), this.posStore.select(selectPosition)),
        filter(([action, results, pos]) => results === null),
        tap(([action, results, pos]) =>
          this.googlePlacesService.searchPlaces(action.term, pos).subscribe({
            next: (result) => this.searchStore.dispatch(SearchActions.searchGoogleSuccess({ result })),
            error: (error) => this.searchStore.dispatch(SearchActions.searchGoogleFailure({ error })),
          })
        )
      ),
    { dispatch: false }
  );
  /*
  loadStoresWithPlaceIDs$ = createEffect(() => this.actions$.pipe(
    ofType(SearchActions.searchGoogleSuccess),
    map(action => action.result.map(item => item.place_id)),
    tap(placeIds => this.data
      .getStoresWithPlaceIds(placeIds)
      .subscribe(
        stores => ShopActions.loadStoresWithPlaceIdsSuccess({ stores })
      )
    )
  ), { dispatch: false });
*/
  constructor(
    private actions$: Actions,
    private data: DataService,
    private googlePlacesService: GooglePlacesService,
    private posStore: Store<fromPosition.PositionState>,
    private searchStore: Store<fromSearch.SearchState>
  ) {}
}
