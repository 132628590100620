import { Component, Input, OnInit } from '@angular/core';

export interface Breadcrumb {
  routerLink: string;
  caption: string;
}

@Component({
  selector: 'rs-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() additional: Breadcrumb | undefined;

  constructor() {}

  ngOnInit(): void {}
}
