/* eslint-disable radix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { UrlSegment, UrlMatchResult } from '@angular/router';

function Matches(s: UrlSegment, reg: any): boolean {
  const param = s ? s.toString() : '';
  return param.match(reg) ? true : false;
}

// prüft ob die URL aus genau zwei Buchstaben besteht: /xx
export function CountryMatcher(url: UrlSegment[]): UrlMatchResult | null {
  return url.length === 1 && Matches(url[0], /^[a-zA-Z][a-zA-Z]$/)
    ? { consumed: url, posParams: { countrycode: url[0] } }
    : null;
}

// prüft, ob die URL ein US-Staat sein könnte: /us/xx
export function StateMatcher(url: UrlSegment[]): UrlMatchResult | null {
  return url.length === 2 && Matches(url[0], /^[uU][sS]$/) && Matches(url[1], /^[a-zA-Z][a-zA-Z]$/)
    ? { consumed: url, posParams: { statecode: url[1] } }
    : null;
}

// prüft, ob die URL eine Stadt sein könnte: /xx/xyz
export function CityMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 2) {
    const firstFragmentIsCountry = Matches(url[0], /^[a-zA-Z][a-zA-Z]$/);
    return firstFragmentIsCountry ? { consumed: url, posParams: { countrycode: url[0], city: url[1] } } : null;
  } else if (url.length === 3) {
    const firstFragmentIsUS = Matches(url[0], /^[uU][sS]$/);
    const secondFragmentIsState = Matches(url[1], /^[a-zA-Z][a-zA-Z]$/);
    return firstFragmentIsUS && secondFragmentIsState
      ? {
          consumed: url,
          posParams: { countrycode: url[0], state: url[1], city: url[2] },
        }
      : null;
  } else {
    return null;
  }
}

// prüft, ob die URL ein Store sein könnte: /000
export function StoreMatcher(url: UrlSegment[]): UrlMatchResult | null {
  return url.length === 1 && !isNaN(parseInt(url[0].toString())) ? { consumed: url, posParams: { id: url[0] } } : null;
}
