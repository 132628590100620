import { HttpErrorResponse } from '@angular/common/http';
import { GotoType, LikeType, Pin, ShopVisit, User, VisitsResult } from '@shared/classes';
import { createAction, props } from '@ngrx/store';
import { SocialUser } from '@abacritt/angularx-social-login';

export const initAuthStore = createAction('[Auth] Init Auth Store');

export const loginValidAction = createAction('[Auth] Check Login Valid');
export const loginAction = createAction('[Auth] Login', props<{ email: string; password: string }>());
export const loginActionSuccess = createAction('[Auth] Login Success', props<{ user: User | null }>());
export const loginActionFailure = createAction('[Auth] Login Failure', props<{ error: HttpErrorResponse }>());

export const loginWithGoogleAction = createAction('[Auth] Login With Google', props<{ user: SocialUser }>());

export const logoutAction = createAction('[Auth] Logout');
export const logoutActionSuccess = createAction('[Auth] Logout Success');
export const logoutActionFailure = createAction('[Auth] Logout Failure', props<{ error: HttpErrorResponse }>());

export const registerAction = createAction(
  '[Auth] Register',
  props<{ email: string; username: string; password: string }>()
);
export const registerActionSuccess = createAction('[Auth] Register Success');
export const registerActionFailure = createAction('[Auth] Register Failure', props<{ error: HttpErrorResponse }>());

export const closeLoginDialogAction = createAction('[Auth] Close Login Dialog');
export const closeRegisterDialogAction = createAction('[Auth] Close Register Dialog');

export const userLikeStore = createAction('[Auth] Like Store', props<{ storeid: number; value: LikeType }>());
export const userLikeStoreSuccess = createAction(
  '[Auth] Like Store Success',
  props<{ storeid: number; value: LikeType }>()
);
export const userLikeStoreFailure = createAction(
  '[Auth] Like Store Failure',
  props<{ storeid: number; error: HttpErrorResponse }>()
);

export const userGotoStore = createAction('[Auth] Goto Store', props<{ storeid: number; value: GotoType }>());
export const userGotoStoreSuccess = createAction(
  '[Auth] Goto Store Success',
  props<{ storeid: number; value: GotoType }>()
);
export const userGotoStoreFailure = createAction(
  '[Auth] Goto Store Failure',
  props<{ storeid: number; error: HttpErrorResponse }>()
);

export const userVisitStore = createAction('[Auth] Visit Store', props<{ storeid: number }>());
export const userVisitStoreSuccess = createAction('[Auth] Visit Store Success', props<{ visit: ShopVisit }>());
export const userVisitStoreFailure = createAction(
  '[Auth] Visit  Store Failure',
  props<{ storeid: number; error: HttpErrorResponse }>()
);

export const userUnvisitStore = createAction('[Auth] Unvisit Store', props<{ visit: ShopVisit }>());
export const userUnvisitStoreSuccess = createAction('[Auth] Unvisit Store Success', props<{ visit: ShopVisit }>());
export const userUnvisitStoreFailure = createAction(
  '[Auth] Unvisit Store Failure',
  props<{ error: HttpErrorResponse }>()
);

export const userColourStore = createAction('[Auth] Colour Store', props<{ storeid: number; value: string }>());
export const userColourStoreSuccess = createAction(
  '[Auth] Colour Store Success',
  props<{ storeid: number; value: string }>()
);
export const userColourStoreFailure = createAction(
  '[Auth] Colour Store Failure',
  props<{ storeid: number; error: HttpErrorResponse }>()
);

export const userLoadVisits = createAction('[Auth] Load Visits');
export const userLoadVisitsSuccess = createAction('[Auth] Load Visits Success', props<{ visits: VisitsResult }>());
export const userLoadVisitsFailure = createAction('[Auth] Load Visits Failure', props<{ error: HttpErrorResponse }>());

export const userRemovePin = createAction('[Auth] Remove Pin', props<{ pinid: number }>());
export const userRemovePinSuccess = createAction('[Auth] Remove Pin Success', props<{ pinid: number }>());
export const userRemovePinFailure = createAction('[Auth] Remove Pin Failure', props<{ error: HttpErrorResponse }>());

export const userSavePin = createAction('[Auth] Save Pin', props<{ pin: Pin }>());
export const userSavePinSuccess = createAction('[Auth] Save Pin Success', props<{ pin: Pin; isNew: boolean }>());
export const userSavePinFailure = createAction('[Auth] Save Pin Failure', props<{ error: HttpErrorResponse }>());
