<div class="rs-panel" *ngIf="city$ | async as city" #storediv>
  <h1 class="rs-header-1">
    {{ city.city.city }}
    <span class="rs-h-small">{{ subtitle }}</span>
  </h1>

  <rs-breadcrumb
    *ngIf="city.city.countrycode !== 'US'"
    [additional]="additional"
    [breadcrumbs]="[
      {
        routerLink: '/' + city.city.countrycode,
        caption: city.city.countrycode
      },
      { routerLink: '', caption: helper.str2url(city.city.city) }
    ]"
  ></rs-breadcrumb>
  <rs-breadcrumb
    *ngIf="city.city.countrycode === 'US'"
    [additional]="additional"
    [breadcrumbs]="[
      { routerLink: '/US', caption: 'US' },
      { routerLink: '/US/' + city.city.state, caption: city.city.state },
      { routerLink: '', caption: helper.str2url(city.city.city) }
    ]"
  ></rs-breadcrumb>

  <ng-container *ngIf="supporters$ | async as supporters">
    <ng-container *ngIf="supporters.length > 0">
      <rs-supporters-list
        [countrycode]="city.city.countrycode"
        title="{{ helper.n(supporters.length, 'Supporter') }} in {{ city.city.city }}"
        [subtitle]="supporters.length > 1 ? 'in random order' : ''"
        [supporters]="supporters"
      ></rs-supporters-list>
    </ng-container>

    <ng-container *ngIf="supporters.length === 0">
      <rs-supporters-list
        [countrycode]="city.city.countrycode"
        title="No Supporters in {{ city.city.city }}"
        subtitle=""
        [supporters]="supporters"
      ></rs-supporters-list>
    </ng-container>
  </ng-container>

  <rs-shops-list
    *ngIf="stores$ | async as stores"
    [shops]="stores"
    title="{{ helper.n(stores.length, 'Store') }} in {{ city.city.city }}"
  ></rs-shops-list>

  <rs-pins-list
    *ngIf="pins$ | async as pins"
    [pins]="pins"
    title="My {{ helper.n(pins.length, 'Pin') }} in {{ city.city.city }}"
  ></rs-pins-list>

  <rs-contact-and-coffee
    [title]="city.city.city + ' / ' + city.city.countrycode"
    [url]="'https://recordstores.love' + helper.city2url(city.city)"
  >
  </rs-contact-and-coffee>

  <rs-sharing
    [url]="'https://recordstores.love' + helper.city2url(city.city)"
    [name]="city.city.city"
    [descript]="'Follow this link to find all record stores in ' + city.city.city"
  ></rs-sharing>
</div>
