import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromPosition from './store/position.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PositionEffects } from './store/position.effects';
import { SharedModule } from '@shared/shared.module';
import { PosStateComponent } from './pos-state/pos-state.component';

@NgModule({
  declarations: [PosStateComponent],
  imports: [
    SharedModule,
    StoreModule.forFeature(fromPosition.positionFeatureKey, fromPosition.reducer),
    EffectsModule.forFeature([PositionEffects]),
  ],
  exports: [PosStateComponent],
})
export class PositionModule {}
