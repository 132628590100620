import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { HomeComponent } from '@modules/shops/components/home/home.component';
import { NearComponent } from '@modules/shops/components/near/near.component';
import { CountriesComponent } from '@modules/countries/components/countries/countries.component';
import { CountryComponent } from '@modules/countries/components/country/country.component';
import { StateComponent } from '@modules/countries/components/state/state.component';
import { CityComponent } from '@modules/cities/city/city.component';
import { StoreComponent } from '@modules/shops/components/store/store.component';
import { ExactSearchResolver, StoreResolver } from './routes/resolvers';
import { StoreMatcher, CountryMatcher, StateMatcher, CityMatcher } from './routes/matchers';
import { ImprintComponent } from './core/pages/imprint/imprint.component';
import { DataPolicyComponent } from './core/pages/data-policy/data-policy.component';
import { SupportComponent } from './core/pages/support/support.component';
import { SearchComponent } from '@modules/search/search/search.component';
import { CanNavigateToAdminGuard } from './can-navigate-to-admin.guard';
import { DonateComponent } from './core/pages/donate/donate.component';
import { UserComponent } from '@shared/components/user/user.component';
import { ResetPasswordComponent } from '@modules/auth/components/reset-password/reset-password.component';
import { PinComponent } from './modules/pins/pin/pin.component';
import { PinsComponent } from './modules/pins/pins/pins.component';
import { VisibleComponent } from './modules/shops/components/visible/visible.component';
import { HighlightStrategiesComponent } from './modules/map/components/highlight-strategies/highlight-strategies.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'welcome',
    redirectTo: '',
  },
  {
    path: 'admin',
    loadChildren: () => import('@modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [CanNavigateToAdminGuard],
    canLoad: [CanNavigateToAdminGuard],
  },
  {
    path: 'impressum',
    component: ImprintComponent,
  },
  {
    path: 'datenschutz',
    component: DataPolicyComponent,
  },
  {
    path: 'highlights',
    component: HighlightStrategiesComponent,
  },
  {
    path: 'donate',
    component: DonateComponent,
  },
  {
    path: 'support',
    component: SupportComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  {
    path: 'near',
    component: NearComponent,
  },
  {
    path: 'nearme',
    redirectTo: 'near',
  },
  {
    path: 'countries',
    component: CountriesComponent,
  },
  {
    path: 'user',
    component: UserComponent,
  },
  {
    path: 'resetpassword/:userid/:key',
    component: ResetPasswordComponent,
  },
  {
    path: 'visible',
    component: VisibleComponent,
  },
  {
    path: 'pins',
    component: PinsComponent,
  },
  {
    path: 'pin/:id',
    component: PinComponent,
  },
  {
    // /[a-zA-Z][a-zA-Z]
    matcher: CountryMatcher,
    component: CountryComponent,
  },
  {
    // /[a-zA-Z][a-zA-Z]/[a-zA-Z][a-zA-Z]
    matcher: StateMatcher,
    component: StateComponent,
  },
  {
    // /[a-zA-Z][a-zA-Z]/[a-zA-Z][a-zA-Z][a-zA-Z]*
    matcher: CityMatcher,
    component: CityComponent, // RouterTargetComponent, //CityComponent,
  },
  {
    // /[0-9]*
    matcher: StoreMatcher,
    component: StoreComponent, // RouterTargetComponent, //StoreComponent,
    resolve: {
      store: StoreResolver,
    },
  },
  {
    path: ':query',
    component: SearchComponent,
    resolve: {
      exactsearch: ExactSearchResolver,
    },
  },
  {
    path: '**',
    component: HomeComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [StoreResolver, ExactSearchResolver],
})
export class AppRoutingModule {}
