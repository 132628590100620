/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import { SearchResult } from '../classes/search-results';
import * as SearchActions from './search.actions';

export const searchFeatureKey = 'search';

export type Prediction = google.maps.places.AutocompletePrediction;

export interface SearchState {
  searching: boolean;
  term: string;

  result: SearchResult | null;
  google: Prediction[] | null;
}

export const initialState: SearchState = {
  searching: false,
  term: '',

  result: null,
  google: null,
};

export const reducer = createReducer(
  initialState,

  on(SearchActions.clearSearch, (state) => ({
    ...state,
    result: null,
    google: null,
    searching: false,
    term: '',
  })),

  on(SearchActions.searchBackend, (state, action) => ({
    ...state,
    searching: action.term !== state.term && action.term !== '',
  })),
  on(SearchActions.searchBackendSuccess, (state, action) => ({
    ...state,
    searching: false,
    term: action.term,
    result: action.result,
    google: null,
  })),
  on(SearchActions.searchBackendFailure, (state, action) => ({
    ...state,
    searching: false,
  })),

  on(SearchActions.searchGoogle, (state, action) => ({
    ...state,
    searching: true,
    term: action.term,
  })),
  on(SearchActions.searchGoogleSuccess, (state, action) => ({
    ...state,
    searching: false,
    google: action.result,
    result: null,
  })),
  on(SearchActions.searchGoogleFailure, (state, action) => ({
    ...state,
    searching: false,
  }))
);
