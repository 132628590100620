export class Link {
  icon = '';
  href = '';
  text = '';
  css = '';
  order = 0;
  valid = false;
  hostname = '';
}

export class LinkHelper {
  static mail2link(mail: string): Link {
    return {
      icon: 'faMail',
      href: 'mailto:' + mail,
      text: mail,
      css: 'mail',
      order: 1,
      valid: true,
      hostname: '',
    };
  }

  static tel2link(tel: string): Link {
    return {
      icon: 'faPhone',
      href: 'tel:' + tel,
      text: tel,
      css: 'phone',
      order: 0,
      valid: true,
      hostname: tel,
    };
  }

  static url2link(url: string): Link {
    const l = document.createElement('a');
    l.href = url;

    let result = {
      icon: 'faGlobe',
      href: url,
      text: l.hostname,
      css: 'link',
      order: 2,
      valid: true,
      hostname: l.hostname,
    };

    if (this.isDomain(url, 'facebook')) {
      result = {
        ...result,
        icon: 'faFacebook',
        text: 'Facebook',
        css: 'facebook',
        order: 3,
      };
    }

    if (this.isDomain(url, 'instagram')) {
      const usr = l.pathname.split('/');
      result = {
        ...result,
        icon: 'faInstagram',
        text: 'Instagram',
        css: 'instagram',
        order: 4,
      };
      result.text = usr.length > 1 && usr.join('') === usr[1] ? '@' + usr[1] : 'Instagram';
    }

    if (this.isDomain(url, 'twitter')) {
      result = {
        ...result,
        icon: 'faTwitter',
        text: 'Twitter',
        css: 'twitter',
        order: 5,
      };
    }

    if (this.isDomain(url, 'discogs')) {
      result = {
        ...result,
        icon: 'faDiscogs',
        text: 'Discogs',
        css: 'discogs',
        order: 6,
      };
      //result.valid = false;
    }

    if (this.isDomain(url, 'ebay') || this.isDomain(url, 'vinylhub')) {
      result.valid = false;
    }

    return result;
  }

  static isDomain(url: string, domain: string): boolean {
    return url.indexOf('.' + domain + '.') > 0 || url.indexOf('//' + domain + '.') > 0;
  }
}
