import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { closeRegisterDialogAction, registerAction } from '@app/modules/auth/store/auth.actions';
import { selectAuthenticating } from '@app/modules/auth/store/auth.selectors';
import { DialogsService } from '@core/services/dialogs.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'rs-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit {
  authenticating$ = this.store.select(selectAuthenticating);

  faSpinnerIcon = faSpinner;

  form: FormGroup = this.fb.group(
    {
      username: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(20)],
        this.validateUsernameNotTaken.bind(this),
      ],
      email: ['', [Validators.required, Validators.email], this.validateEmailNotTaken.bind(this)],
      password: ['', Validators.required],
      password2: ['', Validators.required],
    },
    { validators: this.validatePasswordsEqual.bind(this) }
  );

  constructor(
    private authService: AuthService,
    private dialogs: DialogsService,
    private fb: FormBuilder,
    private store: Store
  ) {}

  ngOnInit(): void {}

  validateEmailNotTaken(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.authService.existsEmail(control.value).pipe(map((res) => (res ? { emailTaken: true } : null)));
  }
  validateUsernameNotTaken(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.authService.existsUsername(control.value).pipe(map((res) => (res ? { usernameTaken: true } : null)));
  }

  validatePasswordsEqual(control: AbstractControl): ValidationErrors | null {
    const p1 = control.get('password')?.value;
    const p2 = control.get('password2')?.value;
    if (p1 === p2) {
      control.get('password2')?.setErrors(null);
      return null;
    } else {
      control.get('password2')?.setErrors({ passwordEquality: false });
      return { passwordEquality: false };
    }
  }

  submitForm() {
    const email = this.form.value.email;
    const username = this.form.value.username;
    const password = this.form.value.password;

    if (email !== '' && username !== '' && password !== '') {
      this.store.dispatch(registerAction({ email, username, password }));
    } else {
      this.dialogs.openMessageDialog('Sign Up Failure', 'Please enter a valid email, user name and password');
    }
  }

  loginWithGoogle() {
    this.close();
    this.authService.showLoginWithGoogle();
  }

  close() {
    this.form.reset();
    this.store.dispatch(closeRegisterDialogAction());
  }
}
