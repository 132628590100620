import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelperService, HOURSUNKNOWN } from '@app/core/services/helper.service';
import { selectUser } from '@app/modules/auth/store/auth.selectors';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { Pin } from '@app/shared/classes';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { faFacebookSquare, faInstagramSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import {
  faPen,
  faPhone,
  faEnvelope as faMail,
  faGlobeAmericas as faGlobe,
  faMapMarkerAlt as faMarker,
  faStar,
  faRoute,
  faMapMarkedAlt,
  faHeart as faHeartSolid,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { faClock, faLifeRing } from '@fortawesome/free-regular-svg-icons';
import { Link, LinkHelper } from '@app/shared/helpers/link.helper';
import { AppStateService } from '@app/core/services/app-state.service';
import { setActiveMarker2Pin, zoomToStore } from '@app/modules/map/store/map.actions';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
})
export class PinComponent extends Unsubscriber implements OnInit {
  user$ = this.store.select(selectUser);
  position$ = this.store.select(selectPosition);
  pin$ = new BehaviorSubject<Pin | undefined>(undefined);

  distance: number | undefined = undefined;
  direction: string | undefined = undefined;
  additional = { routerLink: '/pins', caption: 'show all Pins' };

  links: Link[] = [];
  hours = '';
  hoursunknown = true;

  icons = {
    faFacebook: faFacebookSquare,
    faGlobe,
    faInstagram: faInstagramSquare,
    faMail,
    faPen,
    faPhone,
    faTwitter: faTwitterSquare,
    faMarker,
    faClock,
    faStar,
    faGmaps: faMapMarkedAlt,
    faRoute,
    faLifeRing,
    faHeart: faHeartSolid,
    faSpinner,
    faTrash,
  };

  constructor(
    private appState: AppStateService,
    public helper: HelperService,
    private route: ActivatedRoute,
    private store: Store
  ) {
    super();

    this.unsubscribeLater(
      combineLatest([this.route.paramMap, this.user$]).subscribe(([params, user]) => {
        const id = parseInt(params.get('id') ?? '', 10);
        if (user && id > 0 && !isNaN(id)) {
          this.pin$.next(user.pins.find((pin) => pin.id === id));
        } else {
          this.pin$.next(undefined);
        }
      })
    );
  }

  ngOnInit(): void {
    this.unsubscribeLater(
      combineLatest([this.position$, this.pin$]).subscribe(([pos, pin]) => {
        if (pos && pin) {
          const d = PositionHelpers.getDistanceBetween(pos, pin);
          if (d < 1000) {
            this.distance = d;
            this.direction = PositionHelpers.getDirection(pos, pin);
          } else {
            this.distance = undefined;
            this.direction = undefined;
          }
        }
      }),

      this.pin$.subscribe((pin) => this.initPin(pin))
    );
  }

  initPin(pin: Pin | undefined) {
    if (pin) {
      this.appState.setActiveTitle(pin.name + ' / ' + pin.city + ' on recordstores.love');
      this.hours = this.helper.getShortTimesString(pin);
      this.hoursunknown = this.hours === HOURSUNKNOWN;

      this.links = [];
      if (pin.phone) {
        this.links.push(LinkHelper.tel2link(pin.phone));
      }
      if (pin.email) {
        this.links.push(LinkHelper.mail2link(pin.email));
      }
      if (pin.website) {
        this.links.push(LinkHelper.url2link(pin.website));
      }
      if (pin.ebay) {
        this.links.push(LinkHelper.url2link(pin.ebay));
      }
      if (pin.discogs) {
        this.links.push(LinkHelper.url2link(pin.discogs));
      }
      if (pin.facebook) {
        this.links.push(LinkHelper.url2link(pin.facebook));
      }

      this.links = this.links.filter((x) => x.valid);
      this.links.sort((a, b: Link) => a.order - b.order);

      this.store.dispatch(setActiveMarker2Pin({ position: pin, pinId: pin.id }));
      this.store.dispatch(zoomToStore({ position: pin }));
    }
  }
}
