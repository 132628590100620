import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { selectUser } from '@app/modules/auth/store/auth.selectors';
import { setActiveMarker2Pin } from '@app/modules/map/store/map.actions';
import { selectStoresVisible } from '@app/modules/map/store/map.selectors';
import { Pin } from '@app/shared/classes';
import { MarkerDefinitions } from '@app/shared/helpers/marker-definitions';
import { Store } from '@ngrx/store';
import { BehaviorSubject, first } from 'rxjs';
import { combineLatest } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { MapService } from './map.service';

interface PinObject {
  id: number;
  marker: google.maps.Marker;
}

@Injectable({
  providedIn: 'root',
})
export class PinsService {
  user$ = this.store.select(selectUser);
  storesVisible$ = this.store.select(selectStoresVisible);
  layerVisible$ = new BehaviorSubject<boolean>(this.storage.get('MAP.PinsLayer') ?? true);
  pinsVisible = false;

  map: google.maps.Map | null = null;

  pinObjects: PinObject[] = [];

  constructor(
    private mapService: MapService,
    private router: Router,
    private storage: LocalStorageService,
    private store: Store
  ) {
    this.mapService.map$.pipe(first((x) => !!x)).subscribe((map) => this.initMap(map));
  }

  showPinsLayer(on: boolean) {
    this.layerVisible$.next(on);
    this.storage.set('MAP.PinsLayer', on);
  }

  getPinIcon(colour: string) {
    switch (colour) {
      case 'black':
        return MarkerDefinitions.PIN_BLACK_MARKER;
        break;
      case 'blue':
        return MarkerDefinitions.PIN_BLUE_MARKER;
        break;
      case 'gray':
        return MarkerDefinitions.PIN_GRAY_MARKER;
        break;
      case 'green':
        return MarkerDefinitions.PIN_GREEN_MARKER;
        break;
      case 'orange':
        return MarkerDefinitions.PIN_ORANGE_MARKER;
        break;
      case 'red':
        return MarkerDefinitions.PIN_RED_MARKER;
        break;
      case 'violet':
        return MarkerDefinitions.PIN_VIOLET_MARKER;
        break;
      case 'yellow':
        return MarkerDefinitions.PIN_YELLOW_MARKER;
        break;
    }
    return MarkerDefinitions.PIN_BLUE_MARKER;
  }

  private initMap(map: google.maps.Map | null) {
    if (map) {
      this.map = map;
      this.user$.subscribe((u) => this.initPins(u?.pins));
      combineLatest([this.storesVisible$, this.layerVisible$]).subscribe(([storesVisible, layerVisible]) =>
        this.showPins(storesVisible && layerVisible)
      );
    }
  }

  private clearPins() {
    this.pinObjects.forEach((po) => po.marker.setMap(null));
    this.pinObjects = [];
  }

  private showPins(show: boolean) {
    if (this.pinsVisible !== show) {
      this.pinsVisible = show;
      this.pinObjects.forEach((po) => po.marker.setMap(show ? this.map : null));
    }
  }

  private initPins(pins: Pin[] | undefined) {
    this.clearPins();

    if (pins) {
      pins.forEach((pin) => {
        this.pinObjects.push({
          id: pin.id,
          marker: this.initPin(pin),
        });
      });
    }
  }

  private initPin(pin: Pin) {
    const markerOptions = this.getPinIcon(pin.colour);

    const opt = {
      ...markerOptions,
      map: this.pinsVisible ? this.map : null,
      position: new google.maps.LatLng(pin.lat, pin.lng),
      title: pin.name,
      visible: true,
    };

    const marker = new google.maps.Marker(opt);

    marker.addListener('click', () => {
      this.router.navigateByUrl(`/pin/${pin.id}?view=map`);
      this.store.dispatch(setActiveMarker2Pin({ position: pin, pinId: pin.id }));
    });
    return marker;
  }
}
