import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DialogsService } from '@core/services/dialogs.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { closeLoginDialogAction, loginAction } from '@app/modules/auth/store/auth.actions';
import { selectAuthenticating } from '@app/modules/auth/store/auth.selectors';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'rs-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: [],
})
export class LoginFormComponent implements OnInit {
  authenticating$ = this.store.select(selectAuthenticating);

  faSpinnerIcon = faSpinner;

  form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  constructor(
    private authService: AuthService,
    private dialogs: DialogsService,
    private fb: FormBuilder,
    private store: Store
  ) {}

  ngOnInit(): void {}

  submitForm() {
    const email = this.form.value.email;
    const password = this.form.value.password;

    if (email !== '' && password !== '') {
      this.store.dispatch(loginAction({ email, password }));
    } else {
      this.dialogs.openMessageDialog('Login Failure', 'Please enter a valid email and a password');
    }
  }

  loginWithGoogle() {
    //this.close();
    this.authService.showLoginWithGoogle();
  }

  close() {
    this.form.reset();
    this.store.dispatch(closeLoginDialogAction());
  }

  forgotPassword() {
    const email = this.form.value.email;
    if (this.form.get('email')?.invalid || email === '') {
      this.dialogs.openMessageDialog('Forgot Password', 'Please enter a valid email address.');
    } else {
      this.close();
      this.authService.forgotPassword(email).subscribe(
        (x) =>
          this.dialogs.openMessageDialog(
            'Forgot Password',
            'Please check your emails and follow the instructions to set a new password.'
          ),
        () =>
          this.dialogs.openMessageDialog(
            'Forgot Password',
            'It looks like you have never registered an account with this email.'
          )
      );
    }
  }
}
