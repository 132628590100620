import { AppTarget } from '@core/services/app-state.service';
import { createReducer, on } from '@ngrx/store';
import * as GlobalActions from './global.actions';

export const globalFeatureKey = 'global';

export interface GlobalState {
  activeDiv: AppTarget | null;
  loading: number;
  needsMap: boolean;
  viewMap: boolean;
  isMobile: boolean;
}

export const initialState: GlobalState = {
  activeDiv: null,
  loading: 0,
  needsMap: false,
  viewMap: false,
  isMobile: true,
};

export const reducer = createReducer(
  initialState,

  on(GlobalActions.beginLoading, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(GlobalActions.endLoading, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : 0,
  })),

  on(GlobalActions.activateHomeDiv, (state) => ({
    ...state,
    activeDiv: 'HOME' as AppTarget,
  })),
  on(GlobalActions.activateNearDiv, (state) => ({
    ...state,
    activeDiv: 'NEAR' as AppTarget,
  })),
  on(GlobalActions.activateUserDiv, (state) => ({
    ...state,
    activeDiv: 'USER' as AppTarget,
  })),
  on(GlobalActions.activateVisibleDiv, (state) => ({
    ...state,
    activeDiv: 'VISIBLE' as AppTarget,
  })),
  on(GlobalActions.activateSearchDiv, (state) => ({
    ...state,
    activeDiv: 'SEARCH' as AppTarget,
  })),
  on(GlobalActions.activateOtherDiv, (state) => ({
    ...state,
    activeDiv: 'OTHER' as AppTarget,
  })),

  on(GlobalActions.activateCountriesDiv, (state) => ({
    ...state,
    activeDiv: 'COUNTRIES' as AppTarget,
  })),
  on(GlobalActions.activateCountryDiv, (state) => ({
    ...state,
    activeDiv: 'COUNTRY' as AppTarget,
  })),
  on(GlobalActions.activateStateDiv, (state) => ({
    ...state,
    activeDiv: 'STATE' as AppTarget,
  })),
  on(GlobalActions.activateCityDiv, (state) => ({
    ...state,
    activeDiv: 'CITY' as AppTarget,
  })),
  on(GlobalActions.activateStoreDiv, (state) => ({
    ...state,
    activeDiv: 'STORE' as AppTarget,
  })),

  on(GlobalActions.setIsMobile, (state, action) => ({
    ...state,
    isMobile: action.isMobile,
  })),
  on(GlobalActions.needsMap, (state) => ({ ...state, needsMap: true })),
  on(GlobalActions.setViewMap, (state, action) => ({
    ...state,
    viewMap: action.value,
  }))
);
