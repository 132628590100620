export interface ShopContact {
  email: string | null;
  phone: string | null;
  website: string | null;
  discogs: string | null;
  ebay: string | null;
  facebook: string | null;
}

export interface ShopContactRaw {
  email: string | null;
  phone: string | null;
  website: string | null;
  discogs: string | null;
  ebay: string | null;
  facebook: string | null;
}

export class ShopContactFactory {
  static new(): ShopContact {
    return {
      email: '',
      phone: '',
      website: '',
      discogs: '',
      ebay: '',
      facebook: '',
    };
  }
  static fromRaw(raw: ShopContactRaw): ShopContact {
    return {
      // nicht ...raw um überflüssige Felder nicht mitzunehmen
      email: raw.email,
      phone: raw.phone,
      website: raw.website,
      discogs: raw.discogs,
      ebay: raw.ebay,
      facebook: raw.facebook,
    };
  }
}
