import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'rs-simple-item',
  templateUrl: './simple-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./simple-item.component.scss'],
})
export class SimpleItemComponent implements OnInit {
  @Input() icon?: string;
  @Input() faIcon?: IconDefinition;
  @Input() iconClass?: string;
  @Input() title!: string;
  @Input() noselect? = true;

  constructor() {}

  ngOnInit(): void {}
}
