/* eslint-disable @typescript-eslint/naming-convention */
import { Md5 } from 'ts-md5';
import { ImageFactory } from './image';
import { ShopBasics, ShopBasicsRaw, ShopMediumData, ShopMediumDataRaw } from './shop-basics';
import { ShopDetails, ShopDetailsRaw } from './shop-details';
import { ShopAddressFactory } from './shop-address';
import { ShopContactFactory } from './shop-contact';
import { ShopTimesFactory } from './shop-times';
import { ShopVisit, ShopVisitRaw } from './user';
import { ShopEssentialsFactory } from './shop-essentials';
import { ImageDetailsFactory } from './image-details';

export class ShopFactory {
  static new(): ShopDetails {
    return {
      ...ShopEssentialsFactory.new(),
      ...ShopAddressFactory.new(),
      ...ShopContactFactory.new(),
      ...ShopTimesFactory.new(),

      hasclosed: false,
      supporter: false,
      imageid: null,
      images: [],
      adminimages: [],
    };
  }

  static fromRawDetails(raw: ShopDetailsRaw): ShopDetails {
    const result: ShopDetails = {
      ...raw,
      ...ShopEssentialsFactory.fromRaw(raw),
      ...ShopAddressFactory.fromRaw(raw),
      ...ShopContactFactory.fromRaw(raw),
      ...ShopTimesFactory.fromRaw(raw),

      hasclosed: raw.hasclosed === true || raw.hasclosed === '1',
      supporter: raw.supporter === true || raw.supporter === '1',
      imageid: raw.imageid === null ? null : parseInt(raw.imageid, 10),
      images: ImageFactory.fromRawArray(raw.images),
      adminimages: ImageDetailsFactory.fromRawArray(raw.adminimages),
    };

    return result;
  }

  static fromRawBasic(raw: ShopBasicsRaw): ShopBasics {
    return {
      ...raw,
      id: parseInt(raw.id, 10),
      hasclosed: raw.hasclosed ? raw.hasclosed === true || raw.hasclosed === '1' : false,
      supporter: raw.supporter === '1' || raw.supporter === true,
      imageid: raw.imageid === null ? null : parseInt(raw.imageid, 10),
      lat: parseFloat(raw.lat),
      lng: parseFloat(raw.lng),

      // die folgenden Daten werden erst mit getMedium geladen
      isMedium: false,
      address: '',

      today: null,
      todayfrom: null,
      todayuntil: null,
      todayclosed: null,
    };
  }

  static fromRawMedium(raw: ShopMediumDataRaw): ShopMediumData {
    return {
      ...raw,
      id: parseInt(raw.id, 10),
      todayclosed: raw.todayclosed === null ? null : raw.todayclosed === '1',
    };
  }

  static fromDetailsToBasics(shop: ShopDetails): ShopBasics {
    return {
      id: shop.id,
      name: shop.name,
      hasclosed: shop.hasclosed,
      supporter: shop.supporter,
      imageid: shop.imageid,
      placeidh:
        shop.place_id === undefined || shop.place_id === null ? null : Md5.hashStr(shop.place_id, false).toString(),

      lat: shop.lat,
      lng: shop.lng,

      countrycode: shop.countrycode,
      state: shop.countrycode === 'US' ? shop.state : null,
      city: shop.city,

      // im Folgenden alles, was erst mit Medium geladen wird
      isMedium: false,

      address: shop.address,

      today: null,
      todayfrom: null,
      todayuntil: null,
      todayclosed: null,
    };
  }

  static fromRawVisit(raw: ShopVisitRaw): ShopVisit {
    return {
      id: parseInt(raw.id, 10),
      shopid: parseInt(raw.shopid, 10),
      visit: new Date(raw.visit),
    };
  }
  static fromRawVisits(raw: ShopVisitRaw[]): ShopVisit[] {
    return raw.map((x) => ({
      id: parseInt(x.id, 10),
      shopid: parseInt(x.shopid, 10),
      visit: new Date(x.visit),
    }));
  }
}
