import { HttpErrorResponse } from '@angular/common/http';
import { ImageDetails, ImageUpdateDetails } from '@app/shared/classes/image-details';
import { createAction, props } from '@ngrx/store';
import { ShopBasics, ShopDetails, ShopMediumData } from '@shared/classes';

export const noOpAction = createAction('[Shop] No Op');
export const initApp = createAction('[Shop] Initialize Application');

export const loadSupporters = createAction('[Shop] Load Supporters');
export const loadSupportersSuccess = createAction('[Shop] Load Supporters Success', props<{ stores: ShopBasics[] }>());
export const loadSupportersFailure = createAction(
  '[Shop] Load Supporters Failure',
  props<{ error: HttpErrorResponse }>()
);

export const loadStoresSlice = createAction('[Shop] Load Stores Slice', props<{ lng: number }>());
export const loadStoresSliceSuccess = createAction(
  '[Shop] Load Stores Slice Success',
  props<{ lng: number; stores: ShopBasics[] }>()
);
export const loadStoresSliceFailure = createAction(
  '[Shop] Load Stores Slice Failure',
  props<{ lng: number; error: HttpErrorResponse }>()
);

// tryLoad wird aufgerufen, wenn man noch prüfen lassen möchte, ob Medium nicht vielleicht gerade geladen wird.
export const tryLoadMediumStores = createAction(
  '[Shop] Try Load Medium Stores',
  props<{ stores: ShopBasics[]; from: string }>()
);
export const loadMediumStores = createAction(
  '[Shop] Load Medium Stores',
  props<{ stores: ShopBasics[]; from: string }>()
);
export const loadMediumStoresSuccess = createAction(
  '[Shop] Load Medium Stores Success',
  props<{ stores: ShopMediumData[] }>()
);
export const loadMediumStoresFailure = createAction(
  '[Shop] Load Medium Stores Failure',
  props<{ error: HttpErrorResponse }>()
);

// nach einer Google-Suche:
export const loadStoresByPlaceId = createAction('[Shop] Load Stores by PlaceID', props<{ placeids: string[] }>());
export const loadStoresByPlaceIdSuccess = createAction(
  '[Shop] Load Stores by PlaceID Success',
  props<{ stores: ShopBasics[] }>()
);
export const loadStoresByPlaceIdError = createAction(
  '[Shop] Load Stores by PlaceID Error',
  props<{ error: HttpErrorResponse }>()
);

export const loadStore = createAction('[Shop] Load Store', props<{ id: number }>());
export const loadStoreSuccess = createAction('[Shop] Load Store Success', props<{ store: ShopDetails | null }>());
export const loadStoreFailure = createAction('[Shop] Load Store Failure', props<{ error: HttpErrorResponse }>());

export const loadAllStores = createAction('[Shop] Load All Stores');
export const loadAllStoresSuccess = createAction('[Shop] Load All Stores Success', props<{ stores: ShopBasics[] }>());
export const loadAllStoresFailure = createAction(
  '[Shop] Load All Stores Failure',
  props<{ error: HttpErrorResponse }>()
);

export const saveStore = createAction('[Shop] Save Store', props<{ store: ShopDetails; note: string }>());
export const saveStoreSuccess = createAction(
  '[Shop] Save Store Success',
  props<{ store: ShopDetails; isNew: boolean }>()
);
export const saveStoreFailure = createAction('[Shop] Save Store Failure', props<{ error: HttpErrorResponse }>());

export const updateImage = createAction('[Shop] Update Image', props<{ payload: ImageUpdateDetails; note: string }>());
export const updateImageSuccess = createAction('[Shop] Update Image Success', props<{ image: ImageDetails }>());
export const updateImageFailure = createAction('[Shop] Update Image Failure', props<{ error: HttpErrorResponse }>());

export const setDetailedStore = createAction('[Shop] Set Detailed Store', props<{ store: ShopDetails }>());
export const setActiveStoreID = createAction('[Shop] Set Active StoreID', props<{ id: number }>());

export const addStore = createAction('[Shop] Add Store', props<{ store: ShopBasics }>());

export const setNearPlaces = createAction(
  '[Shop] Set Near Places',
  props<{ places: google.maps.places.PlaceResult[] }>()
);
export const setPlaceAsShop = createAction('[Shop] Set Place As Shop', props<{ placeAsShop: ShopDetails }>());
