import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxKjuaModule } from 'ngx-kjua';

import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SharingComponent } from './components/sharing/sharing.component';
import { LikeButtonsComponent } from './components/like-buttons/like-buttons.component';
import {
  BadgeItemComponent,
  CityItemComponent,
  CountryItemComponent,
  PlaceItemComponent,
  SimpleItemComponent,
  StateItemComponent,
  TitleSubtitleItemComponent,
  SupportersListComponent,
} from './components/lists';
import { UserComponent } from './components/user/user.component';
import { UserImageComponent } from './components/user-image/user-image.component';
import { SupportFormComponent } from './components/support-form/support-form.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '@app/modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PinItemComponent } from './components/lists/pin-item/pin-item.component';
import { PinsListComponent } from './components/lists/pins-list/pins-list.component';
import { AddressFormComponent } from './forms/address-form/address-form.component';
import { ContactFormComponent } from './forms/contact-form/contact-form.component';
import { TimesFormComponent } from './forms/times-form/times-form.component';
import { ShopItemComponent } from './components/lists/shop-item/shop-item.component';
import { ShopsListComponent } from './components/shops-list/shops-list.component';
import { VisitsComponent } from './components/visits/visits.component';
import { VisitsInStoreComponent } from './components/visits-in-store/visits-in-store.component';
import { ContactAndCoffeeComponent } from './components/contact-and-coffee/contact-and-coffee.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    ClickStopPropagationDirective,
    LikeButtonsComponent,
    BadgeItemComponent,
    CityItemComponent,
    CountryItemComponent,
    PlaceItemComponent,
    SimpleItemComponent,
    StateItemComponent,
    TitleSubtitleItemComponent,
    SupportersListComponent,
    SafeUrlPipe,
    SharingComponent,
    SupportFormComponent,
    UserComponent,
    UserImageComponent,
    VisitsComponent,
    PinItemComponent,
    PinsListComponent,
    AddressFormComponent,
    ContactFormComponent,
    TimesFormComponent,
    ShopItemComponent,
    ShopsListComponent,
    VisitsInStoreComponent,
    ContactAndCoffeeComponent,
    QrCodeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxKjuaModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxKjuaModule,

    BreadcrumbComponent,
    ClickStopPropagationDirective,
    LikeButtonsComponent,
    BadgeItemComponent,
    CityItemComponent,
    CountryItemComponent,
    PinItemComponent,
    PinsListComponent,
    PlaceItemComponent,
    SimpleItemComponent,
    StateItemComponent,
    ShopItemComponent,
    TitleSubtitleItemComponent,
    SupportersListComponent,
    SharingComponent,
    ShopsListComponent,
    SupportFormComponent,
    UserComponent,
    UserImageComponent,
    VisitsComponent,
    VisitsInStoreComponent,
    AddressFormComponent,
    ContactFormComponent,
    TimesFormComponent,
    ContactAndCoffeeComponent,
    QrCodeComponent,
  ],
})
export class SharedModule {}
