import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { StoreModule } from '@ngrx/store';
import * as fromAuth from './store/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth.effects';

import { DialogsModule } from '@app/modules/dialogs/dialogs.module';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [LoginFormComponent, RegisterFormComponent, ResetPasswordComponent],
  exports: [ResetPasswordComponent],
  imports: [
    BrowserModule,
    DialogsModule,
    SharedModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
})
export class AuthModule {}
