import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { setActiveMarker2Place, setActiveMarker2Pos, zoomToStore } from '@app/modules/map/store/map.actions';
import { GooglePlacesService } from '@core/services/google-places.service';
import { selectIsMobile } from '@app/modules/global/store/global.selectors';
import { AppStateService } from '@app/core/services/app-state.service';

@Component({
  selector: 'rs-place',
  templateUrl: './place.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [],
})
export class PlaceComponent implements OnChanges {
  @Input() place!: google.maps.places.PlaceResult;

  mobile$ = this.store.select(selectIsMobile);

  oldPlace: google.maps.places.PlaceResult | null = null;

  constructor(
    private appState: AppStateService,
    private googlePlacesService: GooglePlacesService,
    private store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.initPlace(this.place);
  }

  addPlaceAsStore() {
    if (this.place.place_id) {
      this.googlePlacesService.addStoreFromPlaceIcon(this.place.place_id);
    } else if (this.place.geometry && this.place.geometry.location) {
      const position = {
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      };
      this.googlePlacesService.addStoreFromPosition(position);
    } else {
      console.log('problem: no placeid, no geometry');
    }
  }

  addPlaceAsPin() {
    if (this.place.place_id) {
      this.googlePlacesService.addPinFromPlaceIcon(this.place.place_id);
    } else if (this.place.geometry && this.place.geometry.location) {
      const position = {
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      };
      this.googlePlacesService.addPinFromPosition(position);
    } else {
      console.log('problem: no placeid, no geometry');
    }
  }

  showOnMap() {
    this.initPlace(this.place);
    this.appState.gotoMap();
  }

  private initPlace(place: google.maps.places.PlaceResult | null) {
    if (place?.geometry?.location && place !== this.oldPlace) {
      const position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.store.dispatch(zoomToStore({ position }));
      this.store.dispatch(
        place.place_id
          ? setActiveMarker2Place({ position, placeId: place.place_id })
          : setActiveMarker2Pos({ position })
      );
      this.oldPlace = place;
    }
  }
}
