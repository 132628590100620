import { Component, OnInit } from '@angular/core';
import { activateOtherDiv } from '@app/modules/global/store/global.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-donate',
  templateUrl: './donate.component.html',
  styleUrls: [],
})
export class DonateComponent implements OnInit {
  additional = { routerLink: '/support', caption: 'Click here to Support' };

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(activateOtherDiv());
  }
}
