import { Country } from '@shared/classes';

export class IconsHelper {
  constructor() {}

  static getStoreIconPath() {
    return '/assets/markers/diskpin24.png';
  }

  static getCityIconPath(opencnt: number): string {
    if (opencnt === 1) {
      return '/assets/markers/red_20_03.png';
    }
    if (opencnt === 2) {
      return '/assets/markers/red_20_05.png';
    }
    if (opencnt === 3) {
      return '/assets/markers/red_20_07.png';
    }
    if (opencnt <= 5) {
      return '/assets/markers/red_20_09.png';
    }
    if (opencnt < 10) {
      return '/assets/markers/red_20_11.png';
    }
    if (opencnt < 15) {
      return '/assets/markers/red_20_13.png';
    }
    if (opencnt < 25) {
      return '/assets/markers/red_20_15.png';
    }
    if (opencnt < 50) {
      return '/assets/markers/red_20_17.png';
    }
    return '/assets/markers/red_20_19.png';
  }

  static getStateIconPath(opencnt: number): string {
    if (opencnt === 1) {
      return '/assets/markers/red_20_03.png';
    }
    if (opencnt < 3) {
      return '/assets/markers/red_20_05.png';
    }
    if (opencnt < 5) {
      return '/assets/markers/red_20_07.png';
    }
    if (opencnt < 10) {
      return '/assets/markers/red_20_09.png';
    }
    if (opencnt < 25) {
      return '/assets/markers/red_20_11.png';
    }
    if (opencnt < 50) {
      return '/assets/markers/red_20_13.png';
    }
    if (opencnt < 75) {
      return '/assets/markers/red_20_15.png';
    }
    if (opencnt < 100) {
      return '/assets/markers/red_20_17.png';
    }
    return '/assets/markers/red_20_19.png';
  }

  static getCountryIconPath(country: Country): string {
    return '/assets/markers/flags/24x24/' + country.countrycode + '.png';

    if (country.opencnt === 1) {
      return '/assets/markers/red_20_03.png';
    }
    if (country.opencnt < 5) {
      return '/assets/markers/red_20_05.png';
    }
    if (country.opencnt < 10) {
      return '/assets/markers/red_20_07.png';
    }
    if (country.opencnt < 25) {
      return '/assets/markers/red_20_09.png';
    }
    if (country.opencnt < 50) {
      return '/assets/markers/red_20_11.png';
    }
    if (country.opencnt < 100) {
      return '/assets/markers/red_20_13.png';
    }
    if (country.opencnt < 250) {
      return '/assets/markers/red_20_15.png';
    }
    if (country.opencnt < 500) {
      return '/assets/markers/red_20_17.png';
    }
    return '/assets/markers/red_20_19.png';
  }
}
