<!-- fullscreen bar -->
<div [class.display-none]="!fullscreen" class="fs-container" (click)="exitFullScreen($event)">
  <i title="Back" class="arrow-exitfs prev"></i>
</div>
<div #container class="slideshow-container" [class.slideshow-container-fs]="fullscreen">
  <!-- slides -->
  <a
    *ngFor="let slide of slides; index as i; trackBy: trackByFn"
    class="slides"
    href="#!"
    tabindex="-1"
    title="{{ slide?.image?.title }}"
    [ngStyle]="getSlideStyle(i)"
    (click)="handleClick($event)"
    [class.selected]="slide?.selected"
    [class.hide-slide]="!slide?.selected && !slide?.leftSide && !slide?.rightSide"
    [class.left-side]="slide?.leftSide"
    [class.right-side]="slide?.rightSide"
    [class.slide-in-left]="slide?.action === 'slideInLeft'"
    [class.slide-in-right]="slide?.action === 'slideInRight'"
    [class.slide-out-left]="slide?.action === 'slideOutLeft'"
    [class.slide-out-right]="slide?.action === 'slideOutRight'"
    [class.fade-in]="slide?.action === 'fadeIn'"
    [class.fade-out]="slide?.action === 'fadeOut'"
    [class.link]="slide?.image?.clickAction || slide?.image?.href"
  >
    <div class="loader" *ngIf="!slide?.loaded"></div>
    <div
      *ngIf="showCaptions && slide?.image?.caption"
      class="caption"
      [ngStyle]="{
        color: captionColor,
        'background-color': captionBackground
      }"
      [innerHTML]="slide?.image?.caption"
    ></div>
  </a>
  <!-- left arrow -->
  <div [class.display-none]="!showArrows || hideLeftArrow" (click)="onSlide(-1)" class="arrow-container prev">
    <i #prevArrow title="Previous" class="arrow prev"></i>
  </div>
  <!-- right arrow -->
  <div [class.display-none]="!showArrows || hideRightArrow" (click)="onSlide(1)" class="arrow-container next">
    <i #nextArrow title="Next" class="arrow next"></i>
  </div>
  <!-- dots -->
  <ul class="slick-dots" *ngIf="showDots">
    <li
      *ngFor="let slide of slides; index as i; trackBy: trackByFn"
      (click)="goToSlide(i)"
      [class.slick-active]="slide.selected"
    >
      <button type="button" [attr.style]="safeStyleDotColor">
        {{ i }}
      </button>
    </li>
  </ul>
</div>
