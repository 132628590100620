<div class="rs-store-form rs-form">
  <div *ngIf="reason === 'editClosed.permanently_closed'">
    <h2>Mark this store as permanently closed</h2>
    <p>You're suggesting that this store shall be shown as permanently closed.</p>
  </div>
  <div *ngIf="reason === 'editClosed.moved'">
    <h2>This store moved to a new location</h2>
    <p>You're suggesting that this store has closed this location and moved somewhere else.</p>
  </div>
  <div *ngIf="reason === 'editClosed.doesnt_exist'">
    <h2>This store doesn't exist</h2>
    <p>
      You're suggesting that this store shall be removed because it isn't real, doesn't exist here or never existed.
    </p>
  </div>
  <div *ngIf="reason === 'editClosed.duplicate'">
    <h2>This store is a duplicate of another store</h2>
    <p>
      You're suggesting that this store shall be removed because it appears more than once, and this is the wrong
      location.
    </p>
  </div>
  <div *ngIf="reason === 'editClosed.other'">
    <h2>Mark this store as "just not here"</h2>
    <p>You're suggesting that this store shall be removed for some other reason.</p>
  </div>

  <form [formGroup]="storeForm" (ngSubmit)="submitForm()">
    <p>
      <mat-dialog-content>
        <mat-form-field>
          <textarea
            matInput
            formControlName="descript"
            placeholder="Do you have any more info? Maybe a new address?"
            autofocus
          >
          </textarea>
          <mat-error *ngIf="storeForm.get('descript')?.invalid && storeForm.get('descript')?.touched"
            >What do you know? If the store moved, do you have the new address?</mat-error
          >
        </mat-form-field>

        <mat-form-field class="mt-3">
          <input matInput formControlName="name" placeholder="Your Name (optional)" class="rs-bold" />
          <mat-error *ngIf="storeForm.get('name')?.invalid && storeForm.get('name')?.touched"
            >Please enter your name.</mat-error
          >
        </mat-form-field>

        <mat-form-field class="mt-3">
          <input matInput formControlName="email" placeholder="Your Email (optional)" type="email" />
          <mat-error *ngIf="storeForm.get('email')?.invalid && storeForm.get('email')?.touched"
            >Please enter your email so we can contact you for questions.</mat-error
          >
        </mat-form-field>

        <div style="text-align: right" class="mt-3" *ngIf="!sent && !sending">
          <input type="button" value="Cancel" class="mat-button rs-alt-button" (click)="close()" />
          <input
            type="submit"
            value="Save Changes"
            class="mat-flat-button rs-button"
            [class.disabled]="!storeForm.valid"
            [disabled]="!storeForm.valid"
          />
        </div>
      </mat-dialog-content>
    </p>
  </form>

  <p *ngIf="sending" class="red">
    <fa-icon
      title="Trying to sending your Change Submission, please wait"
      [icon]="faSpinnerIcon"
      [spin]="true"
    ></fa-icon>
    Sending your suggestion...
  </p>
  <div *ngIf="sent">
    <p>Thank you for your suggestion. An administrator will review it asap.</p>
    <div class="mt-3 mb-3" style="text-align: center">
      <a mat-stroked-button (click)="close()">Close This Form</a>
    </div>
  </div>
</div>
