import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
  GotoType,
  LikeType,
  ShopFactory,
  ShopGoto,
  ShopLike,
  ShopLikeSaveGotoRaw,
  ShopVisit,
  ShopVisitRaw,
  StoreColour,
  StoreColourRaw,
  User,
  UserFactory,
  UserRaw,
  VisitsResult,
  VisitsResultRaw,
} from '@shared/classes';
import { DialogsService } from '@core/services/dialogs.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Store } from '@ngrx/store';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { LoginFormComponent } from '@modules/auth/components/login-form/login-form.component';
import { RegisterFormComponent } from '@modules/auth/components/register-form/register-form.component';
import { loginWithGoogleAction } from '@modules/auth/store/auth.actions';
import { selectAuthenticated } from '@modules/auth/store/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authenticated$ = this.store.select(selectAuthenticated);

  private readonly userApi = '/api/user';

  private loginDialogRef: MatDialogRef<LoginFormComponent, any> | undefined;
  private loginSubscription: Subscription | undefined;
  private loginSubscriber: Subscriber<boolean> | undefined;

  private registerDialogRef: MatDialogRef<RegisterFormComponent, any> | undefined;

  constructor(
    private dialog: MatDialog,
    private dialogs: DialogsService,
    private http: HttpClient,
    private socialAuthService: SocialAuthService,
    private storage: LocalStorageService,
    private store: Store
  ) {
    // handle Google Login
    this.socialAuthService.authState.subscribe((user) => this.store.dispatch(loginWithGoogleAction({ user })));
  }

  getToken() {
    return this.storage.get('TOKEN');
  }
  getUserID() {
    return this.storage.get('USERID');
  }

  login(email: string, password: string): Observable<User | null> {
    return this.http
      .post<UserRaw>(`${this.userApi}/login`, {
        email,
        password: Md5.hashStr(password, false).toString(),
      })
      .pipe(map((raw) => (raw === null ? null : UserFactory.fromRaw(raw as UserRaw))));
  }

  register(email: string, username: string, password: string): Observable<boolean> {
    return this.http
      .post<any>(`${this.userApi}/register`, {
        email,
        username,
        password: Md5.hashStr(password, false).toString(),
      })
      .pipe(map((raw) => !!raw?.success));
  }

  showLoginWithGoogle() {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (x) => {
        //console.log('success', x)
      },
      (err) => {
        this.dialogs.openMessageDialog(
          'Google Login Failed',
          'The Google Login failed. Please make sure that 3rd party cookies have not been blocked.'
        );
        console.log('rejected', err);
      }
    );
  }

  loginWithGoogle(user: SocialUser): Observable<User | null> {
    return this.http
      .post<UserRaw>(`${this.userApi}/loginsocial`, { ...user })
      .pipe(map((raw) => (raw === null ? null : UserFactory.fromRaw(raw as UserRaw))));
  }

  valid(): Observable<User | null> {
    return this.http
      .post<UserRaw>(`${this.userApi}/valid`, {})
      .pipe(map((raw) => (raw === null ? null : UserFactory.fromRaw(raw as UserRaw))));
  }

  logout(): Observable<boolean> {
    return this.http.post<any>(`${this.userApi}/logout`, {});
  }

  forgotPassword(email: string): Observable<boolean | null> {
    return this.http.post<any>(`${this.userApi}/forgotpassword`, { email }).pipe(map((raw) => raw.success));
  }
  resetPassword(payload: any): Observable<boolean | null> {
    return this.http.post<any>(`${this.userApi}/resetpassword`, payload).pipe(map((raw) => raw.success));
  }

  openLoginDialog(): void {
    let authenticated = false;

    this.loginSubscription = this.authenticated$.subscribe((x) => {
      authenticated = x;
      if (x) {
        this.closeLoginDialog();
      }
    });

    if (!authenticated) {
      const config = new MatDialogConfig();
      config.minWidth = '300px';
      config.maxWidth = '500px';

      this.loginDialogRef = this.dialog.open(LoginFormComponent, config);
    } else {
      this.loginSubscription.unsubscribe();
    }
  }

  openLoginDialog$(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.loginSubscriber = subscriber;
      let authenticated = false;

      this.loginSubscription = this.authenticated$.subscribe((x) => {
        authenticated = x;
        if (x) {
          if (this.loginSubscriber) {
            this.loginSubscriber.next(true);
            this.loginSubscriber.complete();
            this.loginSubscriber = undefined;
          }
          this.closeLoginDialog();
        }
      });

      if (!authenticated) {
        const config = new MatDialogConfig();
        config.minWidth = '300px';
        config.maxWidth = '500px';

        this.loginDialogRef = this.dialog.open(LoginFormComponent, config);
      } else {
        this.loginSubscription.unsubscribe();
      }
    });
  }

  closeLoginDialog() {
    if (this.loginDialogRef) {
      this.loginDialogRef.close(true);
      this.loginDialogRef = undefined;
    }
    if (this.registerDialogRef) {
      this.registerDialogRef.close(true);
      this.registerDialogRef = undefined;
    }
    if (this.loginSubscriber) {
      this.loginSubscriber.next(false);
      this.loginSubscriber.complete();
      this.loginSubscriber = undefined;
    }
    this.loginSubscription?.unsubscribe();
    this.loginSubscription = undefined;
  }

  showRegisterFailureDialog() {
    this.dialogs.openMessageDialog(
      'Sign Up Failed',
      'Unfortunately the sign up failed. Please try again or contact the admin.'
    );
  }
  showRegisterSuccessDialog() {
    this.dialogs.openMessageDialog(
      'Sign Up Successful',
      'You signed up for recordstores.love . Please check your mails and click the link to be able to sign in.'
    );
  }
  openRegisterDialog(): void {
    const authenticated = false;
    /*
    this.loginSubscription = this.authenticated$.subscribe(x => {
      authenticated = x;
      if (x) {
        this.closeLoginDialog();
      }
    });
*/
    if (!authenticated) {
      const config = new MatDialogConfig();
      config.minWidth = '300px';
      config.maxWidth = '500px';

      this.registerDialogRef = this.dialog.open(RegisterFormComponent, config);
    } else {
      //this.loginSubscription.unsubscribe();
    }
  }

  existsEmail(email: string): Observable<boolean> {
    return this.http.post<any>(`${this.userApi}/exists`, { email }).pipe(map((raw) => raw.exists));
  }
  existsUsername(username: string): Observable<boolean> {
    return this.http.post<any>(`${this.userApi}/exists`, { username }).pipe(map((raw) => raw.exists));
  }

  raw2like(raw: ShopLikeSaveGotoRaw): ShopLike {
    return {
      ...raw,
      value: raw.value === 0 ? LikeType.none : raw.value === 1 ? LikeType.like : LikeType.dont,
    };
  }
  saveLike(storeid: number, value: LikeType): Observable<ShopLike> {
    return this.http
      .post<ShopLikeSaveGotoRaw>(`${this.userApi}/like`, { storeid, value })
      .pipe(map((raw) => this.raw2like(raw)));
  }

  raw2goto(raw: ShopLikeSaveGotoRaw): ShopGoto {
    return {
      ...raw,
      value:
        raw.value === 0
          ? GotoType.none
          : raw.value === 1
          ? GotoType.goto
          : raw.value === 2
          ? GotoType.dont
          : GotoType.gone,
    };
  }
  saveGoto(storeid: number, value: GotoType): Observable<ShopGoto> {
    return this.http
      .post<ShopLikeSaveGotoRaw>(`${this.userApi}/goto`, { storeid, value })
      .pipe(map((raw) => this.raw2goto(raw)));
  }

  saveVisit(storeid: number): Observable<ShopVisit> {
    const d = new Date();
    return this.http
      .post<ShopVisitRaw>(`${this.userApi}/visit`, {
        storeid,
        value: d.toISOString(),
      })
      .pipe(map((raw) => ShopFactory.fromRawVisit(raw)));
  }
  saveColour(storeid: number, value: string): Observable<StoreColour> {
    return this.http
      .post<StoreColourRaw>(`${this.userApi}/colour`, { storeid, value })
      .pipe(map((raw) => ({ ...raw, shopid: parseInt(raw.shopid, 10) })));
  }

  removeVisit(visit: ShopVisit): Observable<ShopVisit> {
    const d = new Date();
    return this.http.delete<any>(`${this.userApi}/visit/${visit.id}`).pipe(map(() => visit));
  }
  getVisits(): Observable<VisitsResult> {
    return this.http.get<VisitsResultRaw>(`${this.userApi}/visits`).pipe(
      retry(3),
      map((raw) => ({
        visits: ShopFactory.fromRawVisits(raw.visits),
        shops: raw.shops.map((x) => ShopFactory.fromRawBasic(x)),
      }))
    );
  }
}
