import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { selectIsMobile } from '@app/modules/global/store/global.selectors';
import { faWhatsapp, faGetPocket, faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faQrcode, faSms } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-sharing',
  templateUrl: './sharing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./sharing.component.scss'],
})
export class SharingComponent implements OnInit {
  @Input() url = '';
  @Input() name = '';
  @Input() descript = '';

  isMobile$ = this.store.select(selectIsMobile);

  faFacebook = faFacebookF;
  faTwitter = faTwitter;
  faWhatsApp = faWhatsapp;
  faMail = faEnvelope;
  faGetPocket = faGetPocket;
  faSms = faSms;
  faQrcode = faQrcode;

  showQr = false;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  facebook(): string {
    return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.url);
  }
  twitter(): string {
    return (
      'https://twitter.com/intent/tweet?text=' +
      encodeURIComponent(this.name + ' #rslove') +
      '&url=' +
      encodeURIComponent(this.url)
    );
  }
  whatsapp(): string {
    return (
      'https://api.whatsapp.com/send?text=' + encodeURIComponent(this.descript) + '%20' + encodeURIComponent(this.url)
    );
  }
  mail(): string {
    const body =
      encodeURIComponent(this.descript) +
      '%0A%0A' +
      encodeURIComponent(this.url) +
      '%0A%0A' +
      encodeURIComponent('www.recordstores.love - the record store map') +
      '%0A%0A' +
      encodeURIComponent('https://www.recordstores.love') +
      '%0A' +
      encodeURIComponent('https://www.instagram.com/recordstores.love') +
      '%0A' +
      encodeURIComponent('https://www.facebook.com/recordstores.love');
    return 'mailto:?subject=' + encodeURIComponent(this.descript) + '&body=' + body;
  }
  pocket(): string {
    return 'https://getpocket.com/save?url=' + encodeURIComponent(this.url);
  }
  sms(): string {
    return 'sms:?body=' + encodeURIComponent(this.descript) + '%20' + encodeURIComponent(this.url);
  }
}
