import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { HelperService } from '@core/services/helper.service';
import { AppStateService } from '@core/services/app-state.service';
import { selectSupportersInState, selectSupportersLoaded } from '@app/modules/shops/store/shop.selectors';
import { selectCitiesInActiveState } from '@app/modules/cities/store/city.selectors';
import { setMapBounds } from '@app/modules/map/store/map.actions';

import { selectActiveState, selectAllStates } from '../../store/country.selectors';
import { activateStateDiv } from '@app/modules/global/store/global.actions';
import { setActiveState } from '@app/modules/countries/store/country.actions';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-state',
  templateUrl: './state.component.html',
  styleUrls: [],
})
export class StateComponent extends Unsubscriber implements OnInit {
  state$ = this.store.select(selectActiveState);
  cities$ = this.store.select(selectCitiesInActiveState);
  states$ = this.store.select(selectAllStates);
  supporters$ = this.store.select(selectSupportersInState);
  supportersLoaded$ = this.store.select(selectSupportersLoaded);

  constructor(
    public appState: AppStateService,
    private route: ActivatedRoute,
    public helper: HelperService,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(activateStateDiv());

    this.unsubscribeLater(
      this.route.paramMap.subscribe((params) => {
        const statecode = (params.get('statecode') || '').toUpperCase();
        this.initState(statecode);
      })
    );
  }

  initState(statecode: string) {
    this.states$.pipe(first((x) => x.length > 0)).subscribe((states) => {
      const state = states.find((x) => x.state === statecode);
      if (state) {
        this.appState.setActiveTitle(state.name + ' on recordstores.love');
        this.store.dispatch(setActiveState({ statecode }));
        this.store.dispatch(
          setMapBounds({
            bounds: state,
            zoomlevel: 'cities',
            zoomCenter: state,
          })
        );
      } else {
        this.appState.gotoSearch(statecode);
      }
    });
  }
}
