/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { ElementRef, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { City, ShopBasics } from '@shared/classes';
import { HelperService } from './helper.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { selectActiveCity } from '@app/modules/cities/store/city.selectors';
import { selectActiveStore } from '@app/modules/shops/store/shop.selectors';
import { first } from 'rxjs/operators';
import { FullScreenImage } from '@shared/classes/full-screen-image';
import { BehaviorSubject, Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { selectActiveDiv, selectIsMobile, selectMapVisible } from '@app/modules/global/store/global.selectors';
import { setIsMobile, setViewMap } from '@app/modules/global/store/global.actions';
import { setMapPositionToMe } from '@app/modules/map/store/map.actions';

export declare type AppTarget =
  | 'HOME'
  | 'NEAR'
  | 'STORE'
  | 'VISIBLE'
  | 'CITY'
  | 'STATE'
  | 'COUNTRY'
  | 'COUNTRIES'
  | 'SEARCH'
  | 'OTHER'
  | 'USER';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  public dictionary: Map<string, string> = new Map<string, string>();

  private _fullScreenImages: BehaviorSubject<FullScreenImage[]> = new BehaviorSubject<FullScreenImage[]>([]);
  readonly fullScreenImages$: Observable<FullScreenImage[]> = this._fullScreenImages.asObservable();

  sideNav: MatSidenav | null = null;

  // Bestimmte Werte brauche ich direkt und möchte sie nicht in jeder Komponente
  // neu selektieren. Alles was den AppState ausmacht, landet daher hier.
  isMobile: boolean | undefined = undefined;

  private isMobile$ = this.store.select(selectIsMobile);
  private activeCity$ = this.store.select(selectActiveCity);
  private activeStore$ = this.store.select(selectActiveStore);
  private activeDiv$ = this.store.select(selectActiveDiv);
  private mapVisible$ = this.store.select(selectMapVisible);

  private activeCity: City | null = null;
  private activeStore: ShopBasics | undefined;

  private appDiv: ElementRef | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    public helper: HelperService,
    private router: Router,
    private store: Store,
    private titleService: Title
  ) {
    this.breakpointObserver.observe([Breakpoints.HandsetPortrait]).subscribe((result) => {
      this.store.dispatch(setIsMobile({ isMobile: result.matches }));
    });

    this.activatedRoute.queryParamMap.subscribe((params) => {
      const value = params.get('view') === 'map';
      this.store.dispatch(setViewMap({ value }));
    });

    this.activeCity$.subscribe((x) => (this.activeCity = x));
    this.activeStore$.subscribe((x) => (this.activeStore = x));
    this.isMobile$.subscribe((x) => (this.isMobile = x));
  }
  setActiveTitle(title: string) {
    this.titleService.setTitle(title);
  }
  setAppDiv(div: ElementRef) {
    this.appDiv = div;
  }
  scrollToTop() {
    if (this.appDiv) {
      this.appDiv.nativeElement.scrollTop = 0;
    }
  }
  gotoHome() {
    this.router.navigateByUrl('/');
  }
  gotoNear(viewMap = false) {
    const q = viewMap ? '?view=map' : '';
    this.router.navigateByUrl('/near' + q);
  }
  gotoCountry(countrycode: string, viewMap = false) {
    const q = viewMap ? '?view=map' : '';
    this.router.navigateByUrl('/' + countrycode + q);
  }
  gotoCity(viewMap = false) {
    const q = viewMap ? '?view=map' : '';
    if (this.activeCity) {
      this.router.navigateByUrl(this.helper.city2url(this.activeCity) + q);
    } else if (this.activeStore) {
      this.router.navigateByUrl(this.helper.store2cityurl(this.activeStore) + q);
    } else {
      console.warn('No city to navigate to.');
    }
  }
  gotoStore(viewMap = false) {
    const q = viewMap ? '?view=map' : '';
    if (this.activeStore) {
      this.router.navigateByUrl('/' + this.activeStore.id.toString() + q);
    } else {
      console.warn('No store to navigate to.');
    }
  }
  gotoMap() {
    this.isMobile$.pipe(first()).subscribe((isMobile) => {
      if (isMobile) {
        this.activeDiv$.pipe(first()).subscribe((div) => {
          if (div === 'HOME') {
            this.store.dispatch(setMapPositionToMe());
          }
        });
        this.mapVisible$.pipe(first()).subscribe((div) => {
          const queryParams = div ? { view: 'nomap' } : { view: 'map' };
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge',
          });
        });
      }
    });
  }
  gotoSearch(searchString: string) {
    this.router.navigate(['/search'], { queryParams: { q: searchString } });
  }

  showFullScreenImages(images: FullScreenImage[]) {
    this._fullScreenImages.next(images);
  }
}
