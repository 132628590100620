import { Component, Input, OnInit } from '@angular/core';
import { userUnvisitStore } from '@app/modules/auth/store/auth.actions';
import { ShopVisit } from '@shared/classes';
import { HelperService } from '@core/services/helper.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-visits-in-store',
  templateUrl: './visits-in-store.component.html',
  styleUrls: ['./visits-in-store.component.scss'],
})
export class VisitsInStoreComponent implements OnInit {
  @Input() visits!: ShopVisit[] | null;

  faTimes = faTimes;

  constructor(public helper: HelperService, private store: Store) {}

  ngOnInit(): void {}

  removeVisit(visit: ShopVisit) {
    this.store.dispatch(userUnvisitStore({ visit }));
  }
}
