import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { activateOtherDiv } from '@app/modules/global/store/global.actions';

@Component({
  selector: 'rs-data-policy',
  templateUrl: './data-policy.component.html',
  styleUrls: [],
})
export class DataPolicyComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(activateOtherDiv());
  }
}
