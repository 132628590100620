<div class="sharing-div p-margins">
  <a
    href="{{ facebook() }}"
    target="_blank"
    rel="noopener noreferrer"
    title="Share this page on Facebook"
    title="Share this page on Facebook"
    ><fa-icon [fixedWidth]="true" [icon]="faFacebook" class="sharing-icon"></fa-icon
  ></a>
  <a
    href="{{ twitter() }}"
    target="_blank"
    rel="noopener noreferrer"
    title="Share this page on Twitter"
    title="Share this page on Twitter"
    ><fa-icon [fixedWidth]="true" [icon]="faTwitter" class="sharing-icon"></fa-icon
  ></a>
  <a
    href="{{ whatsapp() }}"
    target="_blank"
    rel="noopener noreferrer"
    title="Share this page via WhatsApp"
    title="Send a link to this page via WhatsApp"
    ><fa-icon [fixedWidth]="true" [icon]="faWhatsApp" class="sharing-icon"></fa-icon
  ></a>
  <a
    href="{{ mail() }}"
    target="_blank"
    rel="noopener noreferrer"
    title="Send  this page via eMail"
    title="Send a link to this page via eMail"
    ><fa-icon [fixedWidth]="true" [icon]="faMail" class="sharing-icon"></fa-icon
  ></a>
  <a
    href="{{ pocket() }}"
    target="_blank"
    rel="noopener noreferrer"
    title="Share this page with Pocket"
    title="Share this page with Pocket"
    ><fa-icon [fixedWidth]="true" [icon]="faGetPocket" class="sharing-icon"></fa-icon
  ></a>
  <a
    *ngIf="isMobile$ | async"
    [attr.href]="sms() | safeUrl"
    target="_blank"
    rel="noopener noreferrer"
    title="Share this page via SMS"
    title="Share this page via SMS"
    ><fa-icon [fixedWidth]="true" [icon]="faSms" class="sharing-icon"></fa-icon
  ></a>
  <a title="Show QR Code for this this page" title="Show QR Code for this this page"
    ><fa-icon (click)="showQr = !showQr" [fixedWidth]="true" [icon]="faQrcode" class="sharing-icon"></fa-icon
  ></a>
</div>

<rs-qr-code *ngIf="showQr" [url]="url"></rs-qr-code>
