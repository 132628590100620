<div class="mt-3 mb-3" style="text-align: center">
  <a *ngIf="editStep === ''" mat-stroked-button [matMenuTriggerFor]="menu">Suggest An Edit</a>
  <mat-menu #menu="matMenu" class="mat-menu-400">
    <div class="rs-inline-form rs-panel">
      <div class="rs-panel-row rs-clickable rs-store-panel-row rs-border-bottom-lightgray" (click)="editDetails()">
        <div class="store-item-container">
          <div class="store-item-icon">
            <fa-icon [fixedWidth]="true" [icon]="faPen" class="fa-marker fa-pen"></fa-icon>
          </div>
          <div class="store-item-title-container">
            <div class="store-item-title">
              <b>Edit details</b>
            </div>
            <div class="store-item-title-info">Change name, contact details, hours, etc</div>
          </div>
        </div>
      </div>
      <div class="rs-panel-row rs-clickable rs-store-panel-row rs-border-bottom-lightgray" (click)="editClosed()">
        <div class="store-item-container">
          <div class="store-item-icon">
            <img src="/assets/markers/markerslash.png" class="google-place-icon" />
          </div>
          <div class="store-item-title-container">
            <div class="store-item-title">
              <b>Close or remove</b>
            </div>
            <div class="store-item-title-info">Store has moved, permanently closed or doesn't exist</div>
          </div>
        </div>
      </div>
    </div>
  </mat-menu>
</div>

<div *ngIf="editStep !== ''" class="rs-inline-form rs-h2-highlighted">
  <div *ngIf="editStep === 'editDetails'">
    <h2>Suggest an Edit</h2>

    <div
      class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row"
      (click)="editStep = 'editDetails.address'"
    >
      <rs-simple-item
        [faIcon]="faInfoCircle"
        iconClass="contact-link"
        title="Edit name, address and description"
      ></rs-simple-item>
    </div>
    <div
      class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row"
      (click)="editStep = 'editClosed.moved'"
    >
      <rs-simple-item
        [faIcon]="faTruckMoving"
        iconClass="contact-link"
        title="The Store has moved to a new location?"
      ></rs-simple-item>
    </div>
    <div
      class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row"
      (click)="editStep = 'editDetails.contact'"
    >
      <rs-simple-item
        [faIcon]="faAt"
        iconClass="contact-link"
        title="Edit contact details and websites"
      ></rs-simple-item>
    </div>
    <div
      class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row"
      (click)="editStep = 'editDetails.openinghours'"
    >
      <rs-simple-item [faIcon]="faClock" iconClass="contact-link" title="Edit opening hours"></rs-simple-item>
    </div>

    <div class="mt-3 pb-3" style="text-align: center">
      <a mat-stroked-button (click)="closeForm()">Cancel</a>
    </div>
  </div>

  <div *ngIf="editStep === 'editClosed'">
    <h2>The store has closed or moved</h2>

    <div
      class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row"
      (click)="editStep = 'editClosed.permanently_closed'"
    >
      <rs-simple-item [faIcon]="faTimesCircle" iconClass="contact-link" title="Permanently closed"></rs-simple-item>
    </div>
    <div
      class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row"
      (click)="editStep = 'editClosed.moved'"
    >
      <rs-simple-item
        [faIcon]="faTruckMoving"
        iconClass="contact-link"
        title="Moved to a new location"
      ></rs-simple-item>
    </div>
    <div
      class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row"
      (click)="editStep = 'editClosed.doesnt_exist'"
    >
      <rs-simple-item [faIcon]="faQuestionCircle" iconClass="contact-link" title="Doesn't exist here"></rs-simple-item>
    </div>
    <div
      class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row"
      (click)="editStep = 'editClosed.duplicate'"
    >
      <rs-simple-item [faIcon]="faClone" iconClass="contact-link" title="Duplicate of another store"></rs-simple-item>
    </div>
    <div
      class="rs-panel-row rs-small-panel-row rs-clickable rs-store-panel-row"
      (click)="editStep = 'editClosed.other'"
    >
      <rs-simple-item [faIcon]="faEdit" iconClass="contact-link" title="Other"></rs-simple-item>
    </div>

    <div class="mt-3 pb-3" style="text-align: center">
      <a mat-stroked-button (click)="closeForm()">Cancel</a>
    </div>
  </div>

  <div *ngIf="editStep.startsWith('editClosed.')">
    <rs-store-has-closed-form [reason]="editStep" [shop]="shop" (closeForm)="closeForm()"></rs-store-has-closed-form>
  </div>

  <ng-container *ngIf="editStep === 'editDetails.address'">
    <h2 mat-dialog-title>Edit name, address and description</h2>
    <rs-address-form
      *ngIf="!sending && !sent"
      [address]="shop"
      [withNote]="true"
      (closeForm)="closeAddressForm($event)"
      class="rs-form"
    ></rs-address-form>
    <p *ngIf="sending" class="red">
      <fa-icon title="Sending your Change Submission, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
      Sending your suggestion...
    </p>
    <div *ngIf="sent">
      <p>Thank you for your suggestion. An administrator will review it asap.</p>
      <div class="mt-3 mb-3" style="text-align: center">
        <a mat-stroked-button (click)="closeForm()">Close This Form</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="editStep === 'editDetails.contact'">
    <h2 mat-dialog-title>Edit phone, email and websites</h2>
    <rs-contact-form
      *ngIf="!sending && !sent"
      [contact]="shop"
      [withNote]="true"
      (closeForm)="closeContactForm($event)"
      class="rs-form"
    ></rs-contact-form>
    <p *ngIf="sending" class="red">
      <fa-icon title="Sending your Change Submission, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
      Sending your suggestion...
    </p>
    <div *ngIf="sent">
      <p>Thank you for your suggestion. An administrator will review it asap.</p>
      <div class="mt-3 mb-3" style="text-align: center">
        <a mat-stroked-button (click)="closeForm()">Close This Form</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="editStep === 'editDetails.openinghours'">
    <h2 mat-dialog-title>Edit opening hours</h2>
    <rs-times-form
      *ngIf="!sending && !sent"
      [times]="shop"
      [withNote]="true"
      (closeForm)="closeTimesForm($event)"
      class="rs-form"
    ></rs-times-form>
    <p *ngIf="sending" class="red">
      <fa-icon title="Sending your Change Submission, please wait" [icon]="faSpinnerIcon" [spin]="true"></fa-icon>
      Sending your suggestion...
    </p>
    <div *ngIf="sent">
      <p>Thank you for your suggestion. An administrator will review it asap.</p>
      <div class="mt-3 mb-3" style="text-align: center">
        <a mat-stroked-button (click)="closeForm()">Close This Form</a>
      </div>
    </div>
  </ng-container>
</div>
