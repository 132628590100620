import { Component, Input, OnInit } from '@angular/core';
import { Unsubscriber } from '@app/framework/unsubscriber';
import { selectUser } from '@app/modules/auth/store/auth.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'rs-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss'],
})
export class UserImageComponent extends Unsubscriber implements OnInit {
  @Input() width = 38;

  user$ = this.store.select(selectUser);
  url$ = new BehaviorSubject<string>('');

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.unsubscribeLater(
      this.user$.subscribe((user) => {
        if (user) {
          if (user.photoUrl) {
            this.url$.next(user.photoUrl);
          } else {
            const emailhash = Md5.hashStr(user.email.toLowerCase().trim(), false).toString();
            const gravatarUrl = 'https://www.gravatar.com/avatar/' + emailhash;
            const fallback =
              '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F' +
              encodeURI(user.username) +
              '%2F' +
              this.width.toString() +
              '%2Fe94e35%2F000';
            this.url$.next(gravatarUrl + fallback);
          }
        } else {
          this.url$.next('');
        }
      })
    );
  }
}
