import { Component, OnInit } from '@angular/core';
import { activateUserDiv } from '@app/modules/global/store/global.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(activateUserDiv());
  }
}
