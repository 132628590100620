<div *ngIf="(positionError$ | async) === null && (position$ | async) === null">
  <p>
    <fa-icon [fixedWidth]="true" [icon]="faSpinner" [spin]="true"></fa-icon>
    Trying to access your location...
  </p>
</div>

<div *ngIf="positionError$ | async as positionError">
  <p><b>Failed to load your position</b></p>
  <p *ngIf="positionError.permission_denied">
    Permission denied. Please check your browser preferences and allow this site to access your location. Then reload
    this site. By default, most browsers ask you when a site wants to see your location. To let the site know where you
    are, choose Allow.
  </p>
  <p *ngIf="positionError.position_unavailable">Position Unavailable. Please try again later.</p>
  <p *ngIf="positionError.timeout">Timeout. Please try again later.</p>
  <p>
    <span class="red"> Error Message: {{ positionError.message }} </span>
    <span *ngIf="position$ | async"> Using your last available position. </span>
  </p>

  <p>
    <button mat-flat-button class="rs-button" (click)="updatePosition()">Retry to access your location</button>
  </p>
</div>
