<div *ngIf="shop.adminimages.length > 0">
  <p>
    <fa-icon [icon]="faLeft" (click)="prev()" class="rs-pointer"></fa-icon>
    &nbsp; Image {{ nr + 1 }} of {{ shop.adminimages.length }}
    &nbsp;
    <fa-icon [icon]="faRight" (click)="next()" class="rs-pointer"></fa-icon>
  </p>
  <p>
    <mat-chip-list [multiple]="true">
      <mat-chip
        *ngFor="let image of shop.adminimages; let idx = index"
        (click)="nr = idx"
        [color]="idx === nr ? 'primary' : !image.active || image.what === null ? 'warn' : 'accent'"
        [selected]="idx === nr || !image.active || image.what === null"
        >{{ idx + 1 }}</mat-chip
      >
    </mat-chip-list>
  </p>
  <rs-admin-image [shop]="shop" [image]="shop.adminimages[nr]"></rs-admin-image>
</div>

<div>
  <img
    *ngFor="let image of shop.adminimages; let idx = index"
    (click)="nr = idx"
    [ngClass]="{
      inactive: !image.active,
      deleted: image.deleted,
      main: image.id === shop.imageid,
      selected: idx === nr
    }"
    [src]="'/images/mthumb.php?h=80&rot=' + image.rotation + '&src=' + image.imageurl"
  />
  <br />
</div>
