import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { faAt, faInfoCircle, faPen, faSpinner, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import { faClock, faTimesCircle, faQuestionCircle, faClone, faEdit } from '@fortawesome/free-regular-svg-icons';
import { ShopDetails } from '@shared/classes';
import { Store } from '@ngrx/store';
import { saveStore } from '../../store/shop.actions';
import { AddressFormResult } from '@app/shared/forms/address-form/address-form.component';
import { selectAuthenticated, selectUser } from '@app/modules/auth/store/auth.selectors';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { ContactFormResult } from '@app/shared/forms/contact-form/contact-form.component';
import { TimesFormResult } from '@app/shared/forms/times-form/times-form.component';
import { HelperService } from '@app/core/services/helper.service';

@Component({
  selector: 'rs-store-edit',
  templateUrl: './store-edit.component.html',
  styleUrls: ['./store-edit.component.scss'],
})
export class StoreEditComponent implements OnInit, OnChanges {
  @Input() shop!: ShopDetails;

  authenticated$ = this.store.select(selectAuthenticated);

  editStep = '';
  sending = false;
  sent = false;

  faAt = faAt;
  faClock = faClock;
  faClone = faClone;
  faEdit = faEdit;
  faInfoCircle = faInfoCircle;
  faPen = faPen;
  faQuestionCircle = faQuestionCircle;
  faTimesCircle = faTimesCircle;
  faTruckMoving = faTruckMoving;
  faSpinnerIcon = faSpinner;

  constructor(private helper: HelperService, private http: HttpClient, private store: Store) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.closeForm();
  }

  closeForm() {
    this.sending = false;
    this.sent = false;
    this.editStep = '';
  }

  editClosed() {
    this.sending = false;
    this.sent = false;
    this.editStep = 'editClosed';
  }
  editDetails() {
    this.sending = false;
    this.sent = false;
    this.editStep = 'editDetails';
  }

  closeAddressForm(result: AddressFormResult | null) {
    if (result) {
      this.sending = true;
      this.authenticated$.pipe(first()).subscribe((authenticated) => {
        if (authenticated) {
          this.store.dispatch(
            saveStore({
              note: result.note,
              store: { ...this.shop, ...result.address },
            })
          );
        }
        this.sendAddressChanges(result, authenticated);
      });
    } else {
      this.closeForm();
    }
  }

  closeContactForm(result: ContactFormResult | null) {
    if (result) {
      this.sending = true;
      this.authenticated$.pipe(first()).subscribe((authenticated) => {
        if (authenticated) {
          this.store.dispatch(
            saveStore({
              note: result.note,
              store: { ...this.shop, ...result.contact },
            })
          );
        }
        this.sendContactChanges(result, authenticated);
      });
    } else {
      this.closeForm();
    }
  }

  closeTimesForm(result: TimesFormResult | null) {
    if (result) {
      this.sending = true;
      this.authenticated$.pipe(first()).subscribe((authenticated) => {
        if (authenticated) {
          this.store.dispatch(
            saveStore({
              note: result.note,
              store: { ...this.shop, ...result.times },
            })
          );
        }
        this.sendTimesChanges(result, authenticated);
      });
    } else {
      this.closeForm();
    }
  }

  private difference(before: string | null, after: string | null, name: string): string {
    return before === after ? '' : `old ${name}: ${before}` + '\n' + `new ${name}: ${after}` + '\n';
  }

  private sendAddressChanges(result: AddressFormResult, authenticated: boolean) {
    const email = '';
    const subject = `Edit Store Address ${this.shop.id} ${this.shop.name} in ${this.shop.city} / ${this.shop.countrycode}`;
    const from = `https://www.recordstores.love/${this.shop.id}`;
    const message =
      (authenticated ? 'authenticated' : 'NOT AUTHENTICATED') +
      '\n' +
      'from: ' +
      from +
      '\n' +
      'store: ' +
      `${this.shop.name} in ${this.shop.city} / ${this.shop.countrycode}` +
      '\n' +
      'note: ' +
      result.note +
      '\n\n' +
      'new values: \n\n' +
      this.difference(this.shop.name, result.address.name, 'name') +
      this.difference(this.shop.address, result.address.address, 'address') +
      this.difference(this.shop.addinfo, result.address.addinfo, 'addinfo') +
      this.difference(this.shop.descript, result.address.descript, 'descript');

    this.http.post('/api/contact', { email, subject, message }).subscribe(() => {
      if (this.sending) {
        this.sending = false;
        this.sent = true;
      }
    });
  }

  private sendContactChanges(result: ContactFormResult, authenticated: boolean) {
    const email = '';
    const subject = `Edit Store Contact ${this.shop.id} ${this.shop.name} in ${this.shop.city} / ${this.shop.countrycode}`;
    const from = `https://www.recordstores.love/${this.shop.id}`;
    const message =
      (authenticated ? 'authenticated' : 'NOT AUTHENTICATED') +
      '\n' +
      'from: ' +
      from +
      '\n' +
      'store: ' +
      `${this.shop.name} in ${this.shop.city} / ${this.shop.countrycode}` +
      '\n' +
      'note: ' +
      result.note +
      '\n\n' +
      'new values: \n\n' +
      this.difference(this.shop.phone, result.contact.phone, 'phone') +
      this.difference(this.shop.email, result.contact.email, 'email') +
      this.difference(this.shop.website, result.contact.website, 'website') +
      this.difference(this.shop.facebook, result.contact.facebook, 'facebook') +
      this.difference(this.shop.discogs, result.contact.discogs, 'discogs') +
      this.difference(this.shop.ebay, result.contact.ebay, 'ebay');

    this.http.post('/api/contact', { email, subject, message }).subscribe(() => {
      if (this.sending) {
        this.sending = false;
        this.sent = true;
      }
    });
  }

  private sendTimesChanges(result: TimesFormResult, authenticated: boolean) {
    const oldTimes = this.helper.getShortTimesString(this.shop);
    const newTimes = this.helper.getShortTimesString(result.times);
    const email = '';
    const subject = `Edit Store Times ${this.shop.id} ${this.shop.name} in ${this.shop.city} / ${this.shop.countrycode}`;
    const from = `https://www.recordstores.love/${this.shop.id}`;
    const message =
      (authenticated ? 'authenticated' : 'NOT AUTHENTICATED') +
      '\n' +
      'from: ' +
      from +
      '\n' +
      'store: ' +
      `${this.shop.name} in ${this.shop.city} / ${this.shop.countrycode}` +
      '\n' +
      'note: ' +
      result.note +
      '\n\n' +
      'new values: \n\n' +
      this.difference(oldTimes, newTimes, 'Opening Hours') +
      this.difference(this.shop.times, result.times.times, 'times');

    this.http.post('/api/contact', { email, subject, message }).subscribe(() => {
      if (this.sending) {
        this.sending = false;
        this.sent = true;
      }
    });
  }
}
