import { selectPosition } from '@app/modules/position/store/position.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMap from './map.reducer';

export const ZOOM_SHOW_CITIES = 11;
export const ZOOM_SHOW_COUNTRIES = 4;
export const ZOOM_STORE_DEFAULT = 16;

export const selectMapState = createFeatureSelector<fromMap.MapState>(fromMap.mapFeatureKey);

export const selectMapPosition = createSelector(selectMapState, (state) => state.mapPosition);

export const selectActiveMarker = createSelector(selectMapState, (state) => state.activeMarker);

export const selectZoom = createSelector(selectMapState, (state) => state.zoom);

export const selectStoresVisible = createSelector(selectZoom, (zoom) =>
  typeof zoom === 'number' ? zoom > ZOOM_SHOW_CITIES : false
);

export const selectCitiesVisible = createSelector(selectZoom, (zoom) =>
  typeof zoom === 'number' ? zoom <= ZOOM_SHOW_CITIES && zoom > ZOOM_SHOW_COUNTRIES : false
);

export const selectCountriesVisible = createSelector(selectZoom, (zoom) =>
  typeof zoom === 'number' ? zoom <= ZOOM_SHOW_COUNTRIES : false
);

export const selectActualBounds = createSelector(selectMapState, (state) => state.actualBounds);

export const selectActiveMarkerVisible = createSelector(
  selectActiveMarker,
  selectStoresVisible,
  selectActualBounds,
  (marker, storesVisible, bounds) =>
    storesVisible &&
    marker !== null &&
    marker.position !== null &&
    bounds !== undefined &&
    bounds.latmin <= marker.position.lat &&
    marker.position.lat <= bounds.latmax &&
    bounds.lngmin <= marker.position.lng &&
    marker.position.lng <= bounds.lngmax
);

export const selectPositionMarkerVisible = createSelector(
  selectPosition,
  selectActualBounds,
  (pos, bounds) =>
    pos !== null &&
    bounds !== undefined &&
    bounds.latmin <= pos.lat &&
    pos.lat <= bounds.latmax &&
    bounds.lngmin <= pos.lng &&
    pos.lng <= bounds.lngmax
);

export const selectShowMapDetails = createSelector(
  selectPositionMarkerVisible,
  selectActiveMarkerVisible,
  selectStoresVisible,
  selectPosition,
  selectActiveMarker,
  selectActualBounds,
  (posVisible, markerVisible, storesVisible, pos, marker, bounds) => {
    let result = markerVisible || (posVisible && storesVisible && marker !== null);
    if (!result && storesVisible && pos && marker?.position && bounds) {
      // Die Bounds sind dazwischen, wenn nicht beide Marker rechts / links / oben / unten liegen
      const bothLeft = pos.lng < bounds.lngmin && marker.position.lng < bounds.lngmin;
      const bothRight = pos.lng > bounds.lngmax && marker.position.lng > bounds.lngmax;
      const bothAbove = pos.lat < bounds.latmin && marker.position.lat < bounds.latmin;
      const bothUnder = pos.lat > bounds.latmax && marker.position.lat > bounds.latmax;
      result = !(bothLeft || bothRight || bothAbove || bothUnder);
    }
    return result;
  }
);
