import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressFormResult } from '@app/shared/forms/address-form/address-form.component';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { ShopDetails } from '@shared/classes';
import { saveStore } from '../../store/shop.actions';

@Component({
  selector: 'rs-store-edit-dialog',
  templateUrl: './store-edit-dialog.component.html',
  styleUrls: ['./store-edit-dialog.component.scss'],
})
export class StoreEditDialogComponent implements OnInit {
  sending = false;
  sent = false;
  faSpinnerIcon = faSpinner;

  constructor(
    private dialogRef: MatDialogRef<StoreEditDialogComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public shop: ShopDetails,
    private store: Store
  ) {}

  ngOnInit(): void {}

  closeAddressForm(result: AddressFormResult | null) {
    if (result) {
      this.sending = true;
      this.store.dispatch(
        saveStore({
          note: result.note,
          store: { ...this.shop, ...result.address },
        })
      );
      this.sendNewStoreNotification(result);
      this.closeForm();
    } else {
      this.closeForm();
    }
  }

  closeForm() {
    this.dialogRef.close(null);
  }

  private sendNewStoreNotification(result: AddressFormResult) {
    const email = '';
    const subject = `New Store ${this.shop.id} ${this.shop.name} in ${this.shop.city} / ${this.shop.countrycode}`;
    const from = `https://www.recordstores.love/${this.shop.id}`;
    const message =
      'from: ' +
      from +
      '\n' +
      'store: ' +
      `${this.shop.name} in ${this.shop.city} / ${this.shop.countrycode}` +
      '\n' +
      'note: ' +
      result.note +
      '\n\n';

    this.http.post('/api/contact', { email, subject, message }).subscribe(() => {
      if (this.sending) {
        this.sending = false;
        this.sent = true;
      }
    });
  }
}
