import { Component, OnInit } from '@angular/core';
import { Unsubscriber } from '@app/framework/unsubscriber';
import { selectAuthenticated } from '@app/modules/auth/store/auth.selectors';
import { setHighlightStrategy } from '@app/modules/markers/store/marker.actions';
import { selectSelectedHighlightStrategyID } from '@app/modules/markers/store/marker.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-highlight-strategies',
  templateUrl: './highlight-strategies.component.html',
  styleUrls: ['./highlight-strategies.component.scss'],
})
export class HighlightStrategiesComponent extends Unsubscriber implements OnInit {
  highlight$ = this.store.select(selectSelectedHighlightStrategyID);
  authenticated$ = this.store.select(selectAuthenticated);

  authenticated = false;

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.unsubscribeLater(this.authenticated$.subscribe((x) => (this.authenticated = x)));
  }

  setHighlightStrategy(strategy: string) {
    this.store.dispatch(setHighlightStrategy({ strategy }));
  }
}
