import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { Continent } from '@shared/classes';
import { setMapBounds } from '@app/modules/map/store/map.actions';
import { setCountriesFilter } from '@app/modules/countries/store/country.actions';

import {
  selectAllContinents,
  selectCountriesFilter,
  selectFilteredCountries,
  selectTotalCountriesCount,
  selectTotalStoresCount,
} from '../../store/country.selectors';
import { activateCountriesDiv } from '@app/modules/global/store/global.actions';
import { AppStateService } from '@core/services/app-state.service';

@Component({
  selector: 'rs-countries',
  templateUrl: './countries.component.html',
  styleUrls: [],
})
export class CountriesComponent implements OnInit {
  continents$ = this.store.select(selectAllContinents);
  countries$ = this.store.select(selectFilteredCountries);
  countriesFilter$ = this.store.select(selectCountriesFilter);
  totalCountries$ = this.store.select(selectTotalCountriesCount);
  totalStores$ = this.store.select(selectTotalStoresCount);

  continents: Continent[] = [];
  title = 'Countries with 100+ stores';

  constructor(private appState: AppStateService, private store: Store) {}

  ngOnInit() {
    this.appState.setActiveTitle('record stores by country on recordstores.love');
    this.store.dispatch(activateCountriesDiv());

    this.continents$.pipe(first()).subscribe((continents) => (this.continents = continents));

    // setFilter mit dem bereits gewählten Filter aufrufen,
    // um die Map auf den korrekten Bereich zu zoomen
    this.countriesFilter$.pipe(first()).subscribe((filter) => this.setFilter(filter));
  }

  setFilter(filter: string) {
    const world = { latmin: -47, latmax: 66, lngmin: -146, lngmax: 170 };
    const level = 'cities';

    this.store.dispatch(setCountriesFilter({ filter }));

    if (filter === '100') {
      this.title = 'Countries with 100+ stores';
      this.store.dispatch(setMapBounds({ bounds: world, zoomlevel: level, zoomCenter: null }));
    } else {
      const continent = this.continents.find((x) => x.code === filter);
      this.title = continent ? `Countries in ${continent.name}` : '';
      if (continent) {
        this.store.dispatch(
          setMapBounds({
            bounds: continent,
            zoomlevel: level,
            zoomCenter: null,
          })
        );
      }
    }
  }
}
