import { createReducer, on } from '@ngrx/store';
import { City, CityFactory } from '@shared/classes';
import * as CityActions from './city.actions';
import { saveStoreSuccess } from '@app/modules/shops/store/shop.actions';

export const cityFeatureKey = 'city';

export interface CityState {
  cities: City[];
  activeCity: City | null;
  loadingCities: boolean;
  loadedCities: boolean;
}

export const initialState: CityState = {
  cities: [],
  activeCity: null,
  loadingCities: false,
  loadedCities: false,
};

export const reducer = createReducer(
  initialState,

  on(CityActions.loadCities, (state) => ({ ...state, loadingCities: true })),
  on(CityActions.loadCitiesFailure, (state) => ({
    ...state,
    loadingCities: false,
  })),
  on(CityActions.loadCitiesSuccess, (state, action) => ({
    ...state,
    cities: action.cities,
    loadingCities: false,
    loadedCities: true,
  })),

  on(CityActions.setActiveCity, (state, action) => ({
    ...state,
    activeCity: action.city,
  })),

  on(saveStoreSuccess, (state, action) => {
    const result = { ...state };
    const shop = action.store;
    if (action.isNew) {
      // Neu: Datensatz anhängen und opencnt in City, State, Country erhöhen.
      if (result.loadedCities) {
        const city =
          shop.countrycode === 'US'
            ? result.cities.find((x) => x.countrycode === 'US' && x.state === shop.state && x.city === shop.city)
            : result.cities.find((x) => x.countrycode === shop.countrycode && x.city === shop.city);
        if (city) {
          result.cities = [
            ...result.cities.filter((x) => x !== city),
            {
              ...city,
              opencnt: city.opencnt + 1,
              latmin: Math.min(city.latmin, shop.lat),
              latmax: Math.max(city.latmax, shop.lat),
              lngmin: Math.min(city.lngmin, shop.lng),
              lngmax: Math.max(city.lngmax, shop.lng),
            },
          ];
        } else {
          result.cities = [...result.cities, CityFactory.fromStore(shop)];
        }
      }
    }
    return result;
  })
);
