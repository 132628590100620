import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ShopDetails } from '@shared/classes';
import { StoreEditDialogComponent } from '@app/modules/shops/components/store-edit-dialog/store-edit-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PlaceDialogsService {
  constructor(public dialog: MatDialog) {}

  openStoreForm(store: ShopDetails): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.minWidth = '350px';
    dialogConfig.maxWidth = '90%';

    dialogConfig.data = store;

    const dialogRef = this.dialog.open(StoreEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('submit');
        //this.openMessageDialog('Saved Store', 'Thank you for your support.');
      } else {
        console.log('cancel');
      }
    });
  }
}
