import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { pos2LatLngPos } from '@shared/classes';
import { GeolocationPosition, GeolocationPositionError } from '@shared/classes/latlng';
import { selectMapVisible } from '@app/modules/global/store/global.selectors';
import { getPositionSuccess, getPositionFailure } from '@modules/position/store/position.actions';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  private mapVisible$ = this.store.select(selectMapVisible);

  private options = {
    maximumAge: 0, // maximales Alter eines gecachten Standorts in ms. 0 = keine gecachten Werte!
    timeout: 10000, // Wann Bestimmung abgebrochen wird in ms
    enableHighAccuracy: true, // hohe Genauigkeit
  };
  private watchID: number | null = null;

  constructor(private store: Store) {
    this.mapVisible$.subscribe((x) => this.watchPosition(x));
  }

  getCurrentPosition() {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setPosition, this.setError, this.options);
    } else {
      this.setError(null);
    }
  }

  watchPosition(on: boolean) {
    if (on) {
      if (!this.watchID) {
        const opt = { ...this.options, maximumAge: 30000 };
        this.watchID = navigator.geolocation.watchPosition(this.setPosition, this.setError, opt);
      }
    } else {
      if (this.watchID) {
        navigator.geolocation.clearWatch(this.watchID);
        this.watchID = null;
      }
    }
  }

  private setPosition = (position: GeolocationPosition) => {
    this.store.dispatch(getPositionSuccess({ position: pos2LatLngPos(position) }));
  };

  private setError = (error: GeolocationPositionError | null) => {
    this.store.dispatch(getPositionFailure({ error }));
  };
}
