<div class="rs-panel">
  <div>
    <h1 class="rs-header-1">
      Support
      <span class="rs-h-small">recordstores.love needs your support</span>
    </h1>
  </div>

  <rs-breadcrumb [additional]="additional" [breadcrumbs]="[{ routerLink: '', caption: 'support' }]"></rs-breadcrumb>

  <div>
    <h2>Support Now and Increase Your Visibility</h2>

    <p>As a supporter your record store will get some bonus features:</p>

    <ul>
      <li>All users in your area will <b>see your store on the Welcome Page</b></li>
      <li>Your store will be <b>listed in the top section of your City</b></li>
      <li>No links to other stores on your store site</li>
      <li>Better listing in Search Results</li>
      <li>Help recordstores.love covering the costs for this site</li>
    </ul>

    <p>And if you send me some good photos, I will also support you on Instagram.</p>

    <h2>Pricing</h2>

    <ul>
      <li><b>60 EUR per year</b> (plus taxes) or</li>
      <li><b>100 EUR for 2 years</b> (plus taxes) or</li>
      <li><b>160 EUR for 4 years</b> (plus taxes).</li>
    </ul>

    <rs-support-form></rs-support-form>

    <p></p>
  </div>

  <rs-contact-and-coffee title="support" url="https://recordstores.love/support"> </rs-contact-and-coffee>

  <rs-sharing
    url="recordstores.love/support"
    name="recordstores.love"
    descript="Follow this link to find all record stores worldwide"
  ></rs-sharing>
</div>
