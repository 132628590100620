import { NgModule } from '@angular/core';

import { FileUploadModule } from 'ng2-file-upload';

import { StoreComponent } from './components/store/store.component';
import { StoreEditDialogComponent } from './components/store-edit-dialog/store-edit-dialog.component';
import { StoreHasClosedFormComponent } from './components/store-has-closed-form/store-has-closed-form.component';
import { StoreEditComponent } from './components/store-edit/store-edit.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SliderDotsComponent } from './components/slider-dots/slider-dots.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';
import { UploadComponent } from './components/upload/upload.component';
import { DialogsModule } from '@app/modules/dialogs/dialogs.module';

import { StoreModule } from '@ngrx/store';
import * as fromShop from './store/shop.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ShopEffects } from './store/shop.effects';
import { CitiesModule } from '@app/modules/cities/cities.module';
import { PositionModule } from '@app/modules/position/position.module';
import { SharedModule } from '@shared/shared.module';
import { HomeComponent } from './components/home/home.component';
import { NearComponent } from './components/near/near.component';
import { VisibleComponent } from './components/visible/visible.component';
import { AdminStoreComponent } from './components/admin-store/admin-store.component';
import { AdminImageComponent } from './components/admin-image/admin-image.component';
import { AdminImagesComponent } from './components/admin-images/admin-images.component';

@NgModule({
  declarations: [
    CarouselComponent,
    SliderDotsComponent,
    SlideshowComponent,
    UploadComponent,
    StoreComponent,
    StoreEditDialogComponent,
    StoreHasClosedFormComponent,
    StoreEditComponent,
    HomeComponent,
    NearComponent,
    VisibleComponent,
    AdminStoreComponent,
    AdminImageComponent,
    AdminImagesComponent,
  ],
  imports: [
    FileUploadModule,
    SharedModule,
    CitiesModule,
    PositionModule,
    DialogsModule,
    StoreModule.forFeature(fromShop.shopFeatureKey, fromShop.reducer),
    EffectsModule.forFeature([ShopEffects]),
  ],
  exports: [VisibleComponent],
})
export class ShopsModule {}
