import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { City, CityListItem, State } from '@shared/classes';
import { HelperService } from '@core/services/helper.service';

export class FilterItem {
  filter = '';
  caption = '';
}

@Component({
  selector: 'rs-cities-states',
  templateUrl: './cities-states.component.html',
  styleUrls: [],
})
export class CitiesStatesComponent implements OnInit, OnChanges {
  @Input() states!: State[] | null;
  @Input() cities!: City[] | null;
  @Input() name!: string;

  filteredCities: CityListItem[] = [];

  title = 'Cities in';

  isAmerica = false;

  filterItems: FilterItem[] = [];
  filter = 'all';

  constructor(public helper: HelperService) {}
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.initFilter();
  }

  initFilter() {
    this.isAmerica = this.states !== null && this.states.length > 0;
    this.filterItems = [];

    let newFilter = 'all';

    if (this.isAmerica) {
      this.filterItems = [
        { filter: 'big', caption: 'Big Cities' },
        { filter: 'states', caption: 'All States' },
      ];
      newFilter = 'big';
    } else if (this.cities !== null) {
      const cntAll = this.cities.length;
      const cnt5 = this.cities.reduce<number>((cnt, city) => (city.opencnt >= 5 ? cnt + 1 : cnt), 0);
      const cnt10 = this.cities.reduce<number>((cnt, city) => (city.opencnt >= 10 ? cnt + 1 : cnt), 0);

      // brauche ich überhaupt Filter?
      this.filterItems.push({ filter: 'all', caption: 'All (' + cntAll + ')' });

      if (cntAll > 10 && cnt5 > 0) {
        this.filterItems.push({
          filter: '5',
          caption: '5+ stores (' + cnt5 + ')',
        });
        newFilter = '5';
      }

      if (cnt5 > 10 && cnt10 > 0) {
        this.filterItems.push({
          filter: '10',
          caption: '10+ stores (' + cnt10 + ')',
        });
        newFilter = '10';
      }
    }

    this.toggleFilter(newFilter);
  }

  toggleFilter = (fltr: string) => {
    this.filter = fltr;
    this.updateCities(fltr);
    const ncity = this.helper.n(this.filteredCities.length, 'City', 'Cities');
    switch (fltr) {
      case 'all':
        this.title = ncity + ' in ' + this.name;
        break;
      case 'big':
        this.title = ncity + ' with 10+ Stores in ' + this.name;
        break;
      case 'states':
        this.title = 'States in ' + this.name;
        break;
      default:
        this.title = ncity + ' with ' + fltr + '+ stores in ' + this.name;
    }
  };

  updateCities(fltr: string) {
    if (fltr === 'all') {
      this.filteredCities = (this.cities ?? []).map((city) => ({ city }));
    } else if (fltr === '5') {
      this.filteredCities = (this.cities ?? []).filter((x) => x.opencnt >= 5).map((city) => ({ city }));
    } else if (fltr === '10') {
      this.filteredCities = (this.cities ?? []).filter((x) => x.opencnt >= 10).map((city) => ({ city }));
    } else if (fltr === 'big') {
      this.filteredCities = (this.cities ?? []).filter((x) => x.opencnt >= 10).map((city) => ({ city }));
    } else if (fltr === 'states') {
      this.filteredCities = [];
    }
  }
}
