/* eslint-disable @typescript-eslint/member-ordering */
/// <reference types="@types/google.maps" />
import { Injectable } from '@angular/core';
import { LatLng, City, Country, ShopBasics, User, ActiveMarkerType } from '@shared/classes';
import { MarkerColor, MarkerDefinitions } from '../../shared/helpers/marker-definitions';
import { AppStateService } from '@core/services/app-state.service';
import { filter, first } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { MapService } from '@core/services/map.service';
import { Store } from '@ngrx/store';
import {
  selectActiveMarker,
  selectCitiesVisible,
  selectStoresVisible,
  ZOOM_SHOW_COUNTRIES,
} from '@app/modules/map/store/map.selectors';
import { selectAllCities } from '@app/modules/cities/store/city.selectors';
import { selectAllCountries } from '@app/modules/countries/store/country.selectors';
import { selectAllStores } from '@app/modules/shops/store/shop.selectors';
import { setActiveStoreID } from '@app/modules/shops/store/shop.actions';
import { CitiesOverlay, CountriesOverlay } from '@modules/markers/overlays';
import { MarkerObject } from '@modules/markers/classes/marker-object';
import { selectSelectedHighlightStrategy } from '@modules/markers/store/marker.selectors';
import { HighlightStrategy } from '@modules/markers/classes/highlight-strategies';
import { beginLoading, endLoading } from '@app/modules/global/store/global.actions';
import { selectIsMobile, selectMapVisible, selectNeedsMap } from '@app/modules/global/store/global.selectors';
import { setActiveMarker2Store, unsetActiveMarker } from '@app/modules/map/store/map.actions';
import { NavigationStart, Router } from '@angular/router';
import { selectTodaysVisits, selectUser } from '@app/modules/auth/store/auth.selectors';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';

@Injectable({
  providedIn: 'root',
})
export class MarkersService {
  private map: google.maps.Map | undefined;

  private user$ = this.store.select(selectUser);
  private allStores$ = this.store.select(selectAllStores);
  private allCities$ = this.store.select(selectAllCities);
  private allCountries$ = this.store.select(selectAllCountries);
  private needsMap$ = this.store.select(selectNeedsMap);
  private mapVisible$ = this.store.select(selectMapVisible);
  private isMobile$ = this.store.select(selectIsMobile);
  private activeMarker$ = this.store.select(selectActiveMarker);
  private activePosition$ = this.store.select(selectPosition);
  private storesVisible$ = this.store.select(selectStoresVisible);
  private citiesVisible$ = this.store.select(selectCitiesVisible);
  private highlightStrategy$ = this.store.select(selectSelectedHighlightStrategy);
  private visitsToday$ = this.store.select(selectTodaysVisits);

  private markers: MarkerObject[] = [];

  private hoverMarker: google.maps.Marker | undefined;
  private highlightMarker: google.maps.Marker | undefined;
  private infoWindow: google.maps.InfoWindow | undefined;

  private line: google.maps.Polyline | undefined;
  private path: google.maps.Polyline | undefined;
  private pathToday: google.maps.Polyline | undefined;

  private overlay: CitiesOverlay | CountriesOverlay | undefined;

  private focused: BehaviorSubject<MarkerObject | null> = new BehaviorSubject<MarkerObject | null>(null);
  readonly focused$: Observable<MarkerObject | null> = this.focused.asObservable();

  constructor(
    private appState: AppStateService,
    private mapService: MapService,
    private router: Router,
    private store: Store
  ) {
    this.mapService.map$.pipe(first((x) => !!x)).subscribe((map) => this.init(map));

    // bei Navigation hoverOff aufrufen
    this.router.events
      .pipe(filter((e): e is NavigationStart => e instanceof NavigationStart))
      .subscribe(() => this.hoverOff());
  }

  drawTodaysPath(positions: LatLng[]) {
    if (this.pathToday === undefined) {
      this.pathToday = new google.maps.Polyline({
        path: positions,
        geodesic: true,
        strokeColor: '#0000FF',
        strokeOpacity: 1.0,
        strokeWeight: 1,
      });
    } else {
      this.pathToday.setPath(positions);
    }

    this.pathToday.setMap(this.map ?? null);
  }
  drawPath(positions: LatLng[]) {
    if (this.path === undefined) {
      this.path = new google.maps.Polyline({
        path: positions,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 1,
      });
    } else {
      this.path.setPath(positions);
    }

    this.path.setMap(this.map ?? null);
  }
  hidePath() {
    this.path?.setMap(null);
  }

  private getUserForStrategy(strategy: HighlightStrategy): User | null {
    let user: User | null = null;
    if (strategy.userRelated) {
      this.user$.pipe(first()).subscribe((u) => (user = u));
    }
    return user;
  }

  private setStoreMarkerColor(
    marker: MarkerObject | undefined,
    strategy: HighlightStrategy,
    store: ShopBasics | undefined,
    user: User | null
  ) {
    if (marker && store) {
      marker.setColor(strategy.getColor(store, user));
    }
  }

  updateSelectedStoreMarkerColors(ids: number[], strategy: HighlightStrategy) {
    const user = this.getUserForStrategy(strategy);

    this.allStores$.pipe(first()).subscribe((stores) =>
      ids.forEach((storeid) =>
        this.setStoreMarkerColor(
          this.markers.find((m) => m.storeid === storeid),
          strategy,
          stores.find((s) => s.id === storeid),
          user
        )
      )
    );
  }

  updateAllStoreMarkerColors(strategy: HighlightStrategy) {
    const user = this.getUserForStrategy(strategy);

    this.allStores$.pipe(first()).subscribe((stores) =>
      this.markers.forEach((marker) => {
        this.setStoreMarkerColor(
          marker,
          strategy,
          stores.find((x) => x.id === marker.storeid),
          user
        );
      })
    );
  }

  init(map: google.maps.Map | null) {
    if (map && this.map === undefined) {
      this.map = map;

      this.initMarkers(map);

      // Wenn sich storesVisible ändert, dann stores oder cities zeigen
      combineLatest([this.needsMap$, this.mapVisible$, this.storesVisible$, this.citiesVisible$]).subscribe(
        ([needsMap, mapVisible, storesVisible, citiesVisible]) => {
          if (needsMap && mapVisible) {
            if (this.highlightMarker) {
              this.highlightMarker.setVisible(storesVisible || citiesVisible);
            }
            if (storesVisible) {
              this.hideCityMarkers();
              this.showStoreMarkers();
            } else {
              this.hideStoreMarkers();
              this.showCityMarkers();
            }
          }
        }
      );

      // Linie zwischen aktueller Position und aktuellem Marker zeichnen
      combineLatest([this.activeMarker$, this.activePosition$, this.storesVisible$]).subscribe(
        ([marker, pos, storesVisible]) => {
          if (storesVisible && this.map && marker && pos && marker.position) {
            const d = PositionHelpers.getDistanceBetween(marker.position, pos);
            if (d < 10) {
              const lineCoordinates = [marker.position, pos];
              if (this.line === undefined) {
                this.line = new google.maps.Polyline({
                  path: lineCoordinates,
                  geodesic: true,
                  strokeColor: '#FF0000',
                  strokeOpacity: 1.0,
                  strokeWeight: 1,
                });
              } else {
                this.line.setPath(lineCoordinates);
              }

              this.line.setMap(this.map);
            } else {
              this.line?.setMap(null);
            }
          } else {
            this.line?.setMap(null);
          }
        }
      );

      // Path Today
      this.visitsToday$.subscribe((visits) => {
        const stores: ShopBasics[] = [];
        visits.forEach((x) => {
          if (x.store) {
            stores.push(x.store);
          }
        });
        this.drawTodaysPath(stores);
      });
    }
  }

  private initMarkers(map: google.maps.Map) {
    this.hoverMarker = this.initMarker(map, MarkerDefinitions.HIGHLIGHT_MARKER, { lat: 0, lng: 0 }, '', false);

    this.activeMarker$.subscribe((x) => {
      if (x && x.position) {
        this.highlightOn(x.position, x.type);
      } else {
        this.highlightOff();
      }
    });

    if (this.mapService.storesVisible) {
      this.showStoreMarkers();
    } else {
      this.showCityMarkers();
    }
  }

  initStoresSlice(stores: ShopBasics[], lng: number) {
    this.store.dispatch(beginLoading({ what: `MarkersService.initStoreMarkersRange lng=${lng}` }));
    this.mapService.map$.pipe(first((x) => !!x)).subscribe((map) => {
      if (map) {
        stores.forEach((store) => {
          if (lng <= store.lng && store.lng < lng + 1 && this.markers.findIndex((x) => x.storeid === store.id) === -1) {
            this.initStoreMarker(map, store);
          }
        });
      }
    });
    this.store.dispatch(endLoading({ what: `MarkersService.initStoreMarkersRange lng=${lng}` }));
  }

  initAllStores(stores: ShopBasics[]) {
    this.store.dispatch(beginLoading({ what: `MarkersService.initAllStore` }));
    this.mapService.map$.pipe(first((x) => !!x)).subscribe((map) => {
      if (map) {
        stores.forEach((store) => {
          if (this.markers.findIndex((x) => x.storeid === store.id) === -1) {
            this.initStoreMarker(map, store);
          }
        });
      }
    });
    this.store.dispatch(endLoading({ what: `MarkersService.initAllStores` }));
  }

  onZoomChanged() {
    if (this.map) {
      const newShowStores = this.mapService.storesVisible;

      this.hideCityMarkers();
      if (!newShowStores) {
        this.showCityMarkers();
      }
    }
  }

  private showCityMarkers() {
    if (this.map && !this.mapService.storesVisible) {
      const zoom = this.map.getZoom();
      if (zoom) {
        if (zoom > ZOOM_SHOW_COUNTRIES) {
          this.allCities$.pipe(first((x) => x.length > 0)).subscribe((cities) => {
            this.initCitiesOverlay(cities);
          });
        } else {
          this.allCountries$.pipe(first((x) => x.length > 0)).subscribe((countries) => {
            this.initCountriesOverlay(countries);
          });
        }
      }
    }
  }

  private initCitiesOverlay(cities: City[]) {
    if (this.map) {
      const zoom = this.map.getZoom();
      if (zoom && (this.overlay === undefined || this.overlay.zoom !== zoom)) {
        this.overlay?.setMap(null);
        this.overlay = new CitiesOverlay(this.map, cities, this.appState, this.store, zoom);
      }
    }
  }

  private initCountriesOverlay(countries: Country[]) {
    if (this.map) {
      const zoom = this.map.getZoom();
      if (zoom && (this.overlay === undefined || this.overlay.zoom !== zoom)) {
        this.overlay?.setMap(null);
        this.overlay = new CountriesOverlay(this.map, countries, this.appState, this.store, zoom);
      }
    }
  }

  private hideCityMarkers() {
    this.overlay?.setMap(null);
    this.overlay = undefined;
  }

  private showStoreMarkers() {
    this.markers.forEach((marker) => marker.marker?.setVisible(true));
  }

  private hideStoreMarkers() {
    this.markers.forEach((marker) => marker.marker?.setVisible(false));
  }

  private initStoreMarker(map: google.maps.Map, store: ShopBasics): void {
    const visible = this.mapService.storesVisible;
    let strategy: HighlightStrategy | undefined;
    this.highlightStrategy$.pipe(first()).subscribe((s) => (strategy = s));
    let color = MarkerColor.blue;
    if (strategy) {
      let user: User | null = null;
      if (strategy.userRelated) {
        this.user$.pipe(first()).subscribe((u) => (user = u));
      }
      color = strategy.getColor(store, user);
    }
    const marker = this.initMarker(map, MarkerDefinitions.getStoreMarker(color), store, store.name, visible);
    const markerObject = new MarkerObject(marker, store.id, color);
    this.markers.push(markerObject);
    marker.addListener('click', () => {
      this.focused.next(markerObject);
      this.allStores$.pipe(first()).subscribe((stores) => {
        const s = stores.find((x) => store.id === x.id);
        if (s) {
          this.store.dispatch(setActiveStoreID({ id: s.id }));
          this.store.dispatch(setActiveMarker2Store({ position: s, storeId: s.id }));
          this.appState.gotoStore(true);
        }
      });
    });

    marker.addListener('mouseover', () => {
      this.isMobile$.pipe(first()).subscribe((isMobile) => {
        if (!isMobile) {
          if (!this.infoWindow) {
            this.infoWindow = new google.maps.InfoWindow({
              disableAutoPan: true,
            });
          }
          const content = `<div style="width:300px">
            <div><img src="/api/stores/${store.id}/image" style="height:192px;object-fit:cover" width="300" height="192"></div>
            <div><b>${store.name}</b></div>
            </div>`;
          // TODO: Asynchron könnte man dem Content noch weitere Infos (z.B. Herz) hinzufügen
          this.infoWindow.setContent(content);
          this.infoWindow.open(this.map, marker);
        }
      });
    });
    marker.addListener('mouseout', () => {
      this.infoWindow?.close();
    });
  }

  updateStoreMarker(store: ShopBasics) {
    const marker = this.markers.find((x) => x.storeid === store.id);
    if (marker) {
      marker.marker?.setPosition(store);
    } else {
      if (this.map) {
        this.initStoreMarker(this.map, store);
      }
    }
  }

  private initMarker(
    map: google.maps.Map,
    markerOptions: google.maps.MarkerOptions,
    pos: LatLng,
    title: string,
    visible: boolean
  ) {
    const opt = {
      ...markerOptions,
      map,
      position: new google.maps.LatLng(pos.lat, pos.lng),
      //label: {
      //  text: '•', // ❤★★♥✔✓•
      //  color: 'red'
      //},
      // für Labels zusätzlich in icon labelOrigin: { x: 12, y: 13 } oder ähnlich einfügen
      title,
      visible,
    };
    return new google.maps.Marker(opt);
  }

  hoverOn(pos: LatLng) {
    if (this.hoverMarker) {
      this.hoverMarker.setPosition(new google.maps.LatLng(pos.lat, pos.lng));
      this.hoverMarker.setVisible(true);
      this.hoverMarker.setAnimation(google.maps.Animation.BOUNCE);
    }
  }
  hoverOff() {
    if (this.hoverMarker) {
      this.hoverMarker.setVisible(false);
    }
  }

  highlightOn(pos: LatLng, type: ActiveMarkerType) {
    if (this.map) {
      this.highlightOff();

      const def =
        type === 'pin' || type === 'store' ? MarkerDefinitions.HIGHLIGHT_MARKER : MarkerDefinitions.NOT_IN_DB_MARKER;

      this.highlightMarker = this.initMarker(this.map, def, pos, '', true);
      this.highlightMarker.addListener('click', () => this.store.dispatch(unsetActiveMarker()));
    }
  }
  highlightOff() {
    if (this.highlightMarker) {
      this.highlightMarker.setMap(null);
      this.highlightMarker = undefined;
    }
  }

  setMarkerDraggable(shopid: number, draggable: boolean) {
    const marker = this.markers.find((x) => x.storeid === shopid);
    if (marker && marker.marker) {
      marker.marker.setDraggable(draggable);
    }
  }
  getMarkerPosition(shopid: number): LatLng | null {
    const marker = this.markers.find((x) => x.storeid === shopid);
    if (marker && marker.marker) {
      const pos = marker.marker.getPosition();
      if (pos) {
        return { lat: pos.lat(), lng: pos.lng() };
      }
    }
    return null;
  }
  setMarkerPosition(shopid: number, pos: LatLng) {
    const marker = this.markers.find((x) => x.storeid === shopid);
    if (marker && marker.marker) {
      marker.marker.setPosition(pos);
    }
  }
}
