<div
  class="rs-panel-row rs-clickable rs-store-panel-row store-item-border noselect"
  [class.redborder]="item.supporter"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
>
  <div class="store-item-container">
    <div class="store-item-icon">
      <img *ngIf="iconurl !== ''" src="{{ iconurl }}" />
    </div>
    <div class="store-item-title-container">
      <div class="store-item-title">
        <fa-icon *ngIf="gotoGoto$ | async" [icon]="faPlusSquare" class="red mr-3"></fa-icon>
        <fa-icon *ngIf="gotoDont$ | async" [icon]="faMinusSquare" class="gray mr-3"></fa-icon>
        <fa-icon *ngIf="gotoGone$ | async" [icon]="faCheckSquare" class="green mr-3"></fa-icon>
        <fa-icon *ngIf="likeLike$ | async" [icon]="faSolidHeart" class="red mr-3"></fa-icon>
        <fa-icon *ngIf="likeDont$ | async" [icon]="faSolidHeart" class="gray mr-3"></fa-icon>
        <fa-icon
          *ngIf="item.supporter"
          transform="up-3"
          [fixedWidth]="true"
          [icon]="faStar"
          class="yellow mr-3"
        ></fa-icon>
        <b>{{ item.name }}</b>
      </div>
      <div class="store-item-title-info">
        <span *ngIf="times !== ''"
          ><span [ngClass]="[timesClass]">{{ times }}</span>
          <span class="rs-separator"> |</span>
        </span>
        <span *ngIf="distance && distance >= 1.0">
          {{ distance | number: '1.1-1' }} km {{ direction }}
          <span class="rs-separator"> |</span>
        </span>
        <span *ngIf="distance && distance >= 0.0 && distance < 1.0"
          ><span class="near"> {{ distance * 1000.0 | number: '1.0-0' }} m {{ direction }}</span>
          <span class="rs-separator"> |</span>
        </span>
        {{ item.address }}
      </div>
    </div>
  </div>
</div>
