import { Component, OnInit } from '@angular/core';
import { AppStateService } from '@core/services/app-state.service';
import {
  faMapMarkedAlt,
  faList,
  faWalking,
  faFlag,
  faSearch,
  faHeart,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { HelperService } from '@core/services/helper.service';
import { DialogsService } from '@core/services/dialogs.service';
import { Store } from '@ngrx/store';
import {
  selectRandomSupporters,
  selectStoresByDistance,
  selectSupportersByDistance,
  selectSupportersLoaded,
} from '@app/modules/shops/store/shop.selectors';
import { selectSelectedCitiesByDistance } from '@app/modules/cities/store/city.selectors';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { selectPosition, selectPositionErrorDetails } from '@app/modules/position/store/position.selectors';
import { activateHomeDiv } from '@app/modules/global/store/global.actions';
import { selectIsMobile } from '@app/modules/global/store/global.selectors';
import { LatLngPos, ShopBasics } from '@app/shared/classes';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import { selectPinsByDistance } from '@app/modules/auth/store/auth.selectors';
import { Unsubscriber } from '@app/framework/unsubscriber';

@Component({
  selector: 'rs-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends Unsubscriber implements OnInit {
  supportersByDistance$ = this.store.select(selectSupportersByDistance);
  randomSupporters$ = this.store.select(selectRandomSupporters);
  storesByDistance$ = this.store.select(selectStoresByDistance);
  cities$ = this.store.select(selectSelectedCitiesByDistance);
  position$ = this.store.select(selectPosition);
  positionError$ = this.store.select(selectPositionErrorDetails);
  supportersLoaded$ = this.store.select(selectSupportersLoaded);

  pins$ = this.store.select(selectPinsByDistance);

  stores$ = new BehaviorSubject<ShopBasics[]>([]);
  supporters$ = new BehaviorSubject<ShopBasics[]>([]);
  oldPosition: LatLngPos | null = null;

  isMobile = false;

  faMap = faMapMarkedAlt;
  faCity = faList;
  faNear = faWalking;
  faFlag = faFlag;
  faSearch = faSearch;
  faSupport = faHeart;
  faSpinner = faSpinner;

  private isMobile$ = this.store.select(selectIsMobile);

  constructor(
    public appState: AppStateService,
    public dialogsService: DialogsService,
    public helper: HelperService,
    private store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(activateHomeDiv());
    this.appState.setActiveTitle('recordstores.love - the record store map');

    this.unsubscribeLater(
      this.isMobile$.subscribe((x) => (this.isMobile = x)),

      // Ich möchte die Entfernung zu den Supporters neu berechnen, wenn sich die
      // eigene Position ändert, aber nur dann, wenn der nächste Supporter keine
      // 50km entfernt ist. Ansonsten würde es bei jedem Positionsupdate flackern,
      // auch wenn die Stadt sehr weit entfernt ist.
      combineLatest([this.supportersByDistance$, this.storesByDistance$, this.position$]).subscribe(
        ([supporters, stores, position]) => {
          const oldStores = this.stores$.value;
          const mediumReducer = (a, b: ShopBasics) => a + (b.isMedium ? 1 : 0);
          if (
            supporters.length !== this.supporters$.value.length ||
            stores.length !== oldStores.length ||
            stores.map((x) => x.store).reduce(mediumReducer, 0) !== oldStores.reduce(mediumReducer, 0)
          ) {
            this.supporters$.next(supporters);
            this.stores$.next(stores.map((x) => x.store));
            this.oldPosition = position;
          } else if (position && this.oldPosition) {
            // Update, wenn man 10m gegangen ist.
            const gone = PositionHelpers.getDistanceBetween(position, this.oldPosition);
            if (gone > 0.1) {
              this.supporters$.next(supporters);
              this.stores$.next(stores.map((x) => x.store));
              this.oldPosition = position;
            }
          }
        }
      )
    );
  }
}
