import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShopContact } from '@app/shared/classes';

export interface ContactFormResult {
  contact: ShopContact;
  note: string;
}

const reg = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;

@Component({
  selector: 'rs-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  @Input() contact!: ShopContact;
  @Input() withNote = true;
  @Output() closeForm = new EventEmitter<ContactFormResult | null>();

  form: FormGroup = this.fb.group({
    phone: [''],
    email: ['', Validators.email],
    website: ['', Validators.pattern(reg)],
    facebook: ['', Validators.pattern(reg)],
    discogs: ['', Validators.pattern(reg)],
    ebay: ['', Validators.pattern(reg)],
    note: [''],
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.patchValue(this.contact);
  }

  close() {
    this.closeForm.emit(null);
  }

  submit() {
    const formValue = this.form.value;

    const result: ContactFormResult = {
      contact: {
        ...this.contact,
        ...formValue,
      },
      note: formValue.note,
    };

    this.closeForm.emit(result);
  }
}
