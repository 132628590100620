/* eslint-disable radix */
export interface Image {
  id: number;
  imageurl: string;
  rotation: number;
}

export interface ImageRaw {
  id: string;
  imageurl: string;
  rotation: string;
}

export class ImageFactory {
  static fromRaw(img: ImageRaw): Image {
    return {
      ...img,
      id: parseInt(img.id),
      rotation: parseInt(img.rotation),
    };
  }

  static fromRawArray(images: ImageRaw[]): Image[] {
    return images ? images.map((item) => ImageFactory.fromRaw(item)) : [];
  }
}
