import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatMap, withLatestFrom, filter, map, catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromShop from './shop.reducer';
import * as ShopActions from './shop.actions';
import { setActiveCity } from '@app/modules/cities/store/city.actions';
import { DataService } from '@core/services/data.service';
import {
  selectActiveStore,
  selectAllShopsInActiveCity,
  selectAllStores,
  selectDetailedStore,
  selectLoadedLngs,
  selectLoadingOrLoadedMediumIds,
  selectMiscSupporters,
  selectStoresByDistance,
  selectSupportersInCountry,
  selectSupportersInState,
  selectSupportersNearDetailedStore,
} from './shop.selectors';
import { Action, resultMemoize, Store } from '@ngrx/store';
import { HelperService } from '@core/services/helper.service';
import { selectActiveCity, selectAllCities } from '@app/modules/cities/store/city.selectors';
import { boundsChanged } from '@app/modules/map/store/map.actions';
import { selectStoresVisible } from '@app/modules/map/store/map.selectors';
import { selectSelectedHighlightStrategy } from '@app/modules/markers/store/marker.selectors';
import { MarkersService } from '@core/services/markers.service';
import {
  activateCountryDiv,
  activateHomeDiv,
  activateNearDiv,
  activateStateDiv,
} from '@app/modules/global/store/global.actions';
import { selectActiveDiv } from '@app/modules/global/store/global.selectors';
import { filterNullish } from '@app/shared/helpers/rxjs.helpers';
import { ShopFactory } from '@shared/classes';
import { Router } from '@angular/router';
import { searchGoogleSuccess } from '@app/modules/search/store/search.actions';
import { getPosition, getPositionSuccess } from '@app/modules/position/store/position.actions';
import { setActiveCountry, setActiveState } from '@app/modules/countries/store/country.actions';

@Injectable()
export class ShopEffects implements OnInitEffects {
  initialization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.initApp),
      map(() => ShopActions.loadSupporters())
    )
  );

  loadSupporters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.loadSupporters),
      // ich nehme hier concatMap, weil switchMap die abgebrochenen Ladevorgänge nicht beenden würde => Ladebalken geht nicht weg
      concatMap(() =>
        this.data.getSupporters().pipe(
          map((stores) => ShopActions.loadSupportersSuccess({ stores })),
          catchError((error) => of(ShopActions.loadSupportersFailure({ error })))
        )
      )
    )
  );

  loadStoreBasicsForCity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveCity),
      map((action) => action.city),
      withLatestFrom(this.store.select(selectLoadedLngs)),
      map(([city, lngs]) => {
        const result: number[] = [];
        for (let i = Math.floor(city.lngmin); i <= Math.floor(city.lngmax); i++) {
          if (lngs.indexOf(i) < 0) {
            result.push(i);
          }
        }
        return result;
      }),
      switchMap((lngs) => lngs.map((lng) => ShopActions.loadStoresSlice({ lng })))
    )
  );

  loadStoreBasicsOnBoundsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(boundsChanged),
      withLatestFrom(this.store.select(selectLoadedLngs), this.store.select(selectStoresVisible)),
      filter(([action, lngs, visible]) => visible),
      map(([action, lngs]) => {
        const result: number[] = [];
        for (let i = Math.floor(action.bounds.lngmin); i <= Math.floor(action.bounds.lngmax); i++) {
          if (!lngs.includes(i)) {
            result.push(i);
          }
        }
        return result;
      }),
      switchMap((lngs) => lngs.map((lng) => ShopActions.loadStoresSlice({ lng })))
    )
  );

  loadStoreBasicsOnNewPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPositionSuccess),
      withLatestFrom(this.store.select(selectLoadedLngs)),
      map(([action, lngs]) => {
        const result: number[] = [];
        for (let i = Math.floor(action.position.lng - 1.0); i < Math.ceil(action.position.lng + 1); i++) {
          if (lngs.indexOf(i) < 0) {
            result.push(i);
          }
        }
        return result;
      }),
      switchMap((lngs) => lngs.map((lng) => ShopActions.loadStoresSlice({ lng })))
    )
  );

  loadStoresSlice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.loadStoresSlice),
      mergeMap((action) =>
        this.data.getStoresSlice(action.lng).pipe(
          map((stores) => ShopActions.loadStoresSliceSuccess({ lng: action.lng, stores })),
          catchError((error) => of(ShopActions.loadStoresSliceFailure({ lng: action.lng, error })))
        )
      )
    )
  );

  loadAllStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.loadAllStores),
      mergeMap((action) =>
        this.data.getAllStores().pipe(
          map((stores) => ShopActions.loadAllStoresSuccess({ stores })),
          catchError((error) => of(ShopActions.loadAllStoresFailure({ error })))
        )
      )
    )
  );

  loadPositionOnHomeAndNear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateHomeDiv, activateNearDiv),
      map(() => getPosition())
    )
  );

  loadMediumStoresForActiveStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.setDetailedStore, ShopActions.loadStoresSliceSuccess),
      withLatestFrom(this.store.select(selectDetailedStore), this.store.select(selectSupportersNearDetailedStore)),
      filter(([action, store, supporters]) => !!store && !store.supporter),
      map(([action, store, supporters]) => ({
        stores: supporters,
        from: !!store ? 'STORE ' + store.id + ' in ' + this.helpers.store2cityurl(store) : 'unknown store',
      })),
      map((tuple) => ShopActions.tryLoadMediumStores(tuple))
    )
  );

  // Hier könnte noch rein, ob bei einem mobilen Gerät gerade die Kartensicht aktiv ist
  // => wenn ja, dann sind die Läden nur als Marker sichtbar und müssen erst geladen werden,
  // wenn von der Kartenansicht auf die City-Ansicht gewechselt wird.
  loadMediumStoresForActiveCity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveCity, ShopActions.loadMediumStoresSuccess, ShopActions.loadStoresSliceSuccess),
      withLatestFrom(
        this.store.select(selectActiveDiv),
        this.store.select(selectActiveCity),
        this.store.select(selectAllShopsInActiveCity),
        this.store.select(selectActiveStore),
        this.store.select(selectLoadingOrLoadedMediumIds)
      ),
      filter(([action, div, city, stores, store, ids]) => div === 'CITY' && city !== null),
      concatMap(([action, div, city, stores, store, ids]): Action[] => {
        // Falls noch kein aktiver Store gesetzt ist einen Supporter aus der City wählen
        if (stores.length > 0 && !store) {
          const supporters = stores.filter((x) => x.supporter);
          if (supporters.length > 0) {
            const id = Math.floor(Math.random() * supporters.length);
            this.store.dispatch(ShopActions.setActiveStoreID({ id: supporters[id].id }));
          } else {
            const id = Math.floor(Math.random() * stores.length);
            this.store.dispatch(ShopActions.setActiveStoreID({ id: stores[id].id }));
          }
        }
        // Medium-Stores laden
        const storesToBeLoaded = stores.filter((x) => !x.isMedium && ids.indexOf(x.id) < 0);
        const from = city
          ? city.countrycode + '/' + (city.countrycode === 'US' ? city.state + '/' : '') + city.city
          : 'unknown city';
        return storesToBeLoaded.length ? [ShopActions.loadMediumStores({ stores: storesToBeLoaded, from })] : [];
      })
    )
  );

  loadMediumStoresForActiveCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveCountry, ShopActions.loadMediumStoresSuccess, ShopActions.loadStoresSliceSuccess),
      withLatestFrom(this.store.select(selectActiveDiv), this.store.select(selectSupportersInCountry)),
      filter(([action, div, supporters]) => div === 'COUNTRY' && supporters.length > 0),
      map(([action, div, supporters]) => supporters),
      map((stores) => ShopActions.tryLoadMediumStores({ stores, from: stores[0].countrycode }))
    )
  );

  loadMediumStoresForActiveState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveState, ShopActions.loadMediumStoresSuccess, ShopActions.loadStoresSliceSuccess),
      withLatestFrom(this.store.select(selectActiveDiv), this.store.select(selectSupportersInState)),
      filter(([action, div, supporters]) => div === 'STATE' && supporters.length > 0),
      map(([action, div, supporters]) => supporters),
      map((stores) => ShopActions.tryLoadMediumStores({ stores, from: stores[0].countrycode }))
    )
  );

  loadMediumStoresForNearAndHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        activateHomeDiv,
        activateNearDiv,
        activateCountryDiv,
        activateStateDiv,
        getPositionSuccess,
        ShopActions.loadMediumStoresSuccess,
        ShopActions.loadStoresSliceSuccess,
        ShopActions.loadSupportersSuccess
      ),
      withLatestFrom(
        this.store.select(selectActiveDiv),
        this.store.select(selectStoresByDistance),
        this.store.select(selectMiscSupporters),
        this.store.select(selectActiveStore),
        this.store.select(selectLoadingOrLoadedMediumIds)
      ),
      filter(
        ([action, div, stores, miscSupporters, store, ids]) =>
          div === 'NEAR' || div === 'HOME' || div === 'VISIBLE' || div === 'COUNTRY' || div === 'STATE'
      ),
      map(([action, div, stores, miscSupporters, store, ids]) => {
        const spprtrs =
          div === 'COUNTRY'
            ? miscSupporters.inCountry
            : div === 'STATE'
            ? miscSupporters.inState
            : miscSupporters.byDistance.length > 0
            ? miscSupporters.byDistance
            : miscSupporters.byRandom;
        let storesToBeLoaded = [...stores.map((x) => x.store), ...spprtrs];
        storesToBeLoaded = storesToBeLoaded.filter((x) => !x.isMedium && ids.indexOf(x.id) < 0);
        // activeStore setzen
        if (storesToBeLoaded.length > 0 && !store) {
          const s = miscSupporters.byDistance.length > 0 ? miscSupporters.byDistance[0] : stores[0]?.store;
          if (s && !store) {
            this.store.dispatch(ShopActions.setActiveStoreID({ id: s.id }));
          }
        }
        return { stores: storesToBeLoaded, from: div ?? 'unknown div' };
      }),
      filter((x) => x.stores.length > 0),
      map((x) => ShopActions.loadMediumStores(x))
    )
  );

  saveStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.saveStore),
      concatMap((action) =>
        this.data.saveStore(action.store).pipe(
          map((newStore) =>
            ShopActions.saveStoreSuccess({
              store: newStore,
              isNew: action.store.id !== newStore.id,
            })
          ),
          catchError((error) => of(ShopActions.saveStoreFailure({ error })))
        )
      )
    )
  );

  saveStoreSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.saveStoreSuccess),
      tap((action) => {
        if (action.isNew) {
          this.router.navigateByUrl('/' + action.store.id.toString());
        }
      }),
      map((action) =>
        ShopActions.addStore({
          store: ShopFactory.fromDetailsToBasics(action.store),
        })
      )
    )
  );

  saveStoreAddMarker$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShopActions.addStore),
        tap((action) => this.markers.updateStoreMarker(action.store))
      ),
    { dispatch: false }
  );

  updateImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.updateImage),
      concatMap((action) =>
        this.data.updateImage(action.payload).pipe(
          map((image) => ShopActions.updateImageSuccess({ image })),
          catchError((error) => of(ShopActions.updateImageFailure({ error })))
        )
      )
    )
  );

  tryLoadMediumStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.tryLoadMediumStores),
      withLatestFrom(this.store.select(selectLoadingOrLoadedMediumIds)),
      map(([action, ids]) => ({
        stores: action.stores.filter((x) => ids.indexOf(x.id) < 0),
        from: action.from,
      })),
      filter((tuple) => tuple.stores.length > 0),
      map((x) => ShopActions.loadMediumStores(x))
    )
  );

  loadMediumStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.loadMediumStores),
      mergeMap((action) =>
        this.data.getMedium(action.stores, action.from).pipe(
          map((stores) => ShopActions.loadMediumStoresSuccess({ stores })),
          catchError((error) => of(ShopActions.loadMediumStoresFailure({ error })))
        )
      )
    )
  );

  loadStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.loadStore),
      mergeMap((action) =>
        this.data.getSingle(action.id).pipe(
          map((store) => ShopActions.loadStoreSuccess({ store })),
          catchError((error) => of(ShopActions.loadStoreFailure({ error })))
        )
      )
    )
  );

  setActiveStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.setActiveStoreID),
      withLatestFrom(this.store.select(selectAllCities), this.store.select(selectActiveStore)),
      map(([action, cities, store]) =>
        cities?.find((c) => this.helpers.city2url(c) === this.helpers.store2cityurl(store))
      ),
      filterNullish(),
      filter((city) => city.opencnt > 1),
      map((city) => setActiveCity({ city }))
    )
  );

  updateMarkersAfterMediumLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShopActions.loadMediumStoresSuccess),
        withLatestFrom(this.store.select(selectSelectedHighlightStrategy)),
        filter(([action, strategy]) => strategy.updateOnMedium),
        map(([action, strategy]) =>
          this.markers.updateSelectedStoreMarkerColors(
            action.stores.map((s) => s.id),
            strategy
          )
        )
      ),
    { dispatch: false }
  );

  triggerLoadStoresByPlaceID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchGoogleSuccess),
      map((action) => action.result.map((item) => item.place_id)),
      map((placeids) => ShopActions.loadStoresByPlaceId({ placeids }))
    )
  );

  loadStoresByPlaceID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.loadStoresByPlaceId),
      mergeMap((action) =>
        this.data.getStoresByPlaceID(action.placeids).pipe(
          map((stores) => ShopActions.loadStoresByPlaceIdSuccess({ stores })),
          catchError((error) => of(ShopActions.loadStoresByPlaceIdError({ error })))
        )
      )
    )
  );

  loadMediumStoresForPlaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.loadStoresByPlaceIdSuccess),
      map((action) => action.stores),
      filter((stores) => stores.length > 0),
      map((stores) => ShopActions.tryLoadMediumStores({ stores, from: 'Google Search' }))
    )
  );

  constructor(
    private actions$: Actions,
    private data: DataService,
    private helpers: HelperService,
    private markers: MarkersService,
    private router: Router,
    private store: Store<fromShop.ShopsState>
  ) {}

  // Dies ist nötig, weil die Actions IsMobile und ViewMap schon VOR der Effects-Initialization feuern!
  ngrxOnInitEffects(): Action {
    return ShopActions.initApp();
  }
}
