<div class="rs-panel">
  <div>
    <h1 class="rs-header-1">
      Highlight Stores
      <span class="rs-h-small"></span>
    </h1>
  </div>

  <rs-breadcrumb [breadcrumbs]="[{ routerLink: '', caption: 'highlights' }]"></rs-breadcrumb>

  <div class="impressum" *ngIf="highlight$ | async as hs">
    <p>
      <strong>Highlight Stores</strong>
    </p>
    <p>You can change the colours of the markers if you want. These are the options:</p>

    <mat-accordion>
      <mat-expansion-panel [expanded]="hs === 'none'">
        <mat-expansion-panel-header>
          <mat-panel-title [class.active]="hs === 'none'">
            No Highlighting <span *ngIf="hs === 'none'">&nbsp;(Selected)</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24black.png" />
          <span>All stores</span>
        </p>
        <p *ngIf="hs !== 'none'">
          <button mat-button color="primary" (click)="setHighlightStrategy('none')">Choose This</button>
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="hs === 'supporters'">
        <mat-expansion-panel-header>
          <mat-panel-title [class.active]="hs === 'supporters'">
            Supporters <span *ngIf="hs === 'supporters'">&nbsp;(Selected)</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24red.png" />
          <span>Supporter</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24black.png" />
          <span>No Supporter</span>
        </p>
        <p *ngIf="hs !== 'supporters'">
          <button mat-button color="primary" (click)="setHighlightStrategy('supporters')">Choose This</button>
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="hs === 'photos'">
        <mat-expansion-panel-header>
          <mat-panel-title [class.active]="hs === 'photos'">
            Stores Without Photo
            <span *ngIf="hs === 'photos'">&nbsp;(Selected)</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24red.png" />
          <span>Store has no photo</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24black.png" />
          <span>Store has a photo</span>
        </p>
        <p *ngIf="hs !== 'photos'">
          <button mat-button color="primary" (click)="setHighlightStrategy('photos')">Choose This</button>
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="hs === 'opennow'">
        <mat-expansion-panel-header>
          <mat-panel-title [class.active]="hs === 'opennow'">
            Stores Open Now
            <span *ngIf="hs === 'opennow'">&nbsp;(Selected)</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24green.png" />
          <span>Store is open now</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24orange.png" />
          <span>Store will open later today</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24gray.png" />
          <span>Store is closed and will not open today</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24black.png" />
          <span>No info about hours today</span>
        </p>
        <p *ngIf="hs !== 'opennow'">
          <button mat-button color="primary" (click)="setHighlightStrategy('opennow')">Choose This</button>
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="hs === 'likes'">
        <mat-expansion-panel-header>
          <mat-panel-title [class.active]="hs === 'likes'">
            My Likes <span *ngIf="hs === 'likes'">&nbsp;(Selected)</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24red.png" />
          <span>I like this store</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24gray.png" />
          <span>I don't like this store</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24black.png" />
          <span>I have not decided yet</span>
        </p>
        <p *ngIf="authenticated && hs !== 'likes'">
          <button mat-button color="primary" (click)="setHighlightStrategy('likes')">Choose This</button>
        </p>
        <p *ngIf="!authenticated">
          <em>Only for registered users.</em>
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="hs === 'gotos'">
        <mat-expansion-panel-header>
          <mat-panel-title [class.active]="hs === 'gotos'">
            My Gotos <span *ngIf="hs === 'gotos'">&nbsp;(Selected)</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24red.png" />
          <span>GoTo! (Stop Here!)</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24green.png" />
          <span>Gone (Already been here)</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24gray.png" />
          <span>Don't want to go here</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24black.png" />
          <span>I have not decided yet</span>
        </p>
        <p *ngIf="authenticated && hs !== 'gotos'">
          <button mat-button color="primary" (click)="setHighlightStrategy('gotos')">Choose This</button>
        </p>
        <p *ngIf="!authenticated">
          <em>Only for registered users.</em>
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="hs === 'togoxhours'">
        <mat-expansion-panel-header>
          <mat-panel-title [class.active]="hs === 'togoxhours'">
            Togo Stores x Hours
            <span *ngIf="hs === 'togoxhours'">&nbsp;(Selected)</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24green.png" />
          <span>On my GoTo-List and Open Now</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24orange.png" />
          <span>On my GoTo-List and will open later today</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24black.png" />
          <span>On my GoTo-List but was open or closed today</span>
        </p>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24gray.png" />
          <span>Not on my GoTo-List</span>
        </p>
        <p *ngIf="authenticated && hs !== 'togoxhours'">
          <button mat-button color="primary" (click)="setHighlightStrategy('togoxhours')">Choose This</button>
        </p>
        <p *ngIf="!authenticated">
          <em>Only for registered users.</em>
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="hs === 'mycolours'">
        <mat-expansion-panel-header>
          <mat-panel-title [class.active]="hs === 'mycolours'">
            My Colours <span *ngIf="hs === 'mycolours'">&nbsp;(Selected)</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="rs-disk">
          <img class="mr-3" src="/assets/markers/disk24black.png" />
          <img class="mr-3" src="/assets/markers/disk24red.png" />
          <img class="mr-3" src="/assets/markers/disk24blue.png" />
          <img class="mr-3" src="/assets/markers/disk24green.png" />
          <img class="mr-3" src="/assets/markers/disk24gray.png" />
          <img class="mr-3" src="/assets/markers/disk24violet.png" />
          <img class="mr-3" src="/assets/markers/disk24orange.png" />
          <img class="mr-3" src="/assets/markers/disk24yellow.png" />
        </p>
        <p><span>All Colours Are Beautiful</span></p>
        <p *ngIf="authenticated && hs !== 'mycolours'">
          <button mat-button color="primary" (click)="setHighlightStrategy('mycolours')">Choose This</button>
        </p>
        <p *ngIf="!authenticated">
          <em>Only for registered users.</em>
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
