<div class="rs-panel">
  <div>
    <h1 class="rs-header-1">
      Donate
      <span class="rs-h-small">Show your appreciation</span>
    </h1>
  </div>

  <rs-breadcrumb [additional]="additional" [breadcrumbs]="[{ routerLink: '', caption: 'donate' }]"></rs-breadcrumb>

  <div class="impressum">
    <h2>Donate and show your appreciation</h2>
    <p class="small"><b>Record store owners</b>, <a routerLink="/support">click here to Become a Supporter</a>.</p>
    <p><strong>Why Donate?</strong></p>
    <p class="small">
      You like this project? It helped you finding record stores wherever you are? I love record stores, too, and it was
      fun creating this site. But now it's here and many people like it, so many in fact, that I get monthly invoices by
      Google. And for hosting the site. And it takes so much time to keep this site alive.
    </p>
    <p class="small">You can help me covering the costs with a donation.</p>
    <p class="small">Thank You :)</p>
    <p><strong>Donate, but how?</strong></p>
    <p class="small">
      Click here to donate now:
      <a href="https://paypal.me/rslove/10" target="_blank" rel="”noopener”">paypal.me/rslove</a>
    </p>
    <p class="small">Or select any one of the amounts below:<br /></p>
    <p>
      <a class="button rs-button" mat-flat-button href="https://www.paypal.me/rslove/5" target="_blank" rel="”noopener”"
        >5€</a
      >
      <a
        class="button rs-button"
        mat-flat-button
        href="https://www.paypal.me/rslove/10"
        target="_blank"
        rel="”noopener”"
        >10€</a
      >
      <a
        class="button rs-button"
        mat-flat-button
        href="https://www.paypal.me/rslove/25"
        target="_blank"
        rel="”noopener”"
        >25€</a
      >
      <a
        class="button rs-button"
        mat-flat-button
        href="https://www.paypal.me/rslove/50"
        target="_blank"
        rel="”noopener”"
        >50€</a
      >
      <a
        class="button rs-button"
        mat-flat-button
        href="https://www.paypal.me/rslove/100"
        target="_blank"
        rel="”noopener”"
        >100€</a
      >
    </p>
    <p class="small">If you want to do it directly, send your donation via Paypal to support@recordstores.love .</p>
    <p><strong>Thank you for your support.</strong></p>
  </div>

  <rs-contact-and-coffee title="donate" url="https://recordstores.love/donate"> </rs-contact-and-coffee>

  <rs-sharing
    url="recordstores.love/donate"
    name="recordstores.love"
    descript="Follow this link to find all record stores worldwide"
  ></rs-sharing>
</div>
