<div class="rs-panel">
  <h1 class="rs-header-1">My Private Pins</h1>

  <rs-breadcrumb [breadcrumbs]="[{ routerLink: '', caption: 'pins' }]"></rs-breadcrumb>

  <h2>Visble Pins <span class="rs-h-small">Drag Map to see other Pins</span></h2>

  <ng-container *ngIf="visiblePins$ | async as pins">
    <rs-pin-item
      *ngFor="let pin of pins"
      class="rs-pointer rs-border-bottom-lightgray"
      [item]="pin"
      [routerLink]="'/pin/' + pin.id"
    ></rs-pin-item>
    <p *ngIf="pins.length === 0">
      <ng-container *ngIf="storesVisible$ | async; else zoomInfo">
        No visible private Pins. Drag the map to see your Pins.
      </ng-container>
      <ng-template #zoomInfo> Zoom in to see your private Pins. </ng-template>
    </p>
  </ng-container>
</div>
