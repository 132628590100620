<div class="rs-panel" *ngIf="country$ | async as country">
  <h1 class="rs-header-1">
    {{ country.country }}
    <span class="rs-h-small">{{ country.countrycode }} - {{ country.opencnt }} Stores</span>
  </h1>

  <rs-breadcrumb
    [additional]="additional"
    [breadcrumbs]="[{ routerLink: '', caption: country.countrycode }]"
  ></rs-breadcrumb>

  <div *ngIf="supportersLoaded$ | async; else loading">
    <div *ngIf="supporters$ | async as supporters">
      <rs-supporters-list
        [countrycode]="country.countrycode"
        title="{{ helper.n(supporters.length, 'Supporter') }} in {{ country.country }}"
        [subtitle]="supporters.length > 1 ? 'in random order' : ''"
        [supporters]="supporters"
      ></rs-supporters-list>
    </div>
  </div>
  <ng-template #loading>
    <rs-supporters-list
      [countrycode]="country.countrycode"
      title="Loading Supporters in {{ country.country }}"
      subtitle=""
      [supporters]="[]"
    ></rs-supporters-list>
  </ng-template>

  <rs-cities-states [name]="country.country" [cities]="cities$ | async" [states]="states$ | async"></rs-cities-states>

  <rs-contact-and-coffee
    [title]="country.country + ' - ' + country.countrycode"
    [url]="'https://recordstores.love/' + country.countrycode"
  >
  </rs-contact-and-coffee>

  <rs-sharing
    [url]="'recordstores.love/' + country.countrycode"
    [name]="'All record stores in ' + country.country"
    [descript]="'Follow this link to find all record stores in ' + country.country"
  ></rs-sharing>
</div>
