import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MarkersService } from '@app/core/services/markers.service';
import { PinsService } from '@app/core/services/pins.service';
import { Unsubscriber } from '@app/framework/unsubscriber';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import { Pin } from '@app/shared/classes';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rs-pin-item',
  templateUrl: './pin-item.component.html',
  styleUrls: [],
})
export class PinItemComponent extends Unsubscriber implements OnInit, OnChanges, OnDestroy {
  @Input() item: Pin | undefined | null = undefined;
  @Input() handleMouseOver = true;

  iconurl = '';
  distance = -1;
  direction = '';

  position$ = this.store.select(selectPosition);

  private sub1?: Subscription;
  private hovering = false;

  constructor(private markersService: MarkersService, private pinService: PinsService, private store: Store) {
    super();
    this.doOnDestroy(
      () => this.sub1?.unsubscribe(),
      () => {
        if (this.hovering) {
          this.mouseLeave();
        }
      }
    );
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    this.init();
  }

  ngOnDestroy(): void {
    this.sub1?.unsubscribe();
  }

  init() {
    if (this.item) {
      this.initIconUrl();
      this.initDistance();
    }
  }

  initDistance() {
    this.sub1?.unsubscribe();
    if (this.item) {
      this.distance = -1;
      this.direction = '';
      this.sub1 = this.position$.subscribe((pos) => {
        if (pos && this.item) {
          const d = PositionHelpers.getDistanceBetween(pos, this.item);
          if (d < 250) {
            this.distance = d;
            this.direction = PositionHelpers.getDirection(pos, this.item);
          }
        }
      });
    }
  }
  initIconUrl() {
    if (this.item) {
      this.iconurl = this.pinService.getPinIcon(this.item.colour).icon.url;
    }
  }

  mouseEnter() {
    if (this.item && this.handleMouseOver) {
      this.hovering = true;
      this.markersService.hoverOn(this.item);
    }
  }

  mouseLeave() {
    if (this.item && this.handleMouseOver && this.hovering) {
      this.hovering = false;
      this.markersService.hoverOff();
    }
  }
}
