<ng-container *ngIf="store$ | async as store">
  <div class="rs-panel">
    <p>
      <fa-icon
        routerLink="/search"
        [fixedWidth]="true"
        [icon]="faSearch"
        title="Search"
        class="rs-icon flex-item-3 rs-no-outline"
      ></fa-icon>
      &nbsp;
      <button mat-flat-button (click)="loadAllStores()" color="warn">Load All</button>
      &nbsp;
      <button mat-stroked-button (click)="prev(store)">
        <fa-icon [icon]="faLeft"></fa-icon>
      </button>
      &nbsp;
      <button mat-stroked-button (click)="next(store)">
        <fa-icon [icon]="faRight"></fa-icon>
      </button>
      &nbsp;
      <button *ngIf="!autoSave" mat-stroked-button (click)="autoSave = true">AutoSave</button>
      <button *ngIf="autoSave" mat-flat-button (click)="autoSave = false" color="warn">AutoSave</button>
    </p>
    <p>
      Photos
      <fa-icon *ngIf="store.images.length > 0" [icon]="faCheck" class="green"></fa-icon>
      <fa-icon *ngIf="store.images.length === 0" [icon]="faTimes" class="red"></fa-icon>
      | PlaceID
      <fa-icon *ngIf="!!store.place_id" [icon]="faCheck" class="green"></fa-icon>
      <fa-icon *ngIf="!store.place_id" [icon]="faTimes" class="red"></fa-icon>
      | Email
      <fa-icon *ngIf="!!store.email" [icon]="faCheck" class="green"></fa-icon>
      <fa-icon *ngIf="!store.email" [icon]="faTimes" class="red"></fa-icon>
      | Desc
      <fa-icon *ngIf="!!store.descript" [icon]="faCheck" class="green"></fa-icon>
      <fa-icon *ngIf="!store.descript" [icon]="faTimes" class="red"></fa-icon>
      | Insta
      <fa-icon *ngIf="hasInsta" [icon]="faCheck" class="green"></fa-icon>
      <fa-icon *ngIf="!hasInsta" [icon]="faTimes" class="red"></fa-icon>
      | Hours
      <fa-icon *ngIf="shours !== 'Hours unknown'" [icon]="faCheck" class="green"></fa-icon>
      <fa-icon *ngIf="shours === 'Hours unknown'" [icon]="faTimes" class="red"></fa-icon>
    </p>
    <p *ngIf="error !== ''" class="red">
      {{ error }}
    </p>
    <p>
      <button *ngIf="!dragging" mat-stroked-button (click)="startDrag(store)">Start Dragging</button>
      <button *ngIf="dragging" mat-flat-button (click)="startDrag(store)" color="warn">Start Dragging</button>
      <span>
        &nbsp;
        <button *ngIf="dragging" mat-stroked-button (click)="stopDrag(store)">Stop Dragging</button>
        &nbsp;
        <button *ngIf="dragging" mat-stroked-button (click)="cancelDrag(store)">Cancel Dragging</button>
      </span>
    </p>
    <p>
      <button mat-stroked-button (click)="loadNearPlaces(store, 25)">25m Pl</button>
      &nbsp;
      <button mat-stroked-button (click)="loadNearPlaces(store, 50)">50m Pl</button>
      &nbsp;
      <button mat-stroked-button (click)="loadNearPlaces(store, 100)">100m St</button>
    </p>
    <p>
      <button *ngIf="!store.hasclosed" mat-stroked-button (click)="closeStore(store)">Closed</button>
      <button *ngIf="store.hasclosed" mat-flat-button (click)="closeStore(store)" color="warn">Closed</button>
      <span *ngIf="store.place_id">
        &nbsp;
        <button mat-stroked-button (click)="removePlaceID(store)">Remove PlaceID</button>
      </span>
      <span *ngIf="store.adminimages.length > 0">
        &nbsp;
        <button *ngIf="!hasNewImages" mat-stroked-button (click)="editPhotos(store)">
          {{ store.adminimages.length }} Photos
        </button>
        <button *ngIf="hasNewImages" mat-flat-button (click)="editPhotos(store)" color="warn">
          {{ store.adminimages.length }} Photos
        </button>
      </span>
    </p>
    <ng-container *ngIf="nearPlaces$ | async as nearPlaces">
      <p *ngIf="nearPlaces.length > 0">
        <span
          *ngFor="let place of nearPlaces"
          class="rs-pointer"
          (click)="comparePlace(place)"
          (mouseover)="highlight(place)"
          (mouseout)="highlight(null)"
          [class.bold]="place.place_id === store.place_id"
          [class.red]="place.name?.toLowerCase() === store.name.toLowerCase()"
          [class.blue]="
            place.name?.toLowerCase() !== store.name.toLowerCase() && containsKeyWord(place.name ?? '', store.name)
          "
        >
          <img [src]="place.icon" width="16" />
          &nbsp;
          {{ place.name }}
          <fa-icon *ngIf="placeIsStore(place.place_id ?? '')" [icon]="faCheck" class="green"></fa-icon>
          <br />
        </span>
      </p>
    </ng-container>
    <p *ngIf="loadingNearPlaces$ | async" class="red">
      <fa-icon title="Loading Near Places, please wait" [icon]="faSpinner" [spin]="true"></fa-icon>
      Loading Near Places...
    </p>
    <p *ngIf="loadingPlace$ | async" class="red">
      <fa-icon title="Loading Place, please wait" [icon]="faSpinner" [spin]="true"></fa-icon>
      Loading Place...
    </p>
    <rs-admin-images *ngIf="showImages" [shop]="store"></rs-admin-images>
    <ng-container *ngIf="placeAsShop$ | async as placeAsShop">
      <p>
        <b>{{ placeAsShop.name }}</b>
        | <span [class.red]="distance > 50">{{ distance }}m</span> |
        <a [href]="placeAsShop.google_url" target="_blank">gmaps</a>
      </p>
      <p *ngIf="place && place.business_status !== 'OPERATIONAL'">
        <span *ngIf="place.business_status === 'CLOSED_PERMANENTLY'" class="bold red">permanently closed</span>
        <span *ngIf="place.business_status === 'CLOSED_TEMPORARILY'" class="red">temporarily closed</span>
      </p>
      <p *ngIf="distance > 10">
        <button mat-flat-button (click)="moveStore(store, placeAsShop)" color="warn">Move</button>
      </p>
      <p *ngIf="store.name !== placeAsShop.name">
        <b class="rs-pointer" (click)="saveName(store, placeAsShop)">Name</b><br />
        {{ store.name }}<br />
        {{ placeAsShop.name }}
      </p>
      <p *ngIf="placeAsShop.city && store.city !== placeAsShop.city">
        <b class="rs-pointer" (click)="saveCity(store, placeAsShop.city)">City (by Place)</b><br />
        {{ store.city }}<br />
        {{ placeAsShop.city }}
      </p>
      <p *ngIf="altCity !== ''">
        <b class="rs-pointer" (click)="saveCity(store, altCity)">City (by Bounds)</b><br />
        {{ store.city }}<br />
        {{ altCity }}
      </p>
      <p *ngIf="store.address !== placeAsShop.address">
        <b class="rs-pointer" (click)="saveAddress(store, placeAsShop)">Address</b><br />
        {{ store.address }}<br />
        {{ placeAsShop.address }}
      </p>
      <p *ngIf="distance > 1">
        <b
          class="rs-pointer"
          (click)="saveLatLng(store, placeAsShop)"
          (mouseover)="highlightShop(placeAsShop)"
          (mouseout)="highlightShop(store)"
          >LatLng</b
        ><br />
        {{ store.lat }} | {{ store.lng }}<br />
        {{ placeAsShop.lat }} | {{ placeAsShop.lng }}
      </p>
      <p *ngIf="store.place_id !== placeAsShop.place_id">
        <b class="rs-pointer" (click)="savePlaceID(store, placeAsShop)">PlaceID</b><br />
        {{ store.place_id }}<br />
        {{ placeAsShop.place_id }}
      </p>
      <p *ngIf="store.google_url !== placeAsShop.google_url">
        <b class="rs-pointer" (click)="saveGoogleUrl(store, placeAsShop)">Google-URL</b><br />
        {{ store.google_url }}<br />
        {{ placeAsShop.google_url }}
      </p>
      <p *ngIf="placeAsShop.phone && store.phone !== placeAsShop.phone">
        <b class="rs-pointer" (click)="savePhone(store, placeAsShop)">Phone</b><br />
        {{ store.phone }}<br />
        {{ placeAsShop.phone }}
      </p>
      <p *ngIf="shours !== phours && phours !== 'Hours unknown'">
        <b class="rs-pointer" (click)="saveTimes(store, placeAsShop)">Times</b><br />
        {{ shours }}<br />
        {{ phours }}
      </p>
      <p
        *ngIf="
          placeAsShop.website &&
          placeAsShop.website !== store.website &&
          placeAsShop.website !== store.facebook &&
          placeAsShop.website !== store.discogs &&
          placeAsShop.website !== store.ebay
        "
      >
        <b>Website</b><br />
        <span class="rs-pointer" (click)="saveWebsite(store, placeAsShop)">1:</span>&nbsp;<a
          [href]="store.website"
          target="_blank"
          >{{ store.website }}</a
        ><br />
        <span class="rs-pointer" (click)="saveFacebook(store, placeAsShop)">2:</span>&nbsp;<a
          [href]="store.facebook"
          target="_blank"
          >{{ store.facebook }}</a
        ><br />
        <span class="rs-pointer" (click)="saveEbay(store, placeAsShop)">3:</span>&nbsp;<a
          [href]="store.ebay"
          target="_blank"
          >{{ store.ebay }}</a
        ><br />
        <span class="rs-pointer" (click)="saveDiscogs(store, placeAsShop)">4:</span>&nbsp;<a
          [href]="store.discogs"
          target="_blank"
          >{{ store.discogs }}</a
        ><br />
        <a [href]="placeAsShop.website" target="_blank">{{ placeAsShop.website }}</a
        ><br />
      </p>
    </ng-container>
  </div>
</ng-container>
