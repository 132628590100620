import { Component, OnInit } from '@angular/core';
import { faLayerGroup, faGlobe, faCar, faSubway, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { LocalStorageService } from '@core/services/local-storage.service';
import { MapService } from '../../../core/services/map.service';
import { PinsService } from '@app/core/services/pins.service';

@Component({
  selector: 'rs-map-layers',
  templateUrl: './map-layers.component.html',
  styleUrls: ['./map-layers.component.scss'],
})
export class MapLayersComponent implements OnInit {
  faLayerGroup = faLayerGroup;
  faSatellite = faGlobe;
  faTraffic = faCar;
  faTransit = faSubway;
  faMarker = faMapMarkerAlt;

  satellite = false;
  traffic = false;
  transit = false;
  pins = true;

  constructor(private mapService: MapService, private pinsService: PinsService, private storage: LocalStorageService) {}

  ngOnInit(): void {
    this.traffic = this.storage.get('MAP.TrafficLayer') ?? false;
    this.transit = this.storage.get('MAP.TransitLayer') ?? true;
    this.satellite = this.storage.get('MAP.SatelliteView') ?? false;
    this.pins = this.storage.get('MAP.PinsLayer') ?? true;
  }

  toggleSatellite() {
    this.mapService.setSatelliteView(this.satellite);
  }
  toggleTraffic() {
    this.mapService.showTrafficLayer(this.traffic);
  }
  toggleTransit() {
    this.mapService.showTransitLayer(this.transit);
  }
  togglePins() {
    this.pinsService.showPinsLayer(this.pins);
  }
}
