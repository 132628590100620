import { NgModule } from '@angular/core';
import { AppRoutingModule } from '@app/app-routing.module';

import { StoreModule } from '@ngrx/store';
import * as fromSearch from './store/search.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SearchEffects } from './store/search.effects';

import { PositionModule } from '@app/modules/position/position.module';

import { SearchComponent } from './search/search.component';
import { PlaceComponent } from './place/place.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [PlaceComponent, SearchComponent],
  imports: [
    SharedModule,
    PositionModule,
    AppRoutingModule,
    StoreModule.forFeature(fromSearch.searchFeatureKey, fromSearch.reducer),
    EffectsModule.forFeature([SearchEffects]),
  ],
  exports: [SearchComponent],
})
export class SearchModule {}
