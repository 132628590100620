<div>
  <fa-icon title="Map Layers" [matMenuTriggerFor]="menu" [icon]="faLayerGroup" size="2x"></fa-icon>
  <mat-menu #menu="matMenu">
    <p class="rs-map-menu-title"><b>Choose additional layers</b></p>
    <mat-list role="list" class="mt8">
      <mat-list-item role="listitem">
        <fa-icon matListIcon [fixedWidth]="true" [icon]="faSatellite"></fa-icon>
        <span matLine>Satellite</span>
        <mat-slide-toggle [(ngModel)]="satellite" (change)="toggleSatellite()"></mat-slide-toggle>
      </mat-list-item>
      <mat-list-item role="listitem">
        <fa-icon matListIcon [fixedWidth]="true" [icon]="faTransit"></fa-icon>
        <span matLine>Public Transport</span>
        <mat-slide-toggle [(ngModel)]="transit" (change)="toggleTransit()"></mat-slide-toggle>
      </mat-list-item>
      <mat-list-item role="listitem">
        <fa-icon matListIcon [fixedWidth]="true" [icon]="faTraffic"></fa-icon>
        <span matLine>Traffic</span>
        <mat-slide-toggle [(ngModel)]="traffic" (change)="toggleTraffic()"></mat-slide-toggle>
      </mat-list-item>
      <mat-list-item role="listitem">
        <fa-icon matListIcon [fixedWidth]="true" [icon]="faMarker"></fa-icon>
        <span matLine>My Pins*</span>
        <mat-slide-toggle [(ngModel)]="pins" (change)="togglePins()"></mat-slide-toggle>
      </mat-list-item>
    </mat-list>
    <p style="text-align: center">* for registered users</p>
  </mat-menu>
</div>
