import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { selectPosition, selectPositionErrorDetails } from '../store/position.selectors';
import { getPosition } from '../store/position.actions';

@Component({
  selector: 'rs-pos-state',
  templateUrl: './pos-state.component.html',
  styleUrls: [],
})
export class PosStateComponent implements OnInit {
  faSpinner = faSpinner;

  positionError$ = this.store.select(selectPositionErrorDetails);
  position$ = this.store.select(selectPosition);

  constructor(private store: Store) {}

  ngOnInit(): void {}

  updatePosition() {
    this.store.dispatch(getPosition());
  }
}
