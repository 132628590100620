import { NgModule } from '@angular/core';

import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [ContactDialogComponent, MessageDialogComponent],
  imports: [SharedModule],
})
export class DialogsModule {}
