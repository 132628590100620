<div class="rs-panel">
  <h1 class="rs-header-1">My User Profile</h1>

  <rs-breadcrumb [breadcrumbs]="[{ routerLink: '', caption: 'user' }]"></rs-breadcrumb>

  <div>
    <p>
      <strong>My User Profile</strong>
    </p>
    <p>
      Not much here yet. There will be more soon.
      <a routerLink="/pins">Click here</a> to see your private markers.
    </p>
  </div>

  <rs-visits></rs-visits>
</div>
