import { createAction, props } from '@ngrx/store';
import { SearchResult } from '../classes/search-results';
import { Prediction } from './search.reducer';

export const clearSearch = createAction('[Search] Clear Search');

export const searchBackend = createAction('[Search] Search Backend', props<{ term: string }>());

export const searchBackendSuccess = createAction(
  '[Search] Search Backend Success',
  props<{ result: SearchResult; term: string }>()
);

export const searchBackendFailure = createAction('[Search] Search Backend Failure', props<{ error: any }>());

export const searchGoogle = createAction('[Search] Search Google', props<{ term: string }>());

export const searchGoogleSuccess = createAction('[Search] Search Google Success', props<{ result: Prediction[] }>());

export const searchGoogleFailure = createAction('[Search] Search Google Failure', props<{ error: any }>());
