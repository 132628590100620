import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DataService } from '@core/services/data.service';
import { ShopDetails } from '@shared/classes';
import { ExactSearchResult } from '@app/modules/search/classes/search-results';

@Injectable()
export class StoreResolver implements Resolve<ShopDetails | null> {
  constructor(private data: DataService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ShopDetails | null> {
    const id = parseInt(route.url[0].toString(), 10);
    return this.data.getSingle(id);
  }
}

@Injectable()
export class ExactSearchResolver implements Resolve<ExactSearchResult | null> {
  constructor(private data: DataService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExactSearchResult | null> {
    const query = route.paramMap.get('query');
    if (query) {
      return this.data.getExactSearch(query);
    } else {
      return of(null);
    }
  }
}
