import { createFeatureSelector, createSelector } from '@ngrx/store';
import { City, CityListItem, LatLng } from '@shared/classes';
import { PositionHelpers } from '@app/modules/position/helpers/position-helpers';
import { selectPosition } from '@app/modules/position/store/position.selectors';
import * as fromCity from './city.reducer';
import { selectActiveCountryCode, selectActiveStateCode } from '@app/modules/countries/store/country.selectors';
import { selectActualBounds, selectCitiesVisible } from '@app/modules/map/store/map.selectors';

export const selectCityState = createFeatureSelector<fromCity.CityState>(fromCity.cityFeatureKey);

export const selectCitiesLoading = createSelector(selectCityState, (state) => state.loadingCities);

export const selectCitiesLoaded = createSelector(selectCityState, (state) => state.loadedCities);

export const selectAllCities = createSelector(selectCityState, (state) => state.cities);

export const selectActiveCity = createSelector(selectCityState, (state) => state.activeCity);

export const selectCitiesInActiveCountry = createSelector(selectAllCities, selectActiveCountryCode, (cities, code) =>
  cities.filter((x) => x.countrycode === code).sort((a, b) => a.city.localeCompare(b.city))
);

export const selectCitiesInActiveState = createSelector(selectAllCities, selectActiveStateCode, (cities, code) =>
  cities.filter((x) => x.countrycode === 'US' && x.state === code).sort((a, b) => a.city.localeCompare(b.city))
);

export const selectActiveCityItem = createSelector(selectPosition, selectActiveCity, (pos, city) => {
  if (city) {
    const result: CityListItem = { city };
    if (pos) {
      let distance = PositionHelpers.getDistanceBetween(pos, city);
      if (distance < 800) {
        const direction = PositionHelpers.getDirection(pos, city);
        distance = PositionHelpers.posInBounds(pos, city) ? -1 : distance;
        result.distance = distance;
        result.direction = direction;
      }
    }
    return result;
  } else {
    return null;
  }
});

// Alle Städte im Umkreis von 200km
export const selectCitiesByDistance = createSelector(
  selectPosition,
  selectAllCities,
  (pos: LatLng | null, cities: City[]) => {
    const result: CityListItem[] = [];

    if (pos && cities.length > 0) {
      const lat1 = PositionHelpers.getLatFactorFor1Km(pos);
      const lng1 = PositionHelpers.getLngFactorFor1Km(pos);
      const dLat = 200.0 * lat1;
      const dLng = 200.0 * lng1;
      cities.forEach((city) => {
        if (Math.abs(city.lat - pos.lat) < dLat && Math.abs(city.lng - pos.lng) < dLng) {
          const item = {
            direction: PositionHelpers.getDirection(pos, city),
            distance: PositionHelpers.getDistanceBetween(pos, city),
            city,
          };
          result.push(item);
          if (
            city.latmin - lat1 <= pos.lat &&
            pos.lat <= city.latmax + lat1 &&
            city.lngmin - lng1 <= pos.lng &&
            pos.lng <= city.lngmax + lng1
          ) {
            item.distance = -1; // inside this city (mit 1km Rahmen um die City)
          }
        }
      });
      result.sort(
        (a, b) => (a.distance !== undefined ? a.distance : 9999) - (b.distance !== undefined ? b.distance : 9999)
      );
    }
    return result;
  }
);

// Interessante Städte im Umkreis von 200km
export const selectSelectedCitiesByDistance = createSelector(selectCitiesByDistance, (cities: CityListItem[]) =>
  cities.filter(
    (x) =>
      x.distance !== undefined &&
      (x.distance < 5.0 ||
        (x.distance < 25.0 && x.city.opencnt > 1) ||
        (x.distance < 50.0 && x.city.opencnt > 2) ||
        (x.distance < 100.0 && x.city.opencnt > 3) ||
        (x.distance < 200.0 && x.city.opencnt > 4))
  )
);

export const selectVisibleCities = createSelector(
  selectAllCities,
  selectActualBounds,
  selectCitiesVisible,
  (cities, bounds, visible) => {
    let result: CityListItem[] = [];
    if (visible && bounds) {
      let items = cities.filter((x) => PositionHelpers.posInBounds(x, bounds, 0));
      let opencnt = 1;
      while (items.length > 25) {
        items = items.filter((x) => x.opencnt > opencnt);
        opencnt++;
      }
      result = items.sort((a, b) => a.city.localeCompare(b.city)).map((city) => ({ city }));
    }
    return result;
  }
);
