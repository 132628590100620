import { Component, OnDestroy, OnInit } from '@angular/core';
import { userLoadVisits, userUnvisitStore } from '@app/modules/auth/store/auth.actions';
import { LatLngBounds, ShopBasics, ShopVisit } from '@shared/classes';
import { HelperService } from '@core/services/helper.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { selectAllVisits } from '@app/modules/auth/store/auth.selectors';
import { selectAllStores } from '@app/modules/shops/store/shop.selectors';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { tryLoadMediumStores } from '@app/modules/shops/store/shop.actions';
import { setMapBounds } from '@app/modules/map/store/map.actions';
import { MarkersService } from '@app/core/services/markers.service';
import { MatSelectChange } from '@angular/material/select';

interface TourVisit {
  visit: ShopVisit;
  shop: ShopBasics | undefined;
}
interface Tour {
  date: string;
  visits: TourVisit[];
}

@Component({
  selector: 'rs-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss'],
})
export class VisitsComponent implements OnInit, OnDestroy {
  visits$ = this.store.select(selectAllVisits);
  stores$ = this.store.select(selectAllStores);

  faTimes = faTimes;

  tours: Tour[] = [];
  selectedTour: Tour | undefined;
  visitedShops$: BehaviorSubject<ShopBasics[]> = new BehaviorSubject<ShopBasics[]>([]);

  private sub1: Subscription | undefined;
  private sub2: Subscription | undefined;

  constructor(public helper: HelperService, private markers: MarkersService, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(userLoadVisits());

    this.sub1 = this.visits$.subscribe((visits) => {
      this.tours = [];
      if (visits) {
        visits.forEach((visit) => {
          const date = HelperService.formatDate(visit.visit);
          const tour = this.tours.find((x) => x.date === date);
          const shop = undefined;
          if (tour) {
            tour.visits.push({ visit, shop });
          } else {
            this.tours.push({ date, visits: [{ visit, shop }] });
          }
        });
        this.tours = this.tours.map((tour) => ({
          ...tour,
          visits: tour.visits.reverse(),
        }));
      }
    });
  }

  ngOnDestroy() {
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
    this.markers.hidePath();
  }

  showTour(event: MatSelectChange) {
    const tour = event.value;
    if (tour) {
      this.sub2?.unsubscribe();
      this.sub2 = this.stores$.subscribe((stores) => {
        const visitedStores: ShopBasics[] = [];
        tour.visits.forEach((visit) => {
          visit.shop = stores.find((x) => x.id === visit.visit.shopid);
          if (visit.shop) {
            visitedStores.push(visit.shop);
          }
        });
        this.store.dispatch(tryLoadMediumStores({ stores: visitedStores, from: 'visits' }));
        this.visitedShops$.next(visitedStores);
        if (this.selectedTour !== tour) {
          this.selectedTour = tour;
          this.setBounds(visitedStores);
          this.markers.drawPath(visitedStores);
        }
      });
    }
  }

  hideTour() {
    this.selectedTour = undefined;
  }

  removeVisit(visit: ShopVisit) {
    this.store.dispatch(userUnvisitStore({ visit }));
  }

  private setBounds(stores: ShopBasics[]) {
    if (stores.length > 0) {
      const bounds: LatLngBounds = {
        latmin: stores[0].lat,
        latmax: stores[0].lat,
        lngmin: stores[0].lng,
        lngmax: stores[0].lng,
      };
      stores.forEach((x) => {
        bounds.latmin = Math.min(bounds.latmin, x.lat);
        bounds.latmax = Math.max(bounds.latmax, x.lat);
        bounds.lngmin = Math.min(bounds.lngmin, x.lng);
        bounds.lngmax = Math.max(bounds.lngmax, x.lng);
      });
      this.store.dispatch(setMapBounds({ bounds, zoomlevel: 'any', zoomCenter: null }));
    }
  }
}
